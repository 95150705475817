import { icons } from '../../../Assets/images/IconHelper';
import './BlogHeader.css'

const BlogHeader = ({ title, img, name, date, duration, ingredients }) => {
    return (
        <div>
            <h2 className="blogHeader-title">
                {title}
            </h2>

            <div className="blogHeader-user">

                {name && <div className="blogHeader-profile-picture">
                    <img src={img ? img : icons.profilePlaceholder}  alt="" />
                </div>}

                {name && <div className="blogHeader-text">
                    <p className="blogHeader-name">
                        {name}
                    </p>

                    <p className="blogHeader-date">
                        {date}
                    </p>
                </div>}

                {duration && <p className="blogHeader-duration">
                    {duration}
                </p>}

                {ingredients && <p className="blogHeader-ingredients">
                    {ingredients}
                </p>}




            </div>
        </div>
    );
}

export default BlogHeader;