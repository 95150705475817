import * as URL from "../../URLs";

import { axiosFn, handleError } from "../../index";

import { toast } from "react-toastify";

export async function updateAccount(recivedData) {
  const data = { user: { ...recivedData } };

  return await axiosFn("patch", URL.ACCOUNT, data)
    .then((res) => {
      return {
        res: res.data,
        err: null,
        status: res?.status,
      };
    })
    .catch(handleError);
}

export async function updateAccountAPI(data) {
  let { err, res, status } = await updateAccount(data);

  return { res, err, status };
}
