import React from 'react'
import TermsConditionsTemplate from '../../../Templates/Home/TermsConditions/TermsConditions.Template'

function TermsConditions() {
    return (
        <TermsConditionsTemplate />
    )
}

export default TermsConditions
