import { getFeaturedBannersAPI } from "../../Contexts/API/Home/FeaturedBanners/getFeaturedBanners";
import { useState } from "react";

export const useFeaturedBanners = () => {
  const [featuredBanners, setFeaturedBanners] = useState(null);
  const [areFeaturedBannersLoading, setAreFeaturedBannersLoading] =
    useState(false);

  const loadFeaturedBanners = async () => {
    setAreFeaturedBannersLoading(true);
    const { res } = await getFeaturedBannersAPI();
    if (res) {
      setFeaturedBanners(res?.featured_banners);
    }
    setAreFeaturedBannersLoading(false);
  };

  return { featuredBanners, loadFeaturedBanners, areFeaturedBannersLoading };
};
