import { useEffect, useState } from "react";

import { Grid } from "@material-ui/core";
import { withTranslation } from "react-i18next";

import { icons } from "../../Assets/images/IconHelper";

import IconOverlay from "../../Components/Atoms/IconOverlay/IconOverlay";

import ChatPanel from "../../Components/Organisms/ChatPanel/ChatPanel";
import HomeCarousel from "../../Components/Organisms/HomePage/HomeCarousel/HomeCarousel";
import HomeShopping from "../../Components/Organisms/HomePage/Shopping/HomeShopping";

import "./HomeTemplate.css";
import BestSeller from "../../Components/Organisms/HomePage/BestSeller/BestSeller";
import OurAdvantage from "../../Components/Organisms/HomePage/OurAdvantage/OurAdvantage";
import OurProcess from "../../Components/Organisms/HomePage/OurProcess/OurProcess";
import FoodSafety from "../../Components/Organisms/HomePage/FoodSafety/FoodSafety";
import CustomersSay from "../../Components/Organisms/HomePage/CustomersSay/CustomersSay";
import OurProfile from "../../Components/Organisms/HomePage/OurProfile/OurProfile";

const HomeTemplate = (props) => {
  const { t } = props;

  const [drawerOpen, setDrawerOpen] = useState(false);

  const [chatOpen, setChatOpen] = useState(false);

  const toggleChat = () => {
    setChatOpen(!chatOpen);
    if (mobileView) {
      setDrawerOpen(true);
    }
  };

  const [state, setState] = useState({
    mobileView: false,
  });
  const { mobileView } = state;
  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 600
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };

    setResponsiveness();
    window.addEventListener("resize", () => setResponsiveness());

    return () => {
      window.removeEventListener("resize", () => setResponsiveness());
    };
  }, [window.innerWidth]);

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerOpen(open);
    setChatOpen(!chatOpen);
  };

  useEffect(() => {
    setDrawerOpen(chatOpen);
  }, [mobileView]);

  return (
    <>
      <div className="home-chat">
        {chatOpen ? (
          <ChatPanel
            toggleChat={toggleChat}
            userId={props.userId}
            mobileView={mobileView}
            drawerOpen={drawerOpen}
            toggleDrawer={toggleDrawer}
            sendMessageRD={props.sendMessageRD}
            getMessagesRD={props.getMessagesRD}
            messages={props.messages}
            morePages={props.morePages}
          />
        ) : (
          <div className="home-chat-icon" onClick={toggleChat}>
            <IconOverlay src={icons.chatIcon} iconStyle="" />
          </div>
        )}
      </div>

      <Grid container>
        <div className="template-app" style={{ width: "100%" }}>
          <HomeCarousel adBanners={props.adBanners} />
          <HomeShopping />
          <BestSeller />
        </div>
        <OurAdvantage />
        <OurProcess />
        <FoodSafety />
        <CustomersSay />
        <div className="our-profile-container">
          <OurProfile />
        </div>
      </Grid>
    </>
  );
};

export default withTranslation()(HomeTemplate);
