import React, { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { getOrderDetailsAPI } from "../../../Contexts/API/Home/Orders.js/getOrderDetails";
import { systemContext } from "../../../Contexts/systemContext/systemContext";
import { useSystem } from "../../../Hooks/useSystem/useSystem";
import OrderDetailsTemplate from "../../../Templates/Home/Profile/Orders/OrderDetails.Template";

const OrderDetails = () => {
  const { systemConfig } = useContext(systemContext);
  const [orderSummary, setorderSummary] = useState({});
  const [shipAddress, setshipAddress] = useState();
  const [paymentMethod, setpaymentMethod] = useState();
  const [orderItems, setorderItems] = useState();

  const { number } = useParams();

  const getOrderDetails = async (num) => {
    let details = await getOrderDetailsAPI(num);
    setorderSummary(details.data);
    let items = [];
    details?.included?.map((el) => {
      if (el.type == "address") {
        setshipAddress(el);
      }
      if (el.type == "payment") {
        setpaymentMethod(el);
      }
      if (el.type == "line_item") {
        items.push(el);
        setorderItems(items);
      }
    });
  };

  useEffect(() => {
    getOrderDetails(number);
  }, []);

  return (
    <OrderDetailsTemplate
      deliveryAddress={shipAddress}
      paymentMethod={paymentMethod}
      orderSummary={orderSummary}
      orderItems={orderItems}
      statesNumber={systemConfig?.state_count}
    />
  );
};

export default OrderDetails;
