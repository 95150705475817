import React, { useEffect, useState } from 'react'
import ContactUsTemplate from '../../../Templates/Home/ContactUs/ContactUs.Template'

function ContactUs() {

    const [state, setState] = useState({
        mobileView: false
    })
    const { mobileView } = state;
    useEffect(() => {
        const setResponsiveness = () => {
            return window.innerWidth < 600
                ? setState((prevState) => ({ ...prevState, mobileView: true }))
                : setState((prevState) => ({ ...prevState, mobileView: false }));
        };

        setResponsiveness();
        window.addEventListener("resize", () => setResponsiveness());

        return () => {
            window.removeEventListener("resize", () => setResponsiveness());
        }
    }, [window.innerWidth]);

    return (
        <ContactUsTemplate mobileView={mobileView} />
    )
}

export default ContactUs
