// import { toast } from "react-toastify";

import * as URL from "../../URLs";

import { axiosFn, handleError } from "../../index";

export async function deleteFromCart(id) {
  return await axiosFn(
    "delete",
    `${URL.CART}/remove_line_item/${id}`,
    null,
    false
  )
    .then((res) => {
      return {
        res: res?.data,
        err: null,
        status: res?.status,
      };
    })
    .catch(handleError);
}
export async function deleteFromCartAPI(id) {
  const { err, res, status } = await deleteFromCart(id);
  return { err, res, status };
}
