import * as URL from "../../URLs";

import { axiosFn, handleError } from "../../index";

//GETS TRADE TERMS LIST NEEDED TO CREATE A REQUEST
export async function getTradeTerms() {
  return await axiosFn("get", `${URL.TRADE_TERMS}`, null, false)
    .then((res) => {
      return {
        res: res?.data,
        err: null,
        status: res?.status,
      };
    })
    .catch(handleError);
}

export async function getTradeTermsAPI() {
  let { err, res, status } = await getTradeTerms();

  return { res, err, status };
}
