import {
  Box,
  Card,
  CardActions,
  CardContent,
  Grid,
  Typography,
} from "@mui/material";

import AddressCard from "../AddressCard/AddressCard";
import IconOverlay from "../../../Atoms/IconOverlay/IconOverlay";
import PrimaryButton from "../../../Atoms/Buttons/Primary/PrimaryButton";
import ProposalBody from "../../../Molecules/ProposalBody/ProposalBody";
import ProposalButtons from "../../../Molecules/ProposalButtons/ProposalButtons";
import VendorProposalHeader from "../../../Molecules/ProposalHeader/VendorProposalHeader";
import { icons } from "../../../../Assets/images/IconHelper";
import { useHistory } from "react-router-dom";
import { withTranslation } from "react-i18next";

const VendorProposalCard = ({
  proposal,
  handleModal,
  setSelectedId,
  handleViewDetails,
  request,
  t,
}) => {
  const history = useHistory();
  console.log(t);
  return (
    <Card
      fullWidth
      style={{
        border: "1px solid #979797",
        marginTop: "24px",
        marginBottom: "24px",
      }}
    >
      <CardContent sx={{ borderBottom: "1px solid #979797" }}>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography
            gutterBottom
            variant="h5"
            component="div"
            sx={{ fontSize: "20px", fontColor: "#303030", fontWeight: "500" }}
          >
            {t("cards.Proposal Number")}
            {" #" + proposal?.id}
          </Typography>
        </Box>
      </CardContent>
      <CardContent>
        <VendorProposalHeader data={proposal} />
      </CardContent>
      <CardContent>
        <Grid container direction="row">
          <Grid item xs={4}>
            <Typography
              sx={{ color: "#303030", fontWeight: 600, fontSize: "14px" }}
            >
              Type of Services
            </Typography>
            <Typography
              sx={{ color: "#383838", fontWeight: 300, fontSize: "14px" }}
            >
              {request?.service_type_name}
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography
              sx={{ color: "#303030", fontWeight: 600, fontSize: "14px" }}
            >
              {/* {systemConfig &&
              systemConfig["System Configurations"]["Project Name"] === "esolar"
                ? "Building type"
                : "Category"} */}
              Building Type
            </Typography>
            <Typography
              sx={{ color: "#383838", fontWeight: 300, fontSize: "14px" }}
            >
              {/* {systemConfig &&
              systemConfig["System Configurations"]["Project Name"] === "esolar"
                ? productRequest?.building_type_name
                : productRequest?.category_name} */}
              {request?.building_type_name}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
      <CardContent>
        <ProposalBody data={proposal} />
      </CardContent>
      <CardContent>
        <Typography
          sx={{
            color: "#303030",
            fontWeight: "500",
            fontSize: "14px",
            mb: "10px",
          }}
        >
          Address
        </Typography>
        <Typography
          sx={{ color: "#303030", fontWeight: "600", fontSize: "16px" }}
        >
          {request?.address?.address1 + " " + request?.address?.address1}
        </Typography>
      </CardContent>

      <CardActions
        style={{ borderTop: "1px solid #979797" }}
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ display: "flex" }}>
          {/* <PrimaryButton
            size="small"
            color="primary"
            text={t("general.edit")}
            startIcon={
              localStorage.getItem("i18nextLng") !== "ar" ? (
                <IconOverlay src={icons.edit} iconStyle="icon-black" />
              ) : (
                ""
              )
            }
            endIcon={
              localStorage.getItem("i18nextLng") !== "ar" ? (
                ""
              ) : (
                <IconOverlay src={icons.edit} iconStyle="icon-black" />
              )
            }
            classBtn="borderless success btn-regular"
            click={() => history.push(`/editproposal/${proposal?.id}`)}
            style={
              localStorage.getItem("i18nextLng") !== "ar"
                ? { marginRight: "56px" }
                : {}
            }
          /> */}
          <PrimaryButton
            size="small"
            color="primary"
            text={t("general.delete")}
            startIcon={
              localStorage.getItem("i18nextLng") !== "ar" ? (
                <IconOverlay src={icons.bin} iconStyle="icon-white" />
              ) : (
                ""
              )
            }
            endIcon={
              localStorage.getItem("i18nextLng") !== "ar" ? (
                ""
              ) : (
                <IconOverlay src={icons.bin} iconStyle="icon-white" />
              )
            }
            classBtn="borderless error"
            click={() => {
              handleModal();
              setSelectedId(proposal?.id);
            }}
          />
        </Box>
        <Box style={{ display: "flex" }}>
          <PrimaryButton
            size="small"
            color="primary"
            text={t("cards.View Details")}
            classBtn=" btn-white-secondry rounded-5 btn-white"
            click={() =>
              history.push({
                pathname: `/profile/proposaldetails/${proposal?.id}`,
                state: request,
              })
            }
            endIcon={
              <IconOverlay
                src={
                  localStorage.getItem("i18nextLng") === "ar"
                    ? icons.arrowLeftIcon
                    : icons.arrowRightIcon
                }
                iconStyle={"icon-esolar"}
                style={{ height: "13px", width: "7px" }}
              />
            }
          />
        </Box>
      </CardActions>
    </Card>
  );
};

export default withTranslation()(VendorProposalCard);
