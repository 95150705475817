import React, { useEffect, useState } from 'react'
import DanKitchenTemplate from '../../../Templates/Home/DanKitchen/DanKitchen.Template'


function DanKitchen() {

    const [state, setState] = useState({
        mobileView: false
    })
    const { mobileView } = state;
    useEffect(() => {
        const setResponsiveness = () => {
            return window.innerWidth < 600
                ? setState((prevState) => ({ ...prevState, mobileView: true }))
                : setState((prevState) => ({ ...prevState, mobileView: false }));
        };

        setResponsiveness();
        window.addEventListener("resize", () => setResponsiveness());

        return () => {
            window.removeEventListener("resize", () => setResponsiveness());
        }
    }, [window.innerWidth]);

    return (
        <DanKitchenTemplate mobileView={mobileView} />
    )
}

export default DanKitchen
