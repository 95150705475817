import React, { useState } from "react";

import { SignInAPI } from "../../Contexts/API/Auth/Signin";
import SigninTemplate from "../../Templates/Auth/Signin.Template";
import { useHistory } from "react-router-dom";
import { withTranslation } from "react-i18next";

const Signin = (props) => {
  const { t } = props;
  const [error, seterror] = useState({});
  const history = useHistory();
  const login = async (data) => {
    if (data?.user?.password?.length < 8) {
      seterror({
        field: "password",
        message: t("form.passwordlessthaneight"),
      });
    } else {
      let { res } = await SignInAPI(data);
      if (res) {
        history.push("/");
      }
    }
  };
  return (
    <SigninTemplate
      login={login}
      error={error}
      {...props}
      systemConfig={props.systemConfig}
    />
  );
};

export default withTranslation()(Signin);
