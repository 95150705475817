import * as URL from "../../URLs";

import { axiosFn, handleError } from "../../index";

// import { toast } from "react-toastify";

export async function rejectServiceProposal(requestID, quotationID) {
  return await axiosFn(
    "delete",
    `${URL.REQUESTS}/${requestID}/proposals/${quotationID}`
  )
    .then((res) => {
      return {
        res: res?.data,
        err: null,
        status: res?.status,
      };
    })
    .catch(handleError);
}
export async function rejectServiceProposalAPI(requestID, quotationID) {
  let { err, res, status } = await rejectServiceProposal(
    requestID,
    quotationID
  );

  return { res, err, status };
}
