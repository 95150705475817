import * as URL from "../../URLs";

import { axiosFn, handleError } from "../../index";

export async function getWishlist() {
  return await axiosFn(
    "get",
    `${URL.DEFAULT_WISHLIST}/default?include=wished_products.variant`
  )
    .then((res) => {
      return {
        res: res.data,
        err: null,
        status: res?.status,
      };
    })
    .catch(handleError);
}
export async function getWishlistAPI() {
  let { err, res, status } = await getWishlist();

  return { res, err, status };
}
