import { axiosFn, handleError } from "../../index";
import * as URL from "../../URLs";

export async function editAddress(id, recivedData) {
  const data = { ...recivedData };
  return await axiosFn("patch", `${URL.PROFILE_ADDRESSES}/${id}`, data)
    .then((res) => {
      return {
        res: res?.data,
        err: null,
      };
    })
    .catch(handleError);
}
export async function editAddressAPI(id, data) {
  let { err, res } = await editAddress(id, data);

  return { err, res };
}
