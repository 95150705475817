import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./HomeCarousel.css";

import { Carousel } from "react-responsive-carousel";
import CarouselItem from "../../../Molecules/CarouselItem/CarouselItem";
import { images } from "../../../../Assets/images/IconHelper";
import { useHistory } from "react-router-dom";
import { withTranslation } from "react-i18next";

const HomeCarousel = ({ t, featuredBanners }) => {
  const history = useHistory();
  return (
    <Carousel
      className="flip-container"
      showArrows={false}
      showThumbs={false}
      showStatus={false}
      infiniteLoop={true}
      // autoPlay={true}
      // interval={6000}  
    >
      {featuredBanners?.map((item) => (
        // <CarouselItem
        //   img={item?.image}
        //   title={item?.title}
        //   text={item?.description}
        //   btnText={"Contact Us"}
        //   click={() => history.push("/aboutus")}
        // />
        <CarouselItem
          img={item.image ? item.image : images.featuredBannerPlaceholder}
          title={item.title}
          text={item.description}
          // btnText={"Contact Us"}
          // click={() => history.push("/contactus")}
        />
      ))}
    </Carousel>
  );
};

export default withTranslation()(HomeCarousel);
