import * as URL from "../../URLs";

import { axiosFn, handleError } from "../../index";

// import { toast } from "react-toastify";

export async function cancelServiceRequest(requestID) {
  return await axiosFn("patch", `${URL.REQUESTS}/${requestID}/cancel`)
    .then((res) => {
      return {
        res: res?.data,
        err: null,
        status: res?.status,
      };
    })
    .catch(handleError);
}
export async function cancelServiceRequestAPI(requestID) {
  let { err, res, status } = await cancelServiceRequest(requestID);

  return { res, err, status };
}
