import { axiosFn, handleError } from "../../index";
import * as URL from "../../URLs";

export async function getProductDetails(id) {
  return await axiosFn("get", `${URL.PRODUCT}/${id}`, undefined, false)
    .then((res) => {
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}
export async function getProductDetailsAPI(id) {
  let { err, res } = await getProductDetails(id);

  if (res) {
    return res;
  }

  if (err) {
    return err;
  }
}
