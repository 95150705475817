import EditRequestTemplate from "../../../Templates/Home/EditRequest/EditRequest.template";
import { queryParamsFormatter } from "../../../utils/queryParamsFormatter/queryParamsFormatter";
import { useCategories } from "../../../Hooks/useCategories/useCategories";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useRequests } from "../../../Hooks/useRequests/useRequests";
import { useServiceRequests } from "../../../Hooks/useServiceRequests/useServiceRequests";
import { useState } from "react";

const EsolarEditRequestPage = () => {
  //STATES AND HOOKS
  const { id } = useParams();
  const history = useHistory();
  const {
    loadBuildingTypes,
    loadServiceTypes,
    loadServiceRequest,
    areBuildingTypesLoading,
    areServiceTypesLoading,
    buildingTypes,
    serviceTypes,
    createRequest,
    isSubmitRequestLoading,
    serviceRequest,
    updateServiceRequest,
  } = useServiceRequests();

  useEffect(() => {
    loadBuildingTypes();
    loadServiceTypes();
    loadServiceRequest(id);
  }, []);

  const handleSubmitRequest = async (id, body) => {
    await updateServiceRequest(id, body);
    history.push(`/profile/requests/${id}`);
  };

  return (
    <EditRequestTemplate
      loading={areBuildingTypesLoading & areServiceTypesLoading}
      buildingTypes={buildingTypes}
      serviceTypes={serviceTypes}
      handleSubmitRequest={handleSubmitRequest}
      isSubmitRequestLoading={isSubmitRequestLoading}
      isRequestSubmitted={isSubmitRequestLoading}
      serviceRequest={serviceRequest}
      id={serviceRequest?.id}
    />
  );
};

export default EsolarEditRequestPage;
