import * as URL from "../../URLs";

import { axiosFn, handleError } from "../../index";

export async function getProposal(id) {
  return await axiosFn("get", `${URL.PROPOSALS}/${id}`, null, false)
    .then((res) => {
      return {
        res: res?.data,
        err: null,
        status: res?.status,
      };
    })
    .catch(handleError);
}

export async function getProposalAPI(id) {
  let { err, res, status } = await getProposal(id);

  return { res, err, status };
}
