import { Grid, Typography } from "@mui/material";

import { Box } from "@mui/system";
import UploadedFile from "../UploadedFile/UploadedFile";

//data prop represents proposal Data
const ProposalBody = ({ data }) => {
  const arrivalDate = new Date(data?.datetime);
  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{ mb: "14px" }}
      >
        <Grid item xs={12}>
          <Typography
            sx={{ fontSize: "14px", fontWeight: 600, color: "#303030" }}
          >
            Job Details
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            sx={{ fontSize: "14px", fontWeight: "600", color: "#383838" }}
          >
            {data?.description ? data?.description : data?.details}
          </Typography>
        </Grid>
      </Grid>
      <Box sx={{ display: "flex", mt: "16px" }}>
        {data?.documents?.map((document) => {
          return (
            <UploadedFile
              fileType={document?.extension}
              fileName={document?.url}
              src={document?.url}
            />
          );
        })}
      </Box>
      <Grid item xs={12} sx={{ marginTop: "30px" }}>
        <Typography
          sx={{ fontSize: "14px", fontWeight: 500, color: "#303030" }}
        >
          Date and Time
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography
          sx={{ fontSize: "16px", color: "#303030", fontWeight: 600 }}
        >
          {arrivalDate.toUTCString()}
        </Typography>
      </Grid>
      <Box sx={{ marginTop: 2 }}>
        <Typography sx={{ fontSize: 16, color: "#303030", fontWeight: 600 }}>
          Estimated Price
        </Typography>
        <Typography sx={{ fontSize: 16, color: "#303030", fontWeight: 600 }}>
          {data?.currency ? data?.currency + " " : data?.currency_type + " "}{" "}
          {data?.total_price ? data?.total_price : data?.estimated_total_price}
        </Typography>
      </Box>
    </>
  );
};

export default ProposalBody;
