import SubmitRFQTemplate from "../../../Templates/Home/SubmitRFQTemplate/SubmitRFQTemplate";
import { createRequestAPI } from "../../../Contexts/API/Home/Requests/createRequest";
import { queryParamsFormatter } from "../../../utils/queryParamsFormatter/queryParamsFormatter";
import { toast } from "react-toastify";
import { useCategories } from "../../../Hooks/useCategories/useCategories";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useRequests } from "../../../Hooks/useRequests/useRequests";
import { useState } from "react";

const SubmitRFQPage = () => {
  //STATES AND HOOKS
  const history = useHistory();
  const [isRequestSubmitted, setIsRequestSubmitted] = useState(false);
  const [queryParams, setQueryParams] = useState({ parent_id: 1 });
  const { loadCategoriesList, categories, areCategoriesLoading } =
    useCategories();
  const subCategories = useCategories(queryParamsFormatter(queryParams));
  const {
    tradeTerms,
    areTradeTermsLoading,
    loadTradeTerms,
    loadUnits,
    units,
    areUnitsLoading,
  } = useRequests();

  //EFFECTS
  useEffect(() => {
    if (!localStorage.getItem("TOKEN")) {
      history.push("/login");
    }
  }, []);

  useEffect(() => {
    loadCategoriesList();
    loadTradeTerms();
    loadUnits();
  }, []);

  useEffect(() => {
    subCategories.loadCategoriesList();
  }, [queryParams]);

  //HANDLERS
  const handleSubmitQuotationRequest = async (quotationRequest) => {
    setIsRequestSubmitted(true);
    const { status, err } = await createRequestAPI(quotationRequest);
    if (status === 200) {
      toast.success("Your request has been submitted successfully");
      history.push("/profile/my_requests");
    } else {
      toast.error(err[0]);
    }
    setIsRequestSubmitted(false);
  };

  return (
    <SubmitRFQTemplate
      categories={categories}
      loading={
        areCategoriesLoading &&
        subCategories.areCategoriesLoading &&
        areTradeTermsLoading &&
        areUnitsLoading
      }
      subCategories={subCategories.categories}
      setQueryParams={setQueryParams}
      tradeTerms={tradeTerms}
      units={units}
      handleSubmitQuotationRequest={handleSubmitQuotationRequest}
      isRequestSubmitted={isRequestSubmitted}
    />
  );
};

export default SubmitRFQPage;
