import * as URL from "../../URLs";

import { axiosFn, handleError } from "../../index";

export async function UpdateQuantity(id, count) {
  const data = {
    line_item_id: id,
    quantity: count,
  };
  return await axiosFn("patch", `${URL.QUANTITY}`, data, false)
    .then((res) => {
      return {
        res: res?.data,
        err: null,
        status: res?.status,
      };
    })
    .catch(handleError);
}
export async function UpdateQuantityAPI(id, count) {
  let { err, res, status } = await UpdateQuantity(id, count);

  return { res, err, status };
}
