import { axiosFn, handleError } from "../../index";
import * as URL from "../../URLs";

export async function ListVendorTypes() {
  return await axiosFn("get", URL.VENDORTYPES)
    .then((res) => {
      console.log(res);
      return {
        res: res?.data,
        err: null,
      };
    })
    .catch(handleError);
}
export async function ListVendorTypesAPI() {
  let { err, res } = await ListVendorTypes();

  return { res, err };
}
