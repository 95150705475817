import { getCategoriesListAPI } from "../../Contexts/API/Home/Categories/getCategoriesList";
import { getOptionValuesAPI } from "../../Contexts/API/Home/Categories/getOptionValues";
import { getTagsAPI } from "../../Contexts/API/Home/Categories/getTags";
import { ListAllCollectionsByTagAPI } from "../../Contexts/API/Home/Collections/ListAllCollectionsByTag";
import { getTaxonsListAPI } from "../../Contexts/API/Home/Categories/getTaxonsList";
import { useState } from "react";

export const useCategories = (queryParams) => {
  const [categories, setCategories] = useState(null);
  const [areCategoriesLoading, setAreCategoriesLoading] = useState(false);
  const [options, setOptions] = useState(null);
  const [areOptionsLoading, setAreOptionsLoading] = useState(false);
  const [areCollectionsLoading, setAreCollectionsLoading] = useState(false);
  const [collections, setCollections] = useState([]);
  const [Products, setProducts] = useState([]);
  const [NoOfCollections, setNoOfCollections] = useState(null);
  const [NoOfProducts, setNoOfProducts] = useState(null);
  const [sub_categories, setSub_categories] = useState([]);
  const [tags, setTags] = useState([]);
  const [hasMore, setHasMore] = useState(true);

  const loadCategories = async () => {
    setAreCategoriesLoading(true);
    const { res } = await getCategoriesListAPI(queryParams);
    if (res) {
      setCategories(res?.data);
    }
    setAreCategoriesLoading(false);
  };

  const loadOptionTypes = async (
    taxonId,
    queryParam,
    page,
    setPage,
    FullLoading
  ) => {
    if (page == 1) {
      setCollections([]);
      setAreCollectionsLoading(true);
      setOptions([]);
    }
    setHasMore(true);
    setProducts([]);
    setNoOfCollections(null);
    setNoOfProducts(null);
    setSub_categories([]);
    if (FullLoading) setAreOptionsLoading(true);
    const { res } = await getOptionValuesAPI(
      taxonId,
      queryParam,
      page,
      setPage
    );
    if (res) {
      if (setPage) setPage(page + 1);
      if (page == 1) {
        setCollections(res?.collections);
      } else {
        setCollections([...collections, ...res?.collections]);
      }
      setOptions(res?.option_types);
      setProducts(res?.products);
      setNoOfCollections(res?.collections_count);
      setNoOfProducts(res.products_count);
      setSub_categories(res?.sub_categories);
      setHasMore(page < res?.total_pages);
    }
    setAreOptionsLoading(false);
    setAreCollectionsLoading(false);
  };

  const loadTags = async () => {
    const { res } = await getTagsAPI();
    if (res) {
      setTags(res?.tags);
    }
  };

  const loadCollectionsByTag = async (tag) => {
    setHasMore(false);
    setCollections([]);
    setNoOfCollections(null);
    setAreOptionsLoading(true);
    let result = await ListAllCollectionsByTagAPI(tag);
    if (result) {
      setCollections(result?.collections);
      setNoOfCollections(result?.collections_count);
      setAreOptionsLoading(false);
    } else {
      setCollections([]);
      setAreOptionsLoading(false);
    }
  };

  //Loads list of categories without any extra information
  const loadCategoriesList = async () => {
    setAreCategoriesLoading(true);
    const { res } = await getTaxonsListAPI(queryParams);
    if (res) {
      setCategories(res?.taxons);
    }
    setAreCategoriesLoading(false);
  };

  return {
    loadCategories,
    loadOptionTypes,
    loadTags,
    options,
    areOptionsLoading,
    categories,
    areCategoriesLoading,
    collections,
    sub_categories,
    tags,
    NoOfCollections,
    loadCollectionsByTag,
    loadCategoriesList,
    Products,
    NoOfProducts,
    setProducts,
    hasMore,
    areCollectionsLoading,
  };
};
