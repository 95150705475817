import "./MyAccount.css";

import { CircularProgress, Grid } from "@material-ui/core";
import { icons, images } from "../../../../Assets/images/IconHelper";

import ChangePasswordModal from "../../../../Components/Organisms/Modals/ChangePassword/ChangePasswordModal";
import { Image } from "../../../../Components/Atoms";
import PhoneInput from "react-phone-input-2";
import PrimaryButton from "../../../../Components/Atoms/Buttons/Primary/PrimaryButton";
import React from "react";
import TextFieldInput from "../../../../Components/Atoms/Inputs/TextField/TextFieldInput";
import Title from "../../../../Components/Atoms/Title/Title";
import { getBase64 } from "../../../../utils/changeToBase64/changeToBase64";
import { uploadToS3API } from "../../../../Contexts/API/S3/uploadToS3";
import { useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";
import { withTranslation } from "react-i18next";

// import { updatePassword } from "../../../../Contexts/API/Home/MyAccount/updatePassword";

function MyAccountTemplate({
  t,
  profileData,
  updateAccount,
  open,
  handleClose,
  updatePassword,
  isProfileLoading,
}) {
  const [email, setEmail] = useState("");

  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [profilePicture, setProfilePicture] = useState(images.profileRectangle);
  const inputFile = useRef(null);

  useEffect(() => {
    setEmail(profileData?.attributes?.email);
    setName(profileData?.attributes?.name);
    setPhone(profileData?.attributes?.phone_number);
    if (profileData?.attributes?.image) {
      setProfilePicture(profileData?.attributes?.image);
    }
  }, [profileData]);

  const handleSave = () => {
    const data = {
      name,
      phone_number: phone,
      email,
      image: profilePicture,
    };
    updateAccount(data);
  };

  const onImageChange = async (event) => {
    if (event.target.files && event.target.files[0]) {
      let img = event.target.files[0];
      const { res } = await uploadToS3API(img);
      setProfilePicture(res);
    }
  };

  return (
    <>
      <Title text={t("Profile.MyAccount")} />
      {isProfileLoading ? (
        <div className="progress-indicator">
          <CircularProgress style={{ color: "var(--secondary-color)" }} />
        </div>
      ) : (
        <>
          <Grid>
            <Image
              src={profilePicture}
              style={{
                width: "96px",
                height: "96px",
                marginTop: "6px",
                position: "relative",
              }}
            >
              <input
                type="file"
                id="file"
                ref={inputFile}
                style={{ display: "none" }}
                onChange={(event) => {
                  onImageChange(event);
                }}
              />
              <img
                onClick={() => inputFile.current.click()}
                src={icons.profilePictureIcon}
                style={{
                  width: "21px",
                  height: "21px",
                  objectFit: "contain",
                  position: "absolute",
                  bottom: "1%",
                  cursor: "pointer",
                }}
                alt="img"
              ></img>
            </Image>
          </Grid>

          <div style={{ marginTop: "45px" }}>
            <h3 className="subtitle">{t("Profile.General Information")}</h3>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <label className="label">{t("loginBtn.Name")}</label>
                <TextFieldInput
                  placeholder=""
                  value={name}
                  onchange={(n) => setName(n)}
                />
              </Grid>
              <Grid item xs={12} md={6} className="myaccount-phone">
                <label className="label">
                  {t("forgetPasswordScreen.phoneLabel")}
                </label>
                <PhoneInput
                  country={"sa"}
                  value={phone}
                  placeholder={t("forgetPasswordScreen.phoneLabel")}
                  onChange={(p) => setPhone(p)}
                />
              </Grid>
              <Grid item xs={12} style={{ marginTop: "7px", flexBasis: "0%" }}>
                <PrimaryButton
                  text={t("forgetPasswordScreen.save")}
                  classBtn="primary profile"
                  click={handleSave}
                />
              </Grid>
            </Grid>
            <img
              src={images.line}
              width="100%"
              className="horizontal_line"
              alt="line"
            />
          </div>
          <div>
            <h3 className="subtitle">{t("Profile.Security")}</h3>
            <Grid container spacing={2}>
              <Grid item xs={12} style={{ marginTop: "7px", flexBasis: "0%" }}>
                <PrimaryButton
                  text={t("forgetPasswordScreen.changePassword")}
                  classBtn="primary profile"
                  click={handleClose}
                />
              </Grid>
              {/* <Grid item xs={12} md={6}>
            <label className="label">{t("loginBtn.Email")}</label>
            <TextFieldInput
              value={email}
              onchange={(e) => setEmail(e)}
              placeholder=""
            />
          </Grid> */}
              {/* <Grid item xs={12} md={6}>
            <label className="label">{t("loginBtn.Password")}</label>
            <TextFieldInput
              value="*********"
              placeholder="********"
              disabled={true}
            />
          </Grid> */}
            </Grid>
            <ChangePasswordModal
              updatePassword={updatePassword}
              open={open}
              email={email}
              handleClose={handleClose}
            />
          </div>
        </>
      )}
    </>
  );
}

export default withTranslation()(MyAccountTemplate);
