import { CircularProgress, Grid, Typography } from "@material-ui/core";
import { icons, images } from "../../../../Assets/images/IconHelper";

import { Carousel } from "react-responsive-carousel";
import EtradelingCategoryCard from "../../../Molecules/EtradelingCategoryCard/EtradelingCategoryCard";
import IconOverlay from "../../../Atoms/IconOverlay/IconOverlay";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";

const EtradelingCategoriesCarousel = ({ categories }) => {
  const [state, setState] = useState(null);
  const [configurableProps, setConfigurableProps] = useState({
    showArrows: false,
    showStatus: false,
    showIndicators: false,
    infiniteLoop: true,
    showThumbs: true,
    useKeyboardArrows: true,
    stopOnHover: true,
    swipeable: false,
    dynamicHeight: false,
    emulateTouch: false,
    autoFocus: false,
    thumbWidth: 100,
    selectedItem: 0,
    interval: 2000,
    transitionTime: 500,
    swipeScrollTolerance: 5,
    ariaLabel: "ariaLabel",
  });
  const pages = [];
  const getPages = (array) => {
    let page = [];
    for (var j = 0; j < array?.length; j++) {
      page.push(array[j]);
      if ((j + 1) % 8 === 0 && j !== 0) {
        pages.push(page);
        page = [];
      } else if (j === array.length - 1) {
        pages.push(page);
        page = [];
      }
    }
    return pages;
  };
  useEffect(() => {
    setState(getPages(categories));
    setConfigurableProps({ ...configurableProps, selectedItem: 0 });
  }, [categories]);
  return (
    <Grid container paddingLeft={10} paddingRight={10}>
      <Grid item xs={1} container justifyContent="center">
        <div
          style={{ cursor: "pointer", paddingTop: 170 }}
          onClick={() => {
            setConfigurableProps({
              ...configurableProps,
              selectedItem:
                configurableProps.selectedItem > 0
                  ? configurableProps.selectedItem - 1
                  : configurableProps.selectedItem,
            });
          }}
          className={""}
        >
          <IconOverlay src={icons.CarouselLeftArrow} />
        </div>
      </Grid>
      <Grid item xs={10}>
        {" "}
        <Carousel centerMode centerSlidePercentage={100} {...configurableProps}>
          {1 === 0 ? (
            <div className="progress-indicator">
              <CircularProgress style={{ color: "var(--secondary-color)" }} />
            </div>
          ) : (
            state?.map((page) => {
              return (
                <Grid
                  container
                  direction="row"
                  spacing={1}
                  justifyContent="center"
                >
                  {page.map((category) => {
                    return (
                      <>
                        <Grid item xs={3}>
                          <Link to={`/shopping?taxon=${category.id}`}>
                            <EtradelingCategoryCard
                              name={category.attributes.name}
                              image={
                                category.attributes.image
                                  ? category.attributes.image
                                  : images.categoriesPlaceholder
                              }
                            />
                          </Link>
                        </Grid>

                        {/* <EsolarAdbannerCard
                          title={banner.title}
                          description={banner.description}
                          image={banner.image}
                        /> */}
                      </>
                    );
                  })}
                </Grid>
              );
            })
          )}
        </Carousel>
      </Grid>
      <Grid item xs={1} container justifyContent="center">
        <div
          style={{ cursor: "pointer", paddingTop: 170 }}
          onClick={() => {
            setConfigurableProps({
              ...configurableProps,
              selectedItem:
                configurableProps.selectedItem < state.length - 1
                  ? configurableProps.selectedItem + 1
                  : configurableProps.selectedItem,
            });
          }}
          className={""}
        >
          <IconOverlay src={icons.CarouselRightArrow} />
        </div>
      </Grid>
    </Grid>
  );
};

export default EtradelingCategoriesCarousel;
