import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  MenuItem,
  Select,
} from "@material-ui/core";
import React, { useContext } from "react";
import "../ModalStyle.css";
import "./NewBoxModal.css";
import { withTranslation } from "react-i18next";
import TextFieldInput from "../../../Atoms/Inputs/TextField/TextFieldInput";
import PrimaryButton from "../../../Atoms/Buttons/Primary/PrimaryButton";
import { useState } from "react";
import { BoxesContext } from "../../../../Contexts/boxesContext/boxesContext";
import { createBoxAPI } from "../../../../Contexts/API/Home/Boxes/createBox";

function NewBoxModal(props) {
  const { t, i18n } = props;
  const [state, setState] = useState({
    name: "",
    quantity: 4,
  });

  // modalState = modalOpen, setModalOpen, selectedProduct, setSelectedProduct, boxes, setBoxes, handleCloseModal
  const { modalState } = useContext(BoxesContext);
  const { boxesDetector, setBoxesDetector } = modalState;
  const handleSubmit = () => {
    const newBox = createNewBox();
    if (props.type === "add") {
      props.addToBox(newBox);
      modalState.handleCloseModal();
    }
    props.handleClose();
  };

  const createNewBox = async () => {
    // const newBox = {
    //   id: modalState.boxes.length + 1,
    //   name: state.name,
    //   quantity: state.quantity,
    // };
    // modalState.setBoxes([...modalState.boxes, newBox]);
    // return newBox.id;
    const res = await createBoxAPI({
      name: state.name,
      description: "Default description",
    });
    setBoxesDetector(boxesDetector + 1);
  };

  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      className={i18n.language === "ar" ? "rtl_modal" : ""}
    >
      <DialogTitle className="newboxmodal-header">
        <p className="Modal_header">{t("myBoxes.create new box")}</p>
      </DialogTitle>
      <DialogContent className="newboxmodal-content">
        <Grid container className="modal_form">
          <Grid item xs={12}>
            <label>{t("myBoxes.box name")}</label>
            <TextFieldInput
              className="form_input"
              placeholder={t("myBoxes.name")}
              value={state.name}
              onchange={(name) => setState({ ...state, name })}
            />
          </Grid>
          <Grid item xs={12}>
            <label>{t("myBoxes.quantity")}</label>
            {/* <TextFieldInput className="form_input" placeholder={"MM/YY"} error={false} onchange={(expDate) => setState({ ...state, expDate })} /> */}
            <div className="newboxmodal-dropdownCont">
              <FormControl variant="outlined">
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={state.quantity}
                  onChange={(e) =>
                    setState({ ...state, quantity: e.target.value })
                  }
                >
                  <MenuItem value={4}>4 Kg</MenuItem>
                  <MenuItem value={6}>6 Kg</MenuItem>
                  <MenuItem value={8}>8 KG</MenuItem>
                  <MenuItem value={10}>10 Kg</MenuItem>
                </Select>
              </FormControl>
            </div>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <div className="newboxmodal-buttonCont">
          <PrimaryButton
            classBtn="primary modal"
            text={t("myBoxes.create")}
            click={handleSubmit}
          />
        </div>
      </DialogActions>
    </Dialog>
  );
}

export default withTranslation()(NewBoxModal);
