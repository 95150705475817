import * as React from "react";
import { useState } from "react";

import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { Link } from "react-router-dom";

import PrimaryButton from "../../../../Components/Atoms/Buttons/Primary/PrimaryButton";
import { icons } from "../../../../Assets/images/IconHelper";
import IconOverlay from "../../../../Components/Atoms/IconOverlay/IconOverlay";
import AccordionDots from "../../../../Components/Molecules/AccordionDots/AccordionDots";
import UploadedFile from "../../../../Components/Molecules/UploadedFile/UploadedFile";

const EtradelingProposalPhase3 = ({
  proposal,
  handleChange,
  handleSubmit,
  RequestID,
  additionalFiles,
}) => {
  return (
    <Box
      component="form"
      onSubmit={console.log("test")}
      sx={{ mt: 2, width: "100%" }}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Typography
          variant="h2"
          sx={{
            textAlign: "left",
            fontSize: "21px",
            fontWeight: "900",
          }}
        >
          Preview proposal
        </Typography>
        <PrimaryButton
          startIcon={<IconOverlay src={icons.bin} iconStyle="icon-primary" />}
          classBtn="borderless edit"
          text={"Edit"}
          click={() => handleChange(1)}
        />
      </Box>
      <Box sx={{ width: "100%", textAlign: "start" }}>
        <Typography
          variant="h4"
          sx={{
            textAlign: "left",
            fontSize: "14px",
            fontWeight: "600",
            marginTop: "26px",
            color: "#303030",
          }}
        >
          Job details
        </Typography>
        <Typography
          variant="p"
          sx={{
            textAlign: "left",
            fontSize: "14px",
            color: "#303030",
            fontWeight: "400",
            wordBreak: "break-all",
          }}
        >
          {proposal?.details}
        </Typography>

        {additionalFiles?.length > 0 ? (
          <Box style={{ display: "flex", padding: "10px" }}>
            {additionalFiles.map((file, index) => {
              return (
                <a href={file.file_url} target="_blank">
                  <UploadedFile
                    fileType={file.extension}
                    fileName={file.file_name}
                    id={index}
                    files={additionalFiles}
                    backendId={file.id}
                  />
                </a>
              );
            })}
          </Box>
        ) : (
          ""
        )}

        <Typography
          variant="h4"
          sx={{
            textAlign: "left",
            fontSize: "14px",
            marginTop: "26px",
            color: "#303030",
            fontWeight: "600",
          }}
        >
          Date and time
        </Typography>
        <Typography
          variant="p"
          sx={{
            textAlign: "left",
            fontSize: "16px",
            color: "#303030",
            fontWeight: "400",
          }}
        >
          {proposal?.date}
        </Typography>
        <Typography
          variant="h4"
          sx={{
            textAlign: "left",
            fontSize: "14px",
            fontWeight: "600",
            marginTop: "26px",
            color: "#303030",
          }}
        >
          Estimated price
        </Typography>
        <Typography
          variant="p"
          sx={{
            textAlign: "left",
            fontSize: "14px",
            color: "#303030",
          }}
        >
          {proposal?.estimated_total_price}
        </Typography>
      </Box>

      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          marginTop: "30px",
        }}
      >
        <PrimaryButton
          text={"Submit proposal"}
          classBtn="primary proposal"
          style={{
            maxWidth: "320px",
          }}
          click={() =>
            handleSubmit({
              quotation: {
                product_request_id: parseInt(RequestID),
                due_date: proposal?.date,
                description: proposal?.details,
                total_price: proposal?.estimated_total_price,
                currency_type_id: proposal?.currency_type_id,
                documents_attributes: additionalFiles?.map((item) => ({
                  url: item?.file_url,
                })),
              },
            })
          }
        />
      </Box>
    </Box>
  );
};
export default EtradelingProposalPhase3;
