import { acceptProposalAPI } from "../../Contexts/API/Home/Requests/acceptProposal";
import { deleteRequestAPI } from "../../Contexts/API/Home/Requests/deleteRequest";
import { getProductRequestAPI } from "../../Contexts/API/Home/Requests/getProductRequest";
import { getProductRequestsAPI } from "../../Contexts/API/Home/Requests/getProductRequests";
import { getTradeTermsAPI } from "../../Contexts/API/Home/Requests/getTradeTerms";
import { getUnitsAPI } from "../../Contexts/API/Home/Requests/getUnits";
import { rejectProposalAPI } from "../../Contexts/API/Home/Requests/rejectProposal";
import { toast } from "react-toastify";
import { updateRequestAPI } from "../../Contexts/API/Home/Requests/updateRequest";
import { useState } from "react";

//PRODUCT REQUESTS (Etradeling)

export const useRequests = () => {
  const [hasMore, setHasMore] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [productRequest, setProductRequest] = useState(null);
  const [isProductRequestLoading, setIsProductRequestLoading] = useState(false);
  const [productRequests, setProductRequests] = useState([]);
  const [productRequestsLoading, setProductRequestsLoading] = useState(null);
  const [tradeTerms, setTradeTerms] = useState(null);
  const [areTradeTermsLoading, setAreTradeTersmLoading] = useState(null);
  const [units, setUnits] = useState(null);
  const [areUnitsLoading, setAreUnitsLoading] = useState(false);

  const loadProductRequest = async (id) => {
    setIsProductRequestLoading(true);
    const { res } = await getProductRequestAPI(id);
    if (res) {
      setProductRequest(res?.request);
    }
    setIsProductRequestLoading(false);
  };
  //E-tradeling
  const loadProductRequests = async (queryParams) => {
    setProductRequestsLoading(true);
    const { res } = await getProductRequestsAPI(queryParams);
    if (res) {
      setProductRequests(res?.requests);

      if (currentPage < res?.total_pages) {
        setHasMore(true);
        setCurrentPage(currentPage + 1);
        console.log(currentPage);
      } else {
        setHasMore(false);
      }
    }
    setProductRequestsLoading(false);
  };

  const loadMoreProductRequests = async (queryParams) => {
    setProductRequestsLoading(true);
    const { res } = await getProductRequestsAPI(queryParams);
    if (res) {
      setProductRequests([...productRequests, ...res?.requests]);

      if (currentPage < res?.total_pages) {
        setHasMore(true);
        setCurrentPage(currentPage + 1);
      } else {
        setHasMore(false);
      }
    }
    setProductRequestsLoading(false);
  };

  const loadUnits = async () => {
    setAreUnitsLoading(true);
    const { res } = await getUnitsAPI();
    if (res) {
      setUnits(res?.units);
    }
    setAreUnitsLoading(false);
  };

  const loadTradeTerms = async () => {
    setAreTradeTersmLoading(true);
    const { res } = await getTradeTermsAPI();
    if (res) {
      setTradeTerms(res?.trade_terms);
    }
    setAreTradeTersmLoading(false);
  };

  const deleteRequest = async (id) => {
    const { status } = await deleteRequestAPI(id);

    if (status === 200) {
      toast.success("Request Deleted Successfully");
    } else {
      toast.error("An error occured please try again");
    }
  };

  const updateRequest = async (id, body) => {
    const { status } = await updateRequestAPI(id, body);
    if (status === 200) {
      toast.success("Request Updated Successfully");
    } else {
      toast.error("An error occured please try again");
    }
  };

  const acceptQuotationProposal = (requestID, quotationID) => {
    acceptProposalAPI(requestID, quotationID);
  };

  const rejectQuotationProposal = (requestID, quotationID) => {
    rejectProposalAPI(requestID, quotationID);
  };

  return {
    tradeTerms,
    areTradeTermsLoading,
    loadTradeTerms,
    loadUnits,
    units,
    areUnitsLoading,
    loadProductRequests,
    productRequests,
    productRequestsLoading,
    loadProductRequest,
    productRequest,
    isProductRequestLoading,
    deleteRequest,
    acceptQuotationProposal,
    rejectQuotationProposal,
    hasMore,
    currentPage,
    setCurrentPage,
    loadMoreProductRequests,
    updateRequest,
  };
};
