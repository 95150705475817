import React, { useEffect } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { BecomeVendorAPI } from "../../../Contexts/API/Home/Vendor/BecomeVendor";
import { ListVendorTypesAPI } from "../../../Contexts/API/Home/Vendor/VendorTypes";
import { useVendor } from "../../../Hooks/useVendor/useVendor";
import BecomeVendorTemplate from "../../../Templates/Home/BecomeVendor/BecomeVendor.Template";

function BecomeVendor() {
  const { vendorTypes, loadVendorTypes } = useVendor();
  useEffect(() => {
    loadVendorTypes();
  }, []);
  console.log(vendorTypes);
  const handleSubmit = async (vendorData) => {
    const { res, err } = await BecomeVendorAPI(vendorData);
    if (res) {
      window.location = "/become_vendor/success";
    }
    if (err) {
      toast.error(err);
    }
  };
  return (
    <BecomeVendorTemplate
      handleSubmit={handleSubmit}
      vendorTypes={vendorTypes}
    />
  );
}

export default BecomeVendor;
