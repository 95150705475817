import { useState } from "react";

import { getSystemAPI } from "../../Contexts/API/Home/system/getSystem";

export const useSystem = () => {
  const [systemConfig, setSystemConfig] = useState();
  const [isSystemConfigLoading, setIsSystemConfigLoading] = useState(false);

  const loadSystemConfig = async () => {
    setIsSystemConfigLoading(true);
    let result = await getSystemAPI();
    if (result) {
      setSystemConfig(result?.data);
      setIsSystemConfigLoading(false);
    } else {
      setIsSystemConfigLoading(false);
    }
  };

  return { systemConfig, isSystemConfigLoading, loadSystemConfig };
};
