import { Grid } from "@material-ui/core";
import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import { icons } from "../../../../Assets/images/IconHelper";
import PrimaryButton from "../../../../Components/Atoms/Buttons/Primary/PrimaryButton";
import IconOverlay from "../../../../Components/Atoms/IconOverlay/IconOverlay";
import Title from "../../../../Components/Atoms/Title/Title";
import PaymentCard from "../../../../Components/Organisms/Cards/PaymentCard/PaymentCard";
import AddCardModal from "../../../../Components/Organisms/Modals/AddCardModal/AddCardModal";

function PaymentsListTemplate(props) {
  const { t, creditCards } = props;
  const [checked, setchecked] = useState(true);
  const [type, settype] = useState();

  return (
    <Grid container>
      <Grid item xs={10}>
        <Title text={t("Profile.MyPayment")} />
      </Grid>
      <Grid item xs={2} style={{ display: "flex", alignItems: "center" }}>
        <PrimaryButton
          text={t("general.Add Card")}
          classBtn="outline"
          endIcon={<IconOverlay src={icons.plus} iconStyle="icon-primary" />}
          click={() => {
            settype("add");
            props.handleClose();
          }}
        />
      </Grid>
      <Grid item xs={12}>
        {creditCards?.map((card) => (
          <PaymentCard
            key={card.id}
            id={card.id}
            checked={checked}
            setchecked={setchecked}
            default={card.attributes.default}
            type={card.attributes.cc_type}
            lastDigits={card.attributes.last_digits}
            exp={`${card.attributes.month}/${card.attributes.year}`}
            pressEdit={(data) => {
              settype(data);
              props.handleClose();
            }}
          />
        ))}
      </Grid>
      <AddCardModal
        type={type}
        open={props.open}
        handleClose={props.handleClose}
        creditCards={props.creditCards}
      />
    </Grid>
  );
}

export default withTranslation()(PaymentsListTemplate);
