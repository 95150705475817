import { CircularProgress, Grid, Typography } from "@material-ui/core";
import InfiniteScroll from "react-infinite-scroll-component";

import AddToCartBox from "../../../Molecules/AddToCartBox/AddToCartBox";
import { Link } from "react-router-dom";
import { QuantityPicker } from "../../../Molecules";
import StarRatings from "react-star-ratings";
import { icons, images } from "../../../../Assets/images/IconHelper";
import { withTranslation } from "react-i18next";
import "./CollectionProducts.css";
import IconOverlay from "../../../Atoms/IconOverlay/IconOverlay";
import CollectionProductCard from "../CollectionProductCard/CollectionProductCard";

const CollectionProducts = ({
  t,
  CollectionTitle,
  Products = [],
  LastOne = false,
  ProductColor,
  isProductsLoading,
  hasMore,
  loadMore,
  page,
  listAll = false,
}) => {
  return (
    <>
      <div style={{ width: "100%", height: "100%", margin: "40px 0" }}>
        <Typography variant="h6" style={{ fontWeight: "bold" }}>
          {CollectionTitle}
        </Typography>

        <InfiniteScroll
          dataLength={Products && Products?.length}
          scrollThreshold={0.7}
          next={loadMore}
          hasMore={hasMore}
          loader={
            isProductsLoading && page != 1 ? (
              <div className="progress-indicator">
                <CircularProgress style={{ color: "var(--secondary-color)" }} />
              </div>
            ) : (
              ""
            )
          }
          style={{ overflow: "" }}
        >
          {isProductsLoading && page == 1 ? (
            <div className="progress-indicator">
              <CircularProgress style={{ color: "var(--secondary-color)" }} />
            </div>
          ) : (
            <>
              {listAll
                ? Products?.map((item) => (
                    <CollectionProductCard
                      product={item}
                      ProductColor={ProductColor}
                    />
                  ))
                : Products?.map((item) => (
                    <CollectionProductCard
                      product={item}
                      ProductColor={ProductColor}
                      isProduct
                    />
                  ))}
              {/* {Products?.map((item) => (
                <CollectionProductCard
                  product={item}
                  ProductColor={ProductColor}
                />
              ))} */}
            </>
          )}
        </InfiniteScroll>
      </div>
      {!LastOne && <hr />}
    </>
  );
};

export default withTranslation()(CollectionProducts);
