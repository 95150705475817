import { getAccountAPI } from "../../Contexts/API/Home/MyAccount/getAccount";
import { toast } from "react-toastify";
import { updateAccountAPI } from "../../Contexts/API/Home/MyAccount/updateAccount";
import { updatePasswordAPI } from "../../Contexts/API/Home/MyAccount/updatePassword";
import { useState } from "react";

export const useProfile = () => {
  const [profileData, setProfileData] = useState(null);
  const [isProfileLoading, setIsprofileLoading] = useState(false);

  //   const getAccount = async () => {
  //     const { res, error, status } = await getAccountAPI();
  //     return res;
  //   };
  const loadProfile = async () => {
    setIsprofileLoading(true);

    const { res, err, status } = await getAccountAPI();

    if (status === 200) {
      setIsprofileLoading(false);
      setProfileData(res?.data);
    } else {
      setIsprofileLoading(false);
      toast.error(err);
    }
  };

  const updatePassword = async (login) => {
    setIsprofileLoading(true);
    const { res, err, status } = await updatePasswordAPI(login);
    console.log(status);
    if (status === 200) {
      setIsprofileLoading(false);
      toast.success("Password changed Successfully");
    } else {
      setIsprofileLoading(false);
      toast.error(err);
    }
  };

  const updateAccount = async (data) => {
    setIsprofileLoading(true);
    const { res, err, status } = await updateAccountAPI(data);
    if (status === 200) {
      setIsprofileLoading(false);
      setProfileData(res?.data);
      toast.success("Profile Updated Successfully");
    } else {
      setIsprofileLoading(false);
      toast.error(err);
    }
  };

  return {
    loadProfile,
    isProfileLoading,
    updatePassword,
    profileData,
    updateAccount,
  };
};
