import { CircularProgress, Grid, Typography } from "@material-ui/core";
import InfiniteScroll from "react-infinite-scroll-component";

import AddToCartBox from "../../../Molecules/AddToCartBox/AddToCartBox";
import { Link } from "react-router-dom";
import { ImageView, QuantityPicker } from "../../../Molecules";
import StarRatings from "react-star-ratings";
import { icons, images } from "../../../../Assets/images/IconHelper";
import { withTranslation } from "react-i18next";
import "./CollectionProductCard.css";
import IconOverlay from "../../../Atoms/IconOverlay/IconOverlay";
import { useEffect, useState } from "react";
import { useCollection } from "../../../../Hooks/useCollection/useCollection";
import { useCart } from "../../../../Hooks/useCart/useCart";
import { Box } from "@mui/system";

const ProductCard = ({
  ProductData,
  availableVariant,
  SelectedVariant,
  setSelectedVariant,
  addToCart,
  isAddToCartLoading,
  isVariantsLoading,
  VariantOptions,
  t,
}) => {
  const [Quantity, setQuantity] = useState(1);
  return (
    <>
      <Grid
        container
        style={{ justifyContent: "space-between", margin: "20px 0" }}
      >
        <Grid xs={3}>
          {/* <div
            style={{
              border: "solid 1px #e3e3e3",
              padding: "50px 15px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "calc(100% - 100px)",
            }}
          >
            <img
              src={
                availableVariant?.length == 1
                  ? availableVariant?.at(0)?.image ??
                    images.productPlaceHolder
                  : ProductData?.default_variant?.image ??
                    images.productPlaceHolder
              }
              alt="product"
              style={{ height: "fit-content", width: "100%" }}
            />
          </div> */}
          <Grid
            item
            xs={12}
            md={4}
            className="order_img"
            style={{
              width: "calc(100% - 10px)",
              border: "1px solid var(--beige-color)",
            }}
          >
            <ImageView
              src={
                availableVariant?.length == 1
                  ? availableVariant?.at(0)?.image ?? images.productPlaceHolder
                  : ProductData?.default_variant?.image ??
                    images.productPlaceHolder
              }
              className="order_item_img"
            />
          </Grid>
        </Grid>
        <Grid xs={5}>
          <Grid
            container
            spacing={1}
            style={{
              justifyContent: "space-between",
              height: "100%",
              flexDirection: "column",
              flexWrap: "nowrap",
            }}
          >
            <Box>
              <Grid item xs={12}>
                <Link to={`/product/1`}>
                  <Typography
                    variant="h6"
                    style={{ color: "var(--dark-gray)" }}
                  >
                    {/* {availableVariant?.at(0)?.variant_name} */}
                    {availableVariant?.length == 1
                      ? availableVariant?.at(0)?.variant_name
                      : ProductData?.default_variant?.variant_name}
                  </Typography>
                </Link>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant="p"
                  style={{
                    color: "var(--dark-gray)",
                    fontSize: "14px",
                  }}
                >
                  {ProductData?.description}
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                style={{ display: "flex", alignItems: "center" }}
              >
                <StarRatings
                  rating={
                    ProductData?.avg_rating
                      ? parseFloat(ProductData?.avg_rating)
                      : 0
                  }
                  starRatedColor="var(--star-color)"
                  numberOfStars={5}
                  starDimension="16px"
                  starSpacing="2px"
                />
                <Typography
                  variant="p"
                  style={{
                    color: "var(--dark-gray)",
                    fontSize: "12px",
                    padding: "0 5px",
                  }}
                >
                  {ProductData?.reviews_count} ratings
                </Typography>
              </Grid>
              {isVariantsLoading ? (
                <div className="progress-indicator">
                  <CircularProgress
                    style={{ color: "var(--secondary-color)" }}
                  />
                </div>
              ) : (
                ProductData?.option_types?.map((option) => (
                  <Grid
                    item
                    xs={12}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: "20px",
                    }}
                  >
                    <Typography
                      variant="h6"
                      style={{
                        color: "#202428",
                        fontSize: "18px",
                        fontWeight: "semi-bold",
                        textTransform: "capitalize",
                      }}
                    >
                      {option.name}:
                    </Typography>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        flexWrap: "wrap",
                      }}
                    >
                      {option.name != "color"
                        ? option?.option_values?.map((value) => (
                            <div
                              className="Collection-product-tab"
                              style={
                                SelectedVariant?.find(
                                  (one) => one.id == value.id
                                )
                                  ? { borderColor: "#c21703" }
                                  : VariantOptions?.find(
                                      (one) => one.id == value.id
                                    )?.is_available ||
                                    VariantOptions?.length == 0
                                  ? { borderColor: "#303030" }
                                  : {
                                      background: "rgb(86,86,86,0.2)",
                                      borderColor: "#303030",
                                      cursor: "not-allowed",
                                    }
                              }
                              onClick={() => {
                                if (
                                  SelectedVariant?.find(
                                    (one) =>
                                      one?.option_type_id ==
                                      value.option_type_id
                                  )
                                )
                                  if (
                                    SelectedVariant?.find(
                                      (one) => one?.id == value.id
                                    )
                                  )
                                    setSelectedVariant([
                                      ...SelectedVariant?.filter(
                                        (one) => one?.id != value.id
                                      ),
                                    ]);
                                  else
                                    setSelectedVariant([
                                      ...SelectedVariant?.filter(
                                        (one) =>
                                          one?.option_type_id !=
                                          value.option_type_id
                                      ),
                                      {
                                        id: value.id,
                                        option_type_id: value.option_type_id,
                                      },
                                    ]);
                                else
                                  setSelectedVariant([
                                    ...SelectedVariant,
                                    {
                                      id: value.id,
                                      option_type_id: value.option_type_id,
                                    },
                                  ]);
                              }}
                            >
                              <Typography
                                className="Collection-product-tab-text"
                                style={
                                  SelectedVariant?.find(
                                    (one) => one.id == value.id
                                  )
                                    ? { color: "#c21703" }
                                    : { color: "#303030" }
                                }
                              >
                                {value.name}
                              </Typography>
                            </div>
                          ))
                        : option?.option_values?.map((value) => (
                            <div
                              className="Collection-product-color-tab"
                              style={{
                                backgroundColor: value.name,
                                border:
                                  SelectedVariant?.find(
                                    (one) => one?.id == value.id
                                  ) && "solid 1px #c21703",
                                cursor:
                                  VariantOptions?.find(
                                    (one) => one.id == value.id
                                  )?.is_available || VariantOptions?.length == 0
                                    ? "pointer"
                                    : "not-allowed",
                              }}
                              onClick={() => {
                                if (
                                  SelectedVariant?.find(
                                    (one) =>
                                      one?.option_type_id ==
                                      value.option_type_id
                                  )
                                )
                                  if (
                                    SelectedVariant?.find(
                                      (one) => one?.id == value.id
                                    )
                                  )
                                    setSelectedVariant([
                                      ...SelectedVariant?.filter(
                                        (one) => one?.id != value.id
                                      ),
                                    ]);
                                  else
                                    setSelectedVariant([
                                      ...SelectedVariant?.filter(
                                        (one) =>
                                          one?.option_type_id !=
                                          value.option_type_id
                                      ),
                                      {
                                        id: value.id,
                                        option_type_id: value.option_type_id,
                                      },
                                    ]);
                                else
                                  setSelectedVariant([
                                    ...SelectedVariant,
                                    {
                                      id: value.id,
                                      option_type_id: value.option_type_id,
                                    },
                                  ]);
                              }}
                            >
                              <IconOverlay
                                iconStyle={
                                  SelectedVariant?.find(
                                    (one) => one.id == value.id
                                  )
                                    ? "ClickedCollectionTrueColor"
                                    : "CollectionTrueColor"
                                }
                                style={{
                                  display: !(
                                    VariantOptions?.find(
                                      (one) => one.id == value.id
                                    )?.is_available ||
                                    VariantOptions?.length == 0
                                  )
                                    ? "none"
                                    : "",
                                }}
                                src={icons.CollectionTrueColor}
                                width="13px"
                              />
                            </div>
                          ))}
                    </div>
                  </Grid>
                ))
              )}
            </Box>
            {isAddToCartLoading ? (
              <div className="progress-indicator">
                <CircularProgress style={{ color: "var(--secondary-color)" }} />
              </div>
            ) : (
              <Box style={{ marginTop: "20px", height: "fit-content" }}>
                <AddToCartBox
                  disabled={
                    availableVariant?.length != 1 ||
                    ProductData?.option_types?.length != SelectedVariant?.length
                  }
                  onClickCart={() => {
                    if (
                      availableVariant?.length == 1 &&
                      ProductData?.option_types?.length ==
                        SelectedVariant?.length
                    ) {
                      if (localStorage.getItem("USER_TYPE") == "guest")
                        window.location = "/login";
                      else addToCart(availableVariant?.at(0)?.id, Quantity);
                    }
                  }}
                />
              </Box>
            )}
          </Grid>
        </Grid>
        <Grid
          xs={3}
          style={{
            borderLeft:
              localStorage.getItem("lang") == "ar" ? "" : "solid 1px #e1e1e1",
            borderRight:
              localStorage.getItem("lang") == "ar" ? "solid 1px #e1e1e1" : "",
            paddingLeft: localStorage.getItem("lang") == "ar" ? "" : "10px",
            paddingRight: localStorage.getItem("lang") == "ar" ? "10px" : "",
          }}
        >
          {/*  <Typography className="Collection-product-grey-text">
          + EGP 120 Shipping & Import Fees Deposit to Egypt
        </Typography>
        <Typography
          className="Collection-product-grey-text"
          style={{
            border: "solid 0.5px #979797",
            padding: "4px 6px",
          }}
        >
          Arrives: Oct 22 - Nov 10
        </Typography>
        <Typography className="Collection-product-grey-text">
          Ship to Egypt
          <span
            style={{
              color: "#c21703",
              fontWeight: "bold",
              cursor: "pointer",
            }}
          >
            (Change Country)
          </span>
        </Typography>
        <div style={{ display: "flex" }}>
          <Typography className="Collection-product-grey-text">
            Share product
          </Typography>
        </div>
        <hr /> */}
          <Grid item xs={12}>
            <Typography className="Collection-product-price">
              {availableVariant?.length == 1
                ? availableVariant?.at(0)?.price
                : ProductData?.default_variant?.price}
              <span style={{ fontWeight: "semi-bold", fontSize: "16px" }}>
                {availableVariant?.length == 1
                  ? availableVariant?.at(0)?.cost_currency
                  : ProductData?.default_variant?.cost_currency}{" "}
                /Sqm
              </span>
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "start",
            }}
          >
            <Typography
              style={{
                marginBottom: "0 !important",
                fontSize: "12px",
                color: "#757575",
              }}
            >
              {t("product details.quantity")}:
            </Typography>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                margin: "0 5px",
              }}
            >
              <QuantityPicker
                collectionProduct={true}
                increaseItemQuantity={() => setQuantity(Quantity + 1)}
                decreaseItemQuantity={() => {
                  if (Quantity > 1) setQuantity(Quantity - 1);
                }}
                quantity={Quantity}
                id={"id"}
                oldQuantity={3}
              />
              <Typography style={{ fontWeight: "bold", fontSize: "12px" }}>
                {t("myBoxes.box")}
              </Typography>
            </div>
          </Grid>
          <Typography
            className="Collection-product-grey-text"
            style={{ marginTop: "10px" }}
          >
            {t("myBoxes.box will match")} 1.44 sqm
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

const VariantCard = ({ variant, addToCart, isAddToCartLoading, t }) => {
  const [Quantity, setQuantity] = useState(1);
  return (
    <>
      <Grid
        container
        style={{ justifyContent: "space-between", margin: "20px 0" }}
      >
        <Grid xs={3}>
          {/* <div
            style={{
              border: "solid 1px #e3e3e3",
              padding: "50px 15px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "calc(100% - 100px)",
            }}
          >
            <img
              src={variant?.image ?? images.productPlaceHolder}
              alt="product"
              style={{ height: "fit-content", width: "100%" }}
            />
          </div> */}
          <Grid
            item
            xs={12}
            className="order_img"
            style={{
              width: "100%",
              border: "1px solid var(--beige-color)",
              padding: "50px 15px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <ImageView
              src={variant?.image ?? images.productPlaceHolder}
              className="order_item_img"
            />
          </Grid>
        </Grid>
        <Grid xs={5}>
          <Grid
            container
            spacing={1}
            style={{
              justifyContent: "space-between",
              height: "100%",
              flexDirection: "column",
              flexWrap: "nowrap",
            }}
          >
            <Box>
              <Grid item xs={12}>
                <Link to={`/product/1`}>
                  <Typography
                    variant="h6"
                    style={{ color: "var(--dark-gray)" }}
                  >
                    {variant?.variant_name}
                  </Typography>
                </Link>
              </Grid>
              {variant?.option_values &&
                variant?.option_values?.map((item) => (
                  <Grid item xs={12} style={{ marginTop: "20px" }}>
                    <span style={{ textTransform: "capitalize" }}>
                      {item?.option_type_name} :
                    </span>{" "}
                    <span>{item?.name}</span>
                  </Grid>
                ))}
            </Box>
            {isAddToCartLoading ? (
              <div className="progress-indicator">
                <CircularProgress style={{ color: "var(--secondary-color)" }} />
              </div>
            ) : (
              <Box style={{ marginTop: "10px", height: "fit-content" }}>
                <AddToCartBox
                  onClickCart={() => {
                    if (localStorage.getItem("USER_TYPE") == "guest")
                      window.location = "/login";
                    else addToCart(variant?.id, Quantity);
                  }}
                />
              </Box>
            )}
          </Grid>
        </Grid>
        <Grid
          xs={3}
          style={{
            borderLeft:
              localStorage.getItem("lang") == "ar" ? "" : "solid 1px #e1e1e1",
            borderRight:
              localStorage.getItem("lang") == "ar" ? "solid 1px #e1e1e1" : "",
            paddingLeft: localStorage.getItem("lang") == "ar" ? "" : "10px",
            paddingRight: localStorage.getItem("lang") == "ar" ? "10px" : "",
          }}
        >
          <Grid item xs={12}>
            <Typography className="Collection-product-price">
              {variant?.price}
              <span style={{ fontWeight: "semi-bold", fontSize: "16px" }}>
                {variant?.cost_currency} / {variant?.box_size_unit?.name}
              </span>
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "start",
            }}
          >
            <Typography
              style={{
                marginBottom: "0 !important",
                fontSize: "12px",
                color: "#757575",
              }}
            >
              {t("product details.quantity")}:
            </Typography>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                margin: "0 5px",
              }}
            >
              <QuantityPicker
                collectionProduct={true}
                increaseItemQuantity={() => setQuantity(parseInt(Quantity) + 1)}
                decreaseItemQuantity={() => {
                  if (parseInt(Quantity) > 0)
                    setQuantity(parseInt(Quantity) - 1);
                }}
                quantity={Quantity}
                id={"id"}
                oldQuantity={3}
                setInput={setQuantity}
              />
              <Typography style={{ fontWeight: "bold", fontSize: "12px" }}>
                {t("myBoxes.box")}
              </Typography>
            </div>
          </Grid>
          <Typography
            className="Collection-product-grey-text"
            style={{ marginTop: "10px" }}
          >
            {t("myBoxes.box will match")} {variant?.box_size}
            {variant?.box_size_unit?.name}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

const CollectionProductCard = ({
  product,
  ProductColor,
  isProduct = false,
  t,
}) => {
  const [ProductData, setProductData] = useState(product);
  const [SelectedVariant, setSelectedVariant] = useState([]);
  const {
    isVariantsLoading,
    availableVariant,
    listAvailableVariants,
    VariantOptions,
  } = useCollection();
  const { addToCart, isAddToCartLoading } = useCart();

  useEffect(() => {
    if (availableVariant?.length == 0)
      setSelectedVariant(SelectedVariant.slice(-1));
  }, [availableVariant]);

  useEffect(() => {
    if (SelectedVariant?.length != 0)
      listAvailableVariants(
        ProductData?.id,
        SelectedVariant?.map((item) => item?.id)
      );
  }, [SelectedVariant]);

  if (isProduct)
    return (
      <ProductCard
        ProductData={ProductData}
        availableVariant={availableVariant}
        SelectedVariant={SelectedVariant}
        setSelectedVariant={setSelectedVariant}
        addToCart={addToCart}
        isAddToCartLoading={isAddToCartLoading}
        t={t}
        isVariantsLoading={isVariantsLoading}
        VariantOptions={VariantOptions}
      />
    );
  else
    return (
      <>
        {ProductData?.variants?.map((variant) => (
          <VariantCard
            variant={variant}
            addToCart={addToCart}
            isAddToCartLoading={isAddToCartLoading}
            t={t}
          />
        ))}
      </>
    );
};

export default withTranslation()(CollectionProductCard);
