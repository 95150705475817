import { Grid } from "@material-ui/core";
import { useState } from "react";
import { withTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { icons, images } from "../../../../Assets/images/IconHelper";
import IconOverlay from "../../../Atoms/IconOverlay/IconOverlay";
import SocialModal from "../../Modals/SocialModal/SocialModal";
import "./BlogCard.css";

const BlogCard = ({ t, id, title, by, date, text, img, mobileView }) => {
  const [openModal, setopenModal] = useState(false);
  const toggleModal = () => {
    setopenModal(!openModal);
  };

  const history = useHistory();

  const [state, setState] = useState({
    likes: 0,
    comments: 0,
    liked: false,
  });

  const likeBlog = () => {
    if (state.liked) {
      setState({
        ...state,
        liked: false,
        likes: state.likes - 1,
      });
    } else {
      setState({
        ...state,
        liked: true,
        likes: state.likes + 1,
      });
    }
  };

  const goToComment = () => {
    localStorage.setItem("goToComment", "true");
    history.push(`/dankitchen/blog/${id}`);
  };

  return (
    <div className="blog-card">
      <Grid container spacing={1}>
        <Grid item xs={12} sm={9} className="item1">
          <div className="blog-content">
            <p className="blog-title">{title}</p>
            <div className="blog-desc">
              <p>{date}</p>
              <p className="blog-by">{by}</p>
            </div>

            <p className="blog-text">{text}</p>
            {/* onClick={() => history.push(`/dankitchen/blog/${id}`)} */}
            <p
              onClick={() => history.push(`/blog/${id}`)}
              className="blog-more"
            >
              {t("dan kitchen.read more")}
            </p>

            <div className="blog-footer">
              <div className="blog-interaction">
                <IconOverlay
                  src={icons.like}
                  iconStyle={state.liked ? "icon-red" : ""}
                  onClick={likeBlog}
                />
                <p>{state.likes}</p>
              </div>

              <div className="blog-interaction">
                <IconOverlay src={icons.comment} onClick={goToComment} />
                <p>{state.comments}</p>
              </div>

              <div className="blog-interaction" onClick={toggleModal}>
                <IconOverlay src={icons.share} />
              </div>
            </div>
          </div>
        </Grid>

        <Grid item xs={12} sm={3} className="item2">
          <div className="blog-image">
            <img src={img} alt="" />
          </div>
        </Grid>
      </Grid>

      <SocialModal
        open={openModal}
        handleClose={toggleModal}
        link={`${window.location.href}/blog/${id}/`}
      />
    </div>
  );
};

export default withTranslation()(BlogCard);
