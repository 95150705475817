import { ClickAwayListener } from "@material-ui/core";
import moment from "moment";
import { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import './ChatMessage.css';

const ChatMessage = ({ isMine, msg, i18n }) => {

    const [showDate, setShowDate] = useState(false);
    const [time] = useState(moment(msg.date))

    useEffect(() => {
        moment.locale(i18n.language)
    }, [])

    return (
        <ClickAwayListener onClickAway={() => setShowDate(false)}>
            <div>
                {isMine ?
                    <div className="chat-message-container">
                        <div className="chat-message chat-me" onClick={() => setShowDate(!showDate)}>
                            {msg.message}
                        </div>
                        {showDate && <p className="chat-me chat-date">{time.format('h:mm a')}</p>}

                    </div>
                    :
                    <div className="chat-message-container">
                        <div className="chat-message chat-other" onClick={() => setShowDate(!showDate)}>
                            {msg.message}
                        </div>
                        {showDate && <p className="chat-other chat-date">{time.format('h:mm a')}</p>}
                    </div>}
            </div>
        </ClickAwayListener>
    );
}

export default withTranslation()(ChatMessage);