import { Grid } from "@material-ui/core";
import { withTranslation } from 'react-i18next';
import { images } from "../../../../Assets/images/IconHelper";
import "../HomePage.css";
import "./FoodSafety.css";

const FoodSafety = (props) => {
    const { t } = props;
    return (
        <div className="imgContainer safety" style={{ backgroundImage: `url(${images.foodSafetyBg})` }}>
            <Grid container className="imgContainerContent" >
                <Grid item xs={12} md={9} >
                    <div className="safety-body">
                        <h2 className="home-title safety-title">{t("foodSafety.title")}</h2>
                        <p className="safety-text">{t("foodSafety.text")}</p>

                    </div>

                </Grid>

            </Grid>
        </div>
    );
}

export default withTranslation()(FoodSafety);