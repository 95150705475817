import { useState } from "react";

import { getSystemAPI } from "../../Contexts/API/Home/system/getSystem";
import { ListVendorTypesAPI } from "../../Contexts/API/Home/Vendor/VendorTypes";

export const useVendor = () => {
  const [vendorTypes, setVendorTypes] = useState([]);

  const loadVendorTypes = async () => {
    let result = await ListVendorTypesAPI();
    if (result) {
      setVendorTypes(result?.res?.vendor_types);
    }
  };

  return { vendorTypes, loadVendorTypes };
};
