import { axiosFn, handleError } from "../../index";
import * as URL from "../../URLs";

export async function getListAllRequests(filter, page = 1) {
  return await axiosFn(
    "get",
    `${URL.ALL_REQUESTS}?per_page=10&page=${page}&${filter}`
  )
    .then((res) => {
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}
export async function getListAllRequestsAPI(filter, page) {
  let { err, res } = await getListAllRequests(filter, page);

  if (res) {
    return res;
  }

  if (err) {
    return err;
  }
}
