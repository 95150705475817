import { useEffect, useRef, useState } from "react";

import { withTranslation } from "react-i18next";
import * as yup from "yup";
import { useFormik } from "formik";

import {
  Box,
  Grid,
  MenuItem,
  Select,
  TextareaAutosize,
  TextField,
  Typography,
} from "@mui/material";

import PrimaryButton from "../../Atoms/Buttons/Primary/PrimaryButton";
import IconOverlay from "../../Atoms/IconOverlay/IconOverlay";
import { icons } from "../../../Assets/images/IconHelper";

import "./VendorRequestCard.css";

const VendorRequestCard = ({
  Key,
  activeService,
  setActiveService,
  Request,
  systemConfig,
}) => {
  return (
    <Box
      key={Key}
      sx={{
        borderRadius: "4px",
        border: "solid 0.5px #979797",
        padding: "18px 0",
        marginTop: Key == 0 ? "10px" : "20px",
        cursor: "pointer",
      }}
      className="JobBorder"
      onClick={() => setActiveService(Request?.id)}
    >
      <Box
        sx={{
          padding: "0 16px",
        }}
        className={activeService == Request?.id ? "margin-check" : ""}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              display: "flex",
            }}
          >
            <IconOverlay
              src={Request?.client_image ?? icons.oval}
              iconStyle="icon-primary"
              style={{ width: 50, height: 50 }}
            />
            <Box>
              <Typography
                variant="h5"
                sx={{
                  fontSize: "18px",
                  padding: "0 10px",
                  fontWeight: "600",
                }}
              >
                {Request?.client_name}
              </Typography>
              <Typography
                variant="p"
                sx={{
                  fontSize: "12px",
                  color: "#9e9e9e",
                  padding: "0 10px",
                  lineHeight: 1,
                }}
              >
                <IconOverlay
                  src={icons.rating_star}
                  iconStyle="like-animation"
                  style={{ width: "10px" }}
                />
                {Request?.client_rating} ({Request?.client_reviews_count})
              </Typography>
            </Box>
          </Box>
          <Typography
            variant="h5"
            sx={{
              fontSize: "12px",
              fontWeight: "300",
              color: "#9d9d9d",
              marginTop: "10px",
            }}
          >
            {Request?.datetime?.split("T")[0]}
          </Typography>
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            marginTop: "14px",
          }}
        >
          <Typography
            variant="h5"
            sx={{
              fontSize: "14px",
              fontWeight: "600",
              color: "#303030",
              width: "130px",
            }}
          >
            {systemConfig &&
            systemConfig["System Configurations"]["Project Name"] ===
              "etradeling"
              ? "Product name"
              : "Type of services"}
          </Typography>
          <Typography
            variant="h5"
            sx={{
              fontSize: "14px",
              fontWeight: "300",
              color: "#383838",
            }}
          >
            {systemConfig &&
            systemConfig["System Configurations"]["Project Name"] ===
              "etradeling"
              ? Request?.product_name
              : Request?.service_type_name}
          </Typography>
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            marginTop: "14px",
          }}
        >
          <Typography
            variant="h5"
            sx={{
              fontSize: "14px",
              fontWeight: "600",
              color: "#303030",
              width: "130px",
            }}
          >
            {systemConfig &&
            systemConfig["System Configurations"]["Project Name"] ===
              "etradeling"
              ? "Category"
              : "Building type"}
          </Typography>
          <Typography
            variant="h5"
            sx={{
              fontSize: "14px",
              fontWeight: "300",
              color: "#383838",
            }}
          >
            {systemConfig &&
            systemConfig["System Configurations"]["Project Name"] ===
              "etradeling"
              ? Request?.category_name
              : Request?.building_type_name}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default withTranslation()(VendorRequestCard);
