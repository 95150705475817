import * as URL from "../../URLs";

import { axiosFn, handleError } from "../../index";

export async function removeFromWishlist(id) {
  return await axiosFn(
    "delete",
    `${URL.DEFAULT_WISHLIST}/${localStorage.getItem(
      "WISHTOKEN"
    )}/wished_products/${id}`
  )
    .then((res) => {
      return {
        res: res.data,
        err: null,
        status: res?.status,
      };
    })
    .catch(handleError);
}
export async function removeFromWishlistAPI(id) {
  let { err, res, status } = await removeFromWishlist(id);

  return { res, err, status };
}
