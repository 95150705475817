import * as URL from "../../URLs";

import { axiosFn, handleError } from "../../index";

export async function updatePassword(recivedData) {
  const data = { user: { ...recivedData } };
  return await axiosFn("patch", URL.ACCOUNT, data)
    .then((res) => {
      return {
        res: res.data,
        err: null,
        status: res?.status,
      };
    })
    .catch(handleError);
  // }
}
export async function updatePasswordAPI(data) {
  let { err, res, status } = await updatePassword(data);

  return { err, res, status };
}
