import React, { useState } from "react";

import { CircularProgress, Grid } from "@material-ui/core";
import { useHistory } from "react-router";
import { withTranslation } from "react-i18next";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";

import { icons } from "../../../../Assets/images/IconHelper";

import PrimaryButton from "../../../../Components/Atoms/Buttons/Primary/PrimaryButton";
import IconOverlay from "../../../../Components/Atoms/IconOverlay/IconOverlay";
import Title from "../../../../Components/Atoms/Title/Title";

import AddressCard from "../../../../Components/Organisms/Cards/AddressCard/AddressCard";
import AddAdressModal from "../../../../Components/Organisms/Modals/AddAdressModal/AddAdressModal";

function MyAddressTemplate(props) {
  const { t, i18n, addresses, setAddresses, selectAddress } = props;

  const history = useHistory();

  const [type, settype] = useState();
  const [dataToEdit, setDataToEdit] = useState({});

  return (
    <Grid container>
      <Grid
        item
        xs={12}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        {props.isCheckout ? (
          <div
            style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
            onClick={() => history.goBack()}
          >
            <ArrowBackIosIcon
              style={{
                transform: `rotate(${i18n.language === "ar" ? "180deg" : "0"})`,
              }}
            />
            <Title text={t("address.change address")} />
          </div>
        ) : (
          <Title text={t("Profile.MyAddresses")} />
        )}
        <div
          style={{ display: "flex", alignItems: "center", maxWidth: "120px" }}
        >
          <PrimaryButton
            text={t("general.Add New")}
            classBtn="outline-secondry"
            endIcon={
              <IconOverlay src={icons.plus} iconStyle="icon-secondary" />
            }
            click={() => {
              settype("add");
              props.handleClose();
            }}
          />
        </div>
      </Grid>
      <Grid item xs={12}>
        {props.isAddressLoading ? (
          <div className="progress-indicator">
            <CircularProgress style={{ color: "var(--secondary-color)" }} />
          </div>
        ) : (
          <>
            {addresses?.map((address, index) => (
              <AddressCard
                id={address?.id}
                index={index}
                key={index}
                address={address}
                deleteAddress={props.deleteAddress}
                setDataToEdit={setDataToEdit}
                addresses={addresses}
                setAddresses={setAddresses}
                pressEdit={(data) => {
                  settype(data);
                  props.handleClose();
                }}
                selectAddress={selectAddress}
              />
            ))}
          </>
        )}
      </Grid>
      <AddAdressModal
        addAddress={props.addAddress}
        editAddress={props.editAddress}
        type={type}
        open={props.open}
        handleClose={props.handleClose}
        dataToEdit={dataToEdit}
      />
    </Grid>
  );
}

export default withTranslation()(MyAddressTemplate);

// let addressesWithCheck = addresses.map(
//   (address) => (address = { ...address, checked: false })
// );
// const staticAddresses = useRef(addresses);
// console.log(staticAddresses);
// const [addressesWCheckState, setAddressesWCheckState] = useState([]);
