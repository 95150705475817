import { Grid } from "@material-ui/core";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";
// import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";

import { icons } from "../../../Assets/images/IconHelper";
import PrimaryButton from "../../Atoms/Buttons/Primary/PrimaryButton";
import IconOverlay from "../../Atoms/IconOverlay/IconOverlay";

// import IconOverlay from "../../Atoms/IconOverlay/IconOverlay";

import "./ProductShipping.css";

const ProductShipping = (props) => {
  const { t, i18n } = props;

  return (
    <Grid xs={12} item class="product-shipping">
      <Grid item xs={12}>
        <p className="product-shipping-price">
          {props.currency} {props.price}
        </p>
      </Grid>
      <Grid item xs={12}>
        <p className="product-shipping-fees">
          + {props.currency} {props.shipping} {t("product details.fees")}
        </p>
        <p className="product-shipping-fees">
          {t("product details.deposit")} Egypt
        </p>
      </Grid>
      <Grid item xs={12} className="product-shipping-arrive">
        <p>
          {t("product details.arrives")}: {props.arrive}
        </p>
      </Grid>
      <Grid item xs={12} className="product-shipping-border">
        <span className="product-shipping-country">
          {t("product details.ship")} Egypt
        </span>

        {/* 
            <span className="product-shipping-change-country">
            ({t("product details.change country")})
            </span> 
        */}
      </Grid>

      <Grid
        item
        xs={12}
        className="product-shipping-share product-shipping-border"
      >
        <span className="product-shipping-fees product-shipping-sharetext">
          {t("product details.share")}
        </span>

        <FacebookShareButton
          url={window.location.href}
          quote={"DanFoods"}
          hashtag=""
        >
          <img
            className="product-shipping-icon"
            src={icons.shareFb}
            alt="Share via facebook"
          />
        </FacebookShareButton>

        <TwitterShareButton
          url={window.location.href}
          quote={"DanFoods"}
          hashtag=""
        >
          <img
            className="product-shipping-icon"
            src={icons.shareTwitter}
            alt="Share via twitter"
          />
        </TwitterShareButton>

        <WhatsappShareButton
          url={window.location.href}
          quote={"DanFoods"}
          hashtag=""
        >
          <img
            className="product-shipping-icon"
            src={icons.shareWa}
            alt="Share via whatsapp"
          />
        </WhatsappShareButton>
      </Grid>

      <Grid item xs={12}>
        <Box
          sx={{
            display: "flex",
            padding: "0",
          }}
        >
          <IconOverlay
            src={icons.oval}
            iconStyle="icon-primary"
            style={{ width: 50, height: 50 }}
          />
          <Box>
            <Typography
              variant="h5"
              sx={{ fontSize: "14px", padding: "0 10px", fontWeight: "500" }}
            >
              Client name
            </Typography>
            <Typography
              variant="p"
              sx={{
                fontSize: "12px",
                color: "#9e9e9e",
                padding: "0 10px",
                lineHeight: 1,
              }}
            >
              4.6 (3000)
            </Typography>
          </Box>
        </Box>
        <PrimaryButton
          text={t("view profile")}
          classBtn="outline BorderRadiusZero"
          style={{ marginTop: "10px" }}
        />
      </Grid>
    </Grid>
  );
};

export default withTranslation()(ProductShipping);
