import { Collapse, Grid, Typography } from "@mui/material";
import { useContext, useState } from "react";

import AddressCard from "../Cards/AddressCard/AddressCard";
import { AddressContext } from "../../../Contexts/addressContext/addressContext";
import { Box } from "@mui/system";
import IconOverlay from "../../Atoms/IconOverlay/IconOverlay";
import Installments from "../Installments/Installments";
import ProposalCard from "../Cards/ProposalCard/ProposalCard";
import UploadedFile from "../../Molecules/UploadedFile/UploadedFile";
import { icons } from "../../../Assets/images/IconHelper";

function RequestDetailsBody({
  productRequest,
  acceptHandler,
  rejectHandler,
  id,
  systemConfig,
  status,
}) {
  const date = new Date(productRequest?.datetime);
  const { pickedAddress } = useContext(AddressContext);
  const [show, setShow] = useState(true);
  console.log(pickedAddress);
  return (
    <>
      {/* Type of service and Building type */}
      <Grid container direction="row" sx={{ mb: "14px", mt: "20px" }}>
        <Grid item xs={4}>
          <Typography
            sx={{ color: "#303030", fontWeight: 600, fontSize: "14px" }}
          >
            Type of Services
          </Typography>
          <Typography
            sx={{ color: "#383838", fontWeight: 300, fontSize: "14px" }}
          >
            {productRequest?.service_type_name}
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography
            sx={{ color: "#303030", fontWeight: 600, fontSize: "14px" }}
          >
            {systemConfig &&
            systemConfig["System Configurations"]["Project Name"] === "esolar"
              ? "Building type"
              : "Category"}
          </Typography>
          <Typography
            sx={{ color: "#383838", fontWeight: 300, fontSize: "14px" }}
          >
            {systemConfig &&
            systemConfig["System Configurations"]["Project Name"] === "esolar"
              ? productRequest?.building_type_name
              : productRequest?.category_name}
          </Typography>
        </Grid>
      </Grid>
      {systemConfig &&
      systemConfig["System Configurations"]["Project Name"] === "esolar" ? (
        <></>
      ) : (
        <Grid container direction="row" sx={{ mb: "14px", mt: "20px" }}>
          <Grid item xs={4}>
            <Typography
              sx={{ color: "#303030", fontWeight: 600, fontSize: "14px" }}
            >
              Quantity
            </Typography>
            <Typography
              sx={{ color: "#383838", fontWeight: 300, fontSize: "14px" }}
            >
              {productRequest?.quantity +
                " " +
                productRequest?.quantity_unit_name}
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography
              sx={{ color: "#303030", fontWeight: 600, fontSize: "14px" }}
            >
              Trade Terms
            </Typography>
            <Typography
              sx={{ color: "#383838", fontWeight: 300, fontSize: "14px" }}
            >
              {productRequest?.trade_term_name}
            </Typography>
          </Grid>
        </Grid>
      )}

      {/* Job Details */}
      <Box>
        <Typography
          sx={{ fontSize: "14px", fontWeight: 600, color: "#303030" }}
        >
          {systemConfig &&
          systemConfig["System Configurations"]["Project Name"] === "esolar"
            ? "Job Details"
            : "   Detailed Product Specification"}
        </Typography>
        <Typography
          sx={{ fontSize: "14px", fontWeight: "normal", color: "#303030" }}
        >
          {productRequest?.details}
        </Typography>
      </Box>
      {/* Files included in the request */}
      {
        <Box sx={{ display: "flex", mt: "16px" }}>
          {productRequest?.documents?.map((attachement) => {
            return (
              <UploadedFile
                fileType={".pdf"}
                fileName={attachement.url}
                src={attachement.url}
              />
            );
          })}
        </Box>
      }
      {/* <Box sx={{ display: "flex", mt: "16px" }}>
        {productRequest?.attachments?.map((attachement) => {
          return (
            <a href={attachement.url} target="_blank">
              <UploadedFile
                fileType={".pdf"}
                fileName={"some test file name"}
              />
            </a>
          );
        })}
      </Box> */}
      {/* Date and time */}
      <Box sx={{ my: "40px" }}>
        <Typography
          sx={{ fontSize: "14px", fontWeight: 600, color: "#303030" }}
        >
          Date and time
        </Typography>
        <Typography
          sx={{ fontSize: "16px", color: "#303030", fontWeight: "normal" }}
        >
          {date.toUTCString()}
        </Typography>
      </Box>
      {status === "inprogress" ? (
        <Grid container direction="row" sx={{ mb: "14px", mt: "20px" }}>
          <Grid item xs={4}>
            <Typography
              sx={{ color: "#303030", fontWeight: 600, fontSize: "14px" }}
            >
              Vendor Name
            </Typography>
            <Typography
              sx={{ color: "#383838", fontWeight: 300, fontSize: "14px" }}
            >
              {productRequest?.accepted_proposal?.vendor_name}
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography
              sx={{ color: "#303030", fontWeight: 600, fontSize: "14px" }}
            >
              Status
            </Typography>
            <Typography
              sx={{ color: "#383838", fontWeight: 300, fontSize: "14px" }}
            >
              {productRequest?.accepted_proposal?.status}
            </Typography>
          </Grid>
        </Grid>
      ) : (
        <></>
      )}
      {/* Address */}
      <AddressCard
        address={{ attributes: productRequest?.address }}
        noDelete={true}
        noEdit={true}
        noCheckbox={true}
        displayCard={true}
      />

      {status === "inprogress" ? (
        <Box>
          {/* Payment Details? */}
          {/* Installments */}
          <Box sx={{ mt: 3 }}>
            <Typography sx={{ fontSize: 24, fontWeight: 900 }}>
              Installments
            </Typography>
            <Installments
              installments={productRequest?.accepted_proposal?.installments}
              proposalStatus={productRequest?.accepted_proposal?.status}
            />
          </Box>
        </Box>
      ) : (
        <Box>
          <Box
            sx={{
              borderBottom: "1px #979797 solid",
              mt: "16px",
              display: "flex",
              justifyContent: "space-between",
              cursor: "pointer",
            }}
            onClick={() => setShow(!show)}
          >
            <Box style={{ display: "flex" }}>
              <Typography sx={{ mr: "10px" }}>Number of proposals</Typography>
              <Typography
                sx={{ color: "#09ce7d", fontSize: "18px", fontWeight: 600 }}
              >
                {"( " + productRequest?.proposals?.length + " )"}
              </Typography>
            </Box>

            {/* <Box style={{ display: "flex" }}>
          <Typography sx={{ mr: "10px" }}>Number of proposals</Typography>
          <Typography
            sx={{ color: "#09ce7d", fontSize: "18px", fontWeight: 600 }}
          >
            {"( " + productRequest?.quotations?.length + " )"}
          </Typography>
        </Box> */}
            <Box>
              <IconOverlay
                src={
                  show ? icons.installmentArrowUp : icons.installmentArrowDown
                }
              />
            </Box>
          </Box>
          <Collapse in={show}>
            <div>
              {show === true ? (
                <Box>
                  {productRequest?.proposals?.map((proposal) => {
                    return (
                      <ProposalCard
                        quotationRequest={proposal}
                        acceptHandler={acceptHandler}
                        rejectHandler={rejectHandler}
                        id={id}
                      />
                    );
                  })}
                </Box>
              ) : (
                <></>
              )}
            </div>
          </Collapse>
        </Box>
      )}
      {/* Proposal Cards */}
    </>
  );
}

export default RequestDetailsBody;
