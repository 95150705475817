import "./ProfileList.css";

import {
  AlsallabState,
  DefaultState,
  ESolarState,
  ETradelingState,
} from "../../../utils/profileStates/profileStates";
import { Link, NavLink } from "react-router-dom";
import { Menu, MenuItem } from "@material-ui/core";
import { useEffect, useState } from "react";

import IconOverlay from "../../Atoms/IconOverlay/IconOverlay";
import LogoutModal from "../Modals/LogoutModal/LogoutModal";
import firebase from "@firebase/app-compat";
import { icons } from "../../../Assets/images/IconHelper";
import { useHistory } from "react-router";
import { withTranslation } from "react-i18next";

const ProfileList = (props) => {
  const { t, account, mobileView, i18n, systemConfig } = props;

  const [User, setUser] = useState(localStorage.getItem("USER_TYPE"));

  const handleSwitch = () => {
    User == "user" ? setUser("vendor") : setUser("user");
  };

  useEffect(() => {
    if (localStorage.getItem("USER_TYPE") != User) {
      localStorage.setItem("USER_TYPE", User);
      window.location = "/";
    }
  }, [User]);

  // useEffect(() => {
  //   if (!account?.vendor && User == "vendor")
  //     localStorage.setItem("USER_TYPE", "user");
  // }, [account]);

  const history = useHistory();

  const links =
    systemConfig &&
    systemConfig["System Configurations"]["Project Name"] === "etradeling"
      ? ETradelingState
      : systemConfig &&
        systemConfig["System Configurations"]["Project Name"] === "esolar"
      ? ESolarState
      : systemConfig &&
        systemConfig["System Configurations"]["Project Name"] === "alsallab"
      ? AlsallabState
      : DefaultState;

  const [anchorEl, setAnchorEl] = useState(null);
  const isMenuOpen = Boolean(anchorEl);

  const [open, setopen] = useState();
  const handleClose = () => {
    setopen(!open);
  };
  const LogOutBtn = async () => {
    handleClose();
    localStorage.removeItem("TOKEN");
    localStorage.removeItem("USER_TYPE");
    localStorage.removeItem("EXP");
    firebase
      .auth()
      .signOut()
      .then(() => {
        // window.location.reload();
        window.location.replace("/");
        // window.location = "/";
      });
  };

  const handleProductMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = (to) => {
    if (to !== "close") {
      history.push(to);
    }
    setAnchorEl(null);
  };
  const menuProductId = "product-menu";
  const renderProdMenu = (
    <Menu
      anchorEl={anchorEl}
      getContentAnchorEl={null}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      id={menuProductId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={(e) => handleMenuClose("close")}
      className={`profile-list-menu ${i18n.language}`}
    >
      {links.map((link) => (
        <MenuItem
          onClick={(e) => handleMenuClose(link.to)}
          key={link.to}
          className="profile-list-menu"
        >
          <NavLink
            activeClassName="activeLink"
            className="NavLink profile-list-menu"
            to={link.to}
          >
            <img className="profile-item-icon" src={link.icon} alt="" />
            {t(link.text)}
          </NavLink>
        </MenuItem>
      ))}

      {systemConfig &&
      JSON.parse(systemConfig["System Configurations"]["Vendor"]) ? (
        account?.attributes?.vendor ? (
          account?.attributes?.vendor?.status == "pending" ? (
            <div className="profile-list-LogoutBtn" style={{ cursor: "unset" }}>
              <img className="profile-item-icon" src={icons.card} alt="" />
              <span style={{ paddingRight: "4px", textAlign: "start" }}>
                Vendor account <br />
                <span style={{ color: "#f0b70d" }}> Pending </span>
              </span>
            </div>
          ) : account?.attributes?.vendor?.status == "rejected" ? (
            <div className="profile-list-LogoutBtn" style={{ cursor: "unset" }}>
              <img className="profile-item-icon" src={icons.card} alt="" />
              <span
                style={{
                  paddingRight: "4px",
                  textAlign: "start",
                  maxWidth: "140px",
                }}
              >
                Vendor account <br />
                <span style={{ color: "#b01f27" }}>
                  Account rejected & need update
                </span>
              </span>
            </div>
          ) : (
            <div className="profile-list-LogoutBtn" onClick={handleSwitch}>
              <img className="profile-item-icon" src={icons.card} alt="" />
              <span style={{ paddingRight: "4px", textAlign: "start" }}>
                {`${t("Profile.Switch")} ${
                  User == "user" ? t("Vendor account") : t("Customer account")
                }`}
              </span>
            </div>
          )
        ) : (
          <div
            className="profile-list-LogoutBtn"
            onClick={() => {
              handleMenuClose("close");
              window.location = "/become_vendor";
            }}
            style={{ borderTop: "1px solid rgba(151, 151, 151, 0.23)" }}
          >
            <img className="profile-item-icon" src={icons.card} alt="" />
            <span style={{ paddingRight: "4px", textAlign: "start" }}>
              Become Vendor <br />
              <span style={{ textDecoration: "underline" }}>
                create account
              </span>
            </span>
          </div>
        )
      ) : (
        ""
      )}

      <div className="profile-list-LogoutBtn" onClick={handleClose}>
        <img src={icons.logout} alt="" /> {t("Profile.LogOut")}
      </div>

      <LogoutModal
        open={open}
        LogOutBtn={LogOutBtn}
        handleClose={handleClose}
      />
    </Menu>
  );

  return (
    <div className="profile-list">
      <div
        container
        aria-controls={menuProductId}
        aria-haspopup={true}
        onClick={handleProductMenuOpen}
        color="inherit"
        className="profile-icons-container"
      >
        <div className="nav-profile-picture">
          <img
            src={
              account?.attributes?.img
                ? account?.attributes?.img
                : icons.profilePlaceholder
            }
            width="30px"
            height="30px"
            alt=""
          />
        </div>
        {
          <div className="profile-name">
            <p>{t("Hello")}</p>
            <p className="profile-usename">
              {account?.attributes?.name
                ? account?.attributes?.name
                : "username"}
            </p>
          </div>
        }

        <IconOverlay src={icons.dropdown} iconStyle="icon-primary" />
        {/* <img src={icons.dropdown} alt="" className="profile-dropdown" /> */}
      </div>
      {renderProdMenu}
    </div>
  );
};

export default withTranslation()(ProfileList);
