import { IconButton } from '@material-ui/core'
import React from 'react'
import './SocialmediaButton.css'

const SocialmediaButton = (props) => {
    return (
        <IconButton
        // variant="fab"
        className={"Socialbtn "+props.classBtn}
        onClick={props.click}
        disabled={props.disabled}
        // mini
      >
       <img src={props.startIcon} alt="start icon" />
      </IconButton>
    )
}

export default SocialmediaButton
