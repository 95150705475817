import { icons } from "../../Assets/images/IconHelper";

export const DefaultState = [
  {
    to: "/profile/account",
    icon: icons.profile,
    text: "Profile.MyAccount",
  },
  {
    to: "/profile/orders",
    icon: icons.boxes,
    text: "Profile.MyOrders",
  },
  // {
  //   to: "/profile/boxes",
  //   icon: icons.box,
  //   text: "Profile.MyBoxes",
  // },
  // {
  //   to: "/profile/items",
  //   icon: icons.group,
  //   text: "Profile.MyItems",
  // },
  // {
  //   to: "/profile/rewards",
  //   icon: icons.medal,
  //   text: "Profile.DanRewards",
  // },
  {
    to: "/profile/rewards",
    icon: icons.medal,
    text: "Profile.Rewards",
  },
  {
    to: "/profile/addresses",
    icon: icons.location,
    text: "Profile.MyAddresses",
  },
  // { to: "/profile/my_requests", icon: icons.group, text: "My Requests" },
  {
    to: "/profile/paymentlist",
    icon: icons.card,
    text: "Profile.MyPayment",
  },
];

export const ETradelingState =
  localStorage.getItem("USER_TYPE") == "vendor"
    ? [
        {
          to: "/profile/account",
          icon: icons.profile,
          text: "Profile.MyAccount",
        },
        {
          to: "/profile/orders",
          icon: icons.boxes,
          text: "Profile.MyOrders",
        },
        {
          to: "/profile/addresses",
          icon: icons.location,
          text: "Profile.MyAddresses",
        },
        {
          to: "/profile/paymentlist",
          icon: icons.card,
          text: "Profile.MyPayment",
        },
        {
          to: "/profile/my_requests",
          icon: icons.boxes,
          text: "My Requests",
        },
        {
          to: "/profile/my_proposals",
          icon: icons.boxes,
          text: "My Proposals",
        },
      ]
    : [
        {
          to: "/profile/account",
          icon: icons.profile,
          text: "Profile.MyAccount",
        },
        {
          to: "/profile/orders",
          icon: icons.boxes,
          text: "Profile.MyOrders",
        },
        {
          to: "/profile/addresses",
          icon: icons.location,
          text: "Profile.MyAddresses",
        },
        {
          to: "/profile/paymentlist",
          icon: icons.card,
          text: "Profile.MyPayment",
        },
        {
          to: "/profile/my_requests",
          icon: icons.boxes,
          text: "My Requests",
        },
      ];

export const ESolarState =
  localStorage.getItem("USER_TYPE") == "vendor"
    ? [
        {
          to: "/profile/account",
          icon: icons.profile,
          text: "Profile.MyAccount",
        },
        {
          to: "/profile/orders",
          icon: icons.boxes,
          text: "Profile.MyOrders",
        },
        {
          to: "/profile/my_requests",
          icon: icons.boxes,
          text: "My Requests",
        },
        {
          to: "/profile/my_proposals",
          icon: icons.boxes,
          text: "My Proposals",
        },
        {
          to: "/profile/messenger",
          icon: icons.MessengerIcon,
          text: "Profile.Messenger",
        },
        {
          to: "/profile/addresses",
          icon: icons.location,
          text: "Profile.MyAddresses",
        },
        {
          to: "/profile/paymentlist",
          icon: icons.card,
          text: "Profile.MyPayment",
        },
      ]
    : [
        {
          to: "/profile/account",
          icon: icons.profile,
          text: "Profile.MyAccount",
        },
        {
          to: "/profile/orders",
          icon: icons.boxes,
          text: "Profile.MyOrders",
        },
        {
          to: "/profile/my_requests",
          icon: icons.boxes,
          text: "My Requests",
        },
        {
          to: "/profile/messenger",
          icon: icons.MessengerIcon,
          text: "Profile.Messenger",
        },
        {
          to: "/profile/addresses",
          icon: icons.location,
          text: "Profile.MyAddresses",
        },
        {
          to: "/profile/paymentlist",
          icon: icons.card,
          text: "Profile.MyPayment",
        },
      ];

export const AlsallabState = [
  {
    to: "/profile/account",
    icon: icons.profile,
    text: "Profile.MyAccount",
  },
  {
    to: "/profile/orders",
    icon: icons.boxes,
    text: "Profile.MyOrders",
  },
  {
    to: "/profile/rewards",
    icon: icons.medal,
    text: "Profile.Rewards",
  },
  {
    to: "/profile/addresses",
    icon: icons.location,
    text: "Profile.MyAddresses",
  },
  {
    to: "/profile/paymentlist",
    icon: icons.card,
    text: "Profile.MyPayment",
  },
];
