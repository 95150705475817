import React, { createContext } from "react";

export const LocalizationContext = createContext();

const LocalizationProviders = (props) => {
  const localizationAr = props.localizationAr;
  return (
    <div>
      <LocalizationContext.Provider value={{ localizationAr }}>
        {props.children}
      </LocalizationContext.Provider>
    </div>
  );
};

export default LocalizationProviders;
