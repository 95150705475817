import { withTranslation } from "react-i18next";
import { Grid } from "@material-ui/core";
import { Link } from "react-router-dom";

import PrimaryButton from "../../Atoms/Buttons/Primary/PrimaryButton";

import "./ShoppingCard.css";

const ShoppingCard = (props) => {
  const { t } = props;

  return (
    <Grid item xs={12} sm={6} md={4}>
      <Link to={`/shopping?taxon=${props.filter}`}>
        <div
          className="shopping_item"
          style={{ backgroundImage: `url(${props.image})` }}>
          <p className="shopping-card-title">{props.title}</p>

          <div className="shopping-card-subtitle">
            <p>{props.text}</p>
          </div>

          <div className="shopping-card-btn">
            <Link to={`/shopping?taxon=${props.filter}`}>
              <PrimaryButton
                classBtn="btn-white btn-rounded btn-mini"
                text={t(`shopping.seeAll`)}
              />
            </Link>
          </div>
        </div>
      </Link>
    </Grid>
  );
};

export default withTranslation()(ShoppingCard);
