import "./Wishlist.css";

import { CircularProgress } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import ProductCard from "../../../Components/Organisms/Cards/ProductCard/ProductCard";
import { images } from "../../../Assets/images/IconHelper";
import { withTranslation } from "react-i18next";

function WishlistTemplate({
  t,
  moveProductToCart,
  wishlistItems,
  isWishlistLoading,
  deleteFromWishlist,
}) {
  return (
    <div>
      <h2 className="wishlist-title">{t("navbar.Whishlist")}</h2>

      {isWishlistLoading ? (
        <div className="progress-indicator">
          <CircularProgress style={{ color: "var(--secondary-color)" }} />
        </div>
      ) : (
        <Grid container spacing={3}>
          {wishlistItems?.map((prod) => {
            return (
              <Grid item key={prod.id} xs={12} sm={6} md={3}>
                <ProductCard
                  variantID={prod?.variant.id}
                  id={prod?.id}
                  img={prod?.variant?.image ?? images.productPlaceHolder}
                  title={prod?.variant?.name}
                  icon="delete"
                  rating={4}
                  currency={prod?.variant?.cost_currency}
                  price={prod?.variant?.price}
                  unit="KG"
                  // discount="30" // needed
                  variant="wishlist"
                  moveProductToCart={moveProductToCart}
                  deleteFromWishlist={deleteFromWishlist}
                />
              </Grid>
            );
          })}
        </Grid>
      )}
    </div>
  );
}

export default withTranslation()(WishlistTemplate);
