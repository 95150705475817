import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import { useHistory } from "react-router";
import { withTranslation } from "react-i18next";

import PrimaryButton from "../../Atoms/Buttons/Primary/PrimaryButton";

import "./CheckoutAddress.css";

const CheckoutAddress = ({ t, i18n, name, phone, address }) => {
  const history = useHistory();

  return (
    <div className="checkoutAddress-card">
      <div className="checkoutAddress-header">
        <p className="checkoutAddress-title">{t("checkout.deliver to")}</p>
        <div>
          <PrimaryButton
            text={t("address.change address")}
            classBtn="outline"
            endIcon={
              <ArrowRightAltIcon
                style={{
                  transform: `rotate(${
                    i18n.language === "ar" ? "180deg" : "0"
                  })`,
                }}
              />
            }
            click={() => history.push("/changeAddress")}
          />
        </div>
      </div>

      <div className="checkoutAddress-context">
        <p className="checkoutAddress-name">{name}</p>
        <p className="checkoutAddress-phone">{phone}</p>
        <p className="checkoutAddress-address">{address}</p>
      </div>
    </div>
  );
};

export default withTranslation()(CheckoutAddress);
