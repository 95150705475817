import { Link, Typography } from "@mui/material";

import CircularProgressWithLabel from "../CircularProgressWithLabel/CircularProgressWithLabel";
import { icons } from "../../../Assets/images/IconHelper";
import { useEffect } from "react";
import { useState } from "react";

const UploadedFile = ({
  fileType,
  fileName,
  handleRemove,
  id,
  files,
  setter,
  backendId,
  src,
  percent,
}) => {
  // IF percent reaches 100 turn its uploaded state to true and show the uploaded file and stop the useeffect from working
  // const [beingUploaded, setBeingUploaded] = useState(true);
  // console.log(percent);
  // useEffect(() => {
  //   if (percent === 100 && beingUploaded) {
  //     setBeingUploaded(false);
  //   } else {
  //     setBeingUploaded(true);
  //   }
  // }, [percent]);
  return (
    <div style={{ width: 80, marginRight: 16 }}>
      <div>
        <div
          style={{
            height: 80,
            width: 80,
            // backgroundColor: "#979797",
            borderRadius: 4,
            display: "flex",
            justifyContent: "flex-end",
            backgroundImage:
              fileType === "pdf" || fileType === ".pdf"
                ? `url(${icons.pdfIcon})`
                : `url("${src}")`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
          }}
        >
          {handleRemove ? (
            <div
              style={{
                color: "white",
                backgroundColor: "black",
                borderRadius: 10,
                height: 23,
                width: 23,
                display: "flex",
                justifyContent: "center",
                position: "relative",
                left: 3,
                bottom: 5,
                cursor: "pointer",
              }}
              onClick={() => handleRemove(setter, files, id, backendId)}
            >
              <Typography>x</Typography>
            </div>
          ) : (
            <></>
          )}
        </div>
        <Link href={src} sx={{ color: "black" }}>
          <div style={{ display: "flex" }}>
            <Typography noWrap>{fileName}</Typography>
            <Typography>{fileType}</Typography>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default UploadedFile;

//  {beingUploaded ? (
//       <div className="progress-indicator">
//         <CircularProgressWithLabel
//           style={{ color: "var(--secondary-color)" }}
//           value={percent}
//         />
//       </div>
//     ) : (
//       <div>
//         <div
//           style={{
//             height: 80,
//             width: 80,
//             // backgroundColor: "#979797",
//             borderRadius: 4,
//             display: "flex",
//             justifyContent: "flex-end",
//             backgroundImage:
//               fileType === "pdf" ? `url(${icons.pdfIcon})` : `url("${src}")`,
//             backgroundRepeat: "no-repeat",
//             backgroundPosition: "center",
//           }}
//         >
//           {handleRemove ? (
//             <div
//               style={{
//                 color: "white",
//                 backgroundColor: "black",
//                 borderRadius: 10,
//                 height: 23,
//                 width: 23,
//                 display: "flex",
//                 justifyContent: "center",
//                 position: "relative",
//                 left: 3,
//                 bottom: 5,
//                 cursor: "pointer",
//               }}
//               onClick={() => handleRemove(setter, files, id, backendId)}
//             >
//               <Typography>x</Typography>
//             </div>
//           ) : (
//             <></>
//           )}
//         </div>
//         <div style={{ display: "flex" }}>
//           <Typography noWrap>{fileName}</Typography>
//           <Typography>{fileType}</Typography>
//         </div>
//       </div>
//     )}
