import { useState, useEffect } from "react";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@material-ui/core";
import { withTranslation } from "react-i18next";
import * as yup from "yup";
import { useFormik } from "formik";
import PhoneInput from "react-phone-input-2";

import TextFieldInput from "../../../Atoms/Inputs/TextField/TextFieldInput";
import PrimaryButton from "../../../Atoms/Buttons/Primary/PrimaryButton";
import TextArea from "../../../Atoms/Inputs/TextArea/TextArea";

import "react-phone-input-2/lib/style.css";
import "./AddAdressModal.css";
import "../ModalStyle.css";

function AddAdressModal(props) {
  const { t, i18n, dataToEdit } = props;

  const validationSchema = yup.object().shape({
    name: yup.string().required("Name is Required"),
    last_name: yup.string().required("Last Name is Required"),
    country: yup.string().required("Country is Required"),
    // city: yup.string().required("City is Required"),
    // ZIPCode: yup.string(),
    state_name: yup.string().required("State is Required"),
    // country_ISO: yup.string().required("country ISO is Required"),
    // country_ISO3: yup.string().required("country ISO3 is Required"),
    // label: yup.string().required("Label is Required"),
    // state_code: yup.string().required("State Code is Required"),
    number: yup
      .string()
      .required("Mobile Number is Required")
      .matches(/^[0-9]+$/, "Mobile Number must be only digits"),
    Address1: yup.string().required("Address 1 is Required"),
    // Address2: yup.string(),
  });

  const [clicked, setClicked] = useState(false);
  const formik = useFormik({
    initialValues: {
      name: "",
      last_name: "",
      country: "",
      // city: "",
      // ZIPCode: "",
      state_name: "",
      // company: "null",
      // country_ISO: "",
      // country_ISO3: "",
      // label: "",
      // state_code: "",
      number: "",
      Address1: "",
      // Address2: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (props.type === "add") {
        props.addAddress({
          address: {
            firstname: values.name,
            lastname: values.last_name,
            address1: values.Address1,
            // address2: values.Address2,
            // city: values.city,
            // zipcode: values.ZIPCode,
            phone: values.number,
            state_name: values.state_name,
            // company: values.company,
            country: values.country,
            // country_iso3: values.country_ISO3,
            // state_code: values.state_code,
            // country_iso: values.country_ISO,
            // label: values.label,
          },
        });
      } else {
        props.editAddress(dataToEdit.id, {
          firstname: values.name,
          lastname: values.last_name,
          address1: values.Address1,
          // address2: values.Address2,
          // city: values.city,
          // zipcode: values.ZIPCode,
          phone: values.number,
          state_name: values.state_name,
          // company: "",
          country_name: values.country,
          // country_iso3: values.country_ISO,
          // country_iso: values.country_ISO3,
          // label: values.label,
          // state_code: values.state_code,
        });
      }
    },
  });

  useEffect(() => {
    if (props.type == "edit") {
      formik.setFieldValue("name", dataToEdit?.firstname);
      formik.setFieldValue("last_name", dataToEdit?.lastname);
      formik.setFieldValue("Address1", dataToEdit?.address1);
      // formik.setFieldValue("Address2", dataToEdit?.address1);
      // formik.setFieldValue("city", dataToEdit?.city);
      // formik.setFieldValue("ZIPCode", dataToEdit?.zipcode);
      formik.setFieldValue("number", dataToEdit?.phone);
      formik.setFieldValue("state_name", dataToEdit?.state_name);
      // formik.setFieldValue("company", dataToEdit?.company);
      formik.setFieldValue("country", dataToEdit?.country_name);
      // formik.setFieldValue("country_ISO3", dataToEdit?.country_iso3);
      // formik.setFieldValue("state_code", dataToEdit?.state_code);
      // formik.setFieldValue("country_ISO", dataToEdit?.country_iso);
      // formik.setFieldValue("label", dataToEdit?.label);
    } else {
      formik.setFieldValue("name", "");
      formik.setFieldValue("last_name", "");
      formik.setFieldValue("Address1", "");
      // formik.setFieldValue("Address2", "");
      // formik.setFieldValue("city", "");
      // formik.setFieldValue("ZIPCode", "");
      formik.setFieldValue("number", "");
      formik.setFieldValue("state_name", "");
      // formik.setFieldValue("company", "");
      formik.setFieldValue("country", "");
      // formik.setFieldValue("country_ISO3", "");
      // formik.setFieldValue("state_code", "");
      // formik.setFieldValue("country_ISO", "");
      // formik.setFieldValue("label", "");
    }
  }, [props.type, dataToEdit]);

  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      className={i18n.language === "ar" ? "rtl_modal" : ""}
    >
      <DialogTitle>
        <p className="Modal_header">
          {props?.type !== "edit"
            ? t("address.Add New Address")
            : t("address.Edit Address")}
        </p>
      </DialogTitle>
      <DialogContent>
        <Grid container className="modal_form">
          <Grid item xs={12}>
            <label>{t("address.Name")}</label>
            <TextFieldInput
              className="form_input"
              placeholder={t("address.Name")}
              onchange={(value) => formik.setFieldValue("name", value)}
              value={formik.values.name}
              error={clicked && formik.errors.name}
              helperText={clicked && formik.errors.name}
            />
          </Grid>
          <Grid item xs={12}>
            <label>{t("address.Last Name")}</label>
            <TextFieldInput
              className="form_input"
              placeholder={t("address.Last Name")}
              onchange={(value) => formik.setFieldValue("last_name", value)}
              value={formik.values.last_name}
              error={clicked && formik.errors.last_name}
              helperText={clicked && formik.errors.last_name}
            />
          </Grid>
          <Grid
            item
            xs={12}
            className={`form_input-phone ${
              formik.errors.number ? "form_input-error" : ""
            }`}
          >
            <label>{t("address.Phone Number")}</label>
            <PhoneInput
              country={"sa"}
              value={formik.values.number}
              placeholder={t("loginBtn.Your Phone Number")}
              onChange={(phone) => {
                formik.setFieldValue("number", phone);
              }}
            />
            {formik.errors.number && (
              <p className="add-address-error">{formik.errors.number}</p>
            )}
          </Grid>
          <Grid item xs={12}>
            <label>{t("address.Country")}</label>
            <TextFieldInput
              className="form_input"
              placeholder={t("address.Country")}
              onchange={(value) => formik.setFieldValue("country", value)}
              value={formik.values.country}
              error={clicked && formik.errors.country}
              helperText={clicked && formik.errors.country}
            />
          </Grid>
          <Grid item xs={12}>
            <label>{t("address.State Name")}</label>
            <TextFieldInput
              className="form_input"
              placeholder={t("address.State Name")}
              onchange={(value) => formik.setFieldValue("state_name", value)}
              value={formik.values.state_name}
              error={clicked && formik.errors.state_name}
              helperText={clicked && formik.errors.state_name}
            />
          </Grid>
          <Grid
            item
            xs={12}
            className={`form_input-textarea ${
              formik.errors.Address1 ? "form_input-error" : ""
            }`}
          >
            <label>{t("address.Address1")}</label>
            <TextArea
              placeholder={t("address.Write Your Address")}
              value={formik.values.Address1}
              error={clicked && formik.errors.Address1}
              onchange={(addr) => formik.setFieldValue("Address1", addr)}
            />

            {formik.errors.Address1 && (
              <p className="add-address-error">{formik.errors.Address1}</p>
            )}

            {/* <label>{t("address.Address2")}</label>
            <TextArea
              placeholder={t("address.Write Your Address")}
              value={formik.values.Address2}
              onchange={(addr) => formik.setFieldValue("Address2", addr)}
            /> */}
          </Grid>
          {/* <Grid item xs={12}>
            <label>{t("address.City")}</label>
            <TextFieldInput
              className="form_input"
              placeholder={t("address.City")}
              onchange={(value) => formik.setFieldValue("city", value)}
              value={formik.values.city}
              error={clicked && formik.errors.city}
              helperText={clicked && formik.errors.city}
            />
          </Grid>
          <Grid item xs={12}>
            <label>{t("address.Zip Code")}</label>
            <TextFieldInput
              className="form_input"
              placeholder={t("address.Zip Code")}
              onchange={(value) => formik.setFieldValue("ZIPCode", value)}
              value={formik.values.ZIPCode}
              error={clicked && formik.errors.ZIPCode}
              helperText={clicked && formik.errors.ZIPCode}
            />
          </Grid> 
          <Grid item xs={12}>
            <label>{t("address.Country ISO")}</label>
            <TextFieldInput
              className="form_input"
              placeholder={t("address.Country ISO")}
              onchange={(value) => formik.setFieldValue("country_ISO", value)}
              value={formik.values.country_ISO}
              error={clicked && formik.errors.country_ISO}
              helperText={clicked && formik.errors.country_ISO}
            />
          </Grid>
          <Grid item xs={12}>
            <label>{t("address.Country ISO3")}</label>
            <TextFieldInput
              className="form_input"
              placeholder={t("address.Country ISO3")}
              onchange={(value) => formik.setFieldValue("country_ISO3", value)}
              value={formik.values.country_ISO3}
              error={clicked && formik.errors.country_ISO3}
              helperText={clicked && formik.errors.country_ISO3}
            />
          </Grid> 
          <Grid item xs={12}>
            <label>{t("address.Label")}</label>
            <TextFieldInput
              className="form_input"
              placeholder={t("address.Label")}
              onchange={(value) => formik.setFieldValue("label", value)}
              value={formik.values.label}
              error={clicked && formik.errors.label}
              helperText={clicked && formik.errors.label}
            />
          </Grid>
          <Grid item xs={12}>
            <label>{t("address.State Code")}</label>
            <TextFieldInput
              className="form_input"
              placeholder={t("address.State Code")}
              onchange={(value) => formik.setFieldValue("state_code", value)}
              value={formik.values.state_code}
              error={clicked && formik.errors.state_code}
              helperText={clicked && formik.errors.state_code}
            />
          </Grid> */}
        </Grid>
      </DialogContent>
      <DialogActions>
        <PrimaryButton
          classBtn="primary modal"
          text={props?.type !== "edit" ? t("general.add") : t("general.edit")}
          click={() => {
            setClicked(true);
            formik.handleSubmit();
          }}
        />
      </DialogActions>
    </Dialog>
  );
}

export default withTranslation()(AddAdressModal);

// const autoValidate = (addressState) => {};

// const validateName = () => {
//   if (!address.name || address.name.trim() === "") {
//     setNameErr("Name cannot be empty");
//     setAddress({ ...address, name: "" });
//     return false;
//   }
//   const name = address.name.split(" ");
//   // setDataFinal({ ...dataFinal, firstname: name[0] })
//   dataFinal = { ...dataFinal, firstname: name[0] };
//   if (name.length > 1) {
//     // setDataFinal({
//     //   ...dataFinal,
//     //   lastname: name[name.length - 1]
//     // })
//     dataFinal = { ...dataFinal, lastname: name[name.length - 1] };
//   }
//   return true;
// };

// const validateLastName = () => {
//   if (!address.lastname || address.lastname.trim() === "") {
//     setLastnameErr("Last name cannot be empty");
//     setAddress({ ...address, lastname: "" });
//     return false;
//   }

//   dataFinal = {
//     ...dataFinal,
//     lastname: address.lastname,
//   };
//   return true;
// };

// const validatePhone = () => {
//   if (!address.phone || address.phone.trim() === "") {
//     setPhoneErr("Phone cannot be empty");
//     setAddress({ ...address, phone: "" });
//     return false;

//   }
//   // setDataFinal({
//   //   ...dataFinal,
//   //   phone: address.phone
//   // })
//   dataFinal = {
//     ...dataFinal,
//     phone: address.phone,
//   };
//   return true;
// };

// const validateAddress1 = () => {
//   if (!address.address1 || address.address1.trim() === "") {
//     setAddErr("Address cannot be empty");
//     setAddress({ ...address, address1: "" });
//     return false;
//   }

//   // setDataFinal({
//   //   ...dataFinal,
//   //   address1: address.address.trim()
//   // })
//   dataFinal = {
//     ...dataFinal,
//     address1: address.address1.trim(),
//   };

//   return true;
// };

// const validateCountry = () => {
//   if (!address.country_name || address.country_name.trim() === "") {
//     setCountryErr("Country cannot be empty");
//     setAddress({ ...address, country_name: "" });
//     return false;
//   }
//   dataFinal = {
//     ...dataFinal,
//     country_name: address.country_name,
//   };
//   return true;
// };

// const validateCity = () => {
//   if (!address.city || address.city.trim() === "") {
//     setCityErr("City cannot be empty");
//     setAddress({ ...address, city: "" });
//     return false;
//   }
//   dataFinal = {
//     ...dataFinal,
//     city: address.city,
//   };
//   return true;
// };

// const validateZipCode = () => {
//   if (!address.zipcode || address.zipcode.trim() === "") {
//     setZipcodeErr("Zipcode cannot be empty");
//     setAddress({ ...address, zipcode: "" });
//     return false;
//   }
//   dataFinal = {
//     ...dataFinal,
//     zipcode: address.zipcode,
//   };
//   return true;
// };

// const validateAddress2 = () => {
//   if (!address.address2 || address.address2.trim() === "") {
//     setZipcodeErr("Address cannot be empty");
//     setAddress({ ...address, address2: "" });
//     return false;
//   }
//   dataFinal = {
//     ...dataFinal,
//     address2: address.address2,
//   };
//   return true;
// };
// const validateStatename = () => {
//   if (!address.state_name || address.state_name.trim() === "") {
//     setZipcodeErr("State name cannot be empty");
//     setAddress({ ...address, state_name: "" });
//     return false;
//   }
//   dataFinal = {
//     ...dataFinal,
//     state_name: address.state_name,
//   };
//   return true;
// };
// const validateCountryIso3 = () => {
//   if (!address.country_iso3 || address.country_iso3.trim() === "") {
//     setZipcodeErr("CountryISO3 cannot be empty");
//     setAddress({ ...address, country_iso3: "" });
//     return false;
//   }
//   dataFinal = {
//     ...dataFinal,
//     country_iso3: address.country_iso3,
//   };
//   return true;
// };
// const validateCountryIso = () => {
//   if (!address.country_iso || address.country_iso.trim() === "") {
//     setZipcodeErr("CountryISO cannot be empty");
//     setAddress({ ...address, country_iso: "" });
//     return false;
//   }
//   dataFinal = {
//     ...dataFinal,
//     country_iso: address.country_iso,
//   };
//   return true;
// };
