import { useContext, useEffect, useState } from "react";

import { Grid, Select, MenuItem } from "@material-ui/core";
import { useHistory, useLocation } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { images } from "../../../Assets/images/IconHelper";

import { ProductContext } from "../../../Contexts/productContext/productContext";
import { BadgesContext } from "../../../Contexts/badgesContext/badgesContext";

import PrimaryButton from "../../../Components/Atoms/Buttons/Primary/PrimaryButton";
import ProductCard from "../../../Components/Organisms/Cards/ProductCard/ProductCard";
import FiltersSidepanel from "../../../Components/Organisms/FiltersSidepanel/FiltersSidepanel";

//since this page has same stying as ShoppingTemplate, this stylesheet is used
import "../Shopping/ShoppingTemplate.css";

const SearchResultsTemplate = (props) => {
  const { t, getProductsList } = props;

  const { addToCart /* , deleteFromCart */ } = useContext(ProductContext);

  const { badges, setBadges } = useContext(BadgesContext);

  const history = useHistory();

  let query = new URLSearchParams(useLocation().search)
    .get("query")
    ?.trim()
    ?.toLowerCase();

  const [filter, setFilter] = useState();
  const [sortBy, setSortBy] = useState(0);
  const [products, setProducts] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);

  const [searchQuery, setSearchQuery] = useState(query);

  // const setSearchFilter = (fil)=>{
  //     fil = fil? `&${fil}`:fil;
  //     setFilter(`filter[name]=${query}${fil}`);
  // }

  useEffect(() => {
    setSearchQuery(query);
  }, [query]);

  //gets the products with filtering from api
  const loadData = async (filter, page) => {
    const p = await getProductsList(filter, page);
    setProductsSorted(page === 1 ? p?.data : products.concat(p?.data));
    setTotalCount(p?.meta?.total_count);
    setTotalPages(p?.meta?.total_pages);
    return p;
  };

  //when the filter changes update products
  useEffect(() => {
    if (searchQuery) {
      loadData(`filter[name]=${searchQuery}`, 1);
      setCurrentPage(1);
    } else loadData(undefined, 1);
  }, [searchQuery]);

  useEffect(() => {
    if (filter) {
      loadData(filter, 1);

      setCurrentPage(1);
    }
    if (!filter && !searchQuery) loadData(undefined, 1);
  }, [filter, searchQuery]);

  //handles the pagination
  const loadMore = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
      loadData(filter, currentPage + 1);
      setProducts(sortProducts(products, sortBy === 0 ? "desc" : "asc"));
    }
  };

  const sortProducts = (prods, crit) => {
    let sorted = prods?.sort((a, b) => {
      if (a?.attributes?.price > b?.attributes?.price) {
        return crit === "desc" ? -1 : 1;
      } else {
        return crit === "desc" ? 1 : -1;
      }
    });

    if (!sorted) {
      sorted = [];
    }
    return sorted;
  };

  //sort the products when the sort dropdown changes
  useEffect(() => {
    setProductsSorted(products);
  }, [sortBy]);

  const setProductsSorted = (prods) => {
    setProducts((products) => {
      products = [...sortProducts(prods, sortBy === 0 ? "desc" : "asc")];
      return products;
    });
  };

  const handleChange = (event) => {
    setSortBy(event.target.value);
  };

  const [state, setState] = useState({
    mobileView: false,
  });

  const { mobileView } = state;

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 600
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };

    setResponsiveness();
    window.addEventListener("resize", () => setResponsiveness());

    return () => {
      window.removeEventListener("resize", () => setResponsiveness());
    };
  }, [window.innerWidth]);

  const handleAddToCart = async (id, count) => {
    if (localStorage.getItem("TOKEN")) {
      const res = await addToCart({
        variant_id: id,
        quantity: count,
        // "options": "{}"
      });
      if (res.err) {
        toast.error(res.err);
      } else {
        setBadges({ ...badges, cart: badges.cart + 1 });
      }
    } else {
      history.push("/login");
    }
  };

  return (
    <Grid container>
      <Grid item xs={3}>
        <FiltersSidepanel
          // filter={filter}
          setFilter={setFilter}
          mobileView={state.mobileView}
          searchQuery={searchQuery}
        />
      </Grid>

      <Grid item xs={9} className="shopping-products-cont">
        <div className="shopping-header">
          <div className="shopping-sort">
            <span className="shopping-sort-text">
              {t("shoppingPage.sort by")}:{" "}
            </span>
            <span>
              <Select
                value={sortBy}
                onChange={handleChange}
                inputProps={{ "aria-label": "Without label" }}
                className="shopping-sort-input shopping-sort-selected">
                <MenuItem value={0} className="shopping-sort-input">
                  {t("shoppingPage.high to low")}
                </MenuItem>
                <MenuItem value={1} className="shopping-sort-input">
                  {t("shoppingPage.low to high")}
                </MenuItem>
              </Select>
            </span>
          </div>
        </div>
        <img src={images.line} width="100%" alt="" />
        <p className="shopping-productsfound">
          {totalCount} {t("shoppingPage.products found")}
        </p>
        <Grid container spacing={2}>
          {products?.map((prod) => (
            <Grid item xs={12} sm={6} md={4} key={prod?.id}>
              <ProductCard
                id={prod?.id}
                img={prod?.relationships?.images?.data[0]}
                title={prod?.attributes?.name}
                rating={4}
                currency={prod?.attributes?.currency}
                price={prod?.attributes?.price}
                unit="KG"
                discount="30"
                handleAddToCart={handleAddToCart}
              />
            </Grid>
          ))}
        </Grid>

        <div className="shopping-loadmore">
          {totalPages > currentPage ? (
            <PrimaryButton
              text={t("load more")}
              click={loadMore}
              classBtn="outline"
            />
          ) : null}
        </div>
      </Grid>
    </Grid>
  );
};

export default withTranslation()(SearchResultsTemplate);
