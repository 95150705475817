import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import { images } from "../../../Assets/images/IconHelper";
import ClientForm from "../../../Components/Organisms/ClientForm/ClientForm";
import "./BecomeVendor.css";
import Stepper from "../../../Components/Molecules/Stepper/Stepper";
import VendorPhase1 from "./Phases/VendorPhase1.Template";
import VendorPhase2 from "./Phases/VendorPhase2.Template";
import VendorPhase3 from "./Phases/VendorPhase3.Template";
import { Box, Container } from "@mui/material";

function BecomeVendorTemplate({ t, handleSubmit, vendorTypes }) {
  const [proposal, setProposal] = useState({
    company_logo: "",
    company_name: "",
    vendor_type_ids: [],
    commercial_number: "",
    tax_id: "",
    company_description: "",
  });
  // Stepper states
  const [value, setValue] = useState(1);
  const handleChange = (newValue) => {
    setValue(newValue);
  };

  const [stepsValidation, setStepsValidation] = useState([
    {
      condition: false,
      number: 1,
    },
    {
      condition: false,
      number: 2,
    },
  ]);

  const steps = [
    {
      title: "Details",
      number: 1,
      component: (
        <VendorPhase1
          proposal={proposal}
          setProposal={setProposal}
          handleChange={handleChange}
          stepsValidation={stepsValidation}
          setStepsValidation={setStepsValidation}
          vendorTypes={vendorTypes}
          handleSubmit={handleSubmit}
        />
      ),
    },
    // {
    //   title: "Review",
    //   number: 2,
    //   component: (
    //     <VendorPhase3
    //       proposal={proposal}
    //       setProposal={setProposal}
    //       handleChange={handleChange}
    //       handleSubmit={handleSubmit}
    //       vendorTypes={vendorTypes}
    //     />
    //   ),
    // },
  ];
  return (
    <div>
      <div className="vendor-header" style={{ background: "#f8f8f8" }}>
        <div className="scaleX">
          <p className="vendor-header-lead">{t("become vendor.love")}</p>
          <p className="vendor-header-title ">{t("become vendor.become")}</p>
          <p className="vendor-header-text">
            Create vendor account and connect your customer in easy way
          </p>
        </div>
      </div>
      <Container component="main" maxWidth="sm">
        <Stepper
          steps={steps}
          stepValue={value}
          stepHandle={handleChange}
          stepsValidation={stepsValidation}
          showCircles={false}
        />
      </Container>
    </div>
  );
}

export default withTranslation()(BecomeVendorTemplate);
