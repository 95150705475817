import { useHistory, useLocation } from "react-router-dom";

import VendorProposalDetailsTemplate from "../../../Templates/Home/Profile/VendorProposalDetails/VendorProposalDetais.template";
import { queryParamsFormatter } from "../../../utils/queryParamsFormatter/queryParamsFormatter";
import { useProposals } from "../../../Hooks/useProposals/useProposals";
import { useState } from "react";

const VendorProposalDetailsPage = () => {
  const location = useLocation();
  const history = useHistory();
  const [modalOpen, setModalOpen] = useState(false);
  const { deleteProposal } = useProposals();

  const handleDelete = async (id) => {
    await deleteProposal(id);
    history.push("/profile/my_proposals");
  };

  const handleModal = () => {
    setModalOpen(!modalOpen);
  };

  return (
    <VendorProposalDetailsTemplate
      data={location?.state}
      handleModal={handleModal}
      handleDelete={handleDelete}
      modalOpen={modalOpen}
    />
  );
};

export default VendorProposalDetailsPage;
