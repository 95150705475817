import React from "react";
import { withTranslation } from "react-i18next";
import { images } from "../../../Assets/images/IconHelper";
import BlogTabs from "../../../Components/Organisms/BlogTabs/BlogTabs";
import BlogCard from "../../../Components/Organisms/Cards/BlogCard/BlogCard";
import "./Blog.css";

function BlogTemplate({ t, mobileView }) {
  return (
    <div>
      <div
        className="kitchen-header"
        style={{
          background: `#f8f8f8`,
        }}
      >
        <p className="kitchen-header-lead">{t("explore")}</p>
        <p className="kitchen-header-title">{t("footer.OurBlog")}</p>
      </div>

      <div className="blog-item">
        <BlogCard
          id={1}
          title={"Get The Boot A Birds Eye Look Into Mcse Boot Camps"}
          by={"Allison Fox"}
          date={"June 8, 2019"}
          text={
            "Some people get into food blogging just to share, while others are hoping to turn their passion into a profession. But while you know your way around the kitchen, when it comes to starting while"
          }
          img={images.mushrooms}
          mobileView={mobileView}
        />
      </div>

      <div className="blog-item">
        <BlogCard
          id={2}
          title={"Get The Boot A Birds Eye Look Into Mcse Boot Camps"}
          by={"Allison Fox"}
          date={"June 8, 2019"}
          text={
            "Some people get into food blogging just to share, while others are hoping to turn their passion into a profession. But while you know your way around the kitchen, when it comes to starting while"
          }
          img={images.mushrooms}
          mobileView={mobileView}
        />
      </div>

      <div className="blog-item">
        <BlogCard
          id={3}
          title={"Get The Boot A Birds Eye Look Into Mcse Boot Camps"}
          by={"Allison Fox"}
          date={"June 8, 2019"}
          text={
            "Some people get into food blogging just to share, while others are hoping to turn their passion into a profession. But while you know your way around the kitchen, when it comes to starting while"
          }
          img={images.mushrooms}
          mobileView={mobileView}
        />
      </div>
    </div>
  );
}

export default withTranslation()(BlogTemplate);
