import "./ESolarAdBannerCard.css";

import { Grid, Typography } from "@mui/material";

import { Link } from "react-router-dom";
import PrimaryButton from "../../Atoms/Buttons/Primary/PrimaryButton";
import { images } from "../../../Assets/images/IconHelper";
import { withTranslation } from "react-i18next";

const EsolarAdbannerCard = ({ image, title, t, description }) => {
  return (
    <Grid item xs={6} lg={6} container justifyContent={"flex-start"}>
      <div
        style={{
          backgroundImage: `url(${image})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
          border: "solid 2px #e2e2e2",
          borderRadius: 10,
          backgroundPosition: "bottom right",
          width: "100%",
          height: "100%",
          maxHeight: "500px",
        }}
      >
        <Grid
          container
          justifyContent="flex-start"
          textAlign={"left"}
          paddingLeft={3}
          paddingTop={3}
          paddingBottom={3}
          paddingRight={3}
        >
          <Grid item xs={12} container justifyContent="flex-start">
            <Typography className="ad-banner-title">{title}</Typography>
          </Grid>
          <Grid item xs={12} container justifyContent="flex-start">
            <Typography style={{ width: "40%" }}>{description}</Typography>
          </Grid>
          <Grid
            item
            xs={12}
            container
            justifyContent="flex-start"
            marginTop={2}
          >
            <div style={{ width: "20%" }}>
              <PrimaryButton
                classBtn={"outline-secondry btn-shadow"}
                text={"Explore"}
                style={{ marginBottom: 20 }}
              />
            </div>
          </Grid>
        </Grid>
      </div>
    </Grid>
  );
};

export default withTranslation()(EsolarAdbannerCard);
