import { Grid } from "@material-ui/core";
import React, { useState } from "react";
import { useEffect } from "react";
import { ImageView } from "../../Molecules";
import styles from "./ProductView.module.css";
import { images } from "../../../Assets/images/IconHelper";

const ProductView = (props) => {
  const [imgView, setimgView] = useState("");
  const [imgs, setImgs] = useState(props?.images);

  // useEffect(() => {
  //     if (!imgs || imgs.length === 0) {
  //         setImgs([
  //             {
  //                 url: images.apple
  //             },
  //             {
  //                 url: images.banana
  //             },
  //             {
  //                 url: images.redapple
  //             }
  //         ])
  //     };
  // }, [])

  useEffect(() => {
    if (props?.images) setImgs(props?.images);
  }, [props?.images]);

  useEffect(() => {
    if (imgs && imgs.length > 0) {
      setimgView(imgs[0].url);
    }
  }, [imgs]);

  return (
    <Grid xs={12} item className={`${styles.container}`}>
      <Grid item xs={3}>
        {imgs?.map((image) => (
          <div
            key={image.id}
            className={`${styles.small}`}
            style={{ backgroundImage: `url(${image.url})` }}
            onClick={() => {
              setimgView(image.url);
            }}
          >
            {/* <img src={image.url} width="60px" height="70px" alt="" onClick={() => { setimgView(image.url) }} /> */}
          </div>
        ))}
      </Grid>
      <Grid xs={9} item className={`${styles.large}`}>
        <div className={`${styles.Image}`}>
          <ImageView src={imgView} />
        </div>
      </Grid>
    </Grid>
  );
};

export default ProductView;
