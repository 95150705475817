import { Grid } from "@material-ui/core";
import React from "react";
import { withTranslation } from "react-i18next";
import { NavLink, useHistory, useParams } from "react-router-dom";
import { icons, images } from "../../../../Assets/images/IconHelper";
import Title from "../../../../Components/Atoms/Title/Title";
import TextArea from "../../../../Components/Atoms/Inputs/TextArea/TextArea";
import StarRatings from "react-star-ratings";

import { addReviewAPI } from "../../../../Contexts/API/Home/Reviews/addReview";
import PrimaryButton from "../../../../Components/Atoms/Buttons/Primary/PrimaryButton";

// import PrimaryButton from "../../../../Components/Atoms/Buttons/Primary/PrimaryButton";

function ProductReviewTemplate(props) {
  const {
    t,
    goBack,
    number,
    apiData,
    rating,
    changeRating,
    handleFeedback,
    review,
    handleSubmit,
  } = props;

  return (
    <>
      <Grid container style={{ marginBottom: "25px" }}>
        <Grid xs={1} item>
          <a onClick={goBack}>
            <img src={icons.dropdown} className="back_icon" />
          </a>
        </Grid>
        <Grid item xs={11}>
          <p className="back_label">Order number: {number}</p>
        </Grid>
      </Grid>
      <Title text={t("Profile.Review Product")} />
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <img src={images.apple}></img>
        </Grid>
        <Grid style={{ display: "inline-block" }}>
          <p
            style={{
              width: "334px",
              height: "24px",
              margin: "0 0 23px 22px",
              fontFamily: "Roboto",
              fontSize: "20.2px",
              fontWeight: "300",
              fontStretch: "normal",
              fontStyle: "normal",
              lineHeight: "normal",
              letterSpacing: "normal",
              color: "#424242",
            }}
          >
            {apiData?.attributes?.name}
          </p>
          <p
            style={{
              width: "27px",
              height: "21px",
              margin: "24px 10px 10px 22px",
              fontFamily: "Roboto",
              fontSize: "18px",
              fontWeight: "normal",
              fontStretch: "normal",
              fontStyle: "normal",
              lineHeight: "normal",
              letterSpacing: "normal",
              color: "#212121",
            }}
          >
            S.R
          </p>
          <p
            style={{
              width: "144px",
              height: "31px",
              margin: "8px 193px 0 19px",
              fontFamily: "Roboto",
              fontSize: "18px",
              fontWeight: "normal",
              fontStretch: "normal",
              fontStyle: "normal",
              lineHeight: "normal",
              letterSpacing: "normal",
              color: "#212121",
            }}
          >
            Deliever on:
          </p>
          <p
            style={{
              width: "186px",
              height: "31px",
              margin: "8px 193px 0 19px",
              fontFamily: "Roboto",
              fontSize: "18px",
              fontWeight: "normal",
              fontStretch: "normal",
              fontStyle: "normal",
              lineHeight: "normal",
              letterSpacing: "normal",
              color: "#212121",
            }}
          >
            Sold by:
          </p>
          <p
            style={{
              width: "91px",
              height: "28px",
              fontSize: "23.5px",
              margin: "24px 10px 10px 15px",
              fontFamily: "Roboto",
              fontWeight: "300",
              fontStretch: "normal",
              fontStyle: "normal",
              lineHeight: "normal",
              letterSpacing: "normal",
              color: "#818181",
            }}
          >
            Quantity
          </p>
        </Grid>
      </Grid>
      <img src={images.line} className="review_line" />
      <Grid container>
        <Grid item xs={12}>
          <p className="card_title">{t("order.Rate This item")}</p>
        </Grid>
        <Grid item xs={12}>
          <StarRatings
            starRatedColor="var(--primary-color)"
            svgIconPath="M19.794 1.186l4.808 11.934 12.31 1.109c.853.077 1.2 1.194.552 1.782l-9.337 8.483 2.798 12.619c.194.877-.712 1.567-1.445 1.1L18.9 31.524l-10.578 6.69c-.736.465-1.64-.225-1.446-1.1l2.798-12.62L.336 16.01c-.648-.588-.302-1.705.553-1.782l12.31-1.11 4.808-11.93c.333-.83 1.453-.83 1.787 0z"
            transform="translate(-571.000000, -646.000000) translate(569.000000, 597.000000) translate(1.000000, 0.000000) translate(1.400000, 49.400000)"
            rating={rating}
            starDimension="38px"
            starSpacing="5px"
            changeRating={(newRating) => {
              changeRating(newRating);
            }}
          />
        </Grid>
      </Grid>
      <img src={images.line} className="review_line" />
      <Grid container>
        <Grid item xs={12}>
          <p className="card_title">{t("order.Write your Feedback")}</p>
        </Grid>
        <Grid item xs={12}>
          <TextArea
            placeholder={t("order.what your experience")}
            onchange={handleFeedback}
          />
          <PrimaryButton
            click={handleSubmit}
            text={"Submit"}
            classBtn="outline shopping-newbox"
          />
        </Grid>
      </Grid>
    </>
  );
}

export default withTranslation()(ProductReviewTemplate);
