import React from 'react'
import AboutUsTemplate from '../../../Templates/Home/AboutUs/AboutUs.Template'

function AboutUs() {
    return (
        <AboutUsTemplate />
    )
}

export default AboutUs
