import * as URL from "../../URLs";

import { axiosFn, handleError } from "../../index";

export async function getProposals(queryParams) {
  return await axiosFn("get", `${URL.PROPOSALS}/?${queryParams}`, null, false)
    .then((res) => {
      return {
        res: res?.data,
        err: null,
        status: res?.status,
      };
    })
    .catch(handleError);
}

export async function getProposalsAPI(queryParams) {
  let { err, res, status } = await getProposals(queryParams);

  return { res, err, status };
}
