import * as URL from "../../URLs";

import { axiosFn, handleError } from "../../index";

export async function getServiceTypes() {
  return await axiosFn("get", URL.SERVICE_TYPES)
    .then((res) => {
      return {
        res: res?.data,
        err: null,
        status: res?.status,
      };
    })
    .catch(handleError);
}
export async function getServiceTypesAPI() {
  let { err, res, status } = await getServiceTypes();

  return { err, res, status };
}
