import {
  createServiceRequest,
  createServiceRequestAPI,
} from "../../Contexts/API/Home/ServiceRequests/createServiceRequest";

import { acceptServiceProposalAPI } from "../../Contexts/API/Home/ServiceRequests/acceptServiceProposal";
import { cancelServiceRequestAPI } from "../../Contexts/API/Home/ServiceRequests/cancelRequest";
import { deleteServiceRequestAPI } from "../../Contexts/API/Home/ServiceRequests/deleteServiceRequest";
import { getBuildingTypesAPI } from "../../Contexts/API/Home/ServiceRequests/getBuildingTypes";
import { getServiceRequestAPI } from "../../Contexts/API/Home/ServiceRequests/getServiceRequest";
import { getServiceRequestsAPI } from "../../Contexts/API/Home/ServiceRequests/getServiceRequests";
import { getServiceTypesAPI } from "../../Contexts/API/Home/ServiceRequests/getServiceTypes";
import { rejectServiceProposalAPI } from "../../Contexts/API/Home/ServiceRequests/rejectServiceProposal";
import { toast } from "react-toastify";
import { updateServiceRequestAPI } from "../../Contexts/API/Home/ServiceRequests/updateServiceRequest";
import { useState } from "react";

//Service REQUESTS (E-Solar)
export const useServiceRequests = () => {
  const [hasMore, setHasMore] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [areRequestsLoading, setAreRequestsLoading] = useState(false);
  const [isSubmitRequestLoading, setIsSubmitRequestLoading] = useState(false);
  const [serviceRequests, setServiceRequests] = useState(null);
  const [areBuildingTypesLoading, setAreBuildingTypesLoading] = useState(false);
  const [areServiceTypesLoading, setAreServiceTypesLoading] = useState(false);
  const [buildingTypes, setBuildingTypes] = useState(null);
  const [serviceTypes, setServiceTypes] = useState(null);
  const [serviceRequest, setServiceRequest] = useState(null);
  const [isServiceRequestLoading, setIsServiceRequestLoading] = useState(false);

  const loadServiceRequest = async (id) => {
    setIsServiceRequestLoading(true);
    const { res } = await getServiceRequestAPI(id);
    if (res) {
      setServiceRequest(res?.request);
    }
    setIsServiceRequestLoading(false);
  };

  const loadServiceRequests = async (queryParams) => {
    setAreRequestsLoading(true);
    const { res } = await getServiceRequestsAPI(queryParams);
    if (res) {
      setServiceRequests(res?.requests);

      if (currentPage < res?.total_pages) {
        setHasMore(true);
        setCurrentPage(currentPage + 1);
        console.log(currentPage);
      } else {
        setHasMore(false);
      }
    }
    setAreRequestsLoading(false);
  };
  const loadMoreServiceRequests = async (queryParams) => {
    setAreRequestsLoading(true);
    const { res } = await getServiceRequestsAPI(queryParams);
    if (res) {
      setServiceRequests([...serviceRequests, ...res?.requests]);

      if (currentPage < res?.total_pages) {
        setHasMore(true);
        setCurrentPage(currentPage + 1);
      } else {
        setHasMore(false);
      }
    }
    setAreRequestsLoading(false);
  };

  const createRequest = async (body) => {
    setIsSubmitRequestLoading(true);
    const { res } = await createServiceRequestAPI(body);
    if (res) {
      toast.success("Your request has been created successfully");
    } else {
      toast.error("An error has occured.");
    }
    setIsSubmitRequestLoading(false);
  };

  const loadBuildingTypes = async () => {
    setAreBuildingTypesLoading(true);
    const { res } = await getBuildingTypesAPI();

    if (res) {
      setBuildingTypes(res?.building_types);
    }
    setAreBuildingTypesLoading(false);
  };

  const loadServiceTypes = async () => {
    setAreServiceTypesLoading(true);
    const { res } = await getServiceTypesAPI();
    if (res) {
      setServiceTypes(res?.service_types);
    }
    setAreServiceTypesLoading(false);
  };

  const updateServiceRequest = async (id, body) => {
    const { status } = await updateServiceRequestAPI(id, body);
    if (status === 200) {
      toast.success("Request Updated Successfully");
    } else {
      toast.error("An error occured please try again");
    }
  };

  const deleteServiceRequest = async (id) => {
    const { status } = await deleteServiceRequestAPI(id);

    if (status === 200) {
      toast.success("Request Deleted Successfully");
    } else {
      toast.error("An error occured please try again");
    }
  };

  const acceptServiceProposal = async (requestID, quotationID) => {
    await acceptServiceProposalAPI(requestID, quotationID);
  };

  const rejectServiceProposal = async (requestID, quotationID) => {
    await rejectServiceProposalAPI(requestID, quotationID);
  };

  const cancelServiceRequest = async (requestID) => {
    await cancelServiceRequestAPI(requestID);
  };
  return {
    loadBuildingTypes,
    loadServiceTypes,
    areBuildingTypesLoading,
    areServiceTypesLoading,
    buildingTypes,
    serviceTypes,
    createRequest,
    isSubmitRequestLoading,
    loadServiceRequests,
    loadMoreServiceRequests,
    hasMore,
    setCurrentPage,
    serviceRequests,
    areRequestsLoading,
    currentPage,
    deleteServiceRequest,
    loadServiceRequest,
    serviceRequest,
    isServiceRequestLoading,
    acceptServiceProposal,
    rejectServiceProposal,
    updateServiceRequest,
    cancelServiceRequest,
  };
};
