import React, { useEffect, useState } from "react";

import { getProductsListAPI } from "../../../Contexts/API/Home/Products/getProductsList";

import ProductsTemplate from "../../../Templates/Home/Products/Products.Template";

const getProductsList = async (filter, page) => {
  return await getProductsListAPI(filter, page);
};

function Products() {
  const [state, setState] = useState({
    mobileView: false,
  });

  const { mobileView } = state;

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 600
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };

    setResponsiveness();
    window.addEventListener("resize", () => setResponsiveness());

    return () => {
      window.removeEventListener("resize", () => setResponsiveness());
    };
  }, [window.innerWidth]);

  return (
    <ProductsTemplate
      mobileView={mobileView}
      getProductsList={getProductsList}
    />
  );
}

export default Products;
