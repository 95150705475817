import * as URL from "../../URLs";

import { axiosFn, handleError } from "../../index";

export async function getOptionValues(taxonId, selectedOptions, page) {
  return await axiosFn(
    "get",
    `${URL.SPECIAL_CATEGORIES}/${taxonId}/filters${
      selectedOptions?.length
        ? `?option_value_ids=[${selectedOptions}]&page=${page}`
        : `?page=${page}`
    }`,
    null,
    false
  )
    .then((res) => {
      return {
        res: res?.data,
        err: null,
        status: res?.status,
      };
    })
    .catch(handleError);
}

export async function getOptionValuesAPI(taxonId, selectedOptions, page) {
  let { err, res, status } = await getOptionValues(
    taxonId,
    selectedOptions,
    page
  );

  return { res, err, status };
}
