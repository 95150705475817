import { useContext, useEffect, useState } from "react";

import { Box, Grid, Tab, Tabs, Typography } from "@material-ui/core";
import { withTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { images, icons } from "../../../Assets/images/IconHelper";

import { ProductContext } from "../../../Contexts/productContext/productContext";
import { BadgesContext } from "../../../Contexts/badgesContext/badgesContext";
import { BoxesContext } from "../../../Contexts/boxesContext/boxesContext";

import AddToCartBox from "../../../Components/Molecules/AddToCartBox/AddToCartBox";

import ProductView from "../../../Components/Organisms/ProductView/ProductView";
import ProductSummary from "../../../Components/Organisms/ProductSummary/ProductSummary";
import ProductShipping from "../../../Components/Organisms/ProductShipping/ProductShipping";
// import ProductCard from "../../../Components/Organisms/Cards/ProductCard/ProductCard";
import BoxDetails from "../../../Components/Organisms/BoxDetails/BoxDetails";
// import DetailedRating from "../../../Components/Organisms/DetailedRating/DetailedRating";

import "./ProductDetails.css";
import { useCart } from "../../../Hooks/useCart/useCart";
import { CircularProgress } from "@mui/material";
import ProductCard from "../../../Components/Organisms/Cards/ProductCard/ProductCard";
import DetailedRating from "../../../Components/Organisms/DetailedRating/DetailedRating";
import StarRatings from "react-star-ratings/build/star-ratings";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && (
        <Box sx={{ width: "100%" }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const ProductDetailsTemplate = (props) => {
  const {
    t,
    i18n,
    product,
    quantity,
    setQuantity,
    isVariantsLoading,
    availableVariant,
    VariantOptions,
    SelectedVariant,
    setSelectedVariant,
    productLoading,
  } = props;

  const { addToCart, isAddToCartLoading } = useCart();

  // const { addToCart } = useContext(ProductContext);

  const [boxItems] = useState([
    {
      id: "1",
      name: "2 KG Red apple",
      img: images.apple,
    },
    {
      id: "2",
      name: "3 KG White Onions",
      img: images.redapple,
    },
    {
      id: "3",
      name: "5 KG Red apple",
      img: images.banana,
    },
    {
      id: "4",
      name: "2 KG Red apple",
      img: images.apple,
    },
  ]);
  const { badges, setBadges } = useContext(BadgesContext);

  const handleAddToCart = async () => {
    //(needed) if item is added to cart or no
    const res = await addToCart({
      variant_id: props.id,
      quantity: quantity,
      //"options": "{}"
    });

    if (res.err) {
      toast.error(res.err);
    } else {
      setBadges({ ...badges, cart: badges.cart + 1 });
    }
  };

  const { modalState } = useContext(BoxesContext);

  const addToBox = (id) => {
    modalState.setSelectedProduct(id);
    modalState.handleCloseModal();
  };

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Grid container>
      {productLoading ? (
        <div className="progress-indicator">
          <CircularProgress style={{ color: "var(--secondary-color)" }} />
        </div>
      ) : (
        <>
          <Grid xs={12} md={4} item>
            <ProductView
              images={
                Array.isArray(product?.attributes?.default_variant?.image)
                  ? product?.attributes?.default_variant?.image
                  : [{ url: product?.attributes?.default_variant?.image }]
              }
            />
          </Grid>
          <Grid
            xs={12}
            sm={6}
            md={4}
            item
            className="product-details-productsummary"
          >
            <div className="product-details-borderbottom">
              <ProductSummary
                name={product?.attributes?.name}
                details={product?.attributes?.slug}
                model={""}
                was={""}
                now={`${product?.attributes?.currency} ${product?.attributes?.price}`}
                saving={""}
                quantity={quantity}
                setQuantity={setQuantity}
                rating={product?.attributes?.avg_rating}
                productOptions={product?.attributes?.option_types}
                isVariantsLoading={isVariantsLoading}
                availableVariant={availableVariant}
                VariantOptions={VariantOptions}
                SelectedVariant={SelectedVariant}
                setSelectedVariant={setSelectedVariant}
              />

              {/* <AddToCartBox
            onClickCart={handleAddToCart}
            showBox={false}
            onClickBox={() => addToBox(product?.id, quantity)}
          /> */}
              <AddToCartBox
                disabled={
                  product?.attributes?.option_types?.length == 0
                    ? false
                    : availableVariant?.length != 1 ||
                      product?.attributes?.option_types?.length !=
                        SelectedVariant?.length
                }
                onClickCart={() => {
                  if (product?.attributes?.option_types?.length == 0) {
                    addToCart(
                      product?.attributes?.default_variant?.id,
                      quantity
                    );
                  } else {
                    if (
                      availableVariant?.length == 1 &&
                      product?.attributes?.option_types?.length ==
                        SelectedVariant?.length
                    )
                      addToCart(availableVariant?.at(0)?.id, quantity);
                  }
                }}
              />
            </div>
          </Grid>
          <Grid
            xs={12}
            sm={6}
            md={4}
            item
            className={"product-details-shipping"}
          >
            <ProductShipping
              product={product}
              price={product?.attributes?.price}
              currency={product?.attributes?.currency}
              shipping="120"
              arrive="Oct 22 - Nov 10"
            />
          </Grid>
          <Grid xs={12} item className="product-details-descriptions-container">
            {props.type === "box" ? (
              <BoxDetails
                description={product?.attributes?.description}
                items={boxItems}
              />
            ) : (
              <>
                {/* if switching tabs needed */}
                <Box
                  className={"tabContainer"}
                  sx={{
                    borderBottom: 1,
                    borderColor: "divider",
                    marginBottom: "20px",
                  }}
                >
                  <Tabs value={value} onChange={handleChange}>
                    <Tab
                      className={"tab"}
                      label={t("product details.description")}
                    />
                    <Tab
                      className={"tab"}
                      label={t("product details.reviews")}
                    />
                  </Tabs>
                </Box>
                {/* if no switching */}
                {/* <p className="product-details-descriptions">
                  {t("product details.description")}
                </p>
                <img src={icons.secondaryRectangle} alt="" />
                <p className="product-details-descriptions-text">
                  {product?.attributes?.description}
                </p> */}
              </>
            )}

            {/* if rating in row */}
            {/* <div className="product-details-ratings">
              <DetailedRating direction="row" />
            </div> */}

            {/* if rating in column with details */}

            <TabPanel value={value} index={0}>
              <p className="product-details-descriptions-text">
                {product?.attributes?.description}
              </p>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <div style={{ display: "flex", width: "100%" }}>
                <div className="product-details-ratings">
                  <DetailedRating direction="column" product={product} />
                </div>
                <div
                  style={{
                    width: "100%",
                  }}
                >
                  {[1, 1, 1].map((item) => (
                    <div
                      style={{
                        width: "calc(100% - 20px)",
                        borderBottom: "1px solid #e1e1e1",
                        margin: "10px 10px 20px",
                        height: "fit-content",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <p className="review-title">Alma Malmberg</p>
                        <p className="review-date">02 Oct 2020</p>
                      </div>
                      <StarRatings
                        rating={4}
                        starRatedColor="var(--star-color)"
                        numberOfStars={5}
                        starDimension={"12px"}
                        starSpacing="3px"
                      />
                      <p className="review-paragraph">
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s,
                        when an unknown printer took a galley of type and
                        scrambled it to make a type specimen .
                      </p>
                    </div>
                  ))}
                </div>
              </div>
            </TabPanel>
          </Grid>
        </>
      )}

      {/*  PEOPLE ALSO VIEWED SECTION  */}
      <Grid xs={12} item className="product-details-descriptions-container">
        <p className="product-details-descriptions">
          {t("product details.reccomendations")}
        </p>

        <img src={icons.secondaryRectangle} />

        <Grid container spacing={4} className="product-details-similarProducts">
          {[1].map((x) => (
            <Grid item xs={6} md={3} key={x}>
              <ProductCard />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default withTranslation()(ProductDetailsTemplate);
