import { Divider, Grid, Typography } from "@material-ui/core";

import { withTranslation } from "react-i18next";

const ChatItem = (props) => {
  const { chatRooms, updateMessages, updateChatBox } = props;

  const visitChatRoom = (messages_url, chat_id, name, image) => {
    console.log("messages_url", chat_id);
    let url = new URL(messages_url);
    localStorage.setItem("Messages_node", url.pathname.slice(1));
    updateMessages(chat_id);
    localStorage.setItem("chatName", name);
    localStorage.setItem("chatProfileImg", image);
    localStorage.setItem("chatId", chat_id);
  };
  return (
    <>
      {chatRooms?.map((item) => (
        <>
          <Grid
            container
            lg={10}
            className="chat-item"
            onClick={() =>
              visitChatRoom(
                item?.messages_url,
                item?.id,
                item?.name,
                item?.image
              )
            }
          >
            <Grid md={2}>
              {item?.image == "" || item?.image == null ? (
                <Typography
                  component="span"
                  style={{
                    width: "50px",
                    height: "50px",
                    borderRadius: "50%",
                    display: "block",
                    background: "#ddd",
                  }}
                ></Typography>
              ) : (
                <img
                  src={item?.image}
                  style={{
                    width: "50px",
                    height: "50px",
                    borderRadius: "50%",
                    display: "block",
                    background: "#ddd",
                    border: "1px solid var(--secondary-color)",
                  }}
                />
              )}
            </Grid>
            <Grid md={7}>
              <Typography component="h1" fontSize="15px">
                {!item?.name ? "Chat Name" : item?.name}
              </Typography>
              <Typography
                component="p"
                style={{
                  color:
                    item.extra_info?.unseen_count > 0
                      ? "#000"
                      : "rgba(0, 0, 0, 0.5)",
                  fontSize: "14px",
                }}
              >
                {item?.extra_info?.last_message}
              </Typography>
            </Grid>
            <Grid md={3} style={{ textAlign: "end" }}>
              <Typography
                component="p"
                style={{
                  color: "rgba(0, 0, 0, 0.25)",
                  fontSize: "12px",
                  marginBottom: "5px",
                }}
              >
                {
                  new Date(item.extra_info?.last_message_date)
                    .toLocaleString()
                    .split(",")[1]
                }
              </Typography>
              <Typography component="p">
                {item.extra_info?.unseen_count > 0 && (
                  <span
                    style={{
                      background: "#ec6608",
                      color: "#fff",
                      width: "15px",
                      height: "15px",
                      borderRadius: "50%",
                      textAlign: "center",
                      fontSize: "10px",
                      display: "inline-block",
                    }}
                  >
                    {item.extra_info?.unseen_count}
                  </span>
                )}
              </Typography>
            </Grid>
          </Grid>
          <Divider style={{ width: "100%" }} />
        </>
      ))}
    </>
  );
};

export default withTranslation()(ChatItem);
