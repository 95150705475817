import "./ExploreFeatures.css";

import EsolarExploreCard from "../../Molecules/EsolarExploreCard/EsolarExploreCard";
import EsolarExploreProductsCard from "../../Molecules/EsolarExploreProductsCard/EsolarExploreProductsCard";
import { Grid } from "@mui/material";
import PrimaryButton from "../../Atoms/Buttons/Primary/PrimaryButton";
import { images } from "../../../Assets/images/IconHelper";
import { useHistory } from "react-router-dom";
import { withTranslation } from "react-i18next";

const ExploreFeatures = ({ t, exploreCardButtonHandler, categories }) => {
  const history = useHistory();
  return (
    <>
      <Grid container spacing={3}>
        <Grid xs={6} item container justifyContent={"center"}>
          <EsolarExploreCard
            image={images.explore1}
            title={"Explore Vedors"}
            buttonText="Explore"
            buttonHandler={() => exploreCardButtonHandler("/shopping")}
          />
        </Grid>
        <Grid xs={6} item container justifyContent="center">
          {" "}
          <EsolarExploreCard
            image={images.explore2}
            title={"Become a vendor"}
            buttonText="Sign up"
            buttonHandler={() => exploreCardButtonHandler("/register")}
          />
        </Grid>
      </Grid>
      <Grid container marginTop={5}>
        <Grid item xs={12}>
          <h2 className="explore-features-title">{t("Explore")}</h2>
        </Grid>
        <Grid item xs={12}>
          <div className="button-container">
            <div>
              <PrimaryButton
                classBtn="btn-secondry rounded-8 btn-small"
                text={t("bestSeller.seeAll")}
                click={() => history.push(`/shopping`)}
              />
            </div>
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        {categories?.map((category, index) => {
          if (index < 3) {
            return (
              <EsolarExploreProductsCard
                id={category.id}
                image={category.attributes.image}
                title={category.attributes.name}
                description={category.attributes.description}
              />
            );
          }
        })}
        {/* <EsolarExploreProductsCard />
        <EsolarExploreProductsCard />
        <EsolarExploreProductsCard /> */}
      </Grid>
    </>
  );
};

export default withTranslation()(ExploreFeatures);
