import * as URL from "../URLs";

import { axiosFn, handleError, s3Fn } from "../index";

export async function uploadToS3(files, arrayOfExtensions) {
  const response = await axiosFn(
    "post",
    `${URL.PRESIGNED_URL}`,
    {
      files: arrayOfExtensions,
    },
    null
  )
    .then((res) => {
      return res;
    })
    .catch(handleError);
  const URLs = response?.data?.presigned_urls;

  for (let i = 0; i < arrayOfExtensions.length; i++) {
    await s3Fn(URLs[i].presigned_url, files[i]);
  }

  return {
    res: response?.data?.presigned_urls,
    err: null,
    status: null,
  };

  // return await s3Fn("put", url, file)
  //   .then((res) => {
  //     return {
  //       res: response?.data?.presigned_urls[0]?.file_url,
  //       err: null,
  //       status: res?.status,
  //     };
  //   })
  //   .catch(handleError);
}
export async function uploadToS3API(file, arrayOfExtensions) {
  const { err, res, status } = await uploadToS3(file, arrayOfExtensions);

  return { res, err, status };
}
