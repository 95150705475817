import * as yup from "yup";

import { CircularProgress, Grid, Typography } from "@mui/material";
import { useContext, useRef, useState } from "react";

import AddressCard from "../Cards/AddressCard/AddressCard";
import { AddressContext } from "../../../Contexts/addressContext/addressContext";
import Box from "@mui/material/Box";
import DropDownSelect from "../../Atoms/DropDownSelect/DropDownSelect";
import EtradelingUploadButton from "../../Atoms/Buttons/EtradelingUploadButton/EtradelingUploadButton";
import PrimaryButton from "../../../Components/Atoms/Buttons/Primary/PrimaryButton";
import TextFieldInput from "../../../Components/Atoms/Inputs/TextField/TextFieldInput";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import UploadedFile from "../../Molecules/UploadedFile/UploadedFile";
import { uploadToS3API } from "../../../Contexts/API/S3/uploadToS3";
import { useEffect } from "react";
import { useFormik } from "formik";

//VALIDATION SCHEMA
const validationSchema = yup.object().shape({
  product_name: yup.string().required("Please enter a product name"),
  address_id: yup.number().required("Please have an address selected"),
  max_budget: yup.string().required("Please enter a max budget"),
  description: yup.string().required("Please enter details of the service"),
  trade_term_id: yup.string().required("Please select a Trade Term"),
  quantity_unit_id: yup.string().required("Please select a Quantity Unit Id"),
  category_id: yup.string().required("Please select a category"),
});

function RequestQuotationForm({
  categories,
  setQueryParams,
  subCategories,
  tradeTerms,
  units,
  handleSubmitQuotationRequest,
  isRequestSubmitted,
  productRequest,
}) {
  const [productCertificates, setProductCertificates] = useState([]);
  const [companyCertificates, setCompanyCertificates] = useState([]);
  const [additionalFiles, setAdditionalFiles] = useState([]);
  const [deletedDocumentIDS, setDeletedDocumentIDS] = useState([]);
  //REFS
  const inputFile = useRef(null);
  const productCertificateInputFile = useRef(null);
  const companyCertificateInputFile = useRef(null);
  //CONTEXT
  const { pickedAddress } = useContext(AddressContext);
  console.log(pickedAddress);
  //FORMIK
  const formik = useFormik({
    initialValues: {
      product_name: "",
      sourcing_type_id: "2",
      trade_term_id: "",
      description: "",
      address_id: Number(pickedAddress?.id),
      category_id: "",
      sub_category_id: "", //optional
      quantity: "0",
      quantity_unit_id: "",
      max_budget: "",

      // product_certificates_attributes: [
      //   {
      //     url: "product url",
      //   },
      // ],
      // company_certificates_attributes: [
      //   {
      //     url: "company url",
      //   },
      // ],
    },

    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (productRequest) {
        const additonalFilesUrls = additionalFiles.map((file) => {
          return { url: file.file_url ? file.file_url : file.url, id: file.id };
        });
        const productCertificatesUrls = productCertificates.map((file) => {
          return { url: file.file_url ? file.file_url : file.url, id: file.id };
        });
        const companyCertificatesUrls = companyCertificates.map((file) => {
          return { url: file.file_url ? file.file_url : file.url, id: file.id };
        });
        const request = {
          ...values,
          attachments_attributes: additonalFilesUrls,
          product_certificates_attributes: productCertificatesUrls,
          company_certificates_attributes: companyCertificatesUrls,
        };
        handleSubmitQuotationRequest(productRequest?.id, {
          request,
          deleted_document_ids: deletedDocumentIDS,
        });
      } else {
        const additonalFilesUrls = additionalFiles.map((file) => {
          return { url: file.file_url };
        });
        const productCertificatesUrls = productCertificates.map((file) => {
          return { url: file.file_url };
        });
        const companyCertificatesUrls = companyCertificates.map((file) => {
          return { url: file.file_url };
        });
        const request = {
          ...values,
          attachments_attributes: additonalFilesUrls,
          product_certificates_attributes: productCertificatesUrls,
          company_certificates_attributes: companyCertificatesUrls,
        };
        handleSubmitQuotationRequest({ request });
      }
    },
  });
  //STATES
  const [age, setAge] = useState("");
  const [value, setValue] = useState(null);
  const [textAreaValue, setTextAreaValue] = useState("");
  const [textAreaCharCount, setTextAreaCharCount] = useState(
    formik.values.description.length
  );

  //EVENT HANDLERS
  const handleDateChange = (newValue) => {
    setValue(newValue);
  };
  const handleChange = (value) => {
    setAge(value);
  };
  const handleTextAreaValueChange = (value) => {
    setTextAreaValue(value);
  };

  const onFileChange = async (event, setter, state) => {
    if (event.target.files && event.target.files[0]) {
      const arrayOfExtensions = [];
      const files = event.target.files;
      for (let [key, value] of Object.entries(files)) {
        arrayOfExtensions.push({
          extension: value.name.substring(value.name.indexOf(".") + 1),
        });
      }

      const { res } = await uploadToS3API(files, arrayOfExtensions);
      console.log(res);
      // const fileUrls = files.map((file) => URL.createObjectURL(file));

      setter([...state, ...res]);
      // dispatch(uploadProductImageStart(img));
      // setProfilePicture(URL.createObjectURL(img));
    }
  };

  const removeFile = (setter, state, id, backendId) => {
    const newState = state.filter((file, index) => id !== index);
    setter(newState);
    setDeletedDocumentIDS([...deletedDocumentIDS, backendId]);
  };

  //EFFECTS
  useEffect(() => {
    setTextAreaCharCount(formik.values.description.length);
  }, [formik.values.description]);

  useEffect(() => {
    formik.setFieldValue("address_id", Number(pickedAddress?.id));
  }, [pickedAddress]);

  //In case of edit
  useEffect(() => {
    if (productRequest) {
      formik.setFieldValue("product_name", productRequest?.product_name);
      formik.setFieldValue("trade_term_id", productRequest?.trade_term_id);
      formik.setFieldValue("description", productRequest?.description);

      formik.setFieldValue("category_id", productRequest?.category_id);
      formik.setFieldValue("sub_category_id", productRequest?.sub_category_id);
      formik.setFieldValue("quantity", productRequest?.quantity);
      formik.setFieldValue(
        "quantity_unit_id",
        productRequest?.quantity_unit_id
      );
      formik.setFieldValue("max_budget", productRequest?.max_budget);
      setAdditionalFiles(productRequest?.attachments);
      setProductCertificates(productRequest?.product_certificates);
      setCompanyCertificates(productRequest?.company_certificates);
    }
  }, [productRequest]);

  return (
    <Box sx={{ mt: 3 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Typography style={{ marginBottom: 12 }}>Product Name</Typography>
          <TextFieldInput
            value={formik.values.product_name}
            placeholder="Apperal"
            error={
              Boolean(formik.errors.product_name) && formik.touched.product_name
            }
            helperText={
              formik.errors.product_name && formik.touched.product_name
            }
            onchange={(value) => formik.setFieldValue("product_name", value)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <DropDownSelect
            items={categories}
            label={"Select Category"}
            value={formik.values.category_id}
            handleChange={(value) => {
              formik.setFieldValue("category_id", value);
              setQueryParams({ parent_id: value });
            }}
            placeholder={"Category"}
            error={formik?.errors?.category_id && formik?.touched?.category_id}
            errorMessage={formik?.errors?.category_id}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <DropDownSelect
            items={tradeTerms}
            label={"Trade Terms"}
            value={formik.values.trade_term_id}
            handleChange={(value) =>
              formik.setFieldValue("trade_term_id", value)
            }
            placeholder={"FOB"}
            error={
              formik?.errors?.trade_term_id && formik?.touched?.trade_term_id
            }
            errorMessage={formik?.errors?.trade_term_id}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <DropDownSelect
            items={subCategories}
            label={"Sub Categories (Optional)"}
            value={formik.values.sub_category_id}
            handleChange={(value) =>
              formik.setFieldValue("sub_category_id", value)
            }
            placeholder={"Sub Category"}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Grid container>
            <Grid item xs={12}>
              {" "}
              <Typography style={{ marginBottom: 12 }}>Max Budget</Typography>
              <TextFieldInput
                value={formik.values.max_budget}
                placeholder="10000"
                error={
                  Boolean(formik.errors.max_budget) && formik.touched.max_budget
                }
                helperText={
                  formik.errors.max_budget && formik.touched.max_budget
                }
                onchange={(value) => formik.setFieldValue("max_budget", value)}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6}></Grid>
        <Grid item xs={12} sm={6}>
          <Typography>Quantity</Typography>
          <Grid container spacing={0.5}>
            <Grid item xs={1.5} container justifyContent={"center"}>
              <div
                style={{ cursor: "pointer", width: "100%" }}
                onClick={() =>
                  formik.setFieldValue(
                    "quantity",
                    Number(formik.values.quantity) - 1
                  )
                }
              >
                <div
                  style={{
                    border: "0.5px solid #979797",
                    paddingBottom: 15,
                    paddingTop: 15,
                    display: "flex",
                    justifyContent: "center",
                    fontSize: 24,
                  }}
                >
                  -
                </div>
              </div>
            </Grid>
            <Grid item xs={5} container justifyContent="center">
              <div
                style={{
                  border: "0.5px solid #979797",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  paddingTop: 20,
                  height: 40,
                }}
              >
                {formik.values.quantity}
              </div>
            </Grid>
            <Grid item xs={1.5} container justifyContent={"center"}>
              <div
                style={{ cursor: "pointer", width: "100%" }}
                onClick={() =>
                  formik.setFieldValue(
                    "quantity",
                    Number(formik.values.quantity) + 1
                  )
                }
              >
                <div
                  style={{
                    border: "0.5px solid #979797",
                    paddingBottom: 15,
                    paddingTop: 15,
                    display: "flex",
                    justifyContent: "center",
                    fontSize: 24,
                  }}
                >
                  +
                </div>
              </div>
            </Grid>
            <Grid item xs={4}>
              <DropDownSelect
                items={units}
                value={formik.values.quantity_unit_id}
                handleChange={(value) =>
                  formik.setFieldValue("quantity_unit_id", value)
                }
                placeholder={"Unit"}
                error={
                  formik?.errors?.quantity_unit_id &&
                  formik?.touched?.quantity_unit_id
                }
                errorMessage={formik?.errors?.quantity_unit_id}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} container justifyContent="flex-start">
          <Typography>Product Certificate</Typography>
        </Grid>
        <Grid item xs={12} sm={12} container justifyContent="flex-start">
          <EtradelingUploadButton
            click={() => productCertificateInputFile.current.click()}
          />
          <input
            multiple
            type="file"
            id="file"
            ref={productCertificateInputFile}
            style={{ display: "none" }}
            onChange={(event) => {
              onFileChange(event, setProductCertificates, productCertificates);
            }}
          />

          {productCertificates.map((file, index) => {
            return (
              <UploadedFile
                fileType={file.extension}
                fileName={file.file_name}
                id={index}
                handleRemove={removeFile}
                files={productCertificates}
                setter={setProductCertificates}
                backendId={file.id}
              />
            );
          })}
        </Grid>
        <Grid item xs={12} sm={12} container justifyContent="flex-start">
          <Typography>Company Certificate</Typography>
        </Grid>
        <Grid item xs={12} sm={12} container justifyContent="flex-start">
          <EtradelingUploadButton
            click={() => companyCertificateInputFile.current.click()}
          />
          <input
            multiple
            type="file"
            id="file"
            ref={companyCertificateInputFile}
            style={{ display: "none" }}
            onChange={(event) => {
              onFileChange(event, setCompanyCertificates, companyCertificates);
            }}
          />
          {companyCertificates.map((file, index) => {
            return (
              <UploadedFile
                fileType={file.extension}
                fileName={file.file_name}
                id={index}
                handleRemove={removeFile}
                files={companyCertificates}
                setter={setCompanyCertificates}
                backendId={file.id}
              />
            );
          })}
        </Grid>

        {/* Details */}
        <Grid item xs={12} sm={12} container>
          <Typography sx={{ mb: "12px", fontWeight: "600", fontSize: "16px" }}>
            Details
          </Typography>
          <TextareaAutosize
            className="textarea"
            style={{
              width: "100%",
              height: "230px",
              resize: "none",
              paddingLeft: "10px",
              paddingTop: "10px",
              borderTop: "1px black solid",
              borderLeft: "1px black solid",
              borderRight: "1px black solid",
              borderBottom: "1px white solid",
            }}
            maxLength="500"
            aria-label="empty textarea"
            placeholder="Tell us about what you want"
            value={formik.values.description}
            onChange={(e) =>
              formik.setFieldValue("description", e.target.value)
            }
          />

          {/* Word count inside the text area */}
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
              borderRight: "1px solid black",
              borderLeft: "1px solid black",
            }}
          >
            <Typography
              sx={{
                mr: "25px",
                color: "#4a4a48",
                mb: "10px",
              }}
            >
              {textAreaCharCount}/500
            </Typography>
          </Box>
          {/* Attach files box */}
          <Box
            sx={{
              backgroundColor: "#F0F0F0",
              width: "100%",
              borderBottom: "solid 1px #3c4550",
              borderRight: "solid 1px #3c4550",
              borderLeft: "solid 1px #3c4550",
            }}
          >
            <Typography
              sx={{
                color: "#000000",
                mb: "12px",
                fontWeight: "500",
                fontSize: "14px",
                ml: "10px",
                pt: "10px",
                textDecoration: "underline",
                cursor: "pointer",
              }}
              onClick={() => inputFile.current.click()}
            >
              <input
                multiple
                type="file"
                id="file"
                ref={inputFile}
                style={{ display: "none" }}
                onChange={(event) => {
                  onFileChange(event, setAdditionalFiles, additionalFiles);
                }}
              />
              Attach files for additional information ({" "}
              {additionalFiles.length + " "}/ 5 )
            </Typography>
            <div
              style={{
                display: "flex",
                marginLeft: 10,
                marginRight: 10,
                marginBottom: 14,
              }}
            >
              {additionalFiles.map((file, index) => {
                return (
                  <UploadedFile
                    fileType={file.extension}
                    fileName={file.file_name}
                    id={index}
                    handleRemove={removeFile}
                    files={additionalFiles}
                    setter={setAdditionalFiles}
                    backendId={file.id}
                  />
                );
              })}
            </div>
          </Box>
          {formik.errors.description && formik.touched.description ? (
            <small style={{ color: "red" }}>{formik.errors.description} </small>
          ) : (
            ""
          )}
        </Grid>
      </Grid>

      <div>
        <AddressCard
          address={pickedAddress}
          noDelete={true}
          noEdit={true}
          noCheckbox={true}
        />
      </div>
      <Box
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "24px",
          height: "60px",
        }}
      >
        {isRequestSubmitted ? (
          <div className="progress-indicator">
            <CircularProgress style={{ color: "var(--secondary-color)" }} />
          </div>
        ) : (
          <PrimaryButton
            style={{ width: "320px", height: "60px" }}
            text={"Submit"}
            classBtn=" btn rounded-8 primary"
            click={formik.handleSubmit}
          />
        )}
      </Box>
    </Box>
  );
}

export default RequestQuotationForm;
