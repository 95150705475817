import { axiosFn, handleError } from "../../index";
import * as URL from "../../URLs";

export async function getSystem() {
  return await axiosFn("get", URL.SYSTEM)
    .then((res) => {
      return {
        res: res,
        err: null,
      };
    })
    .catch(handleError);
}
export async function getSystemAPI() {
  let { err, res } = await getSystem();

  if (res) {
    return res;
  }

  if (err) {
    return err;
  }
}
