import { axiosFn, handleError } from "../../index";
import * as URL from "../../URLs";
// import { toast } from "react-toastify";

export async function addItemsToBox(recivedData, id) {
  const data = { ...recivedData };
  return await axiosFn("post", ` ${URL.BOXES}/${id}/product_boxes`, data)
    .then((res) => {
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}
export async function addItemsToBoxAPI(data, id) {
  let { err, res } = await addItemsToBox(data, id);

  if (res) {
    if (err) {
      return err;
    }
  }
}
