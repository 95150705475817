import { Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import Title from "../../../../Components/Atoms/Title/Title";
import ProductCard from "../../../../Components/Organisms/Cards/ProductCard/ProductCard";
import { images } from "../../../../Assets/images/IconHelper";
import "./MyItems.css";

//props={img, title, rating, currency, price, unit="piece, kg, liter",  discount}

function MyItemsTemplate(props) {
  const { t } = props;

  const [state, setState] = useState({
    mobileView: false,
  });
  const { mobileView } = state;
  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 960
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };
    setResponsiveness();
    window.addEventListener("resize", () => setResponsiveness());

    return () => {
      window.removeEventListener("resize", () => setResponsiveness());
    };
  }, []);

  return (
    <div>
      <Title text={t("Profile.MyItems")} />
      <Grid container>
        <Grid item className="myitem-product">
          <ProductCard
            row={!mobileView}
            quantityPicker="true"
            img={images.apple}
            title="Apples"
            rating={4}
            currency="S.R"
            price="20"
            unit="KG"
            discount="30"
          />
        </Grid>

        <Grid item className="myitem-product">
          <ProductCard
            row={!mobileView}
            quantityPicker="true"
            img={images.apple}
            title="Apples"
            rating={4}
            currency="S.R"
            price="20"
            unit="KG"
            discount="30"
          />
        </Grid>

        <Grid item className="myitem-product">
          <ProductCard
            row={!mobileView}
            quantityPicker="true"
            img={images.apple}
            title="Apples"
            rating={4}
            currency="S.R"
            price="20"
            unit="KG"
            discount="30"
          />
        </Grid>
      </Grid>
    </div>
  );
}

export default withTranslation()(MyItemsTemplate);
