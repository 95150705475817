import {
  Card,
  CardActions,
  CardContent,
  Container,
  Grid,
  Typography,
} from "@mui/material";

import { Box } from "@mui/system";
import DeleteRequestModal from "../Modals/DeleteRequestModal/DeleteRequestModal";
import IconOverlay from "../../Atoms/IconOverlay/IconOverlay";
import PrimaryButton from "../../Atoms/Buttons/Primary/PrimaryButton";
import { dateFormatter } from "../../../utils/dateFormatter/dateFormatter";
import { icons } from "../../../Assets/images/IconHelper";
import { useHistory } from "react-router-dom";

function RequestCard({
  id,
  date,
  handleViewRequestDetails,
  rowTitles,
  rowInfo,
  handleModal,
  setSelectedId,
  statusColor,
  acceptedQuotation,
}) {
  const history = useHistory();
  const formattedDate = dateFormatter(date);
  return (
    <Card
      fullWidth
      style={{
        border: "1px solid #979797",
        marginTop: "24px",
        marginBottom: "24px",
      }}
    >
      <CardContent>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography
            gutterBottom
            variant="h5"
            component="div"
            sx={{ fontSize: "20px", fontColor: "#303030", fontWeight: "500" }}
          >
            Request Number#{id}
          </Typography>
          <Typography
            gutterBottom
            variant="h5"
            component="div"
            sx={{ fontSize: "14px", color: "#6e7a8a", fontWeight: "normal" }}
          >
            {formattedDate}
          </Typography>
        </Box>
      </CardContent>
      <CardContent>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          sx={{ mb: "14px" }}
        >
          <Grid item xs={4}>
            <Typography
              sx={{ fontSize: "14px", fontWeight: 500, color: "#303030" }}
            >
              {" "}
              {rowTitles[0]}
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography
              sx={{ fontSize: "14px", fontWeight: 300, color: "#383838" }}
            >
              {rowInfo[0]}
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          sx={{ my: "14px" }}
        >
          <Grid item xs={4}>
            <Typography
              sx={{ fontSize: "14px", fontWeight: 500, color: "#303030" }}
            >
              {rowTitles[1]}
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography
              sx={{ fontSize: "14px", fontWeight: 300, color: "#383838" }}
            >
              {rowInfo[1]}
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          sx={{ my: "30px" }}
        >
          <Grid item xs={4}>
            <Typography
              sx={{ fontSize: "14px", fontWeight: 500, color: "#303030" }}
            >
              {rowTitles[2]}
            </Typography>
          </Grid>
          {acceptedQuotation ? (
            <Grid item xs={8}>
              <Typography
                sx={{ fontSize: "18px", fontWeight: 600, color: statusColor }}
              >
                Request Accepted by {acceptedQuotation?.vendor_name}
              </Typography>
            </Grid>
          ) : (
            <Grid item xs={8}>
              <Typography
                sx={{ fontSize: "18px", fontWeight: 600, color: statusColor }}
              >
                {rowInfo[2]}
              </Typography>
            </Grid>
          )}
        </Grid>
      </CardContent>
      <CardActions
        style={{ borderTop: "1px solid #979797" }}
        sx={{ display: "flex", justifyContent: "space-between" }}
      >
        {acceptedQuotation ? (
          <></>
        ) : (
          <Box sx={{ display: "flex" }}>
            <PrimaryButton
              size="small"
              color="primary"
              text="Edit"
              startIcon={
                <IconOverlay src={icons.edit} iconStyle="icon-black" />
              }
              classBtn="borderless success btn-regular"
              click={() => history.push(`/edit_request/${id}`)}
              style={{ marginRight: "56px" }}
            />
            <PrimaryButton
              size="small"
              color="primary"
              text="Delete"
              startIcon={<IconOverlay src={icons.bin} iconStyle="icon-white" />}
              classBtn="borderless error"
              click={() => {
                handleModal();
                setSelectedId(id);
              }}
            />
          </Box>
        )}

        <Box sx={{ display: "flex", cursor: "pointer" }}>
          <Typography
            sx={{
              mx: "16px",
              fontSize: "12px",
              fontWeight: 600,
            }}
            onClick={() => handleViewRequestDetails(id)}
          >
            View Request details{" "}
          </Typography>
          <IconOverlay src={icons.pageArrow} />
        </Box>

        {/* 
        <PrimaryButton
          size="small"
          color="primary"
          text="View Request details"
          classBtn="delete-card"
          startIcon={<IconOverlay src={icons.edit} />}
          style={{ width: "140px" }}
        /> */}
      </CardActions>
    </Card>
  );
}

export default RequestCard;
