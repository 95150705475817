import "./FiltersSidepanelAlsallab.css";

import { useContext, useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Checkbox,
  CircularProgress,
  Drawer,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MenuIcon from "@material-ui/icons/Menu";

import { CategoriesContext } from "../../../Contexts/contextCategories/categoriesContext";
import { Typography } from "@mui/material";

const OptionComponent = ({
  option,
  selectedOptionIds,
  setSelectedOptionIds,
  setPage,
  t,
}) => {
  const [more, setMore] = useState(false);
  return (
    <Accordion defaultExpanded={true}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel2a-content"
        id="panel2a-header"
      >
        <p className="filters-accordion-title">{option?.name}</p>
      </AccordionSummary>
      <AccordionDetails>
        <div>
          <FormControl component="fieldset">
            <FormGroup>
              {more
                ? option?.option_values?.map((value) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          value={value?.id}
                          checked={selectedOptionIds?.find(
                            (item) => item == value.id
                          )}
                          onChange={(e) => {
                            setPage(1);
                            if (
                              selectedOptionIds?.find(
                                (item) => item == value.id
                              )
                            ) {
                              setSelectedOptionIds(
                                selectedOptionIds?.filter(
                                  (item) => item != value.id
                                )
                              );
                            } else {
                              setSelectedOptionIds([
                                ...selectedOptionIds,
                                e.target.value,
                              ]);
                            }
                          }}
                          name={value?.name}
                        />
                      }
                      label={value?.name}
                      className="filters-checkbox"
                    />
                  ))
                : option?.option_values?.slice(0, 6)?.map((value) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          value={value?.id}
                          checked={selectedOptionIds?.find(
                            (item) => item == value.id
                          )}
                          onChange={(e) => {
                            setPage(1);
                            if (
                              selectedOptionIds?.find(
                                (item) => item == value.id
                              )
                            ) {
                              setSelectedOptionIds(
                                selectedOptionIds?.filter(
                                  (item) => item != value.id
                                )
                              );
                            } else {
                              setSelectedOptionIds([
                                ...selectedOptionIds,
                                e.target.value,
                              ]);
                            }
                          }}
                          name={value?.name}
                        />
                      }
                      label={value?.name}
                      className="filters-checkbox"
                    />
                  ))}
              {option?.option_values?.length > 6 ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setMore(!more);
                  }}
                >
                  {more ? (
                    <>
                      {t("dan kitchen.see less")}
                      <ExpandMoreIcon style={{ transform: "rotate(180deg)" }} />
                    </>
                  ) : (
                    <>
                      {t("dan kitchen.see more")} <ExpandMoreIcon />
                    </>
                  )}
                </div>
              ) : (
                ""
              )}
            </FormGroup>
          </FormControl>
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

const FiltersSidepanelAlsallab = (props) => {
  const {
    t,
    mobileView,
    setTaxonId,
    taxonId,
    options,
    setSelectedSubCategory,
    selectedOptionIds,
    setSelectedOptionIds,
    setSelectedType,
    loading,
    tags,
    selectedTag,
    handleSelectingTag,
    setPage,
  } = props;

  const categoriesCxt = useContext(CategoriesContext);
  const history = useHistory();

  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleCategory = (event, index) => {
    if (setTaxonId) {
      setPage(1);
      setSelectedType([]);
      setSelectedOptionIds([]);
      setSelectedSubCategory(null);
      setTaxonId([event.target.value]);
      history.replace({
        pathname: "collections",
        search: `?taxon=${event.target.value}`,
      });
    }
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  useEffect(() => {
    setDrawerOpen(false);
  }, [mobileView]);

  const renderSidePanel = (
    <div className="filters">
      {history?.location?.search?.includes("tag") ? (
        <>
          <Accordion defaultExpanded={true}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <p className="filters-accordion-title">Featured Tags</p>
            </AccordionSummary>
            <AccordionDetails>
              <div>
                <FormControl component="fieldset">
                  <FormGroup>
                    {tags
                      ?.filter((tag) => tag?.marketing_tag)
                      ?.map((cat) => (
                        <>
                          <FormControlLabel
                            key={cat.id}
                            control={
                              <Checkbox
                                value={cat.id}
                                checked={parseInt(selectedTag) == cat?.id}
                                onChange={(e) =>
                                  handleSelectingTag(e.target.value)
                                }
                                name={cat.name}
                              />
                            }
                            label={cat.name}
                            className="filters-checkbox"
                          />
                        </>
                      ))}
                  </FormGroup>
                </FormControl>
              </div>
            </AccordionDetails>
          </Accordion>
          <Accordion defaultExpanded={true}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <p className="filters-accordion-title">Tags</p>
            </AccordionSummary>
            <AccordionDetails>
              <div>
                <FormControl component="fieldset">
                  <FormGroup>
                    {tags
                      ?.filter((tag) => !tag?.marketing_tag)
                      ?.map((cat) => (
                        <FormControlLabel
                          key={cat.id}
                          control={
                            <Checkbox
                              value={cat.id}
                              checked={parseInt(selectedTag) == cat?.id}
                              onChange={(e) =>
                                handleSelectingTag(e.target.value)
                              }
                              name={cat.name}
                            />
                          }
                          label={cat.name}
                          className="filters-checkbox"
                        />
                      ))}
                  </FormGroup>
                </FormControl>
              </div>
            </AccordionDetails>
          </Accordion>
        </>
      ) : (
        <>
          <Accordion defaultExpanded={true}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <p className="filters-accordion-title">
                {t("shoppingPage.category")}
              </p>
            </AccordionSummary>
            <AccordionDetails>
              <div>
                <FormControl component="fieldset">
                  <FormGroup>
                    {categoriesCxt?.categories?.data?.map((cat, index) => (
                      <FormControlLabel
                        key={cat.id}
                        control={
                          <Checkbox
                            value={cat.id}
                            checked={taxonId == cat.id}
                            onChange={(e) => handleCategory(e, index)}
                            name={cat.attributes.name}
                          />
                        }
                        label={cat.attributes.name}
                        className="filters-checkbox"
                      />
                    ))}
                  </FormGroup>
                </FormControl>
              </div>
            </AccordionDetails>
          </Accordion>

          {loading ? (
            <div className="progress-indicator">
              <CircularProgress style={{ color: "var(--secondary-color)" }} />
            </div>
          ) : (
            <>
              {options &&
                options?.map((option) => (
                  <OptionComponent
                    t={t}
                    option={option}
                    selectedOptionIds={selectedOptionIds}
                    setSelectedOptionIds={setSelectedOptionIds}
                    setPage={setPage}
                  />
                ))}
            </>
          )}
        </>
      )}
    </div>
  );

  return (
    <div>
      {mobileView && (
        <Button onClick={toggleDrawer("left", true)}>
          <MenuIcon />
        </Button>
      )}

      <Drawer
        anchor="left"
        open={drawerOpen}
        onClose={toggleDrawer("left", false)}
        variant="temporary"
      >
        <div
          onClick={toggleDrawer("left", false)}
          onKeyDown={toggleDrawer("left", false)}
          className="filter-drawer-header"
        >
          <IconButton>
            <CloseIcon />
          </IconButton>
          <p className="filter-drawer-filterby">Filter By:</p>
        </div>
        <div
          role="presentation"
          // onClick={toggleDrawer('left', false)}
          // onKeyDown={toggleDrawer('left', false)}
        >
          {renderSidePanel}
        </div>
      </Drawer>

      {!mobileView && <div>{renderSidePanel}</div>}
    </div>
  );
};

export default withTranslation()(FiltersSidepanelAlsallab);
