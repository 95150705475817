import "./Auth.css";

import Form from "../../Components/Organisms/Forms/Form";
import { Grid } from "@material-ui/core";
import { Link } from "react-router-dom";
import React from "react";
import { images } from "../../Assets/images/IconHelper";
import { withTranslation } from "react-i18next";

const SigninTemplate = (props) => {
  const { t } = props;
  return (
    <Grid container spacing={0}>
      <Grid item md={6}>
        {/* <img
          alt="register image"
          src={images.registrationImage}
          height="1024px"
          width="100%"
        /> */}
        <img
          alt="register image"
          src={
            props.systemConfig
              ? props.systemConfig["System Configurations"]["Signup Image"]
              : ""
          }
          height="1024px"
          width="100%"
        />
      </Grid>
      <Grid item md={6} className="Auth_container">
        <div>
          <img
            alt="logo"
            src={
              props.systemConfig
                ? props.systemConfig["System Configurations"]["Logo"]
                : ""
            }
            width="200px"
            height=""
          />
        </div>
        <div>
          <h1>{t("Welcome")}</h1>
          <h3>{t("loginBtn.Signin")}</h3>
        </div>
        <div>
          <Form
            from={t("loginBtn.Signin")}
            password={t("loginBtn.Password")}
            email={t("loginBtn.Email")}
            onHandle={props.login}
            error={props.error}
          />
        </div>
        <Grid container className="Auth_account">
          <Grid xs={6} item style={{ textAlign: "end" }}>
            <p className="">{t("loginBtn.donthaveaccount")}</p>
          </Grid>
          <Grid xs={2} item>
            <Link className="" to="/register">
              {t("loginBtn.Signup")}
            </Link>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default withTranslation()(SigninTemplate);
