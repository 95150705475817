import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  MenuItem,
  Select,
} from "@material-ui/core";
import React, { useEffect } from "react";
import "../ModalStyle.css";
import "./MyBoxesModal.css";
import { withTranslation } from "react-i18next";
import TextFieldInput from "../../../Atoms/Inputs/TextField/TextFieldInput";
import PrimaryButton from "../../../Atoms/Buttons/Primary/PrimaryButton";
import { useState } from "react";
import { icons } from "../../../../Assets/images/IconHelper";
import IconOverlay from "../../../Atoms/IconOverlay/IconOverlay";
import NewBoxModal from "../NewBoxModal/NewBoxModal";
import { toast } from "react-toastify";
import { addItemsToBoxAPI } from "../../../../Contexts/API/Home/Boxes/addItemsToBox";

function MyBoxesModal(props) {
  const { t, i18n } = props;

  const {
    modalOpen,
    setModalOpen,
    selectedProduct,
    setSelectedProduct,
    boxes,
    setBoxes,
    handleCloseModal,
  } = props.modalState;

  const [openNew, setOpenNew] = useState(false);
  const handleCloseNew = () => {
    setOpenNew(!openNew);
  };

  const addToBox = (boxId) => {
    const res = addItemsToBoxAPI(
      { variant_id: selectedProduct, qty: 3 },
      boxId
    );
    toast.success(`Product ${selectedProduct} has been added to box ${boxId}`);
    handleCloseModal();
  };

  return (
    <Dialog
      open={modalOpen}
      onClose={handleCloseModal}
      className={i18n.language === "ar" ? "rtl_modal" : ""}
    >
      <DialogTitle className="newboxmodal-header">
        <p className="Modal_header">{t("myBoxes.myBoxes")}</p>
      </DialogTitle>
      <DialogContent className="newboxmodal-content">
        <Grid container className="modal_form">
          <Grid item xs={12} className="newboxmodal-header">
            <div className="myboxesmodal-cont">
              <p>{t("myBoxes.create new box")}</p>

              <PrimaryButton
                btnType="icon"
                classBtn="myboxesmodal-plusbtn"
                startIcon={
                  <IconOverlay src={icons.plus} iconStyle="icon-primary" />
                }
                click={() => handleCloseNew()}
              />
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className="myboxesmodal-cont newboxmodal-header">
              <p>{t("myBoxes.box list")}</p>
            </div>
            <div className="myboxesmodal-itemsCont">
              {boxes?.map((b) => (
                <div className="myboxesmodal-item" key={b.id}>
                  <p>
                    {b?.attributes.name}(
                    {b.relationships.product_boxes.data.length})
                  </p>
                  <div>
                    <PrimaryButton
                      classBtn="btn-link myboxesmodal-add"
                      text={t("myBoxes.add")}
                      click={() => addToBox(b.id)}
                    />
                  </div>
                </div>
              ))}
            </div>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions></DialogActions>

      <NewBoxModal
        open={openNew}
        handleClose={handleCloseNew}
        type="add"
        addToBox={addToBox}
      />
    </Dialog>
  );
}

export default withTranslation()(MyBoxesModal);
