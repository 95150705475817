import "./ShoppingTemplate.css";

import { CircularProgress, Grid, MenuItem, Select } from "@material-ui/core";
import { icons, images } from "../../../Assets/images/IconHelper";

import { CategoriesContext } from "../../../Contexts/contextCategories/categoriesContext";
import FiltersSidepanel from "../../../Components/Organisms/FiltersSidepanel/FiltersSidepanel";
import IconOverlay from "../../../Components/Atoms/IconOverlay/IconOverlay";
import NewBoxModal from "../../../Components/Organisms/Modals/NewBoxModal/NewBoxModal";
import PrimaryButton from "../../../Components/Atoms/Buttons/Primary/PrimaryButton";
import ProductCard from "../../../Components/Organisms/Cards/ProductCard/ProductCard";
import { useContext } from "react";
import { withTranslation } from "react-i18next";

const ShoppingTemplate = (props) => {
  const {
    t,
    mobileView,
    loading,
    totalCount,
    products,
    handleAddToCart,
    // totalPages,
    // currentPage,
    // loadMore,
    isAddToCartLoading,
    addToWishlist,
    deleteFromWishlist,
    setTaxonId,
    taxonId,
    options,
    sub_categories,
    selectedSubCategory,
    setSelectedSubCategory,
    handleSelectingCategory,
    selectedOptionIds,
    setSelectedOptionIds,
    sortBy,
    handleChange,
  } = props;

  const categories = useContext(CategoriesContext);

  const catLoading = categories.catLoading;

  return catLoading && products ? (
    <Grid container alignItems="center" justify="center">
      <CircularProgress style={{ color: "var(--secondary-color)" }} />
    </Grid>
  ) : (
    <Grid container>
      {!mobileView && (
        <Grid item xs={0} sm={3}>
          <FiltersSidepanel
            mobileView={mobileView}
            setTaxonId={setTaxonId}
            taxonId={taxonId}
            options={options
              ?.filter((item) => item?.name != "TYPE")
              ?.filter((item) => item?.name != "النوع")}
            sub_categories={sub_categories}
            selectedSubCategory={selectedSubCategory}
            setSelectedSubCategory={setSelectedSubCategory}
            selectedOptionIds={selectedOptionIds}
            setSelectedOptionIds={setSelectedOptionIds}
            loading={loading}
          />
        </Grid>
      )}
      <Grid item xs={12} sm={9} className="shopping-products-cont">
        <div className="shopping-header">
          {/* <h1>{category?.data?.attributes?.name}</h1> */}

          {mobileView && (
            <FiltersSidepanel
              mobileView={mobileView}
              setTaxonId={setTaxonId}
              taxonId={taxonId}
              options={options
                ?.filter((item) => item?.name != "TYPE")
                ?.filter((item) => item?.name != "النوع")}
              sub_categories={sub_categories}
              selectedSubCategory={selectedSubCategory}
              setSelectedSubCategory={setSelectedSubCategory}
              selectedOptionIds={selectedOptionIds}
              setSelectedOptionIds={setSelectedOptionIds}
              loading={loading}
            />
          )}

          {/* <h1>{categories?.categories?.data[param - 1]?.attributes.name}</h1> */}

          <div className="shopping-sort">
            <span className="shopping-sort-text">
              {t("shoppingPage.sort by")}:{" "}
            </span>
            <span>
              <Select
                value={sortBy}
                onChange={handleChange}
                inputProps={{ "aria-label": "Without label" }}
                className="shopping-sort-input shopping-sort-selected"
              >
                <MenuItem value={0} className="shopping-sort-input">
                  {t("shoppingPage.high to low")}
                </MenuItem>
                <MenuItem value={1} className="shopping-sort-input">
                  {t("shoppingPage.low to high")}
                </MenuItem>
              </Select>
            </span>
          </div>
        </div>
        <img src={images.line} width="100%" alt="" />
        <div className="shopping-subheader">
          {!loading && (
            <p className="shopping-productsfound">
              {totalCount} {t("shoppingPage.products found")}
            </p>
          )}

          {/* {urlParam === "boxes" ? (
            <PrimaryButton
              text="Create New Box"
              classBtn="outline shopping-newbox"
              endIcon={
                <IconOverlay src={icons.plus} iconStyle="icon-primary" />
              }
              click={() => props.handleCloseModal()}
            />
          ) : null} */}
        </div>
        <Grid container spacing={2}>
          {products?.map((prod) => (
            <Grid item xs={12} sm={6} md={4} key={prod?.id}>
              <ProductCard
                id={prod?.id}
                img={prod?.default_variant?.image}
                title={prod?.name}
                rating={Number(prod?.avg_rating)}
                currency={prod?.default_variant?.currency}
                price={prod?.default_variant?.price}
                // unit="KG"
                // discount="30"
                // variant={urlParam === "boxes" ? "box" : ""}
                handleAddToCart={handleAddToCart}
                variantID={prod?.default_variant?.id}
                addToWishlist={addToWishlist}
                isFavorite={prod?.is_favorite}
                isAddToCartLoading={isAddToCartLoading}
                deleteFromWishlist={deleteFromWishlist}
              />
            </Grid>
          ))}

          <div className="progress-indicator">
            {loading && (
              <CircularProgress style={{ color: "var(--secondary-color)" }} />
            )}
          </div>
        </Grid>
        {/* 
        <div className="shopping-loadmore">
          {totalPages > currentPage ? (
            <PrimaryButton
              text={t("load more")}
              click={loadMore}
              classBtn="outline"
            />
          ) : null}
        </div> */}
      </Grid>
      <NewBoxModal
        open={props.openModal}
        handleClose={props.handleCloseModal}
      />
    </Grid>
  );
};

export default withTranslation()(ShoppingTemplate);

{
  /* <ProductCard
  id={prod?.relationships?.default_variant?.data?.id}
  img={prod?.attributes?.default_variant?.image}
  title={prod?.attributes?.name}
  rating={3.5}
  // rating={Number(prod?.attributes?.avg_rating) ?? 0}
  currency={prod?.attributes?.currency}
  price={prod?.attributes?.price}
  unit="KG"
  discount="30"
  variant={urlParam === "boxes" ? "box" : ""}
  handleAddToCart={handleAddToCart}
  variantID={prod?.relationships?.default_variant?.data?.id}
  addToWishlist={addToWishlist}
  isFavorite={prod?.attributes?.is_favorite}
  isAddToCartLoading={isAddToCartLoading}
  deleteFromWishlist={deleteFromWishlist}
/> */
}
