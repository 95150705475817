import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./CarouselWithArrows.css";

import { CircularProgress, Grid } from "@mui/material";
import { icons, images } from "../../../Assets/images/IconHelper";

import { Carousel } from "react-responsive-carousel";
import CarouselItem from "../../Molecules/CarouselItem/CarouselItem";
import IconOverlay from "../../Atoms/IconOverlay/IconOverlay";
import SubCategoriesCard from "../Cards/SubCategoriesCard/SubCategoriesCard";
import { withTranslation } from "react-i18next";

const CarouselWithArrows = ({
  t,
  Slides = [],
  SubCategories = [],
  isCollectionLoading,
}) => {
  return (
    <div style={{ position: "relative" }}>
      <Carousel
        className="flip-container carousel-arrow"
        showArrows={true}
        showThumbs={false}
        showStatus={false}
        infiniteLoop={false}
        autoPlay={true}
        interval={6000}
        renderArrowNext={(clickHandler) => (
          <div onClick={clickHandler} className={"CarouselRightArrow"}>
            <IconOverlay src={icons.CarouselRightArrow} />
          </div>
        )}
        renderArrowPrev={(clickHandler) => (
          <div onClick={clickHandler} className={"CarouselLeftArrow"}>
            <IconOverlay src={icons.CarouselLeftArrow} />
          </div>
        )}
      >
        {SubCategories?.length ? (
          isCollectionLoading ? (
            <div className="progress-indicator">
              <CircularProgress style={{ color: "var(--secondary-color)" }} />
            </div>
          ) : (
            SubCategories?.map((page) => {
              return (
                <Grid container direction="row" spacing={3}>
                  {page.map((subCategory) => {
                    return (
                      <>
                        <SubCategoriesCard
                          title={subCategory.name}
                          text={subCategory.name}
                          image={subCategory?.image ?? images.CollectionImage}
                          filter={subCategory.id}
                          key={subCategory.name}
                          filterParent={subCategory?.parent_subcategory?.id}
                        />
                      </>
                    );
                  })}
                </Grid>
              );
            })
          )
        ) : (
          Slides?.length &&
          Slides?.map((item) => (
            <CarouselItem img={item?.url ?? images.carouselPlaceHolder} />
          ))
        )}
      </Carousel>
    </div>
  );
};

export default withTranslation()(CarouselWithArrows);
