import { Grid } from "@material-ui/core";
import { useState } from "react";
import { withTranslation } from "react-i18next";
import "../RecipeStyles.css"
import "./RecipeSteps.css"
import ReactPlayer from 'react-player'

const RecipeSteps = ({ t, steps }) => {



    return (
        <div className="recipe--container">
            <h2 className="recipe--title">
                {t("dan kitchen.how to cook")}
            </h2>

            <div>

                <Grid container spacing={1}>

                    <Grid item xs={12} sm={6}>
                        {steps.map((step, index) =>
                            <div className="recipe-step">
                                <div><div className="recipe-step-number">{index + 1}</div></div>
                                <div className="recipe-step-text">{step}</div>
                            </div>)}
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <div className="recipe-video-container">
                            <ReactPlayer controls={true} url='https://www.youtube.com/watch?v=roCX0AfBseQ' />
                        </div>
                    </Grid>
                </Grid>

            </div>
        </div>
    );
}

export default withTranslation()(RecipeSteps);