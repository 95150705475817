import "react-toastify/dist/ReactToastify.css";
import "moment/locale/ar";
import "./App.css";

import React, { useEffect, useState } from "react";

import Axios from "axios";
import LocalizationProviders from "./Contexts/contextLocalization/locaizationContext";
import Routes from "./Routes";
import { ToastContainer } from "react-toastify";
import moment from "moment";
import { useSystem } from "./Hooks/useSystem/useSystem";
import { withTranslation } from "react-i18next";

const App = (props) => {
  const { systemConfig, isSystemConfigLoading, loadSystemConfig } = useSystem();

  useEffect(() => {
    if (!systemConfig) loadSystemConfig();
  }, []);
  useEffect(() => {
    if (systemConfig) {
      let root = document.querySelector(":root");
      root.style.setProperty(
        "--primary-color",
        systemConfig["System Configurations"]["Primary Color"]
      );
      root.style.setProperty(
        "--secondary-color",
        systemConfig["System Configurations"]["Secondary Color"]
      );
      document.title = systemConfig["System Configurations"]["Project Name"];
    }
  }, [systemConfig]);

  const [value, setvalue] = useState("en");
  const [localizationAr, setlocalizationAr] = useState(false);
  useEffect(() => {
    if (localStorage.getItem("TOKEN")) {
      if (localStorage.getItem("USER_TYPE") == "vendor")
        localStorage.setItem("USER_TYPE", "vendor");
      else localStorage.setItem("USER_TYPE", "user");
    } else {
      localStorage.setItem("USER_TYPE", "guest");
    }

    if (localStorage.getItem("lang")) {
      if (localStorage.getItem("lang") === "ar") {
        setlocalizationAr(true);
        setvalue("ar");
        props.i18n.changeLanguage("ar");
      } else {
        setlocalizationAr(false);
        setvalue("en");
        props.i18n.changeLanguage("en");
      }
    } else {
      localStorage.setItem("lang", "en");
      props.i18n.changeLanguage("en");
    }
  }, []);

  const onLanguageHandle = (event) => {
    let newLang = event;
    setvalue(newLang);
    props.i18n.changeLanguage(newLang);
    localStorage.setItem("lang", newLang);
    if (newLang === "ar") {
      setlocalizationAr(true);
      // Axios.defaults.headers.common["Accept-Language"] = "ar";
      moment.locale("ar");
    } else {
      setlocalizationAr(false);
      // Axios.defaults.headers.common["Accept-Language"] = "en";
      moment.locale("en");
    }
    window.location.reload();
  };

  return (
    <div className={localizationAr ? "ar" : "App"}>
      <LocalizationProviders localizationAr={localizationAr}>
        <ToastContainer rtl={localizationAr} />
        <Routes
          onLanguageHandle={onLanguageHandle}
          value={value}
          systemConfig={systemConfig}
          isSystemConfigLoading={isSystemConfigLoading}
        />
      </LocalizationProviders>
    </div>
  );
};

export default withTranslation()(App);
