import { useHistory, useParams } from "react-router-dom";

import RequestDetailsTemplate from "../../../Templates/Home/Profile/EtradelingRequestDetails/RequestDetails.template";
import { useEffect } from "react";
import { useRequests } from "../../../Hooks/useRequests/useRequests";
import { useState } from "react";

//ETRADELING
const RequestDetailsPage = () => {
  const [modalOpen, setModalOpen] = useState(false);
  //HOOKS
  const history = useHistory();
  let { id } = useParams();
  const {
    productRequest,
    isProductRequestLoading,
    loadProductRequest,
    deleteRequest,
    acceptQuotationProposal,
    rejectQuotationProposal,
  } = useRequests();

  //EFFECTS
  useEffect(() => {
    loadProductRequest(id);
  }, []);

  //HANDLERS
  const deleteHandler = async (id) => {
    await deleteRequest(id);
    history.push("/profile/my_requests");
  };
  const acceptHandler = (requestID, quotationID) => {
    acceptQuotationProposal(requestID, quotationID);
    history.push("/profile/my_requests");
  };
  const rejectHandler = (requestID, quotationID) => {
    rejectQuotationProposal(requestID, quotationID);
    history.push("/profile/my_requests");
  };
  const editHandler = () => {};

  const handleModal = () => {
    setModalOpen(!modalOpen);
  };

  return (
    <RequestDetailsTemplate
      productRequest={productRequest}
      isProductRequestLoading={isProductRequestLoading}
      deleteHandler={deleteHandler}
      id={id}
      acceptHandler={acceptHandler}
      rejectHandler={rejectHandler}
      handleModal={handleModal}
      modalOpen={modalOpen}
    />
  );
};

export default RequestDetailsPage;
