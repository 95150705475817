import * as React from "react";

import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { Link } from "react-router-dom";
import PrimaryButton from "../../../Components/Atoms/Buttons/Primary/PrimaryButton";
import { Typography } from "@mui/material";
import { images } from "../../../Assets/images/IconHelper";

function ProposalConfirmTemplate() {
  return (
    <Container component="main" maxWidth="sm">
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <img
          src={images.confirmation}
          style={{ width: "160px", marginTop: "100px" }}
        />
      </Box>
      <Typography
        variant="h2"
        sx={{
          fontSize: "28px",
          fontWeight: "900",
          marginTop: "50px",
          textAlign: "center",
        }}
      >
        Your Request is submitted successfully
      </Typography>
      <Typography
        variant="h2"
        sx={{
          fontSize: "24px",
          fontWeight: "400",
          marginTop: "18px",
          textAlign: "center",
        }}
      >
        waiting for Client approval
      </Typography>
      <Link
        to="/profile/my_proposals"
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          marginTop: "30px",
        }}
      >
        <PrimaryButton
          text={"Go to my Proposal list"}
          classBtn="primary proposal"
          style={{
            maxWidth: "320px",
          }}
        />
      </Link>
    </Container>
  );
}

export default ProposalConfirmTemplate;
