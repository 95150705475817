import React, { useEffect, useState } from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import BoxItems from "../../../Pages/Home/Profile/BoxItems.page";
import DanReward from "../../../Pages/Home/Profile/DanReward.Page";
import EsolarMyRequestsPage from "../../../Pages/Home/Profile/EsolarMyRequests.page";
import EsolarRequestDetailsPage from "../../../Pages/Home/Profile/EsolaRequestDetails.page";
import EtradelingMyQuotationPage from "../../../Pages/Home/Profile/EtradelingMyQuotation.page";
import { Grid } from "@material-ui/core";
import Messenger from "../../../Pages/Home/Profile/Messenger.Page";
import MyAccount from "../../../Pages/Home/Profile/MyAccount.Page";
import MyAddress from "../../../Pages/Home/Profile/MyAdress.Page";
import MyBoxes from "../../../Pages/Home/Profile/MyBoxes.Page";
import MyItems from "../../../Pages/Home/Profile/MyItems.Page";
import MyOrders from "../../../Pages/Home/Profile/MyOrders.Page";
import MyProposalsPage from "../../../Pages/Home/Profile/MyProposals.page";
import OrderDetails from "../../../Pages/Home/Profile/OrderDetails.Page";
import PaymentsList from "../../../Pages/Home/Profile/PaymentsList.Page";
import ProductReview from "../../../Pages/Home/Profile/ProductReview.Page";
import ProposalDetailsPage from "../../../Pages/Home/Profile/ProposalDetails.page";
import RequestDetailsPage from "../../../Pages/Home/Profile/RequestDetailsPage";
import SideNav from "../../../Components/Organisms/SideNav/SideNav";
import VendorProposalDetailsPage from "../../../Pages/Home/Profile/VendorProposalDetails.page";

function ProfileTemplate({ systemConfig, account }) {
  const [state, setState] = useState({
    mobileView: false,
  });
  const { mobileView } = state;
  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 600
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };

    setResponsiveness();
    window.addEventListener("resize", () => setResponsiveness());

    return () => {
      window.removeEventListener("resize", () => setResponsiveness());
    };
  }, [window.innerWidth]);

  return (
    <Grid container spacing={1}>
      {!mobileView && (
        <Grid item xs={0} sm={4}>
          <SideNav
            mobileView={mobileView}
            systemConfig={systemConfig}
            account={account}
          />
        </Grid>
      )}
      <Grid item xs={12} sm={8}>
        {mobileView && (
          <SideNav
            mobileView={mobileView}
            systemConfig={systemConfig}
            account={account}
          />
        )}
        <Switch>
          <Route exact path="/profile">
            <Redirect to="/profile/account" />
          </Route>
          <Route exact path="/profile/account" component={MyAccount} />
          <Route exact path="/profile/rewards" component={DanReward} />
          <Route exact path="/profile/orders" component={MyOrders} />
          <Route exact path="/profile/boxes" component={MyBoxes} />
          <Route exact path="/profile/items" component={MyItems} />
          <Route exact path="/profile/addresses" component={MyAddress} />
          <Route exact path="/profile/boxes/:id" component={BoxItems} />
          <Route exact path="/profile/messenger" component={Messenger} />
          {systemConfig &&
          systemConfig["System Configurations"]["Project Name"] === "esolar" ? (
            <Route
              exact
              path="/profile/requests/:id"
              component={EsolarRequestDetailsPage}
            />
          ) : (
            <Route
              exact
              path="/profile/requests/:id"
              component={RequestDetailsPage}
            />
          )}

          <Route
            exact
            path="/profile/requests/:id"
            component={RequestDetailsPage}
          />
          {systemConfig &&
          systemConfig["System Configurations"]["Project Name"] === "esolar" ? (
            <Route
              exact
              path="/profile/my_requests"
              component={EsolarMyRequestsPage}
            />
          ) : (
            <Route
              exact
              path="/profile/my_requests"
              component={EtradelingMyQuotationPage}
            />
          )}

          <Route
            exact
            path="/profile/orders/details/:number"
            component={OrderDetails}
          />
          <Route
            exact
            path="/profile/orders/details/:product/review/:number"
            component={ProductReview}
          />
          <Route exact path="/profile/paymentlist" component={PaymentsList} />
          <Route
            exact
            path="/profile/request/:request_id/proposal/:proposal_id"
            component={ProposalDetailsPage}
          />
          <Route
            exact
            path="/profile/proposaldetails/:id"
            component={VendorProposalDetailsPage}
          />
          <Route
            exact
            path="/profile/my_proposals"
            component={MyProposalsPage}
          />
        </Switch>
      </Grid>
    </Grid>
  );
}

export default ProfileTemplate;
