import { Box, Container } from "@mui/system";
import { Divider, Grid, Typography } from "@mui/material";
import { NavLink, useHistory } from "react-router-dom";

import AddressCard from "../../../../Components/Organisms/Cards/AddressCard/AddressCard";
import IconOverlay from "../../../../Components/Atoms/IconOverlay/IconOverlay";
import Installments from "../../../../Components/Organisms/Installments/Installments";
import PrimaryButton from "../../../../Components/Atoms/Buttons/Primary/PrimaryButton";
import PropmptModal from "../../../../Components/Organisms/Modals/PromptModal/PropmptModal";
import ProposalBody from "../../../../Components/Molecules/ProposalBody/ProposalBody";
import RequestDetailsHeader from "../../../../Components/Molecules/RequestDetailsHeader/RequestDetailsHeader";
import UploadedFile from "../../../../Components/Molecules/UploadedFile/UploadedFile";
import { dateFormatter } from "../../../../utils/dateFormatter/dateFormatter";
import { icons } from "../../../../Assets/images/IconHelper";
import { withTranslation } from "react-i18next";

// DETAILS ACTIONS
const DetailsActions = ({ handleModal, id, status, title }) => {
  const history = useHistory();
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        borderBottom: "1px #979797 solid",
      }}
    >
      <Box sx={{ display: "flex", cursor: "pointer", mt: "16px", mb: "18px" }}>
        <NavLink to="/profile/my_proposals">
          <img src={icons.dropdown} className="back_icon" />
        </NavLink>{" "}
        <Typography
          sx={{
            color: "#6f6f6f",
            mx: "16px",
            fontSize: "16px",
            fontWeight: 600,
          }}
        >
          {title} #{id}
        </Typography>
      </Box>
      {status === "inprogress" ? (
        <Box sx={{ display: "flex" }}>
          <PrimaryButton
            size="small"
            color="primary"
            text="Chat with Customer Support"
            // startIcon={<IconOverlay src={icons.edit} iconStyle="icon-black" />}
            classBtn=" rounded-20 outline-secondry btn-white-secondry"
            style={{ marginTop: "11px" }}
            // click={() => history.push(`/edit_request/${id}`)}
          />{" "}
        </Box>
      ) : (
        <Box sx={{ display: "flex" }}>
          <PrimaryButton
            size="small"
            color="primary"
            text="Edit"
            startIcon={<IconOverlay src={icons.edit} iconStyle="icon-black" />}
            classBtn="borderless success btn-regular"
            click={() => history.push(`/editproposal/${id}`)}
            style={{ marginRight: "56px" }}
          />
          <PrimaryButton
            size="small"
            color="primary"
            text="Delete"
            startIcon={<IconOverlay src={icons.bin} iconStyle="icon-white" />}
            classBtn="borderless error"
            click={() => {
              handleModal();
            }}
          />
        </Box>
      )}
    </Box>
  );
};

// DETAILS HEADER
const DetailsHeader = ({
  my,
  image,
  name,
  rating,
  reviewsCount,
  date,
  dueDate,
}) => {
  const formattedDate = dateFormatter(date);
  return (
    <Box sx={{ display: "flex", justifyContent: "space-between", my: my }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
        }}
      >
        <img
          src={image ? image : icons.shopIcon}
          style={{ width: 48, height: 48, borderRadius: "50%" }}
        />
        <Box>
          <Typography
            gutterBottom
            variant="h5"
            component="div"
            sx={{
              fontSize: "14px",
              color: "#3f3f3f",
              fontWeight: "500",
              fontFamily: "Roboto",

              marginLeft: 2,
              marginRight: 2,
            }}
          >
            {name}
          </Typography>
          <div style={{ display: "flex", marginLeft: 20, marginRight: 5 }}>
            <IconOverlay
              src={icons.starIcon}
              style={{ marginBottom: 4, marginRight: 2 }}
            />
            <Typography
              gutterBottom
              variant="h5"
              component="div"
              sx={{
                fontSize: "12px",
                color: "#9e9e9e",
                fontWeight: "500",
                fontFamily: "Roboto",
                marginRight: 0.4,
              }}
            >
              {rating}
            </Typography>
            <Typography
              gutterBottom
              variant="h5"
              component="div"
              sx={{
                fontSize: "10px",
                color: "#a9a9a9",
                fontWeight: "300",
                fontFamily: "Roboto",
                marginTop: 0.5,
              }}
            >
              {"(" + reviewsCount + ")"}
            </Typography>
          </div>
        </Box>
      </Box>

      <Typography
        gutterBottom
        variant="h5"
        component="div"
        sx={{ fontSize: "14px", color: "#6e7a8a", fontWeight: "normal" }}
      >
        {dueDate ? dateFormatter(dueDate?.due_date, true) : formattedDate}
      </Typography>
    </Box>
  );
};

const VendorProposalDetailsBody = ({
  typeOfService,
  buildingType,
  jobDetails,
  attachedFiles,
  dueDate,
  address,
}) => {
  const date = new Date(dueDate);
  return (
    <>
      <Grid container direction="row" sx={{ mb: "14px", mt: "20px" }}>
        <Grid item xs={4}>
          <Typography
            sx={{ color: "#303030", fontWeight: 600, fontSize: "14px" }}
          >
            Type of Services
          </Typography>
          <Typography
            sx={{ color: "#383838", fontWeight: 300, fontSize: "14px" }}
          >
            {typeOfService}
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography
            sx={{ color: "#303030", fontWeight: 600, fontSize: "14px" }}
          >
            Building Type
          </Typography>
          <Typography
            sx={{ color: "#383838", fontWeight: 300, fontSize: "14px" }}
          >
            {/* {systemConfig &&
            systemConfig["System Configurations"]["Project Name"] === "esolar"
              ? productRequest?.building_type_name
              : productRequest?.category_name} */}
            {buildingType}
          </Typography>
        </Grid>
      </Grid>
      <Box>
        <Typography
          sx={{ fontSize: "14px", fontWeight: 600, color: "#303030" }}
        >
          {"Job Details"}
        </Typography>
        <Typography
          sx={{ fontSize: "14px", fontWeight: "normal", color: "#303030" }}
        >
          {jobDetails}
        </Typography>
      </Box>
      {
        <Box sx={{ display: "flex", mt: "16px" }}>
          {attachedFiles?.map((attachement) => {
            return (
              <a href={attachement.url} target="_blank">
                <UploadedFile
                  fileType={".pdf"}
                  fileName={"some test file name"}
                />
              </a>
            );
          })}
        </Box>
      }
      <Box sx={{ my: "40px" }}>
        <Typography
          sx={{ fontSize: "14px", fontWeight: 600, color: "#303030" }}
        >
          Date and time
        </Typography>
        <Typography
          sx={{ fontSize: "16px", color: "#303030", fontWeight: "normal" }}
        >
          {date.toUTCString()}
        </Typography>
      </Box>
      <AddressCard
        address={{ attributes: address }}
        noDelete={true}
        noEdit={true}
        noCheckbox={true}
        displayCard={true}
      />
    </>
  );
};

const VendorProposalDetailsTemplate = ({
  handleModal,
  id,
  status,
  t,
  data,
  handleDelete,
  modalOpen,
}) => {
  console.log("data:", data);
  return (
    <Container component="main" maxWidth="lg">
      <DetailsActions
        handleModal={handleModal}
        id={data?.proposals[0]?.id}
        status={status}
        title={t("cards.Proposal Number")}
      />
      <Typography
        sx={{ fontSize: "22px", color: "#1a1a1a", fontWeight: 500, my: "15px" }}
      >
        Request Details
      </Typography>
      <DetailsHeader
        my={"10px"}
        image={data?.client_image}
        name={data?.client_name}
        date={data?.created_at}
        rating={data?.client_rating}
        reviewsCount={data?.client_reviews_count}
        dueDate={data?.due_date}
      />
      <VendorProposalDetailsBody
        typeOfService={data?.service_type_name}
        buildingType={data?.building_type_name}
        jobDetails={data?.details}
        attachedFiles={data?.documents}
        dueDate={data?.datetime}
        address={data?.address}
      />
      <div style={{ borderTop: "solid 1px #979797", marginTop: "30px" }}></div>
      <Typography sx={{ fontSize: 22, fontWeight: 500, my: "15px" }}>
        Your Proposal Details
      </Typography>

      <ProposalBody data={data?.proposals[0]} />
      <Typography sx={{ fontSize: 22, fontWeight: 500, my: "24px" }}>
        Installments
      </Typography>
      <Installments installments={data?.proposals[0]?.installments} />
      <PropmptModal
        open={modalOpen}
        id={data?.proposals[0]?.id}
        actionHandler={handleDelete}
        handleClose={handleModal}
        title={"Delete Proposal"}
        text={`Are you sure you want to delete proposal`}
      />
    </Container>
  );
};

export default withTranslation()(VendorProposalDetailsTemplate);
