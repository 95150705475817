import "./SubCategoriesCard.css";

import { Grid } from "@material-ui/core";
import { Link } from "react-router-dom";
import PrimaryButton from "../../../Atoms/Buttons/Primary/PrimaryButton";
import { withTranslation } from "react-i18next";
import { useContext } from "react";
import { systemContext } from "../../../../Contexts/systemContext/systemContext";

const SubCategoriesCard = (props) => {
  const { systemConfig } = useContext(systemContext);
  const { t } = props;
  return (
    <Grid item xs={12} sm={6} md={4}>
      <Link
        to={
          systemConfig &&
          JSON.parse(systemConfig["System Configurations"]["Collections"])
            ? `/collections?taxon=${
                props?.categories ? props.filter : props.filterParent
              }`
            : `/shopping?taxon=${
                props?.categories ? props.filter : props.filterParent
              }`
        }
      >
        <div
          className="shopping_item"
          style={{
            backgroundImage: `url(${props.image})`,
            display: "flex",
            alignItems: "flex-end",
          }}
        >
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            // wrap="nowrap"
            alignItems="center"
            style={{ marginBottom: "15px", height: "20%" }}
            className={"flip-content"}
          >
            <p className="sub-categories-card-title">{props.title}</p>

            <div className="shopping-card-btn">
              <Link
                to={
                  systemConfig &&
                  JSON.parse(
                    systemConfig["System Configurations"]["Collections"]
                  )
                    ? `/collections?taxon=${
                        props?.categories ? props.filter : props.filterParent
                      }`
                    : `/shopping?taxon=${
                        props?.categories ? props.filter : props.filterParent
                      }`
                }
              >
                {/* <PrimaryButton
                  classBtn="btn-secondry btn-rounded btn-mini"
                  text={t(`shopping.seeAll`)}
                /> */}
              </Link>
            </div>
          </Grid>
        </div>
      </Link>
    </Grid>
  );
};
export default withTranslation()(SubCategoriesCard);
