import { Box } from "@mui/system";
import IconOverlay from "../../Atoms/IconOverlay/IconOverlay";
import PrimaryButton from "../../Atoms/Buttons/Primary/PrimaryButton";
import { icons } from "../../../Assets/images/IconHelper";
import { systemContext } from "../../../Contexts/systemContext/systemContext";
import { useContext } from "react";
import { useHistory } from "react-router-dom";

// data prop represents proposal data
const ProposalButtons = ({
  data,
  acceptHandler,
  rejectHandler,
  requestId,
  viewDetails,
  mt,
  mb,
}) => {
  const history = useHistory();
  const { systemConfig } = useContext(systemContext);
  return (
    <>
      <Box sx={{ display: "flex", mt: mt, mb: mb }}>
        <Box sx={{ width: 140, marginRight: 2 }}>
          <PrimaryButton
            size="small"
            color="primary"
            text="Accept"
            classBtn="rounded-5 outline-green btn-white"
            click={() => acceptHandler(data?.request_id, data?.id)}
          />
        </Box>
        <Box sx={{ width: 140, marginRight: 2 }}>
          <PrimaryButton
            size="small"
            color="primary"
            text="Reject"
            classBtn="rounded-5 outline-red btn-white"
            click={() => rejectHandler(data?.request_id, data?.id)}
          />
        </Box>
        {systemConfig &&
        systemConfig["System Configurations"]["Project Name"] === "esolar" ? (
          <Box sx={{ width: 140, marginRight: 2 }}>
            <PrimaryButton
              size="small"
              color="primary"
              text="Chat now"
              classBtn=" rounded-5 outline-secondry btn-white-secondry"
              // click={() =>

              // }
            />
          </Box>
        ) : (
          <></>
        )}
      </Box>
      {viewDetails ? (
        <Box style={{ display: "flex" }}>
          <PrimaryButton
            size="small"
            color="primary"
            text="View Details"
            classBtn=" btn-white-secondry rounded-5 btn-white"
            click={() =>
              history.push({
                pathname: `/profile/request/${requestId}/proposal/${data?.id}`,
                state: data,
              })
            }
            endIcon={
              <IconOverlay
                src={icons.arrowRightIcon}
                iconStyle={"icon-esolar"}
              />
            }
          />
        </Box>
      ) : (
        <></>
      )}
    </>
  );
};

export default ProposalButtons;
