import { Grid } from '@material-ui/core'
import React from 'react'
import { withTranslation } from 'react-i18next'
import { icons } from '../../../../Assets/images/IconHelper'
import IconOverlay from '../../../Atoms/IconOverlay/IconOverlay'

import './RewardCard.css'

const AddressCard = (props) => {
    const { t } = props
    return (
        <Grid container className="reward_card">
            <Grid item xs={12} md={2}>
                {/* <img src={icons.medal2} alt=""/> */}
                <IconOverlay src={icons.medal2} iconStyle="icon-primary"/>
            </Grid>
            <Grid item xs={12} md={6}>
                <p className="title">{t('reward.Your point')}</p>
                <h6>3038</h6>
            </Grid>
            <Grid item xs={12} md={3}>
                <h6  style={{marginBlock:"12px"}}>{t('reward.value')} :</h6>
                <h6><span>S.R </span> 120.00 </h6>
            </Grid>
        </Grid>
    )
}

export default withTranslation()(AddressCard)
