import * as URL from "../../URLs";

import { axiosFn, handleError } from "../../index";

// import { toast } from "react-toastify";

export async function deleteServiceRequest(id) {
  return await axiosFn("delete", `${URL.REQUESTS}/${id}`)
    .then((res) => {
      return {
        res: res?.data,
        err: null,
        status: res?.status,
      };
    })
    .catch(handleError);
}
export async function deleteServiceRequestAPI(id) {
  let { res, err, status } = await deleteServiceRequest(id);

  return { res, err, status };
}
