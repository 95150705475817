import { deleteProposalAPI } from "../../Contexts/API/Home/Proposals/deleteProposal";
import { getProposalAPI } from "../../Contexts/API/Home/Proposals/getProposal";
import { getProposalsAPI } from "../../Contexts/API/Home/Proposals/getProposals";
import { toast } from "react-toastify";
import { updateProposalAPI } from "../../Contexts/API/Home/Proposals/updateProposal";
import { useState } from "react";

export const useProposals = () => {
  const [proposals, setProposals] = useState(null);
  const [hasMore, setHasMore] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [proposal, setProposal] = useState(null);

  //A function that gets the proposals out of teh requests since
  //The get proposals API returns a list of requests with proposals contained inside
  // const extractProposals = (requests) => {
  //   const proposals = requests?.map((request) => request?.proposals[0]);
  //   return proposals;
  // };

  const loadProposal = async (id) => {
    setLoading(true);
    const { res } = await getProposalAPI(id);
    if (res) {
      setProposal(res?.request);
    }
    setLoading(false);
  };

  const loadProposals = async (queryParams) => {
    setLoading(true);
    const { res } = await getProposalsAPI(queryParams);
    if (res) {
      // const extractedProposals = await extractProposals();
      setProposals(res?.requests);

      if (currentPage < res?.total_pages) {
        setHasMore(true);
        setCurrentPage(currentPage + 1);
      } else {
        setHasMore(false);
      }
    }
    setLoading(false);
  };

  const loadMoreProposals = async (queryParams) => {
    setLoading(true);
    const { res } = await getProposalsAPI(queryParams);
    if (res) {
      setProposals([...proposals, ...res?.requests]);

      if (currentPage < res?.total_pages) {
        setHasMore(true);
        setCurrentPage(currentPage + 1);
      } else {
        setHasMore(false);
      }
    }
    setLoading(false);
  };

  const deleteProposal = async (id) => {
    const { status } = await deleteProposalAPI(id);

    if (status === 200) {
      toast.success("Proposal Deleted Successfully");
    } else {
      toast.error("An error occured please try again");
    }
  };

  const updateProposal = async (id, data) => {
    const { res } = await updateProposalAPI(id, data);

    if (res) {
      window.location = "/proposal/success";
    }
  };

  return {
    proposals,
    hasMore,
    loadProposals,
    deleteProposal,
    loadMoreProposals,
    loading,
    currentPage,
    setCurrentPage,
    loadProposal,
    proposal,
    updateProposal,
  };
};
