import { Dialog, DialogContent, DialogTitle, Grid } from "@material-ui/core";
import { withTranslation } from "react-i18next";
import { FacebookMessengerShareButton, FacebookShareButton, TwitterShareButton, WhatsappShareButton } from "react-share";
import { toast } from "react-toastify";
import { icons } from "../../../../Assets/images/IconHelper";
import IconOverlay from "../../../Atoms/IconOverlay/IconOverlay";
import TextFieldInput from "../../../Atoms/Inputs/TextField/TextFieldInput";
import SearchBox from "../../../Molecules/SearchBox/SearchBox";

import './SocialModal.css'

const SocialModal = ({ t, i18n, ...props }) => {

    const copyLink = (link) => {
        navigator.clipboard.writeText(link);
        toast.info(`Link copied to clipboard`, {
            autoClose: 1250,
            hideProgressBar: true,
            closeOnClick: true,
            progress: undefined,
        })
    }


    return (
        <Dialog
            open={props.open}
            onClose={props.handleClose}
            className={i18n.language === 'ar' ? "rtl_modal" : ""}
        >
            <DialogTitle>
                <div className="social-modal-header">
                    <p className="Modal_header">Share this Blog</p>

                    <IconOverlay src={icons.xIcon} iconStyle="icon-primary social-modal-x" onClick={props.handleClose} />
                </div>
            </DialogTitle>

            <DialogContent className="newboxmodal-content">
                <Grid container className="modal_form">
                    <Grid item xs={12}>
                        <SearchBox
                            text={"Copy"}
                            placeholder={'Url link'}
                            value={props.link}
                            onchange={(email) => console.log({ email })}
                            submitFn={() => copyLink(props.link)}
                        />
                    </Grid>

                    <div className="social-sharebtns">
                        <FacebookShareButton
                            url={props.link}
                            quote={"DanFoods"}
                            hashtag="">
                            <IconOverlay src={icons.facebookCir} iconStyle="icon-primary" />
                        </FacebookShareButton>

                        <TwitterShareButton
                            url={props.link}
                            quote={"DanFoods"}
                            hashtag="">
                            <IconOverlay src={icons.twitterCir} iconStyle="icon-primary" />
                        </TwitterShareButton>

                        <WhatsappShareButton
                            url={props.link}
                            quote={"DanFoods"}
                            hashtag=""
                        >
                            <IconOverlay src={icons.whatsappCir} iconStyle="icon-primary" />
                        </WhatsappShareButton>

                        <FacebookMessengerShareButton
                            appId=""
                            url={props.link}
                            quote={"DanFoods"}
                            hashtag=""
                        >
                            <IconOverlay src={icons.messengerCir} iconStyle="icon-primary" />
                        </FacebookMessengerShareButton>

                    </div>

                </Grid>
            </DialogContent>
        </Dialog>
    );
}

export default withTranslation()(SocialModal);