import "./CustomerFeedbackBanner.css";

import { Grid, Typography } from "@material-ui/core";

import CustomerFeedbackCard from "../../Molecules/CustomerFeedbackCard/CustomerFeedbackCard";

const CustomerFeedbackBanner = () => {
  return (
    <Grid
      style={{
        backgroundColor: "#F9F9F9",
        marginTop: 125,
        paddingTop: 50,
        paddingBottom: 50,
      }}
    >
      <Typography className="customer-feedback-title">
        What Our Customer Say?
      </Typography>
      <Typography className="customer-feedback-content">
        DAN believes delicious, nutritious food is not a luxury. We see it as
        our responsibility to continually innovate ways to make delicious fruit
        and vegetables enjoyable and affordable anytime
      </Typography>
      <Grid
        container
        direction="row"
        justifyContent="center"
        style={{ paddingBottom: 20 }}
      >
        <CustomerFeedbackCard
          content={
            "Life before Company was very chaotic — we got a lot of phone calls, a lot of mistyped orders. So with Company, the ability to see the order directly from the customer makes it so streamlined."
          }
          user={"Martin Jones, Creative Cons"}
        />
        <CustomerFeedbackCard
          content={
            "Life before Company was very chaotic — we got a lot of phone calls, a lot of mistyped orders. So with Company, the ability to see the order directly from the customer makes it so streamlined."
          }
          user={"Martin Jones, Creative Cons"}
        />
        <CustomerFeedbackCard
          content={
            "Life before Company was very chaotic — we got a lot of phone calls, a lot of mistyped orders. So with Company, the ability to see the order directly from the customer makes it so streamlined."
          }
          user={"Martin Jones, Creative Cons"}
        />
      </Grid>
    </Grid>
  );
};

export default CustomerFeedbackBanner;
