import { axiosFn, handleError } from "../../index";
import * as URL from "../../URLs";

export async function ListCollectionProducts(collection_id, id, page, Ids) {
  return await axiosFn(
    "get",
    `${URL.COLLECTIONS}/${collection_id}/products?page=${page}&per_page=5${
      id ? `&taxon_id=${id}` : ""
    }${Ids?.length > 0 ? `&option_value_ids=[${Ids}]` : ""}`
  )
    .then((res) => {
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}
export async function ListCollectionProductsAPI(collection_id, id, page, Ids) {
  let { err, res } = await ListCollectionProducts(collection_id, id, page, Ids);

  if (res) {
    return res;
  }

  if (err) {
    return err;
  }
}
