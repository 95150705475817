import "./HomeShopping.css";

import { CircularProgress, Grid } from "@material-ui/core";
import React, { useContext } from "react";
import { useEffect, useState } from "react";

import { CategoriesContext } from "../../../../Contexts/contextCategories/categoriesContext";
import ShoppingCard from "../../../Molecules/ShoppingCard/ShoppingCard";
import { SpinnerCircular } from "spinners-react";
import { images } from "../../../../Assets/images/IconHelper";
import { withTranslation } from "react-i18next";

const HomeShopping = (props) => {
  const { t } = props;
  const categories = useContext(CategoriesContext);
  const catLoading = useContext(CategoriesContext);
  // console.log(
  //   "Categories from home shopping",
  //   categories.categories.data.slice(0, 5)
  // );
  const [shoppingCards, setShpopingCards] = useState([]);

  const loadCategories = async () => {
    await setShpopingCards((prevShoppingCards) => {
      prevShoppingCards = categories?.categories?.data;

      prevShoppingCards = prevShoppingCards?.slice(0, 6);
      return prevShoppingCards;
    });
  };

  useEffect(() => {
    loadCategories();
  }, [catLoading]);

  // const shoppingCardDetails = [
  //   {
  //     name: "fruits",
  //     img: images.fruits,
  //     filter: "fruits",
  //   },
  //   {
  //     name: "vegetables",
  //     img: images.vegetables,
  //     filter: "vegetables",
  //   },
  //   {
  //     name: "boxes",
  //     img: images.homeBoxes,
  //     filter: "boxes",
  //   },
  //   {
  //     name: "mushrooms",
  //     img: images.mushrooms,
  //     filter: "mushrooms",
  //   },
  //   {
  //     name: "salads",
  //     img: images.salads,
  //     filter: "salads",
  //   },
  //   {
  //     name: "more",
  //     img: images.more,
  //     filter: "more",
  //   },
  // ];
  return (
    <Grid container className="shopping">
      <Grid item xs={12}>
        <h2 className="shoppingTitle">{t("shopping.title")}</h2>
      </Grid>

      <Grid container spacing={2}>
        {catLoading.catLoading ? (
          <Grid container alignItems="center" justify="center">
            <SpinnerCircular
              size={90}
              thickness={180}
              speed={95}
              color="rgba(65, 172, 57, 1)"
              secondaryColor="rgba(111, 172, 57, 0.44)"
            />
          </Grid>
        ) : (
          shoppingCards?.map((detail, idx) => (
            <ShoppingCard
              title={detail.attributes.name}
              text={detail.attributes.name}
              image={images.fruits}
              filter={detail.id}
              key={idx}
            />
          ))
        )}
      </Grid>
    </Grid>
  );
};

export default withTranslation()(HomeShopping);
