import { axiosFn, handleError } from "../../index";
import * as URL from "../../URLs";

export async function getAccount() {
  return await axiosFn("get", `${URL.ACCOUNT}`)
    .then((res) => {
      localStorage.setItem("user_id", res?.data?.data?.id);
      return {
        res: res?.data,
        err: null,
        status: res?.status,
      };
    })
    .catch(handleError);
}

export async function getAccountAPI() {
  let { err, res, status } = await getAccount();

  return { res, err, status };
}
