import * as URL from "../../URLs";

import { axiosFn, handleError } from "../../index";

// import { toast } from "react-toastify";

export async function updateRequest(id, recivedData) {
  return await axiosFn("put", `${URL.PRODUCT_REQUESTS}/${id}`, recivedData)
    .then((res) => {
      return {
        res: res?.data,
        err: null,
        status: res?.status,
      };
    })
    .catch(handleError);
}
export async function updateRequestAPI(id, data) {
  let { err, res, status } = await updateRequest(id, data);

  return { res, err, status };
}
