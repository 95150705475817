import "../ModalStyle.css";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";

import PrimaryButton from "../../../Atoms/Buttons/Primary/PrimaryButton";
import React from "react";
import { withTranslation } from "react-i18next";

function LogoutModal(props) {
  const { t, i18n } = props;

  return (
    <Dialog
      open={props.open}
      className={
        i18n.language === "ar" ? "rtl_modal logoutModal" : "logoutModal"
      }
    >
      <DialogTitle>
        <p className="Modal_header">{t("general.Sign Out")}</p>
      </DialogTitle>
      <DialogContent>{t("general.logoutText")}</DialogContent>
      <DialogActions>
        <PrimaryButton
          classBtn="outline modal error"
          text={t("general.Cancel")}
          click={props.handleClose}
        />
        <PrimaryButton
          classBtn="outline modal success"
          text={t("general.Sign Out")}
          click={props.LogOutBtn}
        />
      </DialogActions>
    </Dialog>
  );
}

export default withTranslation()(LogoutModal);
