import EsolarRequestServiceTemplate from "../../../Templates/Home/EsolarRequestService/EsolarRequestService.template";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useServiceRequests } from "../../../Hooks/useServiceRequests/useServiceRequests";

const EsolarRequestServicePage = () => {
  const history = useHistory();
  const {
    loadBuildingTypes,
    loadServiceTypes,
    areBuildingTypesLoading,
    areServiceTypesLoading,
    buildingTypes,
    serviceTypes,
    createRequest,
    isSubmitRequestLoading,
  } = useServiceRequests();

  useEffect(() => {
    loadBuildingTypes();
    loadServiceTypes();
  }, []);

  const handleSubmitRequest = async (body) => {
    await createRequest(body);
    history.push("/profile/my_requests");
  };
  return (
    <EsolarRequestServiceTemplate
      loading={areBuildingTypesLoading & areServiceTypesLoading}
      buildingTypes={buildingTypes}
      serviceTypes={serviceTypes}
      handleSubmitRequest={handleSubmitRequest}
      isSubmitRequestLoading={isSubmitRequestLoading}
    />
  );
};
export default EsolarRequestServicePage;
