import "./InstallmentCard.css";

import * as yup from "yup";

import {
  Box,
  Grid,
  MenuItem,
  Select,
  TextField,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";

import IconOverlay from "../../Atoms/IconOverlay/IconOverlay";
import PrimaryButton from "../../Atoms/Buttons/Primary/PrimaryButton";
import UploadedFile from "../UploadedFile/UploadedFile";
import { icons } from "../../../Assets/images/IconHelper";
import { uploadToS3API } from "../../../Contexts/API/S3/uploadToS3";
import { useFormik } from "formik";
import { withTranslation } from "react-i18next";

const InstallmentCard = ({
  number,
  installment,
  proposal,
  setProposal,
  DeleteInstallment,
  InstallmentsValidation,
  setInstallmentsValidation,
  isClicked,
}) => {
  // Card Data
  const [installmentData, setInstallmentData] = useState({
    id: installment.id,
    title: installment?.title,
    estimated_price: installment?.estimated_price,
    currency_type_id: installment?.currency_type_id,
    details: installment?.details,
    documents_attributes: installment?.documents_attributes,
    duration: installment?.duration,
    due_date: installment?.due_date,
  });

  // Files
  const [additionalFiles, setAdditionalFiles] = useState([]);
  const [deletedDocumentIDS, setDeletedDocumentIDS] = useState([]);

  //REFS
  const inputFilee = useRef(null);

  const onFileChangee = async (event, setter, state) => {
    if (event.target.files && event.target.files[0]) {
      const arrayOfExtensions = [];
      const files = event.target.files;
      for (let [key, value] of Object.entries(files)) {
        arrayOfExtensions.push({
          extension: value.name.substring(value.name.indexOf(".") + 1),
        });
      }
      console.log(arrayOfExtensions);
      const { res } = await uploadToS3API(files, arrayOfExtensions);
      console.log(res);
      // const fileUrls = files.map((file) => URL.createObjectURL(file));

      setter([...installmentData?.documents_attributes, ...res]);
      setInstallmentData({
        ...installmentData,
        documents_attributes: [
          ...installmentData?.documents_attributes,
          ...res,
        ],
      });
      // dispatch(uploadProductImageStart(img));
      // setProfilePicture(URL.createObjectURL(img));
    }
  };

  const removeFile = (setter, state, id, backendId) => {
    const newState = installmentData?.documents_attributes?.filter(
      (file, index) => id !== index
    );
    setter(newState);
    setInstallmentData({
      ...installmentData,
      documents_attributes: newState,
    });
    setDeletedDocumentIDS([...deletedDocumentIDS, backendId]);
  };

  const validationSchema = yup.object({
    title: yup.string().required("Title is required"),
    estimated_price: yup.number().required("Estimated price is required"),
    details: yup.string().required("Details is required"),
  });

  //FORMIK
  const formik = useFormik({
    initialValues: {
      title: installmentData?.title,
      estimated_price: installmentData?.estimated_price,
      details: installmentData?.details,
    },
    validationSchema: validationSchema,
  });

  // update validation
  useEffect(() => {
    if (
      formik.isValid &&
      formik.values.title &&
      formik.values.estimated_price &&
      formik.values.details
    )
      setInstallmentsValidation([
        ...InstallmentsValidation.filter((item) => item.id != installment.id),
        { id: installment.id, condition: true },
      ]);
    else {
      setInstallmentsValidation([
        ...InstallmentsValidation.filter((item) => item.id != installment.id),
        { id: installment.id, condition: false },
      ]);
    }
  }, [formik.isValid]);

  // Update Installment State
  useEffect(() => {
    setProposal({
      ...proposal,
      installments: [
        ...proposal?.installments?.filter(
          (item) => item.id != installmentData.id
        ),
        installmentData,
      ].sort(function (x, y) {
        return x.id - y.id;
      }),
    });
  }, [installmentData]);

  //  Check details limit
  const [textAreaCharCount, setTextAreaCharCount] = useState(
    installmentData?.details?.length
  );
  useEffect(() => {
    setTextAreaCharCount(installmentData?.details?.length);
  }, [installmentData?.details?.length]);

  //  files
  const inputFile = useRef(null);
  const onFileChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      let file = event.target.files[0];
      // dispatch(uploadProductImageStart(img));
      // setProfilePicture(URL.createObjectURL(img));
    }
  };

  return (
    <Box
      sx={{
        width: "100%",
        background: "rgba(216, 216, 216, 0.2)",
        paddingLeft: "12px",
        paddingRight: "12px",
        paddingTop: "20px",
        paddingBottom: "20px",
        marginTop: "24px",
      }}
    >
      <Grid
        container
        spacing={1}
        sx={{
          width: "calc(100% - 8px) !important",
          marginLeft: "0 !important",
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: "semibold",
              paddingInlineStart: "8px",
            }}
          >
            Installment {number}
          </Typography>

          <PrimaryButton
            startIcon={<IconOverlay src={icons.bin} iconStyle="icon-white" />}
            classBtn="borderless error"
            text={"Delete"}
            click={() => DeleteInstallment(installment.id)}
          />
        </Box>
        <Grid item xs={6}>
          <TextField
            fullWidth
            placeholder={"Add title"}
            type="text"
            id="title"
            name="title"
            sx={{
              backgroundColor: "#FFFFFF",
            }}
            value={formik.values?.title ?? installmentData?.title}
            onChange={(e) => {
              setInstallmentData({ ...installmentData, title: e.target.value });
              formik.setFieldValue("title", e.target.value);
            }}
            error={isClicked && Boolean(formik.errors.title)}
          />
          {isClicked && formik.errors.title && (
            <Typography
              sx={{
                color: "#d32f2f",
                fontWeight: "400",
                fontSize: " 0.75rem",
                lineHeight: "1.66",
                letterSpacing: "0.03333em",
                textAlign: "left",
                marginTop: "3px",
                marginRight: "14px",
                marginBottom: 0,
                marginLeft: "14px",
              }}
            >
              {formik.errors.title}
            </Typography>
          )}
        </Grid>
        <Grid container item xs={6}>
          <Grid item xs={8}>
            <TextField
              fullWidth
              placeholder={"Estimated price"}
              type="number"
              id="estimated_price"
              name="estimated_price"
              sx={{
                backgroundColor: "#FFFFFF",
              }}
              value={
                formik.values?.estimated_price ??
                installmentData?.estimated_price
              }
              onChange={(e) => {
                setInstallmentData({
                  ...installmentData,
                  estimated_price: e.target.value,
                });
                formik.setFieldValue("estimated_price", e.target.value);
              }}
              error={isClicked && Boolean(formik.errors.estimated_price)}
            />
            {isClicked && formik.errors.estimated_price && (
              <Typography
                sx={{
                  color: "#d32f2f",
                  fontWeight: "400",
                  fontSize: " 0.75rem",
                  lineHeight: "1.66",
                  letterSpacing: "0.03333em",
                  textAlign: "left",
                  marginTop: "3px",
                  marginRight: "14px",
                  marginBottom: 0,
                  marginLeft: "14px",
                }}
              >
                {formik.errors.estimated_price}
              </Typography>
            )}
          </Grid>
          <Grid item xs={4}>
            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              style={{
                fontSize: "14px",
                paddingRight: "30px !important",
                backgroundColor: "#FFFFFF",
              }}
              value={installmentData?.currency_type_id}
              onChange={(e) =>
                setInstallmentData({
                  ...installmentData,
                  currency_type_id: e.target.value,
                })
              }
              defaultValue={1}
              fullWidth
            >
              <MenuItem value={1}>EGP</MenuItem>
              <MenuItem value={2}>USD</MenuItem>
              <MenuItem value={3}>USD</MenuItem>
            </Select>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: "semibold",
              marginBlock: "10px",
            }}
          >
            Duration(Optional)
          </Typography>
          <TextField
            required
            fullWidth
            type="text"
            placeholder="Etc 3 days.. "
            id="duration"
            name="duration"
            value={installmentData?.duration}
            sx={{
              backgroundColor: "#FFFFFF",
            }}
            onChange={(e) =>
              setInstallmentData({
                ...installmentData,
                duration: e.target.value,
              })
            }
          />
        </Grid>
        <Grid item xs={6}>
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: "semibold",
              marginBlock: "10px",
            }}
          >
            Due date(Optional)
          </Typography>
          <TextField
            required
            fullWidth
            type="date"
            placeholder="Select due date.. "
            id="due_date"
            name="due_date"
            value={installmentData?.due_date}
            sx={{
              backgroundColor: "#FFFFFF",
            }}
            onChange={(e) =>
              setInstallmentData({
                ...installmentData,
                due_date: e.target.value,
              })
            }
          />
          {console.log(installmentData)}
        </Grid>
        <Grid item xs={12}>
          <TextareaAutosize
            className="textarea"
            style={{
              width: "100%",
              height: "130px",
              resize: "none",
              // paddingLeft: "10px",
              paddingTop: "10px",
              borderTop:
                isClicked && Boolean(formik.errors.details)
                  ? "1px solid #d32f2f"
                  : "1px solid #3c4550",
              borderLeft:
                isClicked && Boolean(formik.errors.details)
                  ? "1px solid #d32f2f"
                  : "1px solid #3c4550",
              borderRight:
                isClicked && Boolean(formik.errors.details)
                  ? "1px solid #d32f2f"
                  : "1px solid #3c4550",
              borderBottom: "1px white solid",
              marginTop: "18px",
            }}
            maxLength="200"
            aria-label="empty textarea"
            placeholder="Write brief about this stage"
            value={formik.values?.details ?? installmentData?.details}
            onChange={(e) => {
              setInstallmentData({
                ...installmentData,
                details: e.target.value,
              });
              formik.setFieldValue("details", e.target.value);
            }}
            error={isClicked && Boolean(formik.errors.details)}
            helperText={isClicked && formik.errors.details}
          />
          {/* Word count inside the text area */}
          <Box
            sx={{
              marginTop: "-6px",
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
              borderRight:
                isClicked && Boolean(formik.errors.details)
                  ? "1px solid #d32f2f"
                  : "1px solid #3c4550",
              borderLeft:
                isClicked && Boolean(formik.errors.details)
                  ? "1px solid #d32f2f"
                  : "1px solid #3c4550",
              backgroundColor: "#FFFFFF",
            }}
          >
            <Typography
              sx={{
                mr: "25px",
                color: "#4a4a48",
                mb: "10px",
              }}
            >
              {textAreaCharCount}/200
            </Typography>
          </Box>
          {/* Attach files box */}
          <Box
            sx={{
              backgroundColor: "#D8D8D8",
              width: "100%",
              borderBottom:
                isClicked && Boolean(formik.errors.details)
                  ? "1px solid #d32f2f"
                  : "1px solid #3c4550",
              borderRight:
                isClicked && Boolean(formik.errors.details)
                  ? "1px solid #d32f2f"
                  : "1px solid #3c4550",
              borderLeft:
                isClicked && Boolean(formik.errors.details)
                  ? "1px solid #d32f2f"
                  : "1px solid #3c4550",
            }}
          >
            <Typography
              sx={{
                color: "#000000",
                mb: "12px",
                fontWeight: "500",
                fontSize: "14px",
                ml: "10px",
                pt: "10px",
                textDecoration: "underline",
                cursor:
                  installmentData?.documents_attributes?.length < 5
                    ? "pointer"
                    : "not-allowed",
              }}
              onClick={() => {
                if (installmentData?.documents_attributes?.length < 5)
                  inputFilee.current.click();
              }}
            >
              <input
                multiple
                type="file"
                id="file"
                ref={inputFilee}
                style={{ display: "none" }}
                onChange={(event) => {
                  onFileChangee(event, setAdditionalFiles, additionalFiles);
                }}
              />
              Attach files for additional information ({" "}
              {installmentData?.documents_attributes?.length} / 5 )
            </Typography>
            {installmentData?.documents_attributes?.length > 0 ? (
              <Box style={{ display: "flex", padding: "10px" }}>
                {installmentData?.documents_attributes?.map((file, index) => {
                  return (
                    <UploadedFile
                      fileType={file.extension}
                      fileName={file.file_name}
                      id={index}
                      handleRemove={removeFile}
                      files={additionalFiles}
                      setter={setAdditionalFiles}
                      backendId={file.id}
                    />
                  );
                })}
              </Box>
            ) : (
              ""
            )}
          </Box>
          {isClicked && formik.errors.details && (
            <Typography
              sx={{
                color: "#d32f2f",
                fontWeight: "400",
                fontSize: " 0.75rem",
                lineHeight: "1.66",
                letterSpacing: "0.03333em",
                textAlign: "left",
                marginTop: "3px",
                marginRight: "14px",
                marginBottom: 0,
                marginLeft: "14px",
              }}
            >
              {formik.errors.details}
            </Typography>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default withTranslation()(InstallmentCard);
