import "../ModalStyle.css";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@material-ui/core";
import React, { useState } from "react";

import PrimaryButton from "../../../Atoms/Buttons/Primary/PrimaryButton";
import TextFieldInput from "../../../Atoms/Inputs/TextField/TextFieldInput";
import { useEffect } from "react";
import { withTranslation } from "react-i18next";

function ChangePasswordModal(props) {
  const { t, i18n, email } = props;
  const [oldPass, setOldPass] = useState("");
  const [newPass, setnewPass] = useState("");
  const [confirmPass, setConfirmPass] = useState("");

  const [passErr, setPassErr] = useState(false);

  useEffect(() => {
    setPassErr(newPass === confirmPass ? false : true);
  }, [confirmPass]);

  const handleSubmit = () => {
    const login = {
      grant_type: "password",
      username: email,
      password: oldPass,
    };

    const newData = {
      password: newPass,
      password_confirmation: confirmPass,
    };
    props.updatePassword(newData);
    props.handleClose();
  };

  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      className={i18n.language === "ar" ? "rtl_modal" : ""}
    >
      <DialogTitle>
        <p className="Modal_header">
          {t("forgetPasswordScreen.changePassword")}
        </p>
      </DialogTitle>
      <DialogContent>
        <Grid container className="modal_form">
          <Grid item xs={12}>
            <label>{t("forgetPasswordScreen.Old Password")}</label>
            <TextFieldInput
              className="form_input"
              type="password"
              value={oldPass}
              onchange={(oldpw) => setOldPass(oldpw)}
            />
          </Grid>
          <Grid item xs={12}>
            <label>{t("forgetPasswordScreen.newPassword")}</label>
            <TextFieldInput
              className="form_input"
              type="password"
              value={newPass}
              onchange={(newpw) => setnewPass(newpw)}
            />
          </Grid>
          <Grid item xs={12}>
            <label>{t("forgetPasswordScreen.confirmNewPassword")}</label>
            <TextFieldInput
              className="form_input"
              error={passErr}
              type="password"
              value={confirmPass}
              onchange={(confirmpw) => setConfirmPass(confirmpw)}
            />
            {passErr && <p style={{ color: "red" }}>Passwords do not match!</p>}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <PrimaryButton
          classBtn="primary modal"
          text={t("forgetPasswordScreen.changePassword")}
          click={handleSubmit}
        />
      </DialogActions>
    </Dialog>
  );
}

export default withTranslation()(ChangePasswordModal);
