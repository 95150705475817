import * as URL from "../../URLs";

import { axiosFn, handleError } from "../../index";

export async function getFeaturedBanners() {
  return await axiosFn("get", `${URL.FEATURED_BANNERS}`)
    .then((res) => {
      return {
        res: res?.data,
        err: null,
        status: res?.status,
      };
    })
    .catch(handleError);
}

export async function getFeaturedBannersAPI() {
  let { res, err, status } = await getFeaturedBanners();

  return { res, err, status };
}
