import * as URL from "../../URLs";

import { axiosFn, handleError } from "../../index";

//GETS LIST OF CATEGORIES WITHOUT ANY EXTRA INFORMATION
export async function getTaxonsList(queryParams) {
  return await axiosFn(
    "get",
    `${URL.CATEGORIES_LIST}?${queryParams}`,
    null,
    false
  )
    .then((res) => {
      return {
        res: res?.data,
        err: null,
        status: res?.status,
      };
    })
    .catch(handleError);
}

export async function getTaxonsListAPI(queryParams) {
  let { err, res, status } = await getTaxonsList(queryParams);

  return { res, err, status };
}
