import React from 'react'
import RecipeTemplate from '../../../Templates/Home/DanKitchen/Recipe/Recipe.Template'


function Recipe() {
    return (
        <RecipeTemplate />
    )
}

export default Recipe
