import { withTranslation } from "react-i18next";
import OrderedItem from "../../Molecules/OrderedItem/OrderedItem";
import "./CheckoutReview.css";

const CheckoutReview = ({ t, items }) => {
  return (
    <div className="checkoutReview-card">
      <p className="checkoutReview-title">{t("checkout.review")}</p>

      {items?.map((item) => (
        <div className="checkoutReview-item" ley={item.id}>
          <OrderedItem
            item_name={item?.name}
            item_currency={item?.currency}
            item_price={item?.price}
            item_arrive={item?.item_arrive}
            item_quantity={item?.quantity}
            order_number={item?.order_number}
            item_id={item?.id}
            item_image={item?.image}
            btn_text={t("order.Review This Order")}
            showButton={false}
          />
        </div>
      ))}
    </div>
  );
};

export default withTranslation()(CheckoutReview);
