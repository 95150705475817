import ESolarHomeTemplate from "../../../Templates/Home/EsolarHomeTemplate/ESolarHome.template";
import { queryParamsFormatter } from "../../../utils/queryParamsFormatter/queryParamsFormatter";
import { useAdBanners } from "../../../Hooks/useAdBanners/useAdBanners";
import { useCategories } from "../../../Hooks/useCategories/useCategories";
import { useEffect } from "react";
import { useFeaturedBanners } from "../../../Hooks/useFeaturedBanners/useFeaturedBanners";
import { useHistory } from "react-router-dom";
import { useProducts } from "../../../Hooks/useProducts/useProducts";
import { useState } from "react";

const ESolarHomePage = () => {
  const history = useHistory();
  const [queryParams, setQueryParams] = useState({ "filter[roots]": true });
  const { adBanners, areAdBannersLoading, loadAdBanners } = useAdBanners();
  const { areCategoriesLoading, categories, loadCategories } = useCategories(
    queryParamsFormatter(queryParams)
  );
  const { featuredBanners, areFeaturedBannersLoading, loadFeaturedBanners } =
    useFeaturedBanners();
  const exploreCardButtonHandler = (link) => {
    history.push(link);
  };
  const { loadBestSellerProducts, BestProducts, areProductsLoading } =
    useProducts();
  useEffect(() => {
    loadAdBanners();
    loadCategories();
    loadBestSellerProducts();
  }, []);

  console.log(featuredBanners);
  useEffect(() => {
    loadFeaturedBanners();
  }, []);
  return (
    <ESolarHomeTemplate
      exploreCardButtonHandler={exploreCardButtonHandler}
      adBanners={adBanners}
      featuredBanners={featuredBanners}
      categories={categories}
      bestSellerProducts={BestProducts}
      areProductsLoading={areProductsLoading}
    />
  );
};

export default ESolarHomePage;
