import { ClickAwayListener, Drawer, IconButton } from "@material-ui/core";
import { useEffect, useRef, useState } from "react";
import { icons, images } from "../../../Assets/images/IconHelper";
import IconOverlay from "../../Atoms/IconOverlay/IconOverlay";
import TextArea from "../../Atoms/Inputs/TextArea/TextArea";
import "./ChatPanel.css";
import PrimaryButton from "../../Atoms/Buttons/Primary/PrimaryButton";
import CloseIcon from "@material-ui/icons/Close";
import { Link } from "react-router-dom";
import ChatMessage from "../../Atoms/ChatMessage/ChatMessage";
import moment from "moment";
import { withTranslation } from "react-i18next";

const ChatPanel = (props) => {
  const {
    toggleChat,
    userId,
    mobileView,
    drawerOpen,
    toggleDrawer,
    sendMessageRD,
    getMessagesRD,
    messages,
    morePages,
    t,
    i18n,
  } = props;

  const [message, setMessage] = useState("");

  useEffect(() => {
    if (messages?.length === 0) {
      getMessagesRD(null, scrollToBottom);
    }
    scrollToBottom();

    moment.locale(i18n.language);
  }, []);

  const messagesEndRef = useRef(null);

  const scrollToBottomSmooth = () => {
    if (!mobileView)
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
  };
  const scrollToBottom = () => {
    if (!mobileView) messagesEndRef.current.scrollIntoView();
  };

  const loadMore = () => {
    getMessagesRD(null);
  };

  const handleSend = async (e) => {
    if (message) {
      const isSuc = await sendMessageRD(userId, 0, message, 0, "", {
        id: userId,
        name: "",
        avatar: "",
      });
      if (isSuc) {
        setMessage("");
        scrollToBottomSmooth();
      }
    }
  };

  let date = null;
  const handleDates = (d) => {
    const curDate = moment(d).format(
      i18n.language == "ar" ? "dddd, Do MMM" : "ddd, MMM Do"
    );
    if (!date) {
      date = curDate;
      return curDate;
    } else if (date !== curDate) {
      date = curDate;
      return curDate;
    } else {
      return null;
    }
  };

  const renderChatPanel = (
    <>
      <div
        className="chat-header"
        onClick={mobileView ? toggleDrawer("bottom", false) : null}>
        {mobileView && (
          <IconButton>
            <CloseIcon />
          </IconButton>
        )}
        <img src={images.logo2} height="30px" alt="" />
        <span className="chat-header-text">{t("chat.head")}</span>
      </div>

      <div className="chat-list">
        {morePages && userId && (
          <PrimaryButton
            btnType="primary"
            classBtn="btn-link"
            click={loadMore}
            text={t("load more")}
          />
        )}
        {userId ? (
          messages.map((msg, index) => (
            <div key={index}>
              {handleDates(msg.date) ? (
                <div className="chat-day">{date}</div>
              ) : null}
              <ChatMessage msg={msg} isMine={msg?.sender?.id === userId} />
            </div>
          ))
        ) : (
          <div className="chat-notLoggedIn">
            <p>
              <Link to="/login">{t("loginBtn.Signin")}</Link> {t("chat.better")}
            </p>
            <p>
              {t("chat.dont")} <Link to="/register">{t("chat.create")}</Link>
            </p>
          </div>
        )}

        <span ref={messagesEndRef}></span>
      </div>

      <div className="chat-form">
        <form className="chat-formform" onSubmit={handleSend}>
          {/* <input type="text" placeholder="Reply Here" className="chat-form-input" /> */}

          <TextArea
            placeholder={t("chat.reply")}
            rowsMax={4}
            className="chat-form-input"
            value={message}
            onKeyDown={(e) => {
              if (e.key === "Enter") handleSend();
            }}
            onChange={(e) =>
              e.target.value !== "\n" ? setMessage(e.target.value) : null
            }
            disabled={!userId}
          />
        </form>
      </div>
    </>
  );

  return (
    <ClickAwayListener onClickAway={toggleChat}>
      <div>
        {!mobileView && (
          <>
            <div className="chat-x" onClick={toggleChat}>
              <IconOverlay src={icons.xIcon} iconStyle="icon-primary" />
            </div>
            <div className="chat-container">{renderChatPanel}</div>
          </>
        )}

        {mobileView && (
          <Drawer
            anchor="bottom"
            open={drawerOpen}
            onClose={toggleDrawer("bottom", false)}
            variant="temporary">
            <div role="presentation">
              <div className="chat-container">{renderChatPanel}</div>
            </div>
          </Drawer>
        )}
      </div>
    </ClickAwayListener>
  );
};

export default withTranslation()(ChatPanel);
