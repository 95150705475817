import React, { useState } from 'react'
import '../TermsConditions/TermsConditions.css'

function PrivacyPolicyTemplate() {
    const [policies, setPolicies] = useState([
        {
            title: "How Can i pay this products online ?",
            content: `It’s that time again when people start thinking about their New Years Resolutions. Usually they involve, losing weight, quitting smoking, and joining a gym, just to mention a few.
    
                What about you? What News Year Resolution have you planned for this year? Have you considered the benefits of improving your mind? Usually, this is the last thing people consider, but in reality it’s one of the best things you can do for yourself and those around you.
                
                People who want to build their muscles know that it takes time to work on specific areas in order for the muscles to develop properly and these same people will pay big bucks to join the local gym to work on these areas and yet they forget to invest in things that will enrich their minds.
                
                By enriching your mind and building yourself up mentally you will be able to challenge yourself to take on other areas of your life that need improvement. Your life is influenced by your thought processes.
                
                You can learn to build up the muscles in your mind, so to speak, and strengthen your inner man with positive goal producing thoughts.
                
                Most New Year Resolutions fail, mainly because people do not have the proper mind set to set proper goals and lack the ability to make a roadmap that will lead them to success.
                
                What is it you want for you and your family next year? Doesn’t investing in yourself by improving your mind make sense.
                
                You don’t need to spend thousands of dollars in order to benefit from the great motivators of the world. People such as Jim Rohn, Brian Tracy and Les Brown can be heard via your computer for about the cost of a cup of coffee per day!
                
                What are you waiting for? Make this year the best year ever by investing in your mind!`
        }
    ])

    return (
        <div>
            <div className="terms-header">
                <p className="terms-header-lead">Last up on 29 march 2021</p>
                <p className="terms-header-title">Privacy Policies</p>
            </div>

            {policies.map((policy, index) =>
                <div className="terms-container" key={index} >
                    <h2 className="terms-title">{policy.title}</h2>
                    <pre className="terms-content">{policy.content}</pre>
                </div>)}
        </div>
    )
}

export default PrivacyPolicyTemplate
