import { Grid } from "@material-ui/core";
import { withTranslation } from "react-i18next";
import AddToCartBox from "../../../Molecules/AddToCartBox/AddToCartBox";
import { icons, images } from "../../../../Assets/images/IconHelper";
import "./BoxCard.css";
import ProductCardIconButton from "../../../Molecules/ProductCardIconButton/ProductCardIconButton";
import { NavLink } from "react-router-dom";

//props: title, img, quantity, currency, price
const BoxCard = (props) => {
  const { t } = props;
  return (
    <Grid container className="box-card">
      <Grid item xs={6}>
        <div className="box-card-img">
          <ProductCardIconButton
            variant={props.variant}
            id={props.id}
            handleDeleteBox={props.handleDeleteBox}
          />
          <img
            src={props.img ? props.img : images.apple}
            alt="box"
            width="110px"
            height="100px"
          />
        </div>
      </Grid>
      <Grid item xs={6}>
        <div className="box-card-content">
          <NavLink to={`/profile/boxes/${props.id}`}>
            <p className="box-card-title">{props.title}</p>
          </NavLink>
          <p className="box-card-subtitle">
            {props.quantity} {t("general.items")}
          </p>
          <div className="box-card-payment">
            <span className="box-card-currency">{props.currency} </span>
            <span className="box-card-price"> {props.price}</span>
          </div>
          <AddToCartBox showBox={false} />
        </div>
      </Grid>
    </Grid>
  );
};

export default withTranslation()(BoxCard);
