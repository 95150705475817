import React from 'react'
import PrivacyPolicyTemplate from '../../../Templates/Home/PrivacyPolicy/PrivacyPolicy.Template'

function PrivacyPolicy() {
    return (
     <PrivacyPolicyTemplate />   
    )
}

export default PrivacyPolicy
