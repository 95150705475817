import React from "react";
import "./LanguageChange.css";
function LanguageChange(props) {
  return (
    <div className="language">
      {props.value === "en" ? (
        <h3 onClick={(e) => props.onLanguageHandle("ar")}>العربية</h3>
      ) : (
        <h3 onClick={(e) => props.onLanguageHandle("en")}>English</h3>
      )}
    </div>
  );
}

export default LanguageChange;
