import { axiosFn, handleError } from "../../index";
import * as URL from "../../URLs";

export async function updateCheckout(body) {
  return await axiosFn("patch", `${URL.CHECKOUT}`, body)
    .then((res) => {
      return {
        res: res?.data,
        err: null,
        status: res?.status,
      };
    })
    .catch(handleError);
}
export async function updateCheckoutAPI(body) {
  let { res, status, err } = await updateCheckout(body);

  return { res, status, err };
}
