import { Grid } from '@material-ui/core';
import React, { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { icons, images } from '../../../Assets/images/IconHelper'
import IconOverlay from '../../../Components/Atoms/IconOverlay/IconOverlay';
import './ContactUs.css';
import GoogleMapReact from 'google-map-react';
import RoomIcon from '@material-ui/icons/Room';





function ContactUsTemplate({ t, mobileView }) {

    let addressURL = "9+Abd+El-Qader+Ateya+Street%2C+Fleming+Qism+El-Raml%2C+Alexandria%2C+Egypt"
    const [center, setCenter] = useState({
        lat: 31.23349467648608,
        lng: 29.95682896546513 + 0.0015
    })

    useEffect(() => {
        setCenter({
            lat: 31.23349467648608,
            lng: 29.95682896546513 + (mobileView ? 0 : 0.0015)
        })
    }, [mobileView])



    return (
        <div>
            <div className="contact-header" style={{ backgroundImage: `url(${images.faqbg})` }}>
                <p className="contact-header-lead">{t('lets talk')}</p>
                <p className="contact-header-title">{t('footer.ContactUs')}</p>
            </div>

            <div className="contact-container">
                <div className="contact-map">
                    <div className="contact-location">
                        <p className="contact-location-title">{t('lets talk')}</p>
                        <p className="contact-location-sub">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>

                        <Grid container spacing={2}>
                            <Grid item xs={1}>
                                <IconOverlay src={icons.loc} iconStyle="icon-primary" />
                            </Grid>
                            <Grid item xs={11}>
                                <p className="contact-detail-title">
                                    {t("contactus.location")}
                                </p>
                                <p className="contact-detail-text">
                                    9 Abd El-Qader Ateya Street, Fleming Qism El-Raml, Alexandria, Egypt
                                </p>
                            </Grid>

                            <Grid item xs={1}>
                                <IconOverlay src={icons.phone} iconStyle="icon-primary" />
                            </Grid>
                            <Grid item xs={11}>
                                <p className="contact-detail-title">
                                    {t("contactus.phone")}
                                </p>
                                <p className="contact-detail-text">
                                    966582425222
                                </p>
                            </Grid>

                            <Grid item xs={1}>
                                <IconOverlay src={icons.email} iconStyle="icon-primary" />
                            </Grid>
                            <Grid item xs={11}>
                                <p className="contact-detail-title">
                                    {t("contactus.email")}
                                </p>
                                <p className="contact-detail-text">
                                    sales@inovaeg.com
                                </p>
                            </Grid>

                        </Grid>

                    </div>

                    <div className="google-maps">

                        <div class="earth3dmap-com">
                            <iframe
                                id="iframemap"
                                src="https://maps.google.com/maps?q=9+Abd+El-Qader+Ateya+Street%2C+Fleming+Qism+El-Raml%2C+Alexandria%2C+Egypt&amp;ie=UTF8&amp;iwloc=&amp;output=embed"
                                width="100%"
                                height="500"
                                className="google-maps"
                                frameborder="0"
                                scrolling="no">
                            </iframe>
                        </div>




                        {/* <GoogleMapReact
                            defaultCenter={center}
                            defaultZoom={18}
                        >
                            <RoomIcon className="map-pin" lat={31.23349467648608} lng={29.95682896546513} />
                        </GoogleMapReact> */}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withTranslation()(ContactUsTemplate)
