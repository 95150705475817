import { ListAllCollectionsAPI } from "../../Contexts/API/Home/Collections/ListAllCollections";
import { ListCollectionProductsAPI } from "../../Contexts/API/Home/Collections/ListCollectionProducts";
import { getBestSellerCollectionAPI } from "../../Contexts/API/Home/Collections/getBestSellerCollection";
import { getCollectionAPI } from "../../Contexts/API/Home/Collections/getCollection";
import { useState } from "react";
import { getTaxonCollectionsAPI } from "../../Contexts/API/Home/Collections/getTaxonCollections";
import { ListAvailableVariantAPI } from "../../Contexts/API/Home/Collections/ListAvailableVariant";
import { ListAllCollectionsByTagAPI } from "../../Contexts/API/Home/Collections/ListAllCollectionsByTag";

export const useCollection = () => {
  const [collections, setCollections] = useState([]);
  const [collectionData, setCollectionData] = useState();
  const [collectionDataDetails, setCollectionDataDetails] = useState();
  const [isCollectionLoading, setIsCollectionLoading] = useState(false);
  const [isProductsLoading, setIsProductsLoading] = useState(false);
  const [collectionProducts, setCollectionsProducts] = useState([]);
  const [optionsTypes, setOptionTypes] = useState([]);
  const [bestSellerCollection, setBestSellerColection] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  const [availableVariant, setAvailableVariant] = useState();
  const [VariantOptions, setVariantOptions] = useState([]);
  const [isVariantsLoading, setIsVariantsLoading] = useState(false);

  const loadCollections = async () => {
    setIsCollectionLoading(true);
    let result = await ListAllCollectionsAPI();
    if (result) {
      setCollections(result?.collections);
      setIsCollectionLoading(false);
    } else {
      setCollections([]);
      setIsCollectionLoading(false);
    }
  };

  const GetCollection = async (id, taxonId) => {
    setIsCollectionLoading(true);
    let result = await getCollectionAPI(id, taxonId);
    if (result) {
      setCollectionDataDetails(result?.collection);
      setIsCollectionLoading(false);
    } else {
      setIsCollectionLoading(false);
    }
  };

  const GetTaxonCollections = async (id, page, filter) => {
    setIsCollectionLoading(true);
    let result = await getTaxonCollectionsAPI(id, page, filter);
    if (result) {
      setCollectionData(result?.collections);
      setIsCollectionLoading(false);
    } else {
      setIsCollectionLoading(false);
    }
  };

  const ListCollectionProducts = async (
    collection_id,
    id,
    page,
    setPage,
    Ids
  ) => {
    setIsProductsLoading(true);
    if (page == 1) {
      setCollectionsProducts([]);
      setOptionTypes([]);
    }
    let result = await ListCollectionProductsAPI(collection_id, id, page, Ids);
    if (result) {
      setPage(page + 1);
      // remove condition
      if (page == 1) {
        setCollectionsProducts(result?.products);
        setOptionTypes(result?.option_types);
      } else {
        setCollectionsProducts([...collectionProducts, ...result?.products]);
      }
      setHasMore(page < result?.total_pages);
      setIsProductsLoading(false);
    } else {
      setIsProductsLoading(false);
    }
  };

  const loadBestSellerCollection = async () => {
    setIsCollectionLoading(true);
    let result = await getBestSellerCollectionAPI();
    if (result) {
      setBestSellerColection(result?.res?.collections);
      setIsCollectionLoading(false);
    } else {
      setIsCollectionLoading(false);
    }
  };

  const listAvailableVariants = async (id, option_values) => {
    setIsVariantsLoading(true);
    let result = await ListAvailableVariantAPI(id, option_values);
    if (result) {
      setAvailableVariant(result?.available_variants);
      setVariantOptions(result?.all_options);
      setIsVariantsLoading(false);
    } else {
      setIsVariantsLoading(false);
    }
  };

  return {
    collections,
    collectionData,
    collectionDataDetails,
    collectionProducts,
    loadCollections,
    GetCollection,
    GetTaxonCollections,
    ListCollectionProducts,
    isCollectionLoading,
    isProductsLoading,
    hasMore,
    bestSellerCollection,
    loadBestSellerCollection,
    listAvailableVariants,
    availableVariant,
    isVariantsLoading,
    VariantOptions,
    setCollectionData,
    optionsTypes,
  };
};
