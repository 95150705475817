import React from "react";
import MyBoxesTemplate from "../../../Templates/Home/Profile/MyBoxes.Template";
import { getListOfBoxesAPI } from "../../../Contexts/API/Home/Boxes/getBoxes";
import { useState, useEffect } from "react";
import { deleteBoxAPI } from "../../../Contexts/API/Home/Boxes/deleteBox";

function MyBoxes() {
  const [boxes, setBoxes] = useState([]);
  const fetchBoxes = async () => {
    const res = await getListOfBoxesAPI();
    if (res) {
      setBoxes(res?.data);
    }
  };
  const handleDeleteBox = (id) => {
    deleteBoxAPI(id);
    const newBoxes = boxes.filter((box) => box.id !== id);
    setBoxes(newBoxes);
  };
  useEffect(() => {
    fetchBoxes();
  }, []);
  
  return <MyBoxesTemplate boxes={boxes} handleDeleteBox={handleDeleteBox} />;
}

export default MyBoxes;
