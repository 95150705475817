import { Button, Card, CardActionArea, CardActions, CardContent, CardMedia, Typography } from '@material-ui/core';
import { useState } from 'react';
import { useHistory } from 'react-router';
import { icons, images } from '../../../../Assets/images/IconHelper';
import IconOverlay from '../../../Atoms/IconOverlay/IconOverlay';
import SocialModal from '../../Modals/SocialModal/SocialModal';
import './RecipeCard.css'

const RecipeCard = ({ id, title, time, ingredients }) => {

    const history = useHistory();

    const [openModal, setopenModal] = useState(false);
    const toggleModal = ()=>{
        setopenModal(!openModal);
    }

    const [state, setState] = useState({
        id:1,
        likes:0,
        comments:0,
        liked:false
    })

    const likeRecipe = () =>{
        if(state.liked){
            setState({
                ...state,
                liked: false,
                likes: state.likes - 1
            })
        }else{
            setState({
                ...state,
                liked: true,
                likes: state.likes + 1
            })
        }
    }
    
    const goToComment = () => {
        localStorage.setItem("goToComment", "true");
        history.push(`/dankitchen/recipe/${id}`)

    }

    return (
        <Card className="recipe-card">
            <CardActionArea onClick={()=> history.push(`/dankitchen/recipe/${state.id}`)}>
                <CardMedia
                    className="recipe-card-img"
                    image={images.advBg}
                    title={title}
                />
                <CardContent>
                    <Typography gutterBottom variant="h5" component="h2" className="recipe-card-title">
                        {title}
                    </Typography>
                    <div className="recipe-card-descContainer">
                        <Typography variant="body2" color="textSecondary" component="p" className="recipe-card-desc">
                            ± {time}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" component="p" className="recipe-card-desc">
                            •
                        </Typography>
                        <Typography variant="body2" color="textSecondary" component="p" className="recipe-card-desc">
                            {ingredients} ingredients
                        </Typography>
                    </div>
                </CardContent>
            </CardActionArea>
            <CardActions className="recipe-actions">
                <div className="recipe-interaction">
                    <IconOverlay src={icons.like} iconStyle={state.liked ? 'icon-red' : ""} onClick={likeRecipe} />
                    <p>{state.likes}</p>
                </div>

                <div className="recipe-interaction" onClick={()=> goToComment()}>
                    <IconOverlay src={icons.comment} />
                    <p>{state.comments}</p>
                </div>

                <div className="recipe-interaction" onClick={() => toggleModal()}>
                    <IconOverlay src={icons.share} />
                </div>
            </CardActions>

            <SocialModal
                open={openModal}
                handleClose={toggleModal}
                link={`${window.location.href}/recipe/${id}/`}
            />
        </Card>
    );
}

export default RecipeCard;