import { Grid, Paper } from '@material-ui/core'
import { withTranslation } from 'react-i18next';
import '../HomePage.css';
import './CustomersSay.css';
import { images, icons } from '../../../../Assets/images/IconHelper';
import IconOverlay from '../../../Atoms/IconOverlay/IconOverlay';

const CustomersSay = (props) => {


    const { t } = props

    return (
      
        <div className="imgContainer customer" style={{ backgroundImage: `url(${images.customersSayBg})` }}>

            <Grid container className="imgContainerContent" >
                <Grid item xs={12}>
                    <h2 className="home-title customer-title">{t("customersSay.title")}</h2>
                </Grid>

                <Grid item xs={12}>
                    <div className="customer-flex-wrapper">
                        <p className="customer-subtitle">{t("customersSay.subtitle")}</p>
                    </div>
                </Grid>

                <Grid item xs={12}>
                    <Grid container className="justify-content-center" >
                        <Grid item xs={6} sm={5} md={3} >
                            <Paper className="customer-paper">
                                <p className="paper-text">{t("customersSay.review1.review")}</p>
                                <div className="customer-details">
                                    {/* <img src={icons.oval} alt="oval" className="customer-img" /> */}
                                    <IconOverlay src={icons.oval} iconStyle="icon-white" className="customer-img"/>
                                    <span className="paper-footer">{t("customersSay.review1.by")}</span>
                                </div>
                            </Paper>
                        </Grid>
                        <Grid item xs={6} sm={5} md={3}>
                            <Paper className="customer-paper">
                                <p className="paper-text">{t("customersSay.review2.review")}</p>
                                <div className="customer-details">
                                    {/* <img src={icons.oval} alt="oval" className="customer-img" /> */}
                                    <IconOverlay src={icons.oval} iconStyle="icon-white" className="customer-img"/>
                                    <span className="paper-footer">{t("customersSay.review2.by")}</span>
                                </div>
                            </Paper>
                        </Grid>
                        <Grid item xs={7} sm={5} md={3}>
                            <Paper className="customer-paper">
                                <p className="paper-text">{t("customersSay.review3.review")}</p>
                                <div className="customer-details">
                                    {/* <img src={icons.oval} alt="oval" className="customer-img" /> */}
                                    <IconOverlay src={icons.oval} iconStyle="icon-white" className="customer-img"/>
                                    <span className="paper-footer">{t("customersSay.review3.by")}</span>
                                </div>
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
       


    )
}

export default withTranslation()(CustomersSay);
