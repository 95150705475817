import { Grid, Tab, Tabs } from '@material-ui/core';
import { useState } from 'react';
import { withTranslation } from 'react-i18next';
import { images } from '../../../Assets/images/IconHelper';
import BlogCard from '../Cards/BlogCard/BlogCard';
import RecipeCard from '../Cards/RecipeCard/RecipeCard';

import './BlogTabs.css'

const BlogTabs = ({ t, mobileView }) => {

    const [value, setValue] = useState(0);
    const handleChange = async (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div className="blog-tabs">
            <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                aria-label="full width tabs example"
                variant="scrollable"
            >
                <Tab label={t("dan kitchen.Our Blog")} {...a11yProps(0)} />
                <Tab label={t("dan kitchen.recipes")} {...a11yProps(1)} />
            </Tabs>

            <TabPanel value={value} index={0}>
                <div className="tab-panel">

                    <div className="blog-item">
                        <BlogCard
                            id={1}
                            title={"Get The Boot A Birds Eye Look Into Mcse Boot Camps"}
                            by={"Allison Fox"}
                            date={"June 8, 2019"}
                            text={"Some people get into food blogging just to share, while others are hoping to turn their passion into a profession. But while you know your way around the kitchen, when it comes to starting while"}
                            img={images.mushrooms}

                            mobileView={mobileView}
                        />
                    </div>

                    <div className="blog-item">
                        <BlogCard
                            id={2}
                            title={"Get The Boot A Birds Eye Look Into Mcse Boot Camps"}
                            by={"Allison Fox"}
                            date={"June 8, 2019"}
                            text={"Some people get into food blogging just to share, while others are hoping to turn their passion into a profession. But while you know your way around the kitchen, when it comes to starting while"}
                            img={images.mushrooms}

                            mobileView={mobileView}
                        />
                    </div>

                    <div className="blog-item">
                        <BlogCard
                            id={3}
                            title={"Get The Boot A Birds Eye Look Into Mcse Boot Camps"}
                            by={"Allison Fox"}
                            date={"June 8, 2019"}
                            text={"Some people get into food blogging just to share, while others are hoping to turn their passion into a profession. But while you know your way around the kitchen, when it comes to starting while"}
                            img={images.mushrooms}
                            mobileView={mobileView}
                        />
                    </div>

                </div>
            </TabPanel>

            <TabPanel value={value} index={1}>
                <div className="tab-panel">

                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <RecipeCard
                                id="1"
                                title="Cooked Coconut Mussels"
                                time="5 mins"
                                ingredients="4"
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <RecipeCard
                                id="2"
                                title="Cooked Coconut Mussels"
                                time="5 mins"
                                ingredients="4"
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <RecipeCard
                                id="3"
                                title="Cooked Coconut Mussels"
                                time="5 mins"
                                ingredients="4"
                            />
                        </Grid>
                    </Grid>

                </div>
            </TabPanel>

        </div>
    );
}

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <div>{children}</div>
            )}
        </div>
    );
}

export default withTranslation()(BlogTabs);