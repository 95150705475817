import EditRequestTemplate from "../../../Templates/Home/EditRequest/EditRequest.template";
import { queryParamsFormatter } from "../../../utils/queryParamsFormatter/queryParamsFormatter";
import { useCategories } from "../../../Hooks/useCategories/useCategories";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useRequests } from "../../../Hooks/useRequests/useRequests";
import { useState } from "react";

const EtradelingEditRequestPage = () => {
  //STATES AND HOOKS
  const { id } = useParams();
  const history = useHistory();
  const [isRequestSubmitted, setIsRequestSubmitted] = useState(false);
  const [queryParams, setQueryParams] = useState({ parent_id: 1 });
  const { loadCategoriesList, categories, areCategoriesLoading } =
    useCategories();
  const subCategories = useCategories(queryParamsFormatter(queryParams));
  const {
    tradeTerms,
    areTradeTermsLoading,
    loadTradeTerms,
    loadUnits,
    units,
    areUnitsLoading,
    loadProductRequest,
    productRequest,
    isProductRequestLoading,
    updateRequest,
  } = useRequests();

  //EFFECTS
  useEffect(() => {
    if (!localStorage.getItem("TOKEN")) {
      history.push("/login");
    }
  }, []);

  useEffect(() => {
    loadCategoriesList();
    loadTradeTerms();
    loadUnits();
    loadProductRequest(id);
  }, []);

  useEffect(() => {
    subCategories.loadCategoriesList();
  }, [queryParams]);

  //HANDLERS
  const handleSubmitQuotationRequest = async (id, body) => {
    setIsRequestSubmitted(true);
    await updateRequest(id, body);
    setIsRequestSubmitted(false);

    history.push(`/profile/requests/${id}`);
  };

  return (
    <EditRequestTemplate
      categories={categories}
      loading={
        areCategoriesLoading &&
        subCategories.areCategoriesLoading &&
        areTradeTermsLoading &&
        areUnitsLoading &&
        isProductRequestLoading
      }
      subCategories={subCategories.categories}
      setQueryParams={setQueryParams}
      tradeTerms={tradeTerms}
      units={units}
      handleSubmitQuotationRequest={handleSubmitQuotationRequest}
      isRequestSubmitted={isRequestSubmitted}
      productRequest={productRequest}
      id={productRequest?.id}
    />
  );
};

export default EtradelingEditRequestPage;
