import { Checkbox, FormControlLabel, Grid } from '@material-ui/core'
import React from 'react'
import { withTranslation } from 'react-i18next'
import { images, icons } from '../../../../Assets/images/IconHelper'
import PrimaryButton from '../../../Atoms/Buttons/Primary/PrimaryButton'
import IconOverlay from '../../../Atoms/IconOverlay/IconOverlay'

import './PaymentCard.css'

const PaymentCard = (props) => {
    const { t } = props
    return (
        <Grid container className="address_card">
            <Grid item xs={10}>
                <p className="title">Payment Card 1</p>
            </Grid>
            <Grid item xs={2} style={{ display: 'flex', justifyContent: "flex-end" }}>
                <FormControlLabel
                    style={{ width: '27px', height: "27px" }}
                    control={<Checkbox checked={props.checked} onChange={() => {
                        props.setchecked(!props.checked)
                    }} />}

                />
            </Grid>
            <Grid item xs={4} className="label">
            {props.type==='mastercard' && <img src={images.mastercard} alt="mastercard" />}
            {props.type==='visa' && <img src={images.visa} height="11px" width="30px" alt="visa"/>}
                <label>{props.type}:</label>
            </Grid>
            <Grid item xs={8}> <span>***{props.lastDigits}</span></Grid>
            
            <Grid item xs={4} className="label">
                <label>{t('card.exp date')}:</label>
            </Grid>
            
            <Grid item xs={8}><span>{props.exp}</span></Grid>
            
            <Grid item xs={1}>
                <PrimaryButton 
                    //startIcon={<img src={icons.edit} alt="" />}
                    startIcon={<IconOverlay src={icons.edit} iconStyle="icon-primary"/>}
                    classBtn="borderless success"
                    text={t('general.edit')}
                    click={() => props.pressEdit('edit')} />
            </Grid>
            <Grid item xs={3}>
                <PrimaryButton 
                //startIcon={<img src={icons.bin} alt=""/>} 
                startIcon={<IconOverlay src={icons.bin} iconStyle="icon-white"/>}
                classBtn="borderless error" text={t('general.delete')} />
            </Grid>
        </Grid>
    )
}

export default withTranslation()(PaymentCard)
