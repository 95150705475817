import "./TextFieldInput.css";

import { IconButton, InputAdornment, TextField } from "@material-ui/core";
import React, { useState } from "react";
import { Visibility, VisibilityOff } from "@material-ui/icons";

import { PropTypes } from "prop-types";

function TextFieldInput({
  type = "text",
  placeholder = "",
  StartAdornment = null,
  EndAdornment = null,
  className,
  onchange,
  error,
  value,
  helperText,
  ...props
}) {
  const [showPassword, setshowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setshowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <TextField
      helperText={helperText}
      fullWidth
      type={type === "password" ? (showPassword ? "text" : "password") : type}
      className={"textfield " + className}
      error={error}
      placeholder={placeholder}
      variant="outlined"
      value={value}
      onChange={(e) => onchange(e.target.value)}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <>{StartAdornment}</>
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            <>
              {type === "password" ? (
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              ) : (
                EndAdornment
              )}
            </>
          </InputAdornment>
        ),
      }}
      {...props}
    />
  );
}

TextFieldInput.propTypes = {
  type: PropTypes.string,
  placeholder: PropTypes.string,
  StartAdornment: PropTypes.element,
  EndAdornment: PropTypes.element,
  className: PropTypes.string,
};
export default TextFieldInput;
