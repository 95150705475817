import "./AccordionDots.css";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from "@mui/material";

import IconOverlay from "../../Atoms/IconOverlay/IconOverlay";
import Timeline from "@mui/lab/Timeline";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import UploadedFile from "../UploadedFile/UploadedFile";
import { icons } from "../../../Assets/images/IconHelper";
import { withTranslation } from "react-i18next";

const AccordionDots = ({ remaining = [], history = [], Data }) => {
  return (
    <>
      {Data ? (
        <Timeline sx={{ width: "100%" }}>
          {Data?.map((item, i) => (
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot
                  sx={{
                    borderWidth: "4px",
                    padding: "3px",
                    marginTop: "25px",
                    borderColor: "#6e7a8a",
                  }}
                  variant="outlined"
                />
                {Data?.length != i + 1 && (
                  <TimelineConnector
                    sx={{ marginBottom: "-15px", background: "#6e7a8a" }}
                  />
                )}
              </TimelineSeparator>
              <TimelineContent>
                <Accordion>
                  <AccordionSummary
                    expandIcon={
                      <IconOverlay
                        src={icons.accordionArrow}
                        iconStyle="icon-primary"
                      />
                    }
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography>{item?.title}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography
                      variant="h2"
                      sx={{
                        textAlign: "left",
                        fontSize: "16px",
                        color: "var(--lightgray-color)",
                        fontWeight: "500",
                      }}
                    >
                      Descriptions
                    </Typography>
                    <Typography
                      variant="p"
                      sx={{
                        textAlign: "left",
                        fontSize: "16px",
                        color: "var(--paragraph-color)",
                        fontWeight: "500",
                        marginTop: "10px",
                        wordBreak: "break-all",
                      }}
                    >
                      {item?.details}
                    </Typography>
                    {item?.documents_attributes?.length > 0 ? (
                      <Box style={{ display: "flex", padding: "10px" }}>
                        {item?.documents_attributes?.map((file, index) => {
                          return (
                            <a href={file.file_url} target="_blank">
                              <UploadedFile
                                fileType={file.extension}
                                fileName={file.file_name}
                                id={index}
                                files={item?.documents_attributes}
                                src={file?.file_url}
                              />
                            </a>
                          );
                        })}
                      </Box>
                    ) : (
                      ""
                    )}
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "flex-start",
                        marginTop: "20px",
                      }}
                    >
                      <Typography
                        variant="h2"
                        sx={{
                          textAlign: "left",
                          fontSize: "16px",
                          color: "var(--lightgray-color)",
                          fontWeight: "500",
                        }}
                      >
                        Price
                      </Typography>
                      <Typography
                        variant="p"
                        sx={{
                          textAlign: "left",
                          fontSize: "16px",
                          color: "var(--paragraph-color)",
                          fontWeight: "500",
                          paddingInlineStart: "40px",
                        }}
                      >
                        {item?.estimated_price}
                        {/* {item?.currency_type_id} */}
                      </Typography>
                    </Box>
                  </AccordionDetails>
                </Accordion>
              </TimelineContent>
            </TimelineItem>
          ))}
        </Timeline>
      ) : (
        <Timeline sx={{ width: "calc(100% - 32px)", paddingInline: "0" }}>
          {[...history, ...remaining]?.map((item, i) => (
            <TimelineItem>
              <TimelineSeparator
                sx={item?.checked ? "" : { marginInlineStart: "4px" }}
              >
                {item?.checked ? (
                  <TimelineDot
                    sx={{
                      borderWidth: "0",
                      padding: "0",
                      marginTop: "4px",
                    }}
                  >
                    <IconOverlay
                      src={icons.TimeLineCheck}
                      iconStyle="icon-white"
                    />
                  </TimelineDot>
                ) : (
                  <TimelineDot
                    sx={{
                      borderWidth: "6px",
                      padding: "3px",
                      marginTop: "7px",
                      borderColor: "#e1e7ec",
                    }}
                    variant="outlined"
                  />
                )}

                {[...history, ...remaining]?.length != i + 1 && (
                  <TimelineConnector
                    sx={{ marginBottom: "5px", background: "#e1e7ec" }}
                  />
                )}
              </TimelineSeparator>
              <TimelineContent>
                <Typography sx={{ lineHeight: "normal" }}>
                  {item?.order_state}
                </Typography>
                {item?.checked && <p>{item?.changed_at?.split("T") ?? ""}</p>}
              </TimelineContent>
            </TimelineItem>
          ))}
        </Timeline>
      )}
    </>
  );
};

export default withTranslation()(AccordionDots);
