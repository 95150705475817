import "./CarouselItem.css";

import { Link } from "react-router-dom";
import PrimaryButton from "../../Atoms/Buttons/Primary/PrimaryButton";

//props {img, title, text, btnText, click}
const CarouselItem = (props) => {
  return (
    <div
      className="carousel-pos"
      style={{
        backgroundImage: `url(${props.img})`,
        height: "500px",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      {/* <img src={props.img} alt="temp carousel" /> */}
      <div className="legend">
        <div className="carousel-content flip-content">
          <p className="carousel-title">{props.title}</p>
          <p className="carousel-text">{props.text}</p>
          {props.btnText && (
            <div className="carousel-btn-container">
              <div>
                {/* <a href="https://wa.me/966563829592" target="_blank"> */}

                <PrimaryButton
                  classBtn=" rounded-8 btn-shadow btn-small primary secondaryColor"
                  text={props.btnText}
                  click={props.click}
                />

                {/* </a> */}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CarouselItem;
