import * as URL from "../../URLs";

import { axiosFn, handleError } from "../../index";

import { toast } from "react-toastify";

export async function addToCart(recivedData) {
  const data = { ...recivedData };
  return await axiosFn(
    "post",
    `${URL.CART}/add_item?include=line_items,variants,variants.images,billing_address,shipping_address,payments,shipments,promotions`,
    data,
    false
  )
    .then((res) => {
      return {
        res: res?.data,
        err: null,
        status: res?.status,
      };
    })
    .catch(handleError);
}
export async function addToCartAPI(data) {
  let { err, res, status } = await addToCart(data);

  return { err, res, status };
}
