import { Card, CardActions, CardContent } from "@mui/material";

import ProposalBody from "../../../Molecules/ProposalBody/ProposalBody";
import ProposalButtons from "../../../Molecules/ProposalButtons/ProposalButtons";
import ProposalHeader from "../../../Molecules/ProposalHeader/ProposalHeader";

const ProposalCard = ({
  id,
  quotationRequest,
  viewDetails,
  acceptHandler,
  rejectHandler,
}) => {
  return (
    <Card
      fullWidth
      style={{
        border: "1px solid #979797",
        marginTop: "24px",
        marginBottom: "24px",
      }}
    >
      <CardContent>
        <ProposalHeader data={quotationRequest} />
      </CardContent>
      <CardContent>
        <ProposalBody data={quotationRequest} />
      </CardContent>

      <CardActions
        style={{ borderTop: "1px solid #979797" }}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          paddingRight: 3,
          paddingLeft: 3,
          paddingTop: 3,
          paddingBottom: 3,
        }}
      >
        <ProposalButtons
          data={quotationRequest}
          acceptHandler={acceptHandler}
          rejectHandler={rejectHandler}
          requestId={id}
          viewDetails={viewDetails}
        />
      </CardActions>
    </Card>
  );
};

export default ProposalCard;
