import { Grid } from "@material-ui/core";
import React from "react";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { icons, images } from "../../../Assets/images/IconHelper";
import SocialmediaButton from "../../Atoms/Buttons/SocialmediaButton/SocialmediaButton";
import SearchBox from "../../Molecules/SearchBox/SearchBox";
import "./Footer.css";

const Footer = (props) => {
  const { t, systemConfig } = props;
  const User = localStorage.getItem("USER_TYPE") == "user";

  const openInNewTab = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  return (
    <Grid className="Footer">
      <Grid container className="Footer_newsletter">
        {/* <Grid item xs={4}>
                    <img alt="logo" src={images.logo} width="90%" height="99px" />
                </Grid>

                <Grid item xs={8}>
                    <Grid container>
                        <Grid item xs={12} md={3}>
                            <h5>{t('footer.Join our')}</h5>
                            <h6>{t('footer.Newsetter')}</h6>
                        </Grid>
                        <Grid item xs={12} md={9}>
                            <SearchBox
                                text={"subscribe"}
                                placeholder={t('placeholder.What your are Looking for?')}
                                onchange={(email) => console.log({ email })} />
                        </Grid>
                    </Grid>
                </Grid> */}
        <img className="hr" src={images.line} width="100%" alt="" />
      </Grid>
      <Grid container className="Footer_Nav">
        <Grid item xs={12} sm={4}>
          <p>
            Lorem Ipsum is simply dummy text of the printing and typesett ing
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s
          </p>
          <div className="footer_social_cont">
            <h6>{t("footer.followUs")}</h6>
            <div className="footer_social">
              <SocialmediaButton
                startIcon={icons.twitter}
                click={() => openInNewTab("https://twitter.com/danfoods")}
              />
              <SocialmediaButton
                startIcon={icons.linked}
                click={() =>
                  openInNewTab("https://www.linkedin.com/company/danfoods/")
                }
              />
              <SocialmediaButton
                startIcon={icons.insta}
                click={() =>
                  openInNewTab("https://www.instagram.com/danfoods.sa/")
                }
              />
            </div>
          </div>
        </Grid>
        <Grid item xs={3} sm={2}>
          <Link to="/aboutus">{t("footer.AboutUs")}</Link>
          <Link
            to={
              systemConfig &&
              JSON.parse(systemConfig["System Configurations"]["Collections"])
                ? "/collections"
                : "/shopping"
            }
          >
            {t("footer.Categories")}
          </Link>
          {/* <Link to="/careers">{t("footer.Careers")}</Link> */}
          <Link to="/blog">{t("footer.OurBlog")}</Link>
          <Link to="/contactus">{t("footer.ContactUs")}</Link>
          {/* <Link to="/news">{t("footer.News")}</Link>
          <Link to={User ? "/profile" : "/login"}>{t("footer.MyAccount")}</Link> */}
        </Grid>
        <Grid item xs={4} sm={3}>
          <Link to="/faq">{t("footer.faq")}</Link>
          <Link to="/terms">{t("footer.Terms")}</Link>
          <Link to="/policy">{t("footer.PrivacyPolicy")}</Link>
          {/* <Link to="/dankitchen">{t("footer.Dan Kitchen")}</Link>
          <Link to="/reports">{t("footer.Reports")}</Link> */}
        </Grid>
        <Grid item xs={5} sm={3} className="get_app">
          <h4>{t("footer.Get The app")}</h4>
          <span>{t("footer.available")}</span>

          <a
            className="footer-store"
            href="https://apps.apple.com/app/id1583986518"
            target="_blank"
          >
            <img src={images.appStore} alt="app store" />
          </a>
          <a
            className="footer-store"
            href="https://play.google.com/store/apps/details?id=com.inova.danfood"
            target="_blank"
          >
            <img src={images.googlePlay} alt="google play" />
          </a>
        </Grid>
      </Grid>
      <Grid className="Footer_rights">
        <p>{t("footer.All Rights Reserved")}</p>
      </Grid>
    </Grid>
  );
};

export default withTranslation()(Footer);
