import { axiosFn, handleError } from "../../index";
import * as URL from "../../URLs";
import { toast } from "react-toastify";

export async function BecomeVendor(vendor) {
  const data = {
    vendor: { ...vendor },
    branches: [],
  };
  return await axiosFn("post", URL.VENDOR, data)
    .then((res) => {
      return {
        res: res,
        err: null,
      };
    })
    .catch(handleError);
}
export async function BecomeVendorAPI(proposal) {
  let { err, res } = await BecomeVendor(proposal);

  return { res, err };
}
