import { CircularProgress, Grid, Typography } from "@mui/material";
import { withTranslation } from "react-i18next";

import { icons, images } from "../../../Assets/images/IconHelper";
import TabsCarousel from "../../../Components/Organisms/Carousels/TabsCarousel/TabsCarousel";
import AlsallabProductCard from "../../../Components/Organisms/Cards/AlsallabProductCard/AlsallabProductCard";
import FiltersSidepanelAlsallab from "../../../Components/Organisms/FiltersSidepanelAlsallab/FiltersSidepanelAlsallab";
import PrimaryButton from "../../../Components/Atoms/Buttons/Primary/PrimaryButton";
import IconOverlay from "../../../Components/Atoms/IconOverlay/IconOverlay";

import "./Collections.css";
import { useHistory } from "react-router-dom";
import { CategoriesContext } from "../../../Contexts/contextCategories/categoriesContext";
import { useContext } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { systemContext } from "../../../Contexts/systemContext/systemContext";

const CollectionsTemplate = (props) => {
  const {
    t,
    mobileView,
    loading,
    collections,
    setTaxonId,
    taxonId,
    options,
    sub_categories,
    selectedSubCategory,
    setSelectedSubCategory,
    handleSelectingCategory,
    selectedOptionIds,
    setSelectedOptionIds,
    selectedType,
    handleSelectingType,
    setSelectedType,
    tags,
    selectedTag,
    handleSelectingTag,
    NoOfCollections,
    hasMore,
    page,
    loadMore,
    setPage,
    areCollectionsLoading,
  } = props;

  const categories = useContext(CategoriesContext);
  const { account } = useContext(systemContext);

  const history = useHistory();

  return (
    <>
      <Grid container>
        {!mobileView && (
          <Grid item xs={0} sm={3}>
            <FiltersSidepanelAlsallab
              mobileView={mobileView}
              setTaxonId={setTaxonId}
              taxonId={taxonId}
              IsPrice={false}
              options={options
                ?.filter((item) => item?.name != "TYPE")
                ?.filter((item) => item?.name != "النوع")}
              setSelectedSubCategory={setSelectedSubCategory}
              selectedOptionIds={selectedOptionIds}
              setSelectedOptionIds={setSelectedOptionIds}
              setSelectedType={setSelectedType}
              loading={loading}
              tags={tags}
              selectedTag={selectedTag}
              handleSelectingTag={handleSelectingTag}
              setPage={setPage}
            />
          </Grid>
        )}

        <Grid item xs={12} sm={9} className="shopping-products-cont">
          <div
            className="shopping-header"
            style={{ justifyContent: "space-between" }}
          >
            {mobileView && (
              <FiltersSidepanelAlsallab
                mobileView={mobileView}
                setTaxonId={setTaxonId}
                taxonId={taxonId}
                IsPrice={false}
                options={options
                  ?.filter((item) => item?.name != "TYPE")
                  ?.filter((item) => item?.name != "النوع")}
                setSelectedSubCategory={setSelectedSubCategory}
                selectedOptionIds={selectedOptionIds}
                setSelectedOptionIds={setSelectedOptionIds}
                setSelectedType={setSelectedType}
                loading={loading}
                tags={tags}
                selectedTag={selectedTag}
                handleSelectingTag={handleSelectingTag}
                setPage={setPage}
              />
            )}

            <h1>{t("footer.Categories")}</h1>

            {account && account?.attributes?.email == "admin@inovaeg.com" && (
              <PrimaryButton
                classBtn=" rounded-8 btn-shadow btn-small primary secondaryColor"
                text={"add new collection"}
                style={{
                  width: "fit-content",
                  margin: "10px 0",
                }}
                click={() => {
                  history.push(`/updateCollection`);
                }}
              />
            )}
          </div>
          <img src={images.line} width="100%" alt="" />
          {loading ? (
            <div className="progress-indicator">
              <CircularProgress style={{ color: "var(--secondary-color)" }} />
            </div>
          ) : (
            <>
              <div
                className="shopping-subheader"
                style={{ width: "100%", display: "block" }}
              >
                {!loading && (
                  <p className="shopping-productsfound">
                    {NoOfCollections} {t("shoppingPage.collections found")}
                  </p>
                )}
                {history?.location?.search?.includes("tag") ? (
                  ""
                ) : (
                  <>
                    <Typography align="start" className={"tabsTitle"}>
                      {t("shoppingPage.sub category")}
                    </Typography>
                    <TabsCarousel
                      categories={sub_categories}
                      selectedCategory={selectedSubCategory}
                      handleSelectingCategory={handleSelectingCategory}
                      mobileView={mobileView}
                      tags
                    />

                    {options?.find(
                      (item) => item?.name == "TYPE" || item?.name == "النوع"
                    ) ? (
                      <>
                        <Typography align="start" className={"tabsTitle"}>
                          {
                            options?.find(
                              (item) =>
                                item?.name == "TYPE" || item?.name == "النوع"
                            )?.name
                          }
                        </Typography>
                        <TabsCarousel
                          categories={
                            options?.find(
                              (item) =>
                                item?.name == "TYPE" || item?.name == "النوع"
                            )?.option_values
                          }
                          selectedCategory={selectedType}
                          handleSelectingCategory={handleSelectingType}
                          mobileView={mobileView}
                          tags
                          multi
                        />
                      </>
                    ) : (
                      ""
                    )}
                  </>
                )}
              </div>
              <InfiniteScroll
                dataLength={(collections && collections?.length) ?? 0}
                scrollThreshold={0.95}
                next={loadMore}
                hasMore={hasMore}
                loader={
                  <div className="progress-indicator">
                    <CircularProgress
                      style={{ color: "var(--secondary-color)" }}
                    />
                  </div>
                }
                style={{ overflow: "" }}
              >
                <Grid container spacing={2}>
                  {collections?.map((item, i) => (
                    <Grid item xs={6}>
                      <AlsallabProductCard
                        title={item?.name}
                        id={item?.id}
                        image={item?.image}
                        taxonId={taxonId}
                      />
                    </Grid>
                  ))}
                </Grid>
              </InfiniteScroll>
              {/* {Array.isArray(collections)
                  ? collections?.map((item) => (
                      <Grid item xs={6}>
                        <AlsallabProductCard
                          title={item?.name}
                          id={item?.id}
                          image={item?.image}
                          taxonId={taxonId}
                        />
                      </Grid>
                    ))
                  : [collections]?.map((item) => (
                      <Grid item xs={6}>
                        <AlsallabProductCard
                          title={item?.name}
                          id={item?.id}
                          image={item?.image}
                          taxonId={taxonId}
                        />
                      </Grid>
                    ))} */}
            </>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default withTranslation()(CollectionsTemplate);
