import IconOverlay from "../../IconOverlay/IconOverlay";
import { Typography } from "@material-ui/core";
import { icons } from "../../../../Assets/images/IconHelper";

const EtradelingUploadButton = ({ click }) => {
  return (
    <div style={{ cursor: "pointer" }} onClick={click}>
      <div
        style={{
          border: "0.5px solid #979797",
          paddingRight: 17,
          paddingLeft: 17,
          paddingBottom: 18,
          paddingTop: 20,
          marginRight: 10,
        }}
      >
        <div style={{ display: "flex", justifyContent: "center" }}>
          <IconOverlay src={icons.etradelingUploadIcon} />
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Typography fontSize={16} style={{ marginTop: 13, color: "#6f6f6f" }}>
            Upload
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default EtradelingUploadButton;
