import * as URL from "../../URLs";

import { axiosFn, handleError } from "../../index";

export async function ListAvailableVariant(id, option_values = []) {
  return await axiosFn(
    "post",
    `${URL.PRODUCTS}/${id}/available_variants_by_options?option_values=[${option_values}]`
  )
    .then((res) => {
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}
export async function ListAvailableVariantAPI(id, option_values) {
  let { err, res } = await ListAvailableVariant(id, option_values);
  if (res) {
    return res;
  }

  if (err) {
    return err;
  }
}
