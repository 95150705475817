import { axiosFn, handleError } from "../../index";
import * as URL from "../../URLs";

export async function createCart() {
  return await axiosFn(
    "post",
    `${URL.CART}?include=line_items,variants,variants.images,billing_address,shipping_address,payments,shipments,promotions`,
    null,
    false
  )
    .then((res) => {
      return {
        res: res.data.data,
        err: null,
      };
    })
    .catch(handleError);
}
export async function createCartAPI() {
  let res = await createCart();

  if (res.res) {
    return res;
  }
  if (res.err) {
    return res.err;
  }
}
