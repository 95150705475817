import { withTranslation } from "react-i18next";
import { icons } from "../../../Assets/images/IconHelper";
import "./RatingBar.css";

const RatingBar = ({ percentage, direction, i18n }) => {
  return (
    <div
      className={
        direction == "row" ? "ratings-relative" : "ratings-relative-column"
      }
    >
      <img src={icons.ratingsBar} alt="" />
      <img
        src={icons.ratingsBarFull}
        alt=""
        className={
          i18n.language == "ar" ? "ratings-bar-full-ar" : "ratings-bar-full-en"
        }
        style={{ transform: `scaleX(${percentage})` }}
      />
    </div>
  );
};

export default withTranslation()(RatingBar);
