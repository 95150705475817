import "./EtradelingCategoryCard.css";

import { Grid, Typography } from "@material-ui/core";

import { icons } from "../../../Assets/images/IconHelper";

const EtradelingCategoryCard = ({ image, name }) => {
  return (
    <Grid
      container
      justifyContent="flex-end"
      alignContent="center"
      direction="column"
      style={{
        // backgroundImage: `url(${image})`,
        backgroundColor: "#f5f5f5",
        height: 250,
      }}
    >
      <div style={{ marginBottom: 75, height: 83, width: "100%" }}>
        <img src={image} style={{ height: "100%", width: 96 }} />
      </div>

      <Typography
        align="center"
        className="etradeling-category-card-title pb-20px"
      >
        {name}
      </Typography>
    </Grid>
  );
};

export default EtradelingCategoryCard;
