import React, { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useCollection } from "../../../Hooks/useCollection/useCollection";
import CollectionTemplate from "../../../Templates/Home/Collection/Collection.Template";

function NewCollection() {
  const params = useParams();
  // 0 => all
  const [categorySelected, setCategorySelected] = useState();
  const [page, setPage] = useState(1);
  const {
    bestSellerCollection,
    loadBestSellerCollection,
    collectionDataDetails,
    GetCollection,
    isCollectionLoading,
    ListCollectionProducts,
    collectionProducts,
    isProductsLoading,
    hasMore,
  } = useCollection();

  useEffect(() => {
    loadBestSellerCollection();
  }, []);

  useEffect(() => {
    setCategorySelected(null);
    GetCollection(params?.id);
  }, [params?.id]);

  useEffect(() => {
    if (collectionDataDetails)
      if (
        !categorySelected ||
        !collectionDataDetails?.subcategories?.find(
          (cat) => cat?.id == categorySelected
        )
      ) {
        setCategorySelected(collectionDataDetails?.subcategories?.at(0)?.id);
      }
  }, [categorySelected, collectionDataDetails]);

  useEffect(() => {
    if (categorySelected)
      ListCollectionProducts(params?.id, categorySelected, page, setPage);
  }, [categorySelected]);

  const loadMore = () => {
    ListCollectionProducts(params?.id, categorySelected, page, setPage);
  };

  return (
    <CollectionTemplate
      collectionData={collectionDataDetails}
      isCollectionLoading={isCollectionLoading}
      categorySelected={categorySelected}
      setCategorySelected={setCategorySelected}
      collectionProducts={collectionProducts}
      isProductsLoading={isProductsLoading}
      hasMore={hasMore}
      setPage={setPage}
      page={page}
      loadMore={loadMore}
      bestSellerCollection={bestSellerCollection}
    />
  );
}

export default NewCollection;
