import { RealTimeDatabase } from "../../../firebase";
import { axiosFn, handleError } from "../../index";
import * as URL from "../../URLs";
// import { toast } from "react-toastify";

import firebase from "@firebase/app-compat";




export async function sendMessage(recivedData) {
    const data = { ...recivedData }
    return await axiosFn("post", URL.PROFILE_ADDRESSES, data)
        .then((res) => {
            return {
                res: res.data,
                err: null,
            };
        })
        .catch(handleError);
}
export async function sendMessageAPI(id, data) {
    //   let { err, res } = await sendMessage(id, data);

    //   if (res) {
    //       console.log(res)


    //     if (err) {
    //       console.log(err);
    //       return err;
    //     }
    //   }

    return {
        err: null,
        data: {
            data: {
                chat_message: {
                    date: firebase.database.ServerValue.TIMESTAMP,
                    message_type: 'text_msg',
                    media_type: ""
                }
            }

        }
    }
}