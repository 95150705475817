import InstallmentsItem from "./InstallmentsItem";
import { Timeline } from "@mui/lab";

const Installments = ({ installments, proposalStatus }) => {
  return (
    <Timeline position="right">
      {installments?.map((installement) => {
        return (
          <InstallmentsItem
            installement={installement}
            proposalStatus={proposalStatus}
          />
        );
      })}
    </Timeline>
  );
};

export default Installments;
