import { useContext, useEffect, useState } from "react";

import { BadgesContext } from "../../Contexts/badgesContext/badgesContext";
import { addToWishlistAPI } from "../../Contexts/API/Home/Wishlist/addToWishlist";
import { getWishlistAPI } from "../../Contexts/API/Home/Wishlist/getWishlist";
import { removeFromWishlistAPI } from "../../Contexts/API/Home/Wishlist/removeFromWishlist";
import { toast } from "react-toastify";

export const useWishlist = () => {
  const { badges, setBadges } = useContext(BadgesContext);
  const [wishlist, setWishlist] = useState(null);
  const [wishlistItems, setWishlistItems] = useState(null);
  const [isWishlistLoading, setIsWishlistLoading] = useState(false);
  const [productWishedId, setProductWishedId] = useState(null);

  const loadWishlist = async () => {
    setIsWishlistLoading(true);
    const { res, err, status } = await getWishlistAPI();
    if (status === 200) {
      setWishlist(res?.data);
      setWishlistItems(res?.data?.attributes?.wished_products);
      localStorage.setItem("WISHTOKEN", res?.data?.attributes.access_hash);
      setBadges({
        ...badges,
        wishlist: res?.data?.attributes?.wished_products?.length,
      });
      setIsWishlistLoading(false);
    }
    if (err) {
      setIsWishlistLoading(false);
    }
  };

  const addToWishlist = async (id) => {
    setIsWishlistLoading(true);
    const { res, status, err } = await addToWishlistAPI({
      variant_id: id,
      quanity: 3,
    });
    if (status === 200) {
      setProductWishedId(res?.data?.id);
      loadWishlist();
      setIsWishlistLoading(false);
    }
    if (err) {
      setIsWishlistLoading(false);
      toast.error(err);
    }
  };
  const deleteFromWishlist = async (id) => {
    setIsWishlistLoading(true);
    const { res, status, err } = await removeFromWishlistAPI(id);
    if (status === 200) {
      loadWishlist();
      setIsWishlistLoading(false);
    }
    if (err) {
      setIsWishlistLoading(false);
      toast.error(err);
    }
  };

  return {
    wishlist,
    wishlistItems,
    isWishlistLoading,
    addToWishlist,
    loadWishlist,
    deleteFromWishlist,
    productWishedId,
  };
};
