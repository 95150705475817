import { useEffect, useState } from "react";

import { toast } from "react-toastify";

export const useFetch = (API, queryParams = "") => {
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();
  const [reloadState, setReloadState] = useState(1);

  const loader = async () => {
    setIsLoading(true);
    const res = await API(queryParams);
    if (res) {
      setIsLoading(false);
      if (res === "Something went wrong!") {
        setError(res);
        toast.error(res);
      } else {
        setData(res);
      }
    }
  };

  const reload = () => {
    setReloadState(reloadState + 1);
  };

  useEffect(() => {
    loader();
  }, [reloadState, queryParams]);

  return [data, isLoading, error, reload];
};
