import "./EsolarHome.template.css";

import { icons, images } from "../../../Assets/images/IconHelper";

import BestSeller from "../../../Components/Organisms/HomePage/BestSeller/BestSeller";
import { CircularProgress } from "@material-ui/core";
import CustomerFeedbackBanner from "../../../Components/Organisms/CustomerFeedbackBanner/CustomerFeedbackBanner";
import EsolarAboutSection from "../../../Components/Molecules/EsolarAboutSection/EsolarAboutSection";
import EsolarAdBannerCarousel from "../../../Components/Organisms/Carousels/EsolarAdbannerCarousel/EsolarAdBannerCarousel";
import EsolarOurBlog from "../../../Components/Organisms/EsolarOurBlog/EsolarOurBlog";
import ExploreFeatures from "../../../Components/Organisms/ExploreFeatures/ExploreFeatures";
import HomeCarousel from "../../../Components/Organisms/HomePage/HomeCarousel/HomeCarousel";
import HomeFeaturesBanner from "../../../Components/Organisms/HomeFeaturesBanner/HomeFeaturesBanner";
import { withTranslation } from "react-i18next";

const ESolarHomeTemplate = ({
  exploreCardButtonHandler,
  adBanners,
  categories,
  featuredBanners,
  bestSellerProducts,
  areProductsLoading,
  t,
}) => {
  return (
    <>
      <div className="esolar-home-template">
        <HomeCarousel featuredBanners={featuredBanners} />
        {/* Explore Section */}
        <div style={{ marginTop: 80 }}>
          <ExploreFeatures
            exploreCardButtonHandler={exploreCardButtonHandler}
            categories={categories}
          />
        </div>
      </div>
      {/* <PeopleViewed items={bestSellerCollection} /> */}
      <div style={{ marginTop: 80 }}>
        <EsolarAdBannerCarousel banners={adBanners} />
      </div>
      <div className="esolar-home-template">
        {areProductsLoading ? (
          <div className="progress-indicator">
            <CircularProgress style={{ color: "var(--secondary-color)" }} />
          </div>
        ) : (
          <BestSeller
            title={t("bestSeller.title")}
            items={bestSellerProducts}
          />
        )}
      </div>

      <HomeFeaturesBanner
        features={[
          { icon: icons.searchIcon, title: "Search Products or System" },
          { icon: icons.servicesIcon, title: "Request Service" },
          { icon: icons.calendarIcon, title: "Schdueled Time for Delivery" },
        ]}
        backgroundImage={images.esolarBackgroundImage}
        title={"What you can do?"}
      />

      {/* About Section */}
      <div className="esolar-home-template">
        {" "}
        <EsolarAboutSection />
      </div>

      {/* Featured Blog posts Section */}
      <div className="esolar-home-template">
        {" "}
        <EsolarOurBlog />
      </div>
      <CustomerFeedbackBanner />
    </>
  );
};

export default withTranslation()(ESolarHomeTemplate);
