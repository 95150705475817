import React from "react";
import { withTranslation } from "react-i18next";
import { images } from "../../../Assets/images/IconHelper";
import ClientForm from "../../../Components/Organisms/ClientForm/ClientForm";
import "./BecomeClient.css";

function BecomeClientTemplate({ t }) {
  return (
    <div>
      <div
        className="client-header"
        style={{ backgroundImage: `url(${images.becomeClient})` }}
      >
        <div className="scaleX">
          <p className="client-header-lead">{t("become client.love")}</p>
          <p className="client-header-title ">{t("become client.become")}</p>
          <p className="client-header-text">
            Lorem Ipsum is simply dummy text of the printing and typesett ing
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s
          </p>
        </div>
      </div>

      <div className="client-form">
        <ClientForm />
      </div>
    </div>
  );
}

export default withTranslation()(BecomeClientTemplate);
