import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@material-ui/core";
import React from "react";
import "../ModalStyle.css";
import { withTranslation } from "react-i18next";
import TextFieldInput from "../../../Atoms/Inputs/TextField/TextFieldInput";
import PrimaryButton from "../../../Atoms/Buttons/Primary/PrimaryButton";
import { useState } from "react";
import * as yup from "yup";
import { useFormik } from "formik";

function AddCardModal(props) {
  const { t, i18n } = props;
  const validationSchema = yup.object().shape({
    cardNumber: yup
      .string()
      .required("Card Number is Required")
      .matches(/^[0-9]+$/, "Must be only digits")
      .min(16, "Password must contain 16 characters")
      .max(16, "Password must contain 16 characters"),
    expDate: yup.string().required("Expiration Date is Required"),
    securityCode: yup
      .string()
      .required("Security Code is Required")
      .matches(/^[0-9]+$/, "Must be only digits")
      .min(3, "Security Code must contain 3 characters")
      .max(3, "Security Code must contain 3 characters"),
    name: yup.string().required("Name is Required"),
    ZIPCode: yup.string(),
  });

  const [clicked, setClicked] = useState(false);
  const formik = useFormik({
    initialValues: {
      cardNumber: "",
      expDate: "",
      securityCode: "",
      name: "",
      ZIPCode: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      console.log(values);
      //Post request to save data
      // console.log(state);
    },
  });

  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      className={i18n.language === "ar" ? "rtl_modal" : ""}
    >
      <DialogTitle>
        <p className="Modal_header">
          {props?.type !== "edit" ? t("card.Add Card") : t("card.Edit Card")}
        </p>
      </DialogTitle>
      <DialogContent>
        <Grid container className="modal_form">
          <Grid item xs={12}>
            <label>{t("card.card number")}</label>
            <TextFieldInput
              className="form_input"
              placeholder={""}
              onchange={(value) => formik.setFieldValue("cardNumber", value)}
              value={formik.values.cardNumber}
              onKeyDown={(e) => {
                if (e.key === "Enter") formik.handleSubmit();
              }}
              error={clicked && formik.errors.cardNumber}
              helperText={clicked && formik.errors.cardNumber}
            />
            {/* <TextFieldInput
              className="form_input"
              placeholder={""}
              onchange={(number) => setState({ ...state, number })}
            /> */}
          </Grid>
          <Grid item xs={12}>
            <label>{t("card.exp date")}</label>
            <TextFieldInput
              className="form_input"
              placeholder={"MM/YY"}
              onchange={(value) => formik.setFieldValue("expDate", value)}
              value={formik.values.expDate}
              onKeyDown={(e) => {
                if (e.key === "Enter") formik.handleSubmit();
              }}
              error={clicked && formik.errors.expDate}
              helperText={clicked && formik.errors.expDate}
            />
            {/* <TextFieldInput
              className="form_input"
              placeholder={"MM/YY"}
              error={false}
              onchange={(expDate) => setState({ ...state, expDate })}
            /> */}
          </Grid>
          <Grid item xs={12}>
            <label>{t("card.secure code")}</label>
            <TextFieldInput
              className="form_input"
              placeholder={""}
              onchange={(value) => formik.setFieldValue("securityCode", value)}
              value={formik.values.securityCode}
              onKeyDown={(e) => {
                if (e.key === "Enter") formik.handleSubmit();
              }}
              error={clicked && formik.errors.securityCode}
              helperText={clicked && formik.errors.securityCode}
            />
            {/* <TextFieldInput
              className="form_input"
              placeholder={""}
              onchange={(cvv) => setState({ ...state, cvv })}
            /> */}
          </Grid>
          <Grid item xs={12}>
            <label>{t("card.name")}</label>
            <TextFieldInput
              className="form_input"
              placeholder={""}
              onchange={(value) => formik.setFieldValue("name", value)}
              value={formik.values.name}
              onKeyDown={(e) => {
                if (e.key === "Enter") formik.handleSubmit();
              }}
              error={clicked && formik.errors.name}
              helperText={clicked && formik.errors.name}
            />
            {/* <TextFieldInput
              className="form_input"
              placeholder={""}
              onchange={(name) => setState({ ...state, name })}
            /> */}
          </Grid>
          <Grid item xs={12}>
            <label>{t("card.zip")}</label>
            <TextFieldInput
              className="form_input"
              placeholder={""}
              onchange={(value) => formik.setFieldValue("ZIPCode", value)}
              value={formik.values.ZIPCode}
              onKeyDown={(e) => {
                if (e.key === "Enter") formik.handleSubmit();
              }}
              error={clicked && formik.errors.ZIPCode}
              helperText={clicked && formik.errors.ZIPCode}
            />
            {/* <TextFieldInput
              className="form_input"
              placeholder={""}
              onchange={(zip) => setState({ ...state, zip })}
            /> */}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <PrimaryButton
          classBtn="primary modal"
          text={props?.type !== "edit" ? t("general.add") : t("general.edit")}
          click={() => {
            setClicked(true);
            formik.handleSubmit();
          }}
        />
      </DialogActions>
    </Dialog>
  );
}

export default withTranslation()(AddCardModal);
