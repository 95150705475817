import { Grid } from "@material-ui/core";
import PrimaryButton from "../../Atoms/Buttons/Primary/PrimaryButton";
import { icons } from "../../../Assets/images/IconHelper";
import { withTranslation } from "react-i18next";
import "./AddToCartBox.css";
import IconOverlay from "../../Atoms/IconOverlay/IconOverlay";

const AddToCartBox = ({
  onClickCart,
  onClickBox,
  showBox,
  handleMoveToWishlist,
  disabled = false,
  ...props
}) => {
  const { t } = props;
  const gridSize = showBox ? 9 : 12;

  return (
    <Grid container>
      <Grid item xs={gridSize}>
        {props.variant === "cart" ? (
          <PrimaryButton
            text={t("order summary.move to wishlist")}
            classBtn="primary btn-mini product-nobold"
            endIcon={
              <IconOverlay
                src={icons.wishlist}
                iconStyle="icon-white addtocart-icon"
              />
            }
            click={handleMoveToWishlist}
          />
        ) : (
          <PrimaryButton
            text={t("productCard.addToCart")}
            classBtn="primary btn-mini product-nobold"
            endIcon={
              <IconOverlay
                src={icons.cart}
                iconStyle="icon-white addtocart-icon"
              />
            }
            click={onClickCart}
            style={{ cursor: disabled ? "not-allowed" : "pointer" }}
          />
        )}
      </Grid>
      <Grid item xs={12 - gridSize}>
        {showBox && (
          <PrimaryButton
            classBtn="btn-white btn-mini addtocart-iconbutton"
            btnType="icon"
            startIcon={
              <IconOverlay
                src={icons.addToBox}
                iconStyle="icon-primary addtocart-boximg"
              />
            }
            click={onClickBox}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default withTranslation()(AddToCartBox);
