import React from 'react'
import NewsTemplate from '../../../Templates/Home/News/News.Template'

function News() {
    return (
        <NewsTemplate />
    )
}

export default News
