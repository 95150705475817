import React from 'react'
import BecomeClientTemplate from '../../../Templates/Home/BecomeClient/BecomeClient.Template'

function BecomeClient() {
    return (
        <BecomeClientTemplate />
    )
}

export default BecomeClient
