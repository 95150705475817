import { Grid } from "@mui/material";
import ProductCard from "../../../Components/Organisms/Cards/ProductCard/ProductCard";

export const VendorProductsTemplate = ({
  products,
  handleAddToCart,
  addToWishlist,
  isAddToCartLoading,
  deleteFromWishlist,
}) => {
  return (
    <Grid container spacing={2} sx={{ mt: "10px" }}>
      {products?.map((product) => (
        <Grid item xs={12} sm={6} md={4} key={product?.id}>
          <ProductCard
            id={product?.id}
            img={product?.default_variant?.image}
            title={product?.name}
            rating={product?.avg_rating}
            currency={product?.default_variant?.currency}
            price={product?.default_variant?.price}
            handleAddToCart={handleAddToCart}
            variantID={product?.default_variant?.id}
            addToWishlist={addToWishlist}
            isFavorite={product?.is_favorite}
            isAddToCartLoading={isAddToCartLoading}
            deleteFromWishlist={deleteFromWishlist}
          />
        </Grid>
      ))}
    </Grid>
  );
};
