import "./OurCategories.css";

import { useEffect, useState } from "react";

import TabsCarousel from "../Carousels/TabsCarousel/TabsCarousel";
import CategoriesCarousel from "../Carousels/CategoriesCarousel/CategoriesCarousel";
import { Typography } from "@material-ui/core";
import { useCollection } from "../../../Hooks/useCollection/useCollection";
import { withTranslation } from "react-i18next";

const OurCategories = (props) => {
  const { categories, tags, t } = props;
  const [selectedTag, setSelectedTag] = useState(null);
  const handleSelectingTag = (category) => {
    setSelectedTag(category?.id);
  };

  return (
    <div style={{ marginTop: "80px" }}>
      <TabsCarousel
        categories={tags}
        selectedCategory={selectedTag}
        setSelectedCategory={setSelectedTag}
        handleSelectingCategory={handleSelectingTag}
        home={true}
        tags
      />
      <Typography align="center" variant="h4" className="titleWithoutMarginTop">
        {t("Explore our Categories")}
      </Typography>
      <CategoriesCarousel
        isCollectionLoading={categories?.catLoading}
        collections={categories}
      />
    </div>
  );
};

export default withTranslation()(OurCategories);
