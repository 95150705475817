import {
  Box,
  CircularProgress,
  Container,
  Typography,
} from "@material-ui/core";
import { NavLink, useHistory } from "react-router-dom";

import RequestQuotationForm from "../../../Components/Organisms/RequestQuotationForm/RequestQuotationForm";
import RequestServiceForm from "../../../Components/Organisms/RequestServiceForm/RequestServiceForm";
import { icons } from "../../../Assets/images/IconHelper";
import { systemContext } from "../../../Contexts/systemContext/systemContext";
import { useContext } from "react";

const EditRequestTemplate = ({
  categories,
  loading,
  setQueryParams,
  subCategories,
  tradeTerms,
  units,
  handleSubmitQuotationRequest,
  isRequestSubmitted,
  acceptHandler,
  rejectHandler,
  productRequest,
  serviceRequest,
  buildingTypes,
  serviceTypes,
  handleSubmitRequest,
  id,
}) => {
  const history = useHistory();
  const { systemConfig } = useContext(systemContext);
  return (
    <Container maxWidth="lg" style={{ paddingLeft: 100, paddingRight: 100 }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",

          paddingY: "20px",
          borderRadius: 8,
        }}
      >
        <Box
          sx={{
            display: "flex",
          }}
        >
          <div
            style={{ cursor: "pointer", marginRight: 4, marginTop: 2 }}
            onClick={() => history.goBack()}
          >
            <img src={icons.dropdown} className="back_icon" />
          </div>

          <Typography style={{ fontSize: 16, color: "#6f6f6f" }}>
            Request Number #{id}{" "}
          </Typography>
        </Box>

        <Typography style={{ fontSize: 32 }}>Edit Request</Typography>
      </Box>
      {loading ? (
        <div className="progress-indicator">
          <CircularProgress style={{ color: "var(--secondary-color)" }} />
        </div>
      ) : systemConfig !== null &&
        systemConfig["System Configurations"]["Project Name"] === "esolar" ? (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <RequestServiceForm
            buildingTypes={buildingTypes}
            serviceTypes={serviceTypes}
            handleSubmitRequest={handleSubmitRequest}
            serviceRequest={serviceRequest}
          />
        </Box>
      ) : (
        <RequestQuotationForm
          categories={categories}
          setQueryParams={setQueryParams}
          subCategories={subCategories}
          tradeTerms={tradeTerms}
          units={units}
          handleSubmitQuotationRequest={handleSubmitQuotationRequest}
          isRequestSubmitted={isRequestSubmitted}
          acceptHandler={acceptHandler}
          rejectHandler={rejectHandler}
          productRequest={productRequest}
        />
      )}
    </Container>
  );
};

export default EditRequestTemplate;
