import * as URL from "../../URLs";

import { axiosFn, handleError } from "../../index";

//E-Solar Requests
export async function getServiceRequests(params) {
  return await axiosFn("get", `${URL.REQUESTS}?${params}`)
    .then((res) => {
      return {
        res: res?.data,
        err: null,
        status: res?.status,
      };
    })
    .catch(handleError);
}
export async function getServiceRequestsAPI(params) {
  let { err, res, status } = await getServiceRequests(params);

  return { err, res, status };
}
