import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getProductDetailsAPI } from "../../../Contexts/API/Home/Products/getProductDetails";
import { useCart } from "../../../Hooks/useCart/useCart";
import { useCollection } from "../../../Hooks/useCollection/useCollection";
import ProductDetailsTemplate from "../../../Templates/Home/ProductDetails/ProductDetails.Template";

const ProductDetails = () => {
  const { id } = useParams();
  const [product, setProduct] = useState({});
  const [productLoading, setProductLoading] = useState(false);
  const [quantity, setQuantity] = useState(1);

  const getProductDetails = async (idd) => {
    setProductLoading(true);
    let details = await getProductDetailsAPI(idd);
    if (details === "Something went wrong!") {
      return {};
    }
    if (details) setProductLoading(false);
    return details;
  };

  useEffect(() => {
    const loadData = async () => {
      const p = await getProductDetails(id);

      if (p) {
        setProduct(p?.data);
      }
    };
    loadData();
  }, [id]);

  const [SelectedVariant, setSelectedVariant] = useState([]);
  const {
    isVariantsLoading,
    availableVariant,
    listAvailableVariants,
    VariantOptions,
  } = useCollection();
  const { addToCart, isAddToCartLoading } = useCart();

  useEffect(() => {
    if (availableVariant?.length == 0)
      setSelectedVariant(SelectedVariant.slice(-1));
  }, [availableVariant]);

  useEffect(() => {
    if (SelectedVariant?.length != 0)
      listAvailableVariants(
        id,
        SelectedVariant?.map((item) => item?.id)
      );
  }, [SelectedVariant]);

  return (
    <div>
      <ProductDetailsTemplate
        id={id}
        productLoading={productLoading}
        product={product}
        quantity={quantity}
        setQuantity={setQuantity}
        isVariantsLoading={isVariantsLoading}
        availableVariant={availableVariant}
        VariantOptions={VariantOptions}
        SelectedVariant={SelectedVariant}
        setSelectedVariant={setSelectedVariant}
        // type="box"
      />
    </div>
  );
};

export default ProductDetails;
