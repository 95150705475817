import { useEffect, useState } from "react";

import MyProposalsTemplate from "../../../Templates/Home/Profile/MyProposals/MyProposals.template";
import { queryParamsFormatter } from "../../../utils/queryParamsFormatter/queryParamsFormatter";
import { useHistory } from "react-router-dom";
import { useProposals } from "../../../Hooks/useProposals/useProposals";

const MyProposalsPage = () => {
  const [queryParams, setQueryParams] = useState({ status: "pending" });
  const [modalOpen, setModalOpen] = useState(false);
  const history = useHistory();

  const {
    proposals,
    hasMore,
    loadProposals,
    deleteProposal,
    loadMoreProposals,
    loading,
    currentPage,
    setCurrentPage,
  } = useProposals();

  useEffect(() => {
    loadProposals(queryParamsFormatter(queryParams));
    setCurrentPage(1);
  }, [queryParams]);

  const handleTabsChange = (status) => {
    setQueryParams({ ...queryParams, status: status });
  };
  const handleDelete = async (id) => {
    await deleteProposal(id);
    loadProposals(queryParamsFormatter(queryParams));
  };

  const handleModal = () => {
    setModalOpen(!modalOpen);
  };
  return (
    <MyProposalsTemplate
      handleTabsChange={handleTabsChange}
      handleDelete={handleDelete}
      handleModal={handleModal}
      proposals={proposals}
      hasMore={hasMore}
      isLoading={loading}
      loadMore={loadMoreProposals}
      currentPage={currentPage}
      queryParams={queryParams}
      modalOpen={modalOpen}
    />
  );
};

export default MyProposalsPage;
