import { useContext, useEffect, useState } from "react";

import { AddressContext } from "../../../Contexts/addressContext/addressContext";
import { BadgesContext } from "../../../Contexts/badgesContext/badgesContext";
import CheckoutTemplate from "../../../Templates/Home/Checkout/Checkout.Template";
import { completeCheckoutAPI } from "../../../Contexts/API/Home/Checkout/completeCheckout";
import { createCartAPI } from "../../../Contexts/API/Home/Cart/createCart";
import { getAccountAPI } from "../../../Contexts/API/Home/MyAccount/getAccount";
import { getCartAPI } from "../../../Contexts/API/Home/Cart/getCart";
import { nextCheckoutAPI } from "../../../Contexts/API/Home/Checkout/nextCheckout";
import { toast } from "react-toastify";
import { updateCheckoutAPI } from "../../../Contexts/API/Home/Checkout/updateCheckout";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const Checkout = () => {
  const history = useHistory();

  const { pickedAddress } = useContext(AddressContext);
  const { badges, setBadges } = useContext(BadgesContext);
  const [cartData, setcartData] = useState({});
  const [accountEmail, setAccountEmail] = useState("");
  const [address, setAddress] = useState();
  const [isPlaceOrderLoading, setIsPlaceOrderLoading] = useState(false);

  // useEffect(() => {
  //   console.log({ address });
  // }, [address]);

  const getCart = async () => {
    const { res, err, status } = await getCartAPI();

    if (status === 404) {
      await createCartAPI();
    }
    if (status === 200) {
      setcartData(res);
      setItems(res?.included?.filter((item) => item.type === "line_item"));
      //   const line = await cartData?.included?.filter(
      //     (item) => item.type === "line_item"
      //   );
      //   setItems(line);
    }
    if (err) toast.error(err);
  };

  const getUserData = async () => {
    const { res, status } = await getAccountAPI();

    if (status === 200) {
      setAccountEmail(res?.data?.attributes?.email);

      if (!(pickedAddress && Object.keys(pickedAddress)?.length === 0))
        setAddress(pickedAddress);
    }
  };

  useEffect(() => {
    getCart();
    getUserData();
    // const filteredItems = [...items];
    // setItems(filteredItems.filter((item) => item.type === "line_item"));
    // if (cartData.included) {
    //   setItems(cartData?.included?.filter((item) => item.type === "line_item"));
    // }
  }, []);

  const [items, setItems] = useState([]);

  const handlePlaceOrder = async () => {
    setIsPlaceOrderLoading(true);
    const { status } = await updateCheckoutAPI({
      order: {
        email: accountEmail,
        bill_address_attributes: address,
        ship_address_attributes: address,
        payments_attributes: [
          {
            payment_method_id: "3",
          },
        ],
      },
    });

    if (status === 200) {
      const { status: nextCheckoutStatus } = await nextCheckoutAPI();

      if (nextCheckoutStatus === 200) {
        const { status: completeCheckoutStatus } = await completeCheckoutAPI();

        if (completeCheckoutStatus === 200) {
          setIsPlaceOrderLoading(false);
          history.push("/profile/orders");
        } else {
          setIsPlaceOrderLoading(false);
          toast.error("Some error happened idk");
        }
      }
    }

    setBadges({ ...badges, cart: 0 });
  };

  return (
    <CheckoutTemplate
      address={address}
      items={cartData?.data?.attributes?.products}
      // items={items}
      cartData={cartData}
      handlePlaceOrder={handlePlaceOrder}
      isPlaceOrderLoading={isPlaceOrderLoading}
    />
  );
};

export default Checkout;
