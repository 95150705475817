import { Grid } from "@material-ui/core";
import StarRatings from "react-star-ratings/build/star-ratings";
import "./DetailedRating.css";
import { icons } from "../../../Assets/images/IconHelper";

import { withTranslation } from "react-i18next";
import RatingBar from "../../Atoms/RatingBar/RatingBar";

const DetailedRating = (props) => {
  const { t, direction = "row", product } = props;

  return (
    <Grid container style={{ flexDirection: direction }}>
      <Grid
        item
        xs={12}
        sm={direction == "row" ? 3 : 12}
        className="ratings-bg"
      >
        <div className="ratings-flex-center">
          <div className="ratings-avg">
            <span className="ratings-avg-value">
              {parseInt(product?.attributes?.avg_rating) ?? 0}/
            </span>
            <span>5</span>
          </div>
          <StarRatings
            rating={parseInt(product?.attributes?.avg_rating) ?? 0}
            starRatedColor="var(--star-color)"
            numberOfStars={5}
            starDimension="16px"
            starSpacing="2px"
          />
          <p className="ratings-total">
            {product?.attributes?.reviews_count} {t("product details.ratings")}
          </p>
        </div>
      </Grid>
      <Grid item xs={12} sm={direction == "row" ? 9 : 12}>
        <div
          className={
            direction == "row" ? "ratings-flex" : "ratings-flex-column"
          }
        >
          <div>
            {[5, 4, 3, 2, 1].map((item) => (
              <p
                className={
                  direction == "row"
                    ? "ratings-star-text"
                    : "ratings-star-text-column"
                }
              >
                {item}
              </p>
            ))}
          </div>

          <div>
            {[5, 4, 3, 2, 1].map((item) => (
              <div
                className={
                  direction == "row"
                    ? "ratings-star-star"
                    : "ratings-star-star-column"
                }
              >
                <StarRatings
                  rating={1}
                  starRatedColor="var(--star-color)"
                  numberOfStars={1}
                  starDimension={direction == "row" ? "16px" : "10px"}
                  starSpacing="2px"
                />
              </div>
            ))}
          </div>
          <div>
            {[40, 18, 5, 3, 2]?.map((item) => (
              <p
                className={
                  direction == "row"
                    ? "rating-star-total"
                    : "rating-star-total-column"
                }
              >
                ({item})
              </p>
            ))}
          </div>

          <div className="ratings-bars">
            {[4, 5, 3, 2, 1].map((item) => (
              <div className="ratings-bar-cont">
                <RatingBar percentage={item / 5} direction={direction} />
              </div>
            ))}
          </div>
        </div>
      </Grid>
    </Grid>
  );
};

export default withTranslation()(DetailedRating);
