import "./TabsCarousel.css";

import { Grid, Typography } from "@material-ui/core";
import { useEffect, useState } from "react";

import { Carousel } from "react-responsive-carousel";
import IconOverlay from "../../../Atoms/IconOverlay/IconOverlay";
import PrimaryButton from "../../../Atoms/Buttons/Primary/PrimaryButton";
import { icons } from "../../../../Assets/images/IconHelper";
import { Tab, Tabs } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";

const useTabStyles = makeStyles({
  root: {
    justifyContent: "center",
  },
  scroller: {
    flexGrow: "0",
  },
});

const TabsCarousel = ({
  categories,
  handleSelectingCategory,
  selectedCategory,
  home = false,
  tags = false,
  multi = false,
}) => {
  const classes = useTabStyles();
  const [state, setState] = useState(null);
  const arrowStyles = {
    position: "absolute",
    zIndex: 2,
    top: "calc(30% - 15px)",
    width: 40,
    height: 30,
    cursor: "pointer",
  };
  const getConfigurableProps = () => ({
    showArrows: true,
    showStatus: false,
    showIndicators: false,
    infiniteLoop: false,
    showThumbs: true,
    useKeyboardArrows: true,
    stopOnHover: true,
    swipeable: true,
    dynamicHeight: false,
    emulateTouch: false,
    autoFocus: false,
    thumbWidth: 100,
    selectedItem: 0,
    interval: 2000,
    transitionTime: 500,
    swipeScrollTolerance: 5,
    ariaLabel: "ariaLabel",
  });
  const pages = [];
  const getPages = (array) => {
    let page = [];
    for (var j = 0; j < array?.length; j++) {
      page.push(array[j]);
      if ((j + 1) % 4 === 0 && j !== 0) {
        pages.push(page);
        page = [];
      } else if (j === array.length - 1) {
        pages.push(page);
        page = [];
      }
    }
    return pages;
  };

  useEffect(() => {
    if (categories) setState(getPages([...categories, ...categories]));
  }, [categories]);

  const history = useHistory();

  return (
    <Tabs
      classes={home && { root: classes.root, scroller: classes.scroller }}
      variant={"scrollable"}
      scrollButtons="auto"
      aria-label="scrollable auto tabs example"
      style={{ marginBottom: "20px" }}
      centered={true}
      allowScrollButtonsMobile={true}
    >
      {categories &&
        categories?.map((item) => (
          <Tab
            onClick={() =>
              home
                ? history.push(`/collections?tag=${item?.id}`)
                : handleSelectingCategory(item)
            }
            icon={
              <IconOverlay
                src={item?.icon}
                style={{ margin: "0 5px 0 0", maxWidth: "17px" }}
                iconStyle={
                  multi
                    ? selectedCategory?.find((ids) => ids == item?.id)
                      ? "TagIcon"
                      : "TagIconHover"
                    : selectedCategory === item?.id
                    ? "TagIcon"
                    : "TagIconHover"
                }
              />
            }
            iconPosition="start"
            label={tags ? item?.name : item?.attributes?.name}
            className={
              multi
                ? selectedCategory?.find((ids) => ids == item?.id)
                  ? "carousel-item carousel-item-font-selected"
                  : "carousel-item carousel-item-font"
                : selectedCategory === item?.id
                ? "carousel-item carousel-item-font-selected"
                : "carousel-item carousel-item-font"
            }
          />
        ))}
    </Tabs>
  );
};

export default TabsCarousel;

// <Carousel
//   centerMode
//   centerSlidePercentage={100}
//   {...getConfigurableProps()}
//   renderArrowPrev={(onClickHandler, hasPrev, label) =>
//     hasPrev && (
//       <div>
//         <PrimaryButton
//           style={{ ...arrowStyles, left: 15 }}
//           classBtn=""
//           click={onClickHandler}
//           startIcon={
//             <IconOverlay src={icons.arrowLeftIcon} iconStyle="icon-primary" />
//           }
//         />
//       </div>
//     )
//   }
//   renderArrowNext={(onClickHandler, hasNext, label) =>
//     hasNext && (
//       <div>
//         <PrimaryButton
//           style={{ ...arrowStyles, right: 15 }}
//           classBtn=""
//           click={onClickHandler}
//           startIcon={
//             <IconOverlay src={icons.arrowRightIcon} iconStyle="icon-primary" />
//           }
//         />
//       </div>
//     )
//   }
// >
//   {state?.map((page) => {
//     return (
//       <Grid container spacing={home ? 5 : 1}>
//         {page?.map((category) => {
//           return (
//             <Grid
//               xs={home ? 3 : 0}
//               item
//               onClick={() => handleSelectingCategory(category)}
//             >
//               <Typography
//                 className={
//                   selectedCategory === category?.id
//                     ? "carousel-item carousel-item-font-selected"
//                     : "carousel-item carousel-item-font"
//                 }
//                 noWrap
//               >
//                 {tags ? category?.name : category?.attributes?.name}
//               </Typography>
//             </Grid>
//           );
//         })}
//       </Grid>
//     );
//   })}
//   {/* <Grid
// container
// spacing={home ? 5 : 1}
// style={{ overflowX: "scroll", flexWrap: "nowrap" }}
// className="scrollingWithoutBar"
// >
// {categories &&
// [...categories, ...categories]?.map((category) => {
//   return (
//     <Grid
//       xs={0}
//       item
//       onClick={() => handleSelectingCategory(category)}
//     >
//       <Typography
//         className={
//           selectedCategory === category?.id
//             ? "carousel-item carousel-item-font-selected"
//             : "carousel-item carousel-item-font"
//         }
//         noWrap
//       >
//         {category?.attributes?.name}
//       </Typography>
//     </Grid>
//   );
// })}
// </Grid> */}
// </Carousel>;
