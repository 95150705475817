import "./ProductCard.css";

import { CircularProgress, Grid } from "@material-ui/core";
import { Link, useHistory } from "react-router-dom";
import { useContext, useState } from "react";

import AddToCartBox from "../../../Molecules/AddToCartBox/AddToCartBox";
import { BoxesContext } from "../../../../Contexts/boxesContext/boxesContext";
import ProductCardIconButton from "../../../Molecules/ProductCardIconButton/ProductCardIconButton";
import { QuantityPicker } from "../../../Molecules";
import StarRatings from "react-star-ratings";
import { images } from "../../../../Assets/images/IconHelper";
import { systemContext } from "../../../../Contexts/systemContext/systemContext";
import { useCart } from "../../../../Hooks/useCart/useCart";
import { withTranslation } from "react-i18next";

const ProductCard = ({
  t,
  increaseItemQuantity,
  decreaseItemQuantity,
  addToWishlist,
  isFavorite,
  handleMoveToWishlist,
  deleteFromWishlist,
  moveProductToCart,
  quantity,
  row,
  variant,
  id,
  handleRemoveFromCart,
  parent,
  variantID,
  title,
  currency,
  price,
  unit,
  discount,
  rating,
  quantityPicker,
  productOptions,
  img,
  arrives,
  regularImg,
}) => {
  const history = useHistory();
  const { addToCart, isCartLoading: isAddToCartLoading } = useCart();
  const { systemConfig } = useContext(systemContext);
  const imgSize = row ? 4 : 12;
  const gridSize = row ? 8 : 12;
  const regular = regularImg ? "" : "100%";

  const [favorite, setFavorite] = useState(isFavorite);

  const [count, setCount] = useState(quantity ? quantity : 1);

  // modalState = modalOpen, setModalOpen, selectedProduct, setSelectedProduct, boxes, setBoxes, handleCloseModal
  const { modalState } = useContext(BoxesContext);
  const addToBox = (id) => {
    modalState.setSelectedProduct(id);
    modalState.handleCloseModal();
  };

  return (
    <Grid container spacing={2} className="product-card">
      <Grid xs={imgSize}>
        <div
          style={{
            border: "solid 1px #e3e3e3",
            padding: "75px 15px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "calc(100% - 159px)",
            position: "relative",
          }}
        >
          <ProductCardIconButton
            variant={variant}
            id={id}
            handleAddToWishlist={() => {
              setFavorite(!favorite);
              addToWishlist(id);
            }}
            handleRemoveFromWishlist={() => deleteFromWishlist(id)}
            handleRemoveFromCart={() => handleRemoveFromCart(id)}
            favorite={favorite}
          />
          <img
            onClick={() => history.push(`/product/${id}`)}
            src={img ?? images.productPlaceHolder}
            alt="product"
            style={{ height: "fit-content", width: regular, cursor: "pointer" }}
          />
        </div>
      </Grid>
      {/* <Grid item xs={imgSize}>
        <div className="product-card-imgCont">
          <Link
            to={
              parent === "basket" ? `/product/${variantID}` : `/product/${id}`
            }
          >
            <div
              className="product-card-img"
              style={{ backgroundImage: `url(${img ?? images.apple})` }}
            ></div>
          </Link>
        </div>
      </Grid> */}
      <Grid item xs={gridSize}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Link to={`/product/${id}`}>
              <p className="product-card-titleCont">{title}</p>
            </Link>
          </Grid>
          <Grid item xs={12}>
            <StarRatings
              rating={rating}
              starRatedColor="var(--star-color)"
              numberOfStars={5}
              starDimension="16px"
              starSpacing="2px"
            />
          </Grid>
          {productOptions &&
            productOptions?.map((item) => (
              <Grid item xs={12} style={{ marginBottom: "5px" }}>
                <span style={{ textTransform: "capitalize" }}>
                  {item?.option_type_name} :
                </span>{" "}
                <span>{item?.name}</span>
              </Grid>
            ))}
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={9}>
                <span className="product-card-currency">{currency}</span>
                <span className="product-card-price">{price}</span>
                <span className="product-card-price">/{unit}</span>
              </Grid>
              <Grid item xs={3}>
                <div className="product-card-flex">
                  {discount > 0 && (
                    <div className="product-card-badge">{discount}% OFF</div>
                  )}
                </div>
              </Grid>
            </Grid>
          </Grid>
          {arrives && (
            <Grid item xs={12} style={{ marginBottom: "5px" }}>
              <span style={{ textTransform: "capitalize" }}>Arrives :</span>{" "}
              <span>{arrives}</span>
            </Grid>
          )}
          {(row || quantityPicker) && (
            <Grid container>
              <Grid item xs={3} className="product-card-quantity">
                <p className="product-card-quantity">
                  {t("product details.quantity")}:
                </p>
              </Grid>
              <Grid item xs={12} sm={9} className="product-card-quantitypicker">
                <QuantityPicker
                  increaseItemQuantity={increaseItemQuantity}
                  decreaseItemQuantity={decreaseItemQuantity}
                  quantity={count}
                  id={id}
                  oldQuantity={quantity}
                />
              </Grid>
            </Grid>
          )}
          {isAddToCartLoading ? (
            <div className="progress-indicator">
              <CircularProgress style={{ color: "var(--secondary-color)" }} />
            </div>
          ) : (
            <Grid item xs={12}>
              <AddToCartBox
                variant={variant}
                onClickCart={() => {
                  variant === "wishlist"
                    ? moveProductToCart(variantID, id)
                    : addToCart(id, 1);
                }}
                onClickBox={() => addToBox(id)}
                showBox={
                  systemConfig
                    ? systemConfig["System Configurations"]["Boxes"] === "false"
                      ? false
                      : true
                    : false
                }
                handleMoveToWishlist={() => handleMoveToWishlist(variantID, id)}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default withTranslation()(ProductCard);
