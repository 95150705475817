import "./RequestServiceForm.css";

import * as yup from "yup";

import { CircularProgress, Grid, Typography } from "@mui/material";
import { useContext, useRef, useState } from "react";

import AddressCard from "../../../Components/Organisms/Cards/AddressCard/AddressCard";
import { AddressContext } from "../../../Contexts/addressContext/addressContext";
import Box from "@mui/material/Box";
import CircularProgressWithLabel from "../../Molecules/CircularProgressWithLabel/CircularProgressWithLabel";
import DropDownSelect from "../../Atoms/DropDownSelect/DropDownSelect";
import IconOverlay from "../../Atoms/IconOverlay/IconOverlay";
import PrimaryButton from "../../../Components/Atoms/Buttons/Primary/PrimaryButton";
import TextFieldInput from "../../../Components/Atoms/Inputs/TextField/TextFieldInput";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import UploadedFile from "../../Molecules/UploadedFile/UploadedFile";
import eventEmitter from "../../../utils/EventEmitter/Emitter";
import { icons } from "../../../Assets/images/IconHelper";
import { numberFormatter } from "../../../utils/dateFormatter/dateFormatter";
import { uploadToS3API } from "../../../Contexts/API/S3/uploadToS3";
import { useEffect } from "react";
import { useFormik } from "formik";

// import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

// import { DatePicker } from "@mui/x-date-pickers/DatePicker";

// import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

//VALIDATION SCHEMA
const validationSchema = yup.object().shape({
  address_id: yup.string().required("Please have an address selected"),
  // time_range_id: yup.string().required("Please select a time range"),
  service_type_id: yup.string().required("Please select a service type"),
  building_type_id: yup.string().required("Please select a building type"),
  details: yup.string().required("Please enter details of the service"),
  date: yup.string().required("Please enter a date"),
  time: yup.string().required("Please enter time"),
});

function RequestServiceForm({
  buildingTypes,
  serviceTypes,
  handleSubmitRequest,
  serviceRequest,
}) {
  const [additionalFiles, setAdditionalFiles] = useState([]);
  const [deletedDocumentIDS, setDeletedDocumentIDS] = useState([]);
  const [uploadToS3Loading, setUploadToS3Loading] = useState(false);
  const [uploadPercent, setUploadPercent] = useState(0);
  // const [numberOfUploadedFiles, setNumberOfUploadedFiles]
  const [beingUploadedState, setBeingUploadedState] = useState([]);
  //REFS
  const inputFile = useRef(null);
  //CONTEXT
  const { pickedAddress } = useContext(AddressContext);

  //FORMIK
  const formik = useFormik({
    initialValues: {
      address_id: pickedAddress?.id,
      service_type_id: "",
      building_type_id: "",
      details: "",
      date: "",
      time: "",
    },

    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (serviceRequest) {
        const additonalFilesUrls = additionalFiles.map((file) => {
          return { url: file.file_url ? file.file_url : file.url, id: file.id };
        });

        const request = {
          ...values,
          documents_attributes: additonalFilesUrls,
        };
        handleSubmitRequest(serviceRequest?.id, {
          request,
          deleted_document_ids: deletedDocumentIDS,
        });
      } else {
        const additonalFilesUrls = additionalFiles.map((file) => {
          return { url: file.file_url };
        });
        const request = {
          address_id: Number(formik.values.address_id),
          service_type_id: formik.values.service_type_id,
          building_type_id: formik.values.building_type_id,
          details: formik.values.details,
          datetime: formik.values.date + " " + formik.values.time,
          documents_attributes: additonalFilesUrls,
        };

        handleSubmitRequest({ request: request });
      }
    },
  });
  //STATES
  const [textAreaCharCount, setTextAreaCharCount] = useState(
    formik.values.details.length
  );

  const onFileChange = async (event, setter, state) => {
    if (event.target.files && event.target.files[0]) {
      const arrayOfExtensions = [];
      const files = event.target.files;
      const uploadingState = [];
      for (let [key, value] of Object.entries(files)) {
        arrayOfExtensions.push({
          extension: value.name.substring(value.name.indexOf(".") + 1),
        });
        uploadingState.push(false);
      }
      setUploadToS3Loading(true);
      const { res } = await uploadToS3API(files, arrayOfExtensions);
      setUploadToS3Loading(false);
      setter([...state, ...res]);
    }
  };
  const removeFile = (setter, state, id, backendId) => {
    const newState = state.filter((file, index) => id !== index);
    setter(newState);
    setDeletedDocumentIDS([...deletedDocumentIDS, backendId]);
  };

  //EFFECTS
  useEffect(() => {
    setTextAreaCharCount(formik.values.details.length);
  }, [formik.values.details]);

  useEffect(() => {
    formik.setFieldValue("address_id", Number(pickedAddress?.id));
  }, [pickedAddress]);

  useEffect(() => {
    if (serviceRequest) {
      formik.setFieldValue("service_type_id", serviceRequest?.service_type_id);
      formik.setFieldValue(
        "building_type_id",
        serviceRequest?.building_type_id
      );
      formik.setFieldValue("details", serviceRequest?.details);

      setAdditionalFiles(serviceRequest?.documents);
      const dateTime = new Date(serviceRequest?.datetime);
      //formatting time to work with the time input format
      let hours = dateTime.getHours();
      let minutes = dateTime.getMinutes();
      minutes = numberFormatter(minutes);
      hours = numberFormatter(hours);
      const time = hours + ":" + minutes;

      formik.setFieldValue("time", time);
      let day = dateTime.getDate();
      day = numberFormatter(day);
      let month = dateTime.getMonth() + 1;
      month = numberFormatter(month);
      formik.setFieldValue(
        "date",
        dateTime.getFullYear() + "-" + month + "-" + day
      );
    }
  }, [serviceRequest]);

  useEffect(() => {
    eventEmitter.on("UPLOAD_PROGRESS", (percent) => {
      setUploadPercent(percent);
    });

    return function cleanup() {
      eventEmitter.off("UPLOAD_PROGRESS");
    };
  }, []);

  return (
    <Box sx={{ mt: 3, width: "50%" }}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <DropDownSelect
            items={serviceTypes}
            label={"Type of Service"}
            value={formik.values.service_type_id}
            handleChange={(value) =>
              formik.setFieldValue("service_type_id", value)
            }
            placeholder={"Select type of service"}
            error={
              formik?.errors?.service_type_id &&
              formik?.touched?.service_type_id
            }
            errorMessage={formik?.errors?.service_type_id}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <DropDownSelect
            id={"building_type_id"}
            name={"building_type_id"}
            items={buildingTypes}
            label={"Building type"}
            value={formik.values.building_type_id}
            handleChange={(value) =>
              formik.setFieldValue("building_type_id", value)
            }
            placeholder={"Select type of buliding"}
            error={
              formik?.errors?.building_type_id &&
              formik?.touched?.building_type_id
            }
            errorMessage={formik?.errors?.building_type_id}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography>Add time</Typography>

          <TextFieldInput
            type="time"
            placeholder="Select suitable Time"
            value={formik.values.time}
            onchange={(e) => {
              formik.setFieldValue("time", e);
            }}
            EndAdornment={<IconOverlay src={icons.clockIcon} />}
            error={Boolean(formik.errors.time) && formik.touched.time}
            helperText={formik.touched.time && formik.errors.time}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography>Add Date</Typography>
          <TextFieldInput
            placeholder="Select suitable Time"
            type="date"
            value={formik.values.date}
            onchange={(e) => {
              formik.setFieldValue("date", e);
            }}
            EndAdornment={<IconOverlay src={icons.inputCalendarIcon} />}
            error={Boolean(formik.errors.date) && formik.touched.date}
            helperText={formik.touched.date && formik.errors.date}
          />
        </Grid>
        {/* Details */}
        <Grid item xs={12} sm={12} container>
          <Typography sx={{ mb: "12px", fontWeight: "600", fontSize: "16px" }}>
            Details
          </Typography>
          <TextareaAutosize
            className="textarea"
            style={{
              width: "100%",
              height: "230px",
              resize: "none",
              paddingLeft: "10px",
              paddingTop: "10px",
              borderTop: "1px black solid",
              borderLeft: "1px black solid",
              borderRight: "1px black solid",
              borderBottom: "1px white solid",
            }}
            maxLength="500"
            aria-label="empty textarea"
            placeholder="Tell us about what you want"
            value={formik.values.details}
            onChange={(e) => formik.setFieldValue("details", e.target.value)}
          />

          {/* Word count inside the text area */}
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
              borderRight: "1px solid black",
              borderLeft: "1px solid black",
            }}
          >
            <Typography
              sx={{
                mr: "25px",
                color: "#4a4a48",
                mb: "10px",
              }}
            >
              {textAreaCharCount}/500
            </Typography>
          </Box>
          {/* Attach files box */}
          <Box
            sx={{
              backgroundColor: "#F0F0F0",
              width: "100%",
              borderBottom: "solid 1px #3c4550",
              borderRight: "solid 1px #3c4550",
              borderLeft: "solid 1px #3c4550",
            }}
          >
            <Typography
              sx={{
                color: "#000000",
                mb: "12px",
                fontWeight: "500",
                fontSize: "14px",
                ml: "10px",
                pt: "10px",
                textDecoration: "underline",
                cursor: "pointer",
              }}
              onClick={() => inputFile.current.click()}
            >
              <input
                multiple
                type="file"
                id="file"
                ref={inputFile}
                style={{ display: "none" }}
                onChange={(event) => {
                  onFileChange(event, setAdditionalFiles, additionalFiles);
                }}
              />
              Attach files for additional information ({" "}
              {additionalFiles.length + " "}/ 5 )
            </Typography>
            <div
              style={{
                display: "flex",
                marginLeft: 10,
                marginRight: 10,
                marginBottom: 14,
              }}
            >
              {uploadToS3Loading ? (
                <div className="progress-indicator">
                  <CircularProgress
                    style={{ color: "var(--secondary-color)" }}
                  />
                  {/* <Typography>Uploaded {}/3</Typography> */}
                </div>
              ) : (
                additionalFiles.map((file, index) => {
                  return (
                    <div>
                      {uploadToS3Loading ? (
                        <CircularProgressWithLabel
                          style={{ color: "var(--secondary-color)" }}
                          value={uploadPercent}
                        />
                      ) : (
                        <UploadedFile
                          src={file.file_url}
                          fileType={file.extension}
                          fileName={file.file_name}
                          id={index}
                          handleRemove={removeFile}
                          files={additionalFiles}
                          setter={setAdditionalFiles}
                          backendId={file.id}
                        />
                      )}
                    </div>
                  );
                })
              )}
              {/* {additionalFiles.map((file, index) => {
                return (
                  <UploadedFile
                    src={file.file_url}
                    fileType={file.extension}
                    fileName={file.file_name}
                    id={index}
                    handleRemove={removeFile}
                    files={additionalFiles}
                    setter={setAdditionalFiles}
                    backendId={file.id}
                    percent={uploadPercent}
                  />
                );
              })} */}
            </div>
          </Box>
          {formik.errors.details && formik.touched.details ? (
            <small style={{ color: "red" }}>{formik.errors.details} </small>
          ) : (
            ""
          )}
        </Grid>
      </Grid>

      <div>
        <AddressCard
          address={pickedAddress}
          noDelete={true}
          noEdit={true}
          noCheckbox={true}
        />
      </div>
      <Box
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "24px",
          height: "60px",
        }}
      >
        <PrimaryButton
          disabled={uploadToS3Loading}
          style={{ width: "320px", height: "60px" }}
          text={"Submit"}
          classBtn="btn-secondry rounded-8"
          click={formik.handleSubmit}
        />
      </Box>
    </Box>
  );
}

export default RequestServiceForm;
