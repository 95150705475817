import { IconButton } from "@material-ui/core";
import IconOverlay from "../../Atoms/IconOverlay/IconOverlay";
import { icons } from "../../../Assets/images/IconHelper";

const ProductCardIconButton = (props) => {
  const {
    handleAddToWishlist,
    handleRemoveFromWishlist,
    handleRemoveFromCart,
    favorite,
    handleDeleteBox,
  } = props;

  return (
    <>
      {props.variant === "wishlist" ? (
        <IconButton
          className="product-card-wishlist"
          onClick={handleRemoveFromWishlist}
          aria-label="remove from wishlist"
          component="span"
        >
          <IconOverlay src={icons.deleteIcon} iconStyle="icon-white" />
        </IconButton>
      ) : props.variant === "cart" ? (
        <IconButton
          className="product-card-wishlist"
          onClick={handleRemoveFromCart}
          aria-label="remove from wishlist"
          component="span"
        >
          <IconOverlay src={icons.deleteIcon} iconStyle="icon-white" />
        </IconButton>
      ) : props.variant === "box" ? (
        <IconButton
          className="product-card-wishlist"
          onClick={handleDeleteBox}
          aria-label="remove from wishlist"
          component="span"
        >
          <IconOverlay src={icons.deleteIcon} iconStyle="icon-white" />
        </IconButton>
      ) : (
        <IconButton
          className="product-card-wishlist"
          aria-label="add to wishlist"
          component="span"
        >
          {!favorite && (
            <span className="product-card-inwishlist">
              <IconOverlay
                src={icons.addToWishlist2}
                iconStyle="icon-white"
                onClick={handleAddToWishlist}
              />
            </span>
          )}

          {favorite && (
            <span className="product-card-inwishlist">
              <IconOverlay
                src={icons.addToWishlist2}
                iconStyle="like-animation icon-red"
                onClick={handleRemoveFromWishlist} //TODO REMOVE FROM WISHLIST
              />
            </span>
          )}
        </IconButton>
      )}
    </>
  );
};

export default ProductCardIconButton;
