import { PropTypes } from "prop-types";
import styles from "./QuantityPicker.module.css";
import { withTranslation } from "react-i18next";
import TextFieldInput from "../../Atoms/Inputs/TextField/TextFieldInput";
import { TextField } from "@mui/material";

const QuantityPicker = ({
  collectionProduct,
  quantity,
  increaseItemQuantity,
  decreaseItemQuantity,
  id,
  oldQuantity,
  input = false,
  setInput,
}) => {
  return (
    <>
      <div
        className={
          collectionProduct ? styles.containerCollection : styles.container
        }
      >
        <div
          className={styles.inc}
          onClick={() => decreaseItemQuantity(id, oldQuantity)}
        >
          -
        </div>
        {setInput ? (
          <TextField
            type="number"
            id="quantity"
            name="quantity"
            value={quantity}
            onChange={(e) => {
              if (e.target.value) setInput(e.target.value);
              else setInput(0);
            }}
            style={{ border: "0", padding: "0", width: "fit-content" }}
          />
        ) : (
          <div className={styles.quantity}>{quantity}</div>
        )}

        <div
          className={styles.dec}
          onClick={() => increaseItemQuantity(id, oldQuantity)}
        >
          +
        </div>
      </div>
    </>
  );
};

QuantityPicker.propTypes = {
  quantity: PropTypes.string,
};
export default withTranslation()(QuantityPicker);
