import { images } from '../../../Assets/images/IconHelper';
import './BlogRecommendation.css';
const BlogRecommendation = ({ img, title, date, by, text }) => {
    return (
        <div className="blog-rec">
            <div className="blog-rec-image" style={{ backgroundImage: `url(${img})` }}>
            </div>

            <div className="blog-rec-title">
                {title}
            </div>

            <div className="blog-rec-meta">
                <span>{date}</span>
                <span>&nbsp;by&nbsp;</span>
                <span className="blog-rec-by">{by}</span>
            </div>

            <div className="blog-rec-text">
                {text}
            </div>
        </div>
    );
}

export default BlogRecommendation;