export const queryParamsFormatter = (params) => {
  const queryParams = Object.entries(params).map((item, idx) => {
    return `${
      item[1]
        ? `${item[0]}=${
            Array.isArray(item[1]) ? `[${item[1].join(",")}]` : item[1]
          }${idx === Object.entries(params).length - 1 ? "" : "&"}`
        : ""
    }`;
  });
  return queryParams.join("");
};
