import "./OrderCard.css";

import { icons, images } from "../../../../Assets/images/IconHelper";

import { Grid } from "@material-ui/core";
import IconOverlay from "../../../Atoms/IconOverlay/IconOverlay";
import { NavLink } from "react-router-dom";
import React from "react";
import { withTranslation } from "react-i18next";

const OrderCard = (props) => {
  const { t, id, orderNumber, deliveryDate, products } = props;

  return (
    <Grid container className="order_card" style={{ alignItems: "center" }}>
      <Grid item xs={9}>
        <p className="title">
          {t("order.order number")}: {orderNumber}
        </p>
        <p>
          {t("order.delivery date")}: {deliveryDate}
        </p>
      </Grid>
      <Grid item xs={3} style={{ display: "flex", justifyContent: "flex-end" }}>
        <NavLink to={`/profile/orders/details/${orderNumber}`}>
          {t("order.view details")}
          {/* <img className="back" src={icons.dropdown} alt=""/>  */}
          <IconOverlay src={icons.dropdown} iconStyle="icon-white back" />
        </NavLink>
      </Grid>

      <img src={images.line} alt="" />

      {products?.map((item) => {
        return (
          <Grid item xs={12} md={6} style={{ marginBottom: "10px" }}>
            <Grid container style={{ alignItems: "center" }}>
              <Grid item xs={4}>
                <div className="small-image">
                  <img
                    src={item?.image ?? images.productPlaceHolder}
                    alt="product"
                  />
                </div>
              </Grid>
              <Grid item xs={8}>
                <div className="order-details">
                  <p className="order-title">{item?.name}</p>
                  <p className="order-status">IN PROCESSING</p>
                </div>
              </Grid>
            </Grid>
          </Grid>
        );
      })}
      {/* 
      <Grid item xs={12} md={6}>
        <Grid container>
          <Grid item xs={4}>
            <div className="small-image">
              <img src={images.apple} alt="" />
            </div>
          </Grid>
          <Grid item xs={8}>
            <div className="order-details">
              <p className="order-title">
                Large Fruit & Veg Box, Organic ( 3 KG ){" "}
              </p>
              <p className="order-status">IN PROCESSING</p>
            </div>
          </Grid>
        </Grid>
      </Grid> */}
    </Grid>
  );
};

export default withTranslation()(OrderCard);
