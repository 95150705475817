import React from "react";
import "./Title.css";

function Title(props) {
  return (
    <h1 className="titleMain" style={props.style}>
      {props.text}
    </h1>
  );
}

export default Title;
