import * as URL from "../../URLs";

import { axiosFn, handleError } from "../../index";

// import { toast } from "react-toastify";

export async function acceptProposal(requestID, quotationID) {
  return await axiosFn(
    "patch",
    `${URL.PRODUCT_REQUESTS}/${requestID}/quotations/${quotationID}`
  )
    .then((res) => {
      return {
        res: res?.data,
        err: null,
        status: res?.status,
      };
    })
    .catch(handleError);
}
export async function acceptProposalAPI(requestID, quotationID) {
  let { err, res, status } = await acceptProposal(requestID, quotationID);

  return { res, err, status };
}
