import { Box, Grid, Tab, Tabs, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";
import PrimaryButton from "../../../Components/Atoms/Buttons/Primary/PrimaryButton";
import ProductItemCard from "../../../Components/Organisms/Cards/ProductItemCard/ProductItemCard";
import ProductsTab from "../../../Components/Organisms/ProductsTab/ProductsTab";
import ProductSideNav from "../../../Components/Organisms/SideNav/ProductSideNav";
import "./Products.css";

function ProductsTemplate(props) {
  const { t, getProductsList, mobileView } = props;
  let { type } = useParams();
  const history = useHistory();

  const initialProducts = {
    fruits: [],
    vegetables: [],
    leafy: [],
  };

  const [products, setProducts] = useState({ ...initialProducts });
  const [pagination, setPagination] = useState({
    fruits: {
      currentPage: 0,
      maxPage: 1,
    },
    vegetables: {
      currentPage: 0,
      maxPage: 1,
    },
    leafy: {
      currentPage: 0,
      maxPage: 1,
    },
  });
  const [loading, setLoading] = useState(true);

  //taxon==> fruits, vegetables, leafy vegetables
  //type ==> fresh cut, repacked, more
  const loadData = async (type, taxon, page) => {
    const filter = `filter[taxons]=${taxon}&filter[name]=${type}`;
    const res = await getProductsList(filter, page);
    return res;
  };

  const getTitle = (title) => {
    switch (title) {
      case "fresh":
        return "navbar.Fresh cut";
      case "repacked":
        return "navbar.RePacked";
      default:
        return "navbar.More";
    }
  };

  const getTypeFilter = (ty) => {
    switch (ty) {
      case "fresh":
        return " ";
      case "repacked":
        return " ";
      default:
        return " ";
    }
  };

  const [title, setTitle] = useState(getTitle(type));

  useEffect(() => {
    if (type !== "more" && type !== "fresh" && type !== "repacked") {
      history.push("/products/more");
    } else {
      setTitle(getTitle(type));
      setProducts({
        fruits: [],
        vegetables: [],
        leafy: [],
      });
      setPagination({
        fruits: {
          currentPage: 0,
          maxPage: 1,
        },
        vegetables: {
          currentPage: 0,
          maxPage: 1,
        },
        leafy: {
          currentPage: 0,
          maxPage: 1,
        },
      });

      initializeTabs();
      // console.log(products)
    }
  }, [type]);

  const [value, setValue] = React.useState(0);

  const loadMore = async (newValue) => {
    const currentType = getTypeFilter(type);
    setLoading(true);

    if (newValue === 0) {
      const { currentPage, maxPage } = pagination.fruits;
      if (currentPage < maxPage) {
        let nextPage = currentPage + 1;
        const data = await loadData(currentType, 2, nextPage);
        setPagination({
          ...pagination,
          fruits: {
            currentPage: nextPage,
            maxPage: maxPage,
          },
        });
        setProducts({
          ...products,
          fruits: products.fruits.concat(data?.data),
        });
      }
    } else if (newValue === 1) {
      const { currentPage, maxPage } = pagination.vegetables;
      if (currentPage < maxPage) {
        let nextPage = currentPage + 1;
        const data = await loadData(currentType, 3, nextPage);
        setPagination({
          ...pagination,
          vegetables: {
            currentPage: nextPage,
            maxPage: maxPage,
          },
        });
        setProducts({
          ...products,
          vegetables: products.vegetables.concat(data?.data),
        });
      }
    } else if (newValue === 2) {
      const { currentPage, maxPage } = pagination.leafy;
      if (currentPage < maxPage) {
        let nextPage = currentPage + 1;
        const data = await loadData(currentType, 4, nextPage);
        setPagination({
          ...pagination,
          leafy: {
            currentPage: nextPage,
            maxPage: maxPage,
          },
        });
        setProducts({ ...products, leafy: products.leafy.concat(data?.data) });
      }
    }
    setLoading(false);
  };

  const getTab = async (taxon) => {
    const currentType = getTypeFilter(type);
    const data = await loadData(currentType, taxon, 1);
    const max = data?.meta?.total_pages;
    return { data: data.data, pagination: { currentPage: 1, maxPage: max } };
  };

  const initializeTabs = async () => {
    setLoading(true);
    const fruits = await getTab(2);
    const vegetables = await getTab(3);
    const leafy = await getTab(4);
    setLoading(false);

    setProducts({
      fruits: fruits.data,
      vegetables: vegetables.data,
      leafy: leafy.data,
    });
    setPagination({
      fruits: { ...fruits.pagination },
      vegetables: { ...vegetables.pagination },
      leafy: { ...leafy.pagination },
    });
  };

  const handleChange = async (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Grid container spacing={1} className="products-container">
      {!mobileView && (
        <Grid item xs={0} sm={3}>
          <ProductSideNav mobileView={mobileView} />
        </Grid>
      )}
      <Grid item xs={12} sm={9}>
        {mobileView && <ProductSideNav mobileView={mobileView} />}

        <Grid container>
          <Grid item xs={12}>
            <h2 className="products-title">{t(title)}</h2>
          </Grid>

          <Grid item xs={12} className="products-tab-container">
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
              variant="fullWidth"
              aria-label="full width tabs example"
            >
              <Tab label={t("navbar.Fruits")} {...a11yProps(0)} />
              <Tab label={t("navbar.Vegetables")} {...a11yProps(1)} />
              <Tab label={t("navbar.Leafy")} {...a11yProps(2)} />
            </Tabs>
          </Grid>

          <Grid item xs={12}>
            <ProductsTab
              products={products?.fruits}
              value={value}
              index={0}
              currentPage={pagination.fruits.currentPage}
              maxPage={pagination.fruits.maxPage}
              loadMore={loadMore}
              loading={loading}
            />

            <ProductsTab
              products={products?.vegetables}
              value={value}
              index={1}
              currentPage={pagination.vegetables.currentPage}
              maxPage={pagination.vegetables.maxPage}
              loadMore={loadMore}
              loading={loading}
            />
            <ProductsTab
              products={products?.leafy}
              value={value}
              index={2}
              currentPage={pagination.leafy.currentPage}
              maxPage={pagination.leafy.maxPage}
              loadMore={loadMore}
              loading={loading}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

export default withTranslation()(ProductsTemplate);
