import MessengerTemplate from "../../../Templates/Home/Profile/Messenger/Messenger.Template";
import { useState, useEffect } from "react";
import { getChatRooms, GetMessages, visitChatRoom, SendMessage, handleFiles, handleFileInputs } from "../../../Contexts/API/Home/Chats/LiveChat";
import { RealTimeDatabase } from "../../../Contexts/firebase";

const Messenger = (props) => {
    const [chatRooms, setChatRooms] = useState([]);
    const [messagesList, setMessagesList] = useState([]);
    const [message, setMessage] = useState("");
    const [Files, setfiles] = useState([]);
    const [FilesToSend, setFilesToSend] = useState([]);

    const listChatRooms = async (filterObj) => {
        const {res, err} = await getChatRooms(filterObj);
        if(res){
            setChatRooms(res?.chat_rooms);
        }
        if(err){

        }
    };

    const getMessages = async () => {
        if(localStorage.getItem("Messages_node")){
            await GetMessages(localStorage.getItem("Messages_node"))
            window.addEventListener('getmessage', async (evt) => {
              setMessagesList(evt.data)
            });
        }
    };

    const updateMessages = async (chat_id) => {
        await visitChatRoom(chat_id);
        getMessages();
        listChatRooms();
    };

    const sendMessage = async () => {
        await SendMessage(message, FilesToSend, localStorage.getItem("user_id"));
        listChatRooms();
        setMessage("");
        setfiles([]);
        setFilesToSend([]);
    };

    const handleFileInput = ({ target: { files } }) => {
        let result = handleFileInputs(Files, files);
        setfiles(result);
    };

    useEffect(async () => {
        listChatRooms();
        getMessages();
        var extra = await RealTimeDatabase.ref("extra_options")
        extra.on('child_changed', async (snapshot) => {
            const Users = Object.values(snapshot.val().members).map(function(user){return user.user_id});
            if(Users.includes(parseInt(localStorage.getItem("user_id")))){
                listChatRooms();
            }
        });
    }, []);

    useEffect(async () => {
        let urls = await handleFiles(Files);
        const FilesArray = await Promise.all(urls);
        setFilesToSend(FilesArray);
    }, [Files]);

    return (
        <MessengerTemplate
            chatRooms={chatRooms}
            updateMessages={updateMessages}
            messagesList={messagesList}
            sendMessage={sendMessage}
            setMessage={setMessage}
            message={message}
            handleFileInput={handleFileInput}
            updateListWithSearch={listChatRooms}
        />
    );
};

export default Messenger;