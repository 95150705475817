import { Grid } from "@material-ui/core";
import React, { useState } from "react";
import PrimaryButton from "../../Atoms/Buttons/Primary/PrimaryButton";
import TextFieldInput from "../../Atoms/Inputs/TextField/TextFieldInput";
import * as yup from "yup";
import { useFormik } from "formik";

const Form = ({
  from,
  name,
  email,
  password,
  confirm,
  onHandle,
  error,
  // clicked,
}) => {
  //VALIDATION SCHEMA
  const validationSchema = yup.object().shape({
    name:
      from === "Sign in" || from === "تسجيل دخول"
        ? yup.string().notRequired()
        : yup.string().required("Name is Required"),
    email: yup.string().email().required("Email is Required"),
    password: yup
      .string()
      .required("Password is Required")
      .min(8, "Password must contain 8 characters at least"),
    confirmPassword:
      from === "Sign in" || from === "تسجيل دخول"
        ? yup.string().notRequired()
        : yup
            .string()
            .min(8, "Password must contain 8 characters at least")
            .when("password", {
              is: (val) => (val && val.length > 0 ? true : false),
              then: yup
                .string()
                .oneOf(
                  [yup.ref("password")],
                  "Both password need to be the same"
                ),
            })
            .required("Password is Required"),
  });

  const [clicked, setClicked] = useState(false);
  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      password: "",
      confirmPassword: "",
    },

    validationSchema: validationSchema,
    onSubmit: (values) => {
      from === "Sign in" || from === "تسجيل دخول"
        ? onHandle({
            user: {
              grant_type: "password",
              username: values.email,
              password: values.password,
            },
          })
        : onHandle({
            user: {
              name: values.name,
              email: values.email,
              password: values.password,
              password_confirmation: values.confirmPassword,
            },
          });
    },
  });
  // const [namee, setname] = useState();
  // const [emaill, setemail] = useState();
  // const [passwordd, setpassword] = useState();
  // const [confirmm, setconfirm] = useState();

  // const clickEnter = () => {
  //   const dis =
  //     from === "Sign in" || from === "تسجيل دخول"
  //       ? !emaill || !passwordd
  //       : !namee || !emaill || !passwordd || !confirmm;
  //   if (!dis) {
  //     from === "Sign in" || from === "تسجيل دخول"
  //       ? onHandle({
  //           user: {
  //             grant_type: "password",
  //             username: emaill,
  //             password: passwordd,
  //           },
  //         })
  //       : onHandle({
  //           user: {
  //             name: namee,
  //             email: emaill,
  //             password: passwordd,
  //             password_confirmation: confirmm,
  //           },
  //         });
  //   }
  // };

  return (
    <Grid
      container
      className="Auth_form"
      direction="column"
      alignItems="center"
    >
      {name && (
        <TextFieldInput
          placeholder={name}
          className="mb-20px"
          onchange={(value) => formik.setFieldValue("name", value)}
          value={formik.values.name}
          onKeyDown={(e) => {
            if (e.key === "Enter") formik.handleSubmit();
          }}
          error={clicked && formik.errors.name}
          helperText={clicked && formik.errors.name}
        />
      )}

      {email && (
        <TextFieldInput
          placeholder={email}
          className="mb-20px"
          onchange={(value) => formik.setFieldValue("email", value)}
          value={formik.values.email}
          onKeyDown={(e) => {
            if (e.key === "Enter") formik.handleSubmit();
          }}
          error={clicked && formik.errors.email}
          helperText={clicked && formik.errors.email}
        />
      )}

      {password && (
        <TextFieldInput
          placeholder={password}
          className="mb-20px"
          type="password"
          onchange={(value) => formik.setFieldValue("password", value)}
          value={formik.values.password}
          onKeyDown={(e) => {
            if (e.key === "Enter") formik.handleSubmit();
          }}
          error={clicked && formik.errors.password}
          helperText={clicked && formik.errors.password}
        />
      )}

      {confirm && (
        <TextFieldInput
          placeholder={confirm}
          className="mb-30px"
          type="password"
          onchange={(value) => formik.setFieldValue("confirmPassword", value)}
          value={formik.values.confirmPassword}
          onKeyDown={(e) => {
            if (e.key === "Enter") formik.handleSubmit();
          }}
          error={clicked && formik.errors.confirmPassword}
          helperText={clicked && formik.errors.confirmPassword}
        />
      )}

      <PrimaryButton
        text={from}
        classBtn="primary"
        click={() => {
          setClicked(true);
          formik.handleSubmit();
        }}
        // disabled={
        //   false
        //   //   from === "Sign in" || from === "تسجيل دخول"
        //   //     ? !emaill || !passwordd
        //   //     : !namee || !emaill || !passwordd || !confirmm
        // }
        // click={() =>
        //   from === "Sign in" || from === "تسجيل دخول"
        //     ? onHandle({
        //         user: {
        //           grant_type: "password",
        //           username: emaill,
        //           password: passwordd,
        //         },
        //       })
        //     : onHandle({
        //         user: {
        //           name: namee,
        //           email: emaill,
        //           password: passwordd,
        //           password_confirmation: confirmm,
        //         },
        //       })
        // }
      />
    </Grid>
  );
};

export default Form;
