import { CircularProgress, Typography } from "@mui/material";

import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import RequestServiceForm from "../../../Components/Organisms/RequestServiceForm/RequestServiceForm";

// import RequestServiceForm from "../../../Components/Organisms/RequestServiceForm/RequestServiceForm";

function EsolarRequestServiceTemplate({
  buildingTypes,
  serviceTypes,
  isLoading,
  handleSubmitRequest,
}) {
  return (
    <Container component="main" maxWidth="lg">
      {isLoading ? (
        <Box sx={{ display: "flex", justifyContent: "center", mt: "250px" }}>
          <CircularProgress color="secondary" />
        </Box>
      ) : (
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              backgroundColor: "#f8f8f8",
              paddingY: "48px",
              borderRadius: 1,
            }}
          >
            <Typography>We'd Love to help you</Typography>
            <Typography variant="h4" sx={{}}>
              Request Service
            </Typography>
            <Typography variant="h6" sx={{ width: "70%" }} align="center">
              Tell suppliers what you need The more specific your information,
              the faster response you will get.
            </Typography>
          </Box>
          <RequestServiceForm
            buildingTypes={buildingTypes}
            serviceTypes={serviceTypes}
            handleSubmitRequest={handleSubmitRequest}
          />
        </Box>
      )}
    </Container>
  );
}

export default EsolarRequestServiceTemplate;
