import { Grid } from '@material-ui/core'
import { withTranslation } from 'react-i18next';
import './OurProcess.css';
import '../HomePage.css';
import { images } from '../../../../Assets/images/IconHelper';

const OurProcess = (props) => {
    const { t } = props
    return (

        <div className="imgContainer process" style={{ backgroundImage: `url(${images.processBg})` }}>
            <Grid container direction="row-reverse" className="imgContainerContent"  >

                <Grid item xs={12} md={6} >
                    <div className="process-container">
                        <h2 className="home-title process-title">{t('ourProcess.title')}</h2>
                        <p className="process-body">{t('ourProcess.text')}</p>
                    </div>
                </Grid>

            </Grid>
        </div>

    );
}

export default withTranslation()(OurProcess);