import "../ModalStyle.css";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";

import PrimaryButton from "../../../Atoms/Buttons/Primary/PrimaryButton";
import React from "react";
import { withTranslation } from "react-i18next";

function PromptModal({
  t,
  i18n,
  id,
  handleClose,
  actionHandler,
  open,
  title,
  text,
}) {
  return (
    <Dialog
      open={open}
      className={
        i18n.language === "ar" ? "rtl_modal logoutModal" : "logoutModal"
      }
    >
      <DialogTitle>
        <p className="Modal_header">{title}</p>
      </DialogTitle>
      <DialogContent>{t(text) + " " + "number #" + id + "?"}</DialogContent>
      <DialogActions>
        <PrimaryButton
          classBtn="outline modal error"
          text={t("general.Cancel")}
          click={handleClose}
        />
        <PrimaryButton
          classBtn="outline modal success"
          text={t("Delete")}
          click={() => {
            actionHandler(id);
            handleClose();
          }}
        />
      </DialogActions>
    </Dialog>
  );
}

export default withTranslation()(PromptModal);
