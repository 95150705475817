import { VendorProductsTemplate } from "../../../Templates/Home/VendorDetails/VendorProducts.template";
import { getProductsList } from "../../../Contexts/API/Home/Products/getProductsList";
import { useEffect } from "react";
import { useState } from "react";

export const VendorProductsPage = () => {
  const [products, setProducts] = useState(null);
  const loadProducts = async () => {
    const data = await getProductsList();
    setProducts(data?.res?.data);
  };
  useEffect(() => {
    loadProducts();
  }, []);
  return <VendorProductsTemplate products={products} />;
};
