import * as URL from "../../URLs";

import { axiosFn, handleError } from "../../index";

//GETS TRADE TERMS LIST NEEDED TO CREATE A REQUEST
export async function getAllProductRequest(filter, page = 1) {
  return await axiosFn(
    "get",
    `${URL.PRODUCT_REQUESTS}/index?per_page=10&page=${page}&${filter}`
  )
    .then((res) => {
      return {
        res: res?.data,
        err: null,
        status: res?.status,
      };
    })
    .catch(handleError);
}

export async function getAllProductRequestAPI(filter, page) {
  let { err, res, status } = await getAllProductRequest(filter, page);

  return { res, err, status };
}
