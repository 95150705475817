import * as URL from "../../URLs";

import { axiosFn, handleError } from "../../index";

// import { toast } from "react-toastify";

export async function createServiceRequest(recivedData) {
  return await axiosFn("post", URL.REQUESTS, recivedData)
    .then((res) => {
      return {
        res: res?.data,
        err: null,
        status: res?.status,
      };
    })
    .catch(handleError);
}
export async function createServiceRequestAPI(data) {
  let { err, res, status } = await createServiceRequest(data);

  return { res, err, status };
}
