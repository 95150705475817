import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { createCollectionAPI } from "../../../Contexts/API/Home/Collections/createCollection";
import { updateCollectionAPI } from "../../../Contexts/API/Home/Collections/updateCollection";
import { useCategories } from "../../../Hooks/useCategories/useCategories";
import { useCollection } from "../../../Hooks/useCollection/useCollection";
import UploadCollectionImageTemplate from "../../../Templates/Home/UploadCollectionImage/UploadCollectionImage.Template";
import { queryParamsFormatter } from "../../../utils/queryParamsFormatter/queryParamsFormatter";

function UploadCollectionImage() {
  const params = useParams();
  const { collectionDataDetails, GetCollection } = useCollection();
  const { loadCategoriesList, categories } = useCategories();

  const [CollectionCategory, setCollectionCategory] = useState("");
  const [queryParams, setQueryParams] = useState({ parent_id: "" });
  const subCategories = useCategories(queryParamsFormatter(queryParams));

  useEffect(() => {
    if (CollectionCategory?.id != queryParams?.parent_id)
      setQueryParams({ parent_id: CollectionCategory?.id });
  }, [CollectionCategory]);

  useEffect(() => {
    if (queryParams?.parent_id) subCategories.loadCategoriesList();
  }, [queryParams]);

  useEffect(() => {
    loadCategoriesList();
    if (params?.id) GetCollection(params?.id);
  }, [params?.id]);

  const updateNow = async (Data) => {
    const data = {
      collection: {
        name_en: Data?.name_en,
        name_ar: Data?.name_ar,
        description: Data?.desc,
        image: Data?.image?.file_url,
        documents_attributes: Data?.listOfCarousel?.map((item) => ({
          url: item?.file_url,
          id: item?.id,
        })),
        taxon_ids: Data?.taxon_ids,
        tag_ids: Data?.tag_ids,
      },
      deleted_document_ids: Data?.deleted_document_ids,
    };
    const { res, err } = await updateCollectionAPI(params?.id, data);
    if (res) {
      window.location = `/collection/${params?.id}`;
    }
    if (err) {
      toast.error(err?.at(0));
    }
  };

  const AddNewCollection = async (Data) => {
    const data = {
      collection: {
        name_en: Data?.name_en,
        name_ar: Data?.name_ar,
        description: Data?.desc,
        image: Data?.image?.file_url,
        documents_attributes: Data?.listOfCarousel?.map((item) => ({
          url: item?.file_url,
        })),
        taxon_ids: Data?.taxon_ids,
        tag_ids: Data?.tag_ids,
      },
    };
    const { res, err } = await createCollectionAPI(data);
    if (res) {
      window.location = `/collection/${res?.collection?.id}`;
    }
    if (err) {
      toast.error(err?.at(0));
    }
  };

  return (
    <UploadCollectionImageTemplate
      ID={params?.id}
      updateNow={updateNow}
      collectionDataDetails={collectionDataDetails}
      edit={params?.id ? true : false}
      AddNewCollection={AddNewCollection}
      categories={categories}
      subCategories={subCategories}
      setQueryParams={setQueryParams}
      queryParams={queryParams}
      CollectionCategory={CollectionCategory}
      setCollectionCategory={setCollectionCategory}
    />
  );
}

export default UploadCollectionImage;
