import "./AlsallabProductCard.css";

import { Grid, Typography } from "@material-ui/core";

import PrimaryButton from "../../../Atoms/Buttons/Primary/PrimaryButton";
import { images } from "../../../../Assets/images/IconHelper";
import { withTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";

const AlsallabProductCard = ({
  t,
  title,
  id,
  currency,
  price,
  unit,
  discount,
  image,
  taxonId,
}) => {
  const history = useHistory();
  return (
    <Grid container className="Alsallab-product-card">
      <Grid item xs={12}>
        <img
          src={image ? image : images.CollectionImage}
          alt="product"
          style={{
            height: "300px",
            objectFit: "cover",
            width: "100%",
            cursor: "pointer",
          }}
          onClick={() =>
            history.push(
              `/collection/${id}${taxonId ? `?taxon=${taxonId}` : ""}`
            )
          }
        />
      </Grid>
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={9}>
            <Typography
              style={{ color: "#4a4a48", fontSize: "14px", cursor: "pointer" }}
              onClick={() =>
                history.push(
                  `/collection/${id}${taxonId ? `?taxon=${taxonId}` : ""}`
                )
              }
            >
              {title}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <div className="Alsallab-product-card-flex">
              {discount > 0 && (
                <div className="Alsallab-product-card-badge">
                  {discount}% OFF
                </div>
              )}
            </div>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Typography
          variant="h6"
          style={{ color: "#202428", cursor: "pointer" }}
          onClick={() =>
            history.push(
              `/collection/${id}${taxonId ? `?taxon=${taxonId}` : ""}`
            )
          }
        >
          {title}
        </Typography>
      </Grid>
      {/* <Grid item xs={12}>
        <Typography className="Alsallab-product-card-price ">
          <span style={{ fontSize: "16px" }}>From</span> {price}{" "}
          <span style={{ fontSize: "16px" }}>EGP / SQM</span>
        </Typography>
      </Grid> */}
      <Grid item xs={12}>
        <Link to={`/collection/${id}`}>
          <PrimaryButton
            text={t("Details")}
            classBtn="outline Alsallab-product-card-button"
          />
        </Link>
      </Grid>
    </Grid>
  );
};

export default withTranslation()(AlsallabProductCard);
