import "./HomeCollectionsCarousel.css";

import { icons, images } from "../../../../Assets/images/IconHelper";

import { Carousel } from "react-responsive-carousel";
import IconOverlay from "../../../Atoms/IconOverlay/IconOverlay";
import { Link } from "react-router-dom";
import PrimaryButton from "../../../Atoms/Buttons/Primary/PrimaryButton";
import { Typography } from "@material-ui/core";
import { useState } from "react";
import { useContext } from "react";
import { systemContext } from "../../../../Contexts/systemContext/systemContext";

const HomeCollectionsCarousel = ({ bathroomCollection }) => {
  const { systemConfig } = useContext(systemContext);
  const [configurableProps, setConfigurableProps] = useState({
    showArrows: true,
    showStatus: false,
    showIndicators: false,
    infiniteLoop: true,
    showThumbs: true,
    useKeyboardArrows: true,
    stopOnHover: true,
    swipeable: true,
    dynamicHeight: false,
    emulateTouch: false,
    autoFocus: false,
    thumbWidth: 100,
    selectedItem: 0,
    interval: 2000,
    transitionTime: 500,
    swipeScrollTolerance: 5,
    ariaLabel: "ariaLabel",
  });
  const arrowStyles = {
    position: "absolute",
    zIndex: 2,
    top: "calc(30% - 15px)",
    width: 40,
    height: 30,
    cursor: "pointer",
  };
  return (
    <>
      <Typography
        align="center"
        variant="h4"
        className="home-collections-carousel-title"
        style={{ marginTop: "100px", marginBottom: "20px" }}
      >
        Bathroom collections
      </Typography>
      <div className="see-all-btn">
        <Link
          to={
            systemConfig && systemConfig["System Configurations"]["Collections"]
              ? `/collections`
              : "/shopping"
          }
        >
          <PrimaryButton
            classBtn="btn-secondry btn-rounded btn-small"
            text={"Explore more"}
          />
        </Link>
      </div>
      <Carousel
        centerMode
        centerSlidePercentage={35}
        {...configurableProps}
        renderArrowPrev={(onClickHandler, hasPrev, label) =>
          hasPrev && (
            <div>
              <PrimaryButton
                style={{ ...arrowStyles, left: 0 }}
                classBtn=""
                click={onClickHandler}
                startIcon={
                  <IconOverlay
                    src={icons.arrowLeftIcon}
                    iconStyle="icon-primary"
                  />
                }
              />
            </div>
          )
        }
        renderArrowNext={(onClickHandler, hasNext, label) =>
          hasNext && (
            <div>
              <PrimaryButton
                style={{ ...arrowStyles, right: 0 }}
                classBtn=""
                click={onClickHandler}
                startIcon={
                  <IconOverlay
                    src={icons.arrowRightIcon}
                    iconStyle="icon-primary"
                  />
                }
              />
            </div>
          )
        }
      >
        {bathroomCollection?.map((collection) => {
          return (
            <Link to={`/collection/${collection?.id}`}>
              <div
                style={{
                  backgroundImage: `url(${
                    collection.image
                      ? collection.image
                      : images.homeCollectionPlaceHolder
                  })`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  cursor: "pointer",
                }}
                className="home-collections-carousel-item"
              >
                <Typography className="home-collections-card-title">
                  {collection.name}
                </Typography>
              </div>
            </Link>
          );
        })}
        {/* <div
          style={{
            backgroundImage: `url(${images.homeCollectionPlaceHolder})`,
          }}
          className="home-collections-carousel-item"
        >
          <Typography className="home-collections-card-title">
            Collection
          </Typography>
        </div>
        <div
          style={{
            backgroundImage: `url(${images.homeCollectionPlaceHolder})`,
          }}
          className="home-collections-carousel-item"
        >
          <Typography className="home-collections-card-title">
            Collection
          </Typography>
        </div>
        <div
          style={{
            backgroundImage: `url(${images.homeCollectionPlaceHolder})`,
          }}
          className="home-collections-carousel-item"
        >
          <Typography className="home-collections-card-title">
            Collection
          </Typography>
        </div>
        <div
          style={{
            backgroundImage: `url(${images.homeCollectionPlaceHolder})`,
          }}
          className="home-collections-carousel-item"
        >
          <Typography className="home-collections-card-title">
            Collection
          </Typography>
        </div> */}
      </Carousel>
    </>
  );
};

export default HomeCollectionsCarousel;
