import "./HomeFeaturesBanner.css";

import { Grid, Typography } from "@material-ui/core";

import FeatureCard from "../../Molecules/FeatureCard/FeatureCard";

const HomeFeaturesBanner = ({
  features,
  backgroundImage,
  title,
  backgroundColor,
}) => {
  return (
    <Grid
      justifyContent="center"
      container
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: "cover",
        backgroundColor: backgroundColor,
      }}
      className="home-features-banner-container esolar-home-template"
    >
      <Grid
        container
        xs={12}
        direction="row"
        justifyContent="center"
        item
        className="home-features-banner-inner-container "
      >
        <Grid item xs={12}>
          {" "}
          <Typography className="home-features-banner-title" align="center">
            {title}
          </Typography>
        </Grid>

        <Grid
          item
          xs={10}
          container
          direction="row"
          className="home-features-banner-card-container"
        >
          {features?.map((feature) => {
            return (
              <Grid item xs={12} sm={4}>
                <FeatureCard
                  icon={feature.icon}
                  title={feature.title}
                  description={
                    feature.description
                      ? feature.description
                      : "We Provide all agricultural products, multiple options of different sesons in whole the year"
                  }
                />
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default HomeFeaturesBanner;
