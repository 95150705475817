import "./CustomerFeedbackCard.css";

import { Grid, Typography } from "@material-ui/core";
import { icons, images } from "../../../Assets/images/IconHelper";

import IconOverlay from "../../Atoms/IconOverlay/IconOverlay";

const CustomerFeedbackCard = ({ content, user, userImage }) => {
  return (
    <Grid
      item
      xs={12}
      sm={3}
      xl={3}
      style={{ backgroundColor: "#ffffff" }}
      className="feedback-card"
    >
      <Typography className="feedback-card-content">{content}</Typography>
      <Grid container justifyContent="center" style={{ marginTop: 20 }}>
        <IconOverlay src={icons.oval} iconStyle="icon-primary" />
        <Typography align="center" className="feedback-card-user" noWrap>
          {user}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default CustomerFeedbackCard;
