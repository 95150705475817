import { Grid } from "@material-ui/core";
import React from "react";
import Title from "../../../../Components/Atoms/Title/Title";
import { withTranslation } from "react-i18next";
import RewardCard from "../../../../Components/Organisms/Cards/RewardCard/RewardCard";

function DanRewardTemplate(props) {
  const { t } = props;

  return (
    <Grid container>
      <Title text={t("Profile.Rewards")} />
      {/* <Title text={t("Profile.DanRewards")} /> */}
      <Grid item xs={12}>
        <RewardCard />
      </Grid>
    </Grid>
  );
}

export default withTranslation()(DanRewardTemplate);
