import React from 'react'
import { withTranslation } from 'react-i18next';
import { images } from '../../../Assets/images/IconHelper';
import BlogTabs from '../../../Components/Organisms/BlogTabs/BlogTabs';
import './DanKitchen.css';

function DanKitchenTemplate({ t, mobileView }) {
    return (
        <div>
            <div className="kitchen-header" style={{ backgroundImage: `url(${images.danKitchen})` }}>
                <p className="kitchen-header-lead">{t('explore')}</p>
                <p className="kitchen-header-title">{t('footer.Dan Kitchen')}</p>
            </div>

            <div>
                <BlogTabs mobileView={mobileView} />
            </div>
        </div>
    )
}

export default withTranslation()(DanKitchenTemplate)
