import * as URL from "../../URLs";

import { axiosFn, handleError } from "../../index";

//,variants.images,billing_address,shipping_address,user,payments,shipments,promotions
export async function getOrdersList() {
  return await axiosFn("get", `${URL.ORDERS}`)
    .then((res) => {
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}
export async function getOrdersListAPI() {
  let { err, res } = await getOrdersList();

  if (res) {
    return res;
  }

  if (err) {
    return [];
  }
}
