import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import './ProductItemCard.css';


const ProductItemCard = (props) => {

    const { t } = props;
    return (
        <div className="productItem">
            <Link to={`/product/${props.id}`}>
                <div className="productItem-img-cont">
                    <div className="productItem-card-img" style={{ backgroundImage: `url(${props.img})` }}>
                    </div>
                </div>

                <div className="productItem-title">
                    <span>{props.title}</span>
                </div>

                {props.cut && <div className="productItem-text">
                    <span>{t("product details.cut type")}:</span>
                    <span className="productItem-textvalue">{props.cut}</span>
                </div>}
                
                <div className="productItem-text">
                    <span>{t("product details.country")}:</span>
                    <span className="productItem-textvalue">{props.country}</span>
                </div>
            </Link>
        </div>
    );
}

export default withTranslation()(ProductItemCard);