import { Collapse, Grid, Typography } from "@mui/material";
import {
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
} from "@mui/lab";

import { Box } from "@mui/system";
import IconOverlay from "../../Atoms/IconOverlay/IconOverlay";
import UploadedFile from "../../Molecules/UploadedFile/UploadedFile";
import { icons } from "../../../Assets/images/IconHelper";
import { useState } from "react";

const InstallmentsItem = ({ installement, proposalStatus }) => {
  const date = new Date(installement?.datetime);
  const [show, setShow] = useState(false);

  return (
    <TimelineItem>
      <TimelineSeparator>
        {proposalStatus === "accepted" && installement?.status === "pending" ? (
          <IconOverlay
            src={icons.pendingInstallementIcon}
            style={{ marginTop: 10 }}
          />
        ) : (
          <TimelineDot
            color="grey"
            variant="outlined"
            sx={{
              borderWidth: "4px",
              padding: "2.5px",
              borderColor: "#6f7a8a",
            }}
          ></TimelineDot>
        )}

        <TimelineConnector sx={{ backgroundColor: "#a3abb5" }} />
      </TimelineSeparator>
      <TimelineContent sx={{ py: "4px", px: 2, transition: "height 2s" }}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Typography
            onClick={() => setShow(!show)}
            sx={{
              cursor: "pointer",
              fontSize: "19.6px",
              fontWeight: 500,
              color: "#212934",
            }}
          >
            {installement?.title}
          </Typography>

          <IconOverlay
            src={show ? icons.installmentArrowUp : icons.installmentArrowDown}
            onClick={() => setShow(!show)}
            style={{ cursor: "pointer" }}
          />
        </div>
        <Collapse in={show}>
          <div>
            {show ? (
              <div>
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  sx={{ mb: "14px" }}
                >
                  <Grid item xs={12}>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: 500,
                        color: "#303030",
                      }}
                    >
                      Description
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: 300,
                        color: "#383838",
                      }}
                    >
                      {installement?.description
                        ? installement?.description
                        : installement?.details}
                    </Typography>
                  </Grid>
                </Grid>
                <Box sx={{ display: "flex", mt: "16px" }}>
                  {installement?.documents?.map((document) => {
                    return (
                      <UploadedFile
                        fileType={document?.extension}
                        fileName={document?.url}
                        src={document?.url}
                      />
                    );
                  })}
                </Box>
                <Grid item xs={12} sx={{ marginTop: 2 }}>
                  <Typography
                    sx={{ fontSize: "14px", fontWeight: 500, color: "#303030" }}
                  >
                    Date and Time
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography sx={{ fontSize: "16px", color: "#303030" }}>
                    {installement?.due_date}
                  </Typography>
                </Grid>
                <Box sx={{ marginTop: 2 }}>
                  <Typography
                    sx={{ fontSize: 16, color: "#303030", fontWeight: 500 }}
                  >
                    Estimated Price
                  </Typography>
                  <Typography
                    sx={{ fontSize: 16, color: "#303030", fontWeight: 500 }}
                  >
                    {installement?.currency
                      ? installement?.currency + " "
                      : installement?.currency_type + " "}{" "}
                    {installement?.estimated_price}
                  </Typography>
                </Box>
              </div>
            ) : (
              <></>
            )}
          </div>
        </Collapse>
      </TimelineContent>
    </TimelineItem>
  );
};

export default InstallmentsItem;
