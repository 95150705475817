import { Box, Container } from "@mui/system";
import { CircularProgress, Collapse, Grid, Typography } from "@mui/material";
import { NavLink, useHistory } from "react-router-dom";
import { useContext, useState } from "react";

import DeleteRequestModal from "../../../../Components/Organisms/Modals/DeleteRequestModal/DeleteRequestModal";
import RequestDetailsBody from "../../../../Components/Organisms/RequestDetailsBody/RequestDetailsBody";
import RequestDetailsHeader from "../../../../Components/Molecules/RequestDetailsHeader/RequestDetailsHeader";

function RequestDetailsTemplate({
  id,
  isProductRequestLoading,
  productRequest,
  deleteHandler,
  acceptHandler,
  rejectHandler,
  handleModal,
  modalOpen,
  systemConfig,
}) {
  return (
    <Container component="main" maxWidth="lg">
      <RequestDetailsHeader
        id={id}
        deleteHandler={deleteHandler}
        handleModal={handleModal}
      />
      {isProductRequestLoading ? (
        <div className="progress-indicator">
          <CircularProgress style={{ color: "var(--secondary-color)" }} />
        </div>
      ) : (
        <RequestDetailsBody
          productRequest={productRequest}
          acceptHandler={acceptHandler}
          rejectHandler={rejectHandler}
          id={id}
          systemConfig={systemConfig}
        />
      )}
      <DeleteRequestModal
        open={modalOpen}
        id={id}
        deleteHandler={deleteHandler}
        handleClose={handleModal}
      />
    </Container>
  );
}

export default RequestDetailsTemplate;
