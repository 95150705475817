import "./OrderSummary.css";

import { CircularProgress, Grid } from "@material-ui/core";

import PrimaryButton from "../../Atoms/Buttons/Primary/PrimaryButton";
import SearchBox from "../../Molecules/SearchBox/SearchBox";
import { useHistory } from "react-router";
import { useState } from "react";
import { withTranslation } from "react-i18next";

const OrderSummary = (props) => {
  const {
    t,
    placeOrderDisabled,
    removeCoupon,
    promoCode,
    isPlaceOrderLoading,
  } = props;

  const history = useHistory();

  const [promocode, setpromo] = useState("");

  return (
    <Grid container className="order-summary">
      <Grid item xs={12}>
        <p className="order-summary-title">{t("order.Order Summery")}</p>
      </Grid>

      {props.promo !== "0.0" ? (
        <>
          <Grid item xs={12}>
            <div className="order-summary-text">
              <p className="order-summary-gray">
                Promo Code Applied: {promoCode}
              </p>
              <p
                style={{ cursor: "pointer" }}
                className="order-summary-bold order-summary-dark"
                onClick={() => removeCoupon(promoCode)}
              >
                Remove
              </p>
            </div>
          </Grid>
        </>
      ) : (
        <Grid item xs={12}>
          <div className="order-summary-fields">
            <SearchBox
              BGcolor={"buttonRedColor"}
              text={t("general.apply")}
              placeholder={t("order summary.Enter a Promocode")}
              onchange={(value) => setpromo(value)}
              submitFn={() => props.handleApplyCoupon(promocode)}
            />
          </div>
        </Grid>
      )}

      <Grid item xs={12}>
        <div div className="order-summary-fields">
          <SearchBox
            BGcolor={"buttonRedColor"}
            text={t("general.add")}
            placeholder={t("order summary.Add Dan Rewards Points")}
            onchange={(email) => console.log({ email })}
            submitFn={() => console.log("add")}
          />
        </div>
      </Grid>

      <Grid item xs={12}>
        <div className="order-summary-text">
          <p className="order-summary-gray">{`${t("order.Subtotal")} ( ${
            props.itemNumber
          } ${t("order.items")} )`}</p>
          <p className="order-summary-bold order-summary-dark">{`${props.currency} ${props.subtotal}`}</p>
        </div>
      </Grid>

      <Grid item xs={12}>
        <div className="order-summary-text">
          <p className="order-summary-gray">{`${t("Promo")}`}</p>
          <p className="order-summary-bold order-summary-dark">{`${props.currency} ${props.promo}`}</p>
        </div>
      </Grid>

      <Grid item xs={12}>
        <div className="order-summary-text order-summary-underline">
          <p className="order-summary-gray">+ {t("order.Delivery")}:</p>
          <p className="order-summary-bold order-summary-dark">{`${props.currency} ${props.delivery}`}</p>
        </div>
      </Grid>

      <Grid item xs={12}>
        <div className="order-summary-text">
          <p className="order-summary-dark">{t("order.Total")}</p>
          <p className="order-summary-bold">{`${props.currency} ${props.total}`}</p>
        </div>
      </Grid>

      <Grid item xs={12}>
        {props.btn === "placeOrder" ? (
          isPlaceOrderLoading ? (
            <div className="progress-indicator">
              <CircularProgress style={{ color: "var(--secondary-color)" }} />
            </div>
          ) : (
            <div
              style={{ cursor: placeOrderDisabled ? "not-allowed" : "pointer" }}
            >
              <PrimaryButton
                disabled={placeOrderDisabled}
                classBtn="primary order-summary-button buttonRedColor"
                text={t("checkout.place order")}
                click={() => {
                  props.handlePlaceOrder();
                }}
              />
            </div>
          )
        ) : (
          <PrimaryButton
            classBtn="primary order-summary-button buttonRedColor"
            text={t("order summary.proceed to checkout")}
            click={() => history.push("/checkout")}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default withTranslation()(OrderSummary);
