import * as React from "react";
import { useState, useRef, useEffect } from "react";
import * as yup from "yup";
import { useFormik } from "formik";

import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  Typography,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";

import PrimaryButton from "../../../../Components/Atoms/Buttons/Primary/PrimaryButton";
import { images } from "../../../../Assets/images/IconHelper";
import { uploadToS3API } from "../../../../Contexts/API/S3/uploadToS3";
import IconOverlay from "../../../../Components/Atoms/IconOverlay/IconOverlay";

const VendorPhase1 = ({
  proposal,
  setProposal,
  handleChange,
  stepsValidation,
  setStepsValidation,
  vendorTypes,
  handleSubmit,
}) => {
  const [image, setImage] = useState(null);
  // VALIDATION SCHEMA
  const validationSchema = yup.object({
    company_name: yup.string().required("Company Name is required"),
    vendor_type_ids: yup.array().min(1).required("Type of vendor is required"),
    commercial_number: yup.string().required("Commercial number  is required"),
    tax_id: yup.string().required("Tax ID is required"),
  });

  //FORMIK
  const formik = useFormik({
    initialValues: {
      company_logo: proposal?.company_logo,
      company_name: proposal?.company_name,
      vendor_type_ids: proposal?.vendor_type_ids,
      commercial_number: proposal?.commercial_number,
      tax_id: proposal?.tax_id,
      company_description: proposal?.company_description,
    },
    validationSchema: validationSchema,
    onSubmit: () => {
      // handleChange(2);
      handleSubmit(proposal);
    },
  });

  // validating steps
  useEffect(() => {
    if (
      formik.isValid &&
      formik.values.company_name &&
      formik.values.vendor_type_ids &&
      formik.values.commercial_number &&
      formik.values.tax_id
    ) {
      setStepsValidation([
        ...stepsValidation.filter((item) => item.number != 1),
        { number: 1, condition: true },
      ]);
    } else {
      setStepsValidation([
        { number: 1, condition: false },
        { number: 2, condition: false },
      ]);
    }
  }, [formik.isValid]);

  // details limit
  const [textAreaCharCount, setTextAreaCharCount] = useState(
    proposal?.company_description?.length
  );
  useEffect(() => {
    setTextAreaCharCount(proposal?.company_description?.length);
  }, [proposal?.company_description]);

  // Logo file
  const inputLogo = useRef(null);
  const onLogoChange = async (event) => {
    if (event.target.files && event.target.files[0]) {
      const arrayOfExtensions = [];
      const files = event.target.files;
      for (let [key, value] of Object.entries(files)) {
        arrayOfExtensions.push({
          extension: value.name.substring(value.name.indexOf(".") + 1),
        });
      }

      const { res } = await uploadToS3API(files, arrayOfExtensions);
      setProposal({ ...proposal, company_logo: res?.at(0)?.file_url });
    }
  };

  return (
    <Box component="form" sx={{ mt: 2, width: "100%" }}>
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          sx={{ marginTop: "20px", display: "flex", alignItems: "center" }}
        >
          {proposal?.company_logo == "" ? (
            <img
              src={images.inputImage}
              width="60px"
              height="fit-content"
              alt="Logo"
              style={{
                cursor: "pointer",
              }}
              onClick={() => inputLogo.current.click()}
            />
          ) : (
            <img
              src={proposal?.company_logo}
              width="60px"
              height="fit-content"
              alt="Logo"
              style={{
                cursor: "pointer",
              }}
              onClick={() => inputLogo.current.click()}
            />
          )}

          <Typography
            sx={{
              color: "#000000",
              mb: "12px",
              fontWeight: "600",
              fontSize: "14px",
              ml: "10px",
              pt: "10px",
              cursor: "pointer",
            }}
            onClick={() => inputLogo.current.click()}
          >
            <input
              type="file"
              id="file"
              ref={inputLogo}
              style={{ display: "none" }}
              onChange={(event) => {
                onLogoChange(event, setImage, image);
              }}
            />
            Upload company logo
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ marginTop: "20px" }}>
          <Typography sx={{ marginBottom: "10px", fontWeight: "600" }}>
            Company Name
          </Typography>
          <TextField
            fullWidth
            type="text"
            id="company_name"
            name="company_name"
            placeholder="Write your company name"
            value={formik.values?.company_name ?? proposal?.company_name}
            onChange={(e) => {
              setProposal({ ...proposal, company_name: e.target.value });
              formik.setFieldValue("company_name", e.target.value);
            }}
            error={
              formik.touched.company_name && Boolean(formik.errors.company_name)
            }
            helperText={
              formik.touched.company_name && formik.errors.company_name
            }
          />
        </Grid>
        <Grid item xs={12} sx={{ marginTop: "20px" }}>
          <Typography sx={{ fontWeight: "600" }}>
            Type of vendor ( select one or more)
          </Typography>

          {formik.touched.vendor_type_ids && formik.errors.vendor_type_ids && (
            <Typography
              sx={{
                marginBottom: "10px",
                color: "#d32f2f",
                fontWeight: "400",
                fontSize: " 0.75rem",
                lineHeight: "1.66",
                letterSpacing: "0.03333em",
                textAlign: "left",
              }}
            >
              {formik.errors.vendor_type_ids}
            </Typography>
          )}

          <FormControl
            component="fieldset"
            style={{ display: "flex", flexDirection: "row" }}
          >
            {vendorTypes &&
              vendorTypes?.map((type) => (
                <FormControlLabel
                  label={type?.name}
                  control={
                    <Checkbox
                      value={type?.id}
                      checked={proposal?.vendor_type_ids?.find(
                        (item) => item == type?.id
                      )}
                      onChange={(e) => {
                        if (
                          proposal?.vendor_type_ids?.find(
                            (item) => item == type?.id
                          )
                        ) {
                          setProposal({
                            ...proposal,
                            vendor_type_ids: proposal?.vendor_type_ids?.filter(
                              (item) => item != type?.id
                            ),
                          });
                          formik.setFieldValue(
                            "vendor_type_ids",
                            proposal?.vendor_type_ids?.filter(
                              (item) => item != type?.id
                            )
                          );
                        } else {
                          setProposal({
                            ...proposal,
                            vendor_type_ids: [
                              ...proposal?.vendor_type_ids,
                              parseInt(e.target.value),
                            ],
                          });
                          formik.setFieldValue("vendor_type_ids", [
                            ...proposal?.vendor_type_ids,
                            parseInt(e.target.value),
                          ]);
                        }
                      }}
                      name={type?.name}
                    />
                  }
                />
              ))}
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} sx={{ marginTop: "20px" }}>
          <Typography sx={{ marginBottom: "10px", fontWeight: "600" }}>
            Commercial number
          </Typography>
          <TextField
            fullWidth
            type="text"
            id="commercial_number"
            name="commercial_number"
            value={
              formik.values?.commercial_number ?? proposal?.commercial_number
            }
            onChange={(e) => {
              setProposal({ ...proposal, commercial_number: e.target.value });
              formik.setFieldValue("commercial_number", e.target.value);
            }}
            error={
              formik.touched.commercial_number &&
              Boolean(formik.errors.commercial_number)
            }
            helperText={
              formik.touched.commercial_number &&
              formik.errors.commercial_number
            }
          />
        </Grid>
        <Grid item xs={12} sm={6} sx={{ marginTop: "20px" }}>
          <Typography sx={{ marginBottom: "10px", fontWeight: "600" }}>
            Tax ID
          </Typography>
          <TextField
            fullWidth
            type="text"
            id="tax_id"
            name="tax_id"
            value={formik.values?.tax_id ?? proposal?.tax_id}
            onChange={(e) => {
              setProposal({ ...proposal, tax_id: e.target.value });
              formik.setFieldValue("tax_id", e.target.value);
            }}
            error={formik.touched.tax_id && Boolean(formik.errors.tax_id)}
            helperText={formik.touched.tax_id && formik.errors.tax_id}
          />
        </Grid>
        <Grid item xs={12} sm={12} sx={{ marginTop: "10px" }}>
          <Typography sx={{ marginBottom: "10px", fontWeight: "600" }}>
            Your company description
          </Typography>
          <TextareaAutosize
            className="textarea"
            style={{
              width: "100%",
              height: "230px",
              resize: "none",
              // paddingLeft: "10px",
              paddingTop: "10px",
              borderTop:
                formik.touched.company_description &&
                Boolean(formik.errors.company_description)
                  ? "1px solid #d32f2f"
                  : "1px solid black",
              borderLeft:
                formik.touched.company_description &&
                Boolean(formik.errors.company_description)
                  ? "1px solid #d32f2f"
                  : "1px solid black",
              borderRight:
                formik.touched.company_description &&
                Boolean(formik.errors.company_description)
                  ? "1px solid #d32f2f"
                  : "1px solid black",
              borderBottom: "1px white solid",
            }}
            maxLength="500"
            aria-label="empty textarea details"
            placeholder="Write short pharagraph about your campany"
            value={
              formik.values?.company_description ??
              proposal?.company_description
            }
            onChange={(e) => {
              setProposal({ ...proposal, company_description: e.target.value });
              formik.setFieldValue("company_description", e.target.value);
            }}
          />

          {/* Word count inside the text area */}
          <Box
            sx={{
              marginTop: "-6px",
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
              borderRight:
                formik.touched.company_description &&
                Boolean(formik.errors.company_description)
                  ? "1px solid #d32f2f"
                  : "1px solid black",
              borderLeft:
                formik.touched.company_description &&
                Boolean(formik.errors.company_description)
                  ? "1px solid #d32f2f"
                  : "1px solid black",
              borderBottom:
                formik.touched.company_description &&
                Boolean(formik.errors.company_description)
                  ? "1px solid #d32f2f"
                  : "1px solid black",
            }}
          >
            <Typography
              sx={{
                mr: "25px",
                color: "#4a4a48",
                mb: "10px",
              }}
            >
              {textAreaCharCount}/500
            </Typography>
          </Box>

          {formik.touched.company_description &&
            formik.errors.company_description && (
              <Typography
                sx={{
                  color: "#d32f2f",
                  fontWeight: "400",
                  fontSize: " 0.75rem",
                  lineHeight: "1.66",
                  letterSpacing: "0.03333em",
                  textAlign: "left",
                  marginTop: "3px",
                  marginRight: "14px",
                  marginBottom: 0,
                  marginLeft: "14px",
                }}
              >
                {formik.errors.company_description}
              </Typography>
            )}
        </Grid>
      </Grid>

      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          marginTop: "30px",
        }}
      >
        {/* <PrimaryButton
          text={"Next"}
          classBtn="primary proposal"
          style={{
            maxWidth: "320px",
          }}
          click={formik.handleSubmit}
        /> */}
        <PrimaryButton
          text={"Submit"}
          classBtn="primary proposal"
          style={{
            maxWidth: "320px",
          }}
          click={formik.handleSubmit}
          // click={() => handleSubmit(proposal)}
        />
      </Box>
    </Box>
  );
};
export default VendorPhase1;
