import { useContext, useEffect, useState } from "react";

import { useCollection } from "../../../Hooks/useCollection/useCollection";
import { useCategories } from "../../../Hooks/useCategories/useCategories";

import { CategoriesContext } from "../../../Contexts/contextCategories/categoriesContext";

import SallabHomeTemplate from "../../../Templates/Home/SallabHome/SallabHome.template";
import { useAdBanners } from "../../../Hooks/useAdBanners/useAdBanners";
import { getFeaturedBannersAPI } from "../../../Contexts/API/Home/FeaturedBanners/getFeaturedBanners";

const SallabHomePage = () => {
  const categories = useContext(CategoriesContext);
  const { loadTags, tags } = useCategories();
  const { loadAdBanners, adBanners } = useAdBanners();
  const [FeatureBanners, setFeatureBanners] = useState([]);
  const {
    loadBestSellerCollection,
    GetTaxonCollections,
    bestSellerCollection,
    collectionData,
  } = useCollection();

  useEffect(() => {
    loadAdBanners();
    loadBestSellerCollection();
    GetTaxonCollections(27);
    loadTags();
  }, []);

  useEffect(() => {
    getFeaturedBannersAPI().then((res) =>
      setFeatureBanners(res?.res?.featured_banners)
    );
  }, []);

  const [state, setState] = useState({
    mobileView: false,
  });

  const { mobileView } = state;

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 600
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };

    setResponsiveness();
    window.addEventListener("resize", () => setResponsiveness());

    return () => {
      window.removeEventListener("resize", () => setResponsiveness());
    };
  }, [window.innerWidth]);

  return (
    <SallabHomeTemplate
      categories={categories?.categories?.data}
      bestSellerCollection={bestSellerCollection}
      bathroomCollection={collectionData}
      mobileView={mobileView}
      tags={tags}
      FeatureBanners={FeatureBanners}
    />
  );
};

export default SallabHomePage;
