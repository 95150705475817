import * as React from "react";

import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";

import { images } from "../../../Assets/images/IconHelper";

import PrimaryButton from "../../../Components/Atoms/Buttons/Primary/PrimaryButton";
import { Link } from "react-router-dom";

function VendorConfirmTemplate() {
  return (
    <Container component="main" maxWidth="sm">
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <img
          src={images.confirmation}
          style={{ width: "160px", marginTop: "100px" }}
        />
      </Box>
      <Typography
        variant="h2"
        sx={{
          fontSize: "28px",
          fontWeight: "900",
          marginTop: "50px",
          textAlign: "center",
        }}
      >
        Your account was submitted successfully and being reviewed , we will
        inform you once the review is done.
      </Typography>
      <Link
        to="/"
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          marginTop: "30px",
        }}
      >
        <PrimaryButton
          text={"Go to home page"}
          classBtn="primary proposal"
          style={{
            maxWidth: "320px",
          }}
        />
      </Link>
    </Container>
  );
}

export default VendorConfirmTemplate;
