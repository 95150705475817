import { Image, LocalConvenienceStoreOutlined } from "@material-ui/icons";
import { Typography } from "@mui/material";
import React, { useContext, useRef, useState } from "react";
import { useEffect } from "react";
import { withTranslation } from "react-i18next";
import { images } from "../../../Assets/images/IconHelper";
import PrimaryButton from "../../../Components/Atoms/Buttons/Primary/PrimaryButton";
import DropDownSelect from "../../../Components/Atoms/DropDownSelect/DropDownSelect";
import TextFieldInput from "../../../Components/Atoms/Inputs/TextField/TextFieldInput";
import MultiDropDownSelect from "../../../Components/Atoms/MultiDropDownSelect/MultiDropDownSelect";
import { uploadToS3API } from "../../../Contexts/API/S3/uploadToS3";
import { CategoriesContext } from "../../../Contexts/contextCategories/categoriesContext";
import { useCategories } from "../../../Hooks/useCategories/useCategories";

function UploadCollectionImageTemplate({
  t,
  ID,
  updateNow,
  collectionDataDetails,
  edit,
  AddNewCollection,
  categories,
  setQueryParams,
  queryParams,
  subCategories,
  CollectionCategory,
  setCollectionCategory,
}) {
  const { loadTags, tags } = useCategories();

  const [CollectionARName, setCollectionARName] = useState("");
  const [CollectionEGName, setCollectionEGName] = useState("");
  const [CollectionDesc, setCollectionDesc] = useState("");
  const [CollectionImage, setCollectionImage] = useState("");
  const [CollectionTags, setCollectionTags] = useState([]);
  const [CollectionIDS, setCollectionIDS] = useState([]);
  const [CarouselImages, setCarouselImages] = useState([]);
  const [DeletedImages, setDeletedImages] = useState([]);
  const [CollectionSubCategory, setCollectionSubCategory] = useState("");

  useEffect(() => {
    loadTags();
  }, []);

  useEffect(() => {
    if (edit) {
      setCollectionIDS(
        collectionDataDetails?.categories
          ?.map((item) =>
            item?.collection_subcategories?.map((sub) => ({
              category: item,
              subCategory: sub,
            }))
          )
          .flat(1)
      );
      setCollectionARName(collectionDataDetails?.name_ar);
      setCollectionEGName(collectionDataDetails?.name_en);
      setCollectionDesc(collectionDataDetails?.description);
      setCollectionImage({ file_url: collectionDataDetails?.image });
      setCollectionTags(collectionDataDetails?.tag_ids ?? []);
      setCarouselImages(
        collectionDataDetails?.documents?.map((item) => ({
          file_url: item?.url,
          id: item?.id,
        })) ?? []
      );
    }
  }, [collectionDataDetails]);

  //REFS
  const inputMainImage = useRef(null);
  const inputCarousel = useRef(null);

  const onCollectionChange = async (event) => {
    if (event.target.files && event.target.files[0]) {
      const arrayOfExtensions = [];
      const files = event.target.files;
      for (let [key, value] of Object.entries(files)) {
        arrayOfExtensions.push({
          extension: value.name.substring(value.name.indexOf(".") + 1),
        });
      }
      const { res } = await uploadToS3API(files, arrayOfExtensions);
      setCollectionImage(res?.at(0));
    }
  };

  const onCarouselChange = async (event) => {
    if (event.target.files && event.target.files[0]) {
      const arrayOfExtensions = [];
      const files = event.target.files;
      for (let [key, value] of Object.entries(files)) {
        arrayOfExtensions.push({
          extension: value.name.substring(value.name.indexOf(".") + 1),
        });
      }
      const { res } = await uploadToS3API(files, arrayOfExtensions);
      setCarouselImages([...CarouselImages, ...res]);
    }
  };

  let cat = CollectionIDS?.map((item) => item?.category?.id);
  let SubCat = CollectionIDS?.map((item) => item?.subCategory?.id)?.filter(
    (item) => item
  );

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <Typography align="left" variant="h5" className="titleWithoutMarginTop">
          {t("Collection English Name")}
        </Typography>
        <TextFieldInput
          error={false}
          value={CollectionEGName}
          onchange={(e) => setCollectionEGName(e)}
          placeholder={"Collection English Name"}
          helperText={""}
        />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <Typography align="left" variant="h5" className="titleWithoutMarginTop">
          {t("Collection Arabic Name")}
        </Typography>
        <TextFieldInput
          error={false}
          value={CollectionARName}
          onchange={(e) => setCollectionARName(e)}
          placeholder={"Collection Arabic Name"}
          helperText={""}
        />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <Typography align="left" variant="h5" className="titleWithoutMarginTop">
          {t("Collection Description")}
        </Typography>
        <TextFieldInput
          error={false}
          value={CollectionDesc}
          onchange={(e) => setCollectionDesc(e)}
          placeholder={"Collection Description"}
          helperText={""}
        />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <Typography align="left" variant="h5" className="titleWithoutMarginTop">
          {t("Collection Category & SubCategory")}
        </Typography>

        {CollectionIDS?.map((item, index) => (
          <p>
            <span style={{ fontWeight: 600 }}>{index + 1} - Category:</span>{" "}
            {item?.category?.name}
            {item?.subCategory && (
              <span>
                {" "}
                , <span style={{ fontWeight: 600 }}>Subcategory:</span>{" "}
                {item?.subCategory?.name}
              </span>
            )}
            {"    "}
            <span
              style={{ fontWeight: 600, color: "red", cursor: "pointer" }}
              onClick={() => {
                if (item?.subCategory)
                  setCollectionIDS(
                    CollectionIDS?.filter(
                      (pro) => pro?.subCategory?.id != item?.subCategory?.id
                    )
                  );
                else {
                  setCollectionIDS(
                    CollectionIDS?.filter(
                      (pro) => pro?.category?.id != item?.category?.id
                    )
                  );
                }
              }}
            >
              Delete
            </span>
          </p>
        ))}

        <DropDownSelect
          items={categories}
          value={CollectionCategory}
          handleChange={(value) => {
            setCollectionCategory(value);
            setQueryParams({ parent_id: value?.id });
          }}
          placeholder={"Category"}
          returnIDonly={false}
        />

        {subCategories?.categories?.length ? (
          <DropDownSelect
            items={subCategories?.categories}
            value={CollectionSubCategory}
            handleChange={(value) => {
              setCollectionSubCategory(value);
            }}
            placeholder={"SubCategory"}
            returnIDonly={false}
          />
        ) : (
          ""
        )}
        <PrimaryButton
          text={t("add")}
          classBtn="primary btn-mini"
          click={() => {
            if (CollectionCategory && CollectionSubCategory)
              setCollectionIDS([
                ...CollectionIDS,
                {
                  category: CollectionCategory,
                  subCategory: CollectionSubCategory,
                },
              ]);
            else if (CollectionCategory && !CollectionSubCategory)
              setCollectionIDS([
                ...CollectionIDS,
                { category: CollectionCategory },
              ]);

            setCollectionSubCategory("");
          }}
          style={{ maxWidth: "300px", marginTop: "10px" }}
        />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <Typography align="left" variant="h5" className="titleWithoutMarginTop">
          {t("Tags")}
        </Typography>
        <DropDownSelect
          multiple={true}
          items={tags}
          value={CollectionTags}
          handleChange={(value) => {
            setCollectionTags(value);
          }}
          placeholder={"Tags"}
        />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography align="left" variant="h5" className="titleWithoutMarginTop">
          {t("Upload Collection Image")}
        </Typography>
        <img
          src={images.inputImage}
          width="60px"
          height="60px"
          style={{
            cursor: "pointer",
          }}
          onClick={() => inputMainImage.current.click()}
        />
      </div>
      <input
        type="file"
        id="file"
        ref={inputMainImage}
        style={{ display: "none" }}
        onChange={(event) => {
          onCollectionChange(event);
        }}
      />
      {CollectionImage?.file_url && (
        <img src={CollectionImage?.file_url} alt="collection" height="200px" />
      )}

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography align="left" variant="h5" className="titleWithoutMarginTop">
          {t("Upload Collection Carousel Images")}
        </Typography>
        <img
          src={images.inputImage}
          width="60px"
          height="60px"
          style={{
            cursor: "pointer",
          }}
          onClick={() => inputCarousel.current.click()}
        />
      </div>
      <input
        multiple
        type="file"
        id="file"
        ref={inputCarousel}
        style={{ display: "none" }}
        onChange={(event) => {
          onCarouselChange(event);
        }}
      />

      <div style={{ display: "flex", flexWrap: "wrap" }}>
        {edit ? (
          CarouselImages?.map((item, i) => (
            <div>
              {i != 0 ? (
                <div
                  style={{
                    color: "white",
                    backgroundColor: "black",
                    borderRadius: 10,
                    height: 23,
                    width: 23,
                    display: "flex",
                    justifyContent: "center",
                    position: "relative",
                    left: 3,
                    bottom: 5,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    if (item?.id) {
                      setDeletedImages([...DeletedImages, item?.id]);
                      setCarouselImages(
                        CarouselImages?.filter((file) => file?.id != item?.id)
                      );
                    } else
                      setCarouselImages(
                        CarouselImages?.filter(
                          (file) => file?.file_url != item?.file_url
                        )
                      );
                  }}
                >
                  <Typography>x</Typography>
                </div>
              ) : (
                ""
              )}
              <img src={item?.file_url} alt="collection" height="200px" />
            </div>
          ))
        ) : CollectionImage && CarouselImages?.length ? (
          [CollectionImage, ...CarouselImages]?.length &&
          [CollectionImage, ...CarouselImages]?.map((item, i) => (
            <div>
              {i != 0 ? (
                <div
                  style={{
                    color: "white",
                    backgroundColor: "black",
                    borderRadius: 10,
                    height: 23,
                    width: 23,
                    display: "flex",
                    justifyContent: "center",
                    position: "relative",
                    left: 3,
                    bottom: 5,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setCarouselImages(
                      CarouselImages?.filter(
                        (file) => file?.file_url != item?.file_url
                      )
                    );
                  }}
                >
                  <Typography>x</Typography>
                </div>
              ) : (
                ""
              )}
              <img src={item?.file_url} alt="collection" height="200px" />
            </div>
          ))
        ) : CarouselImages?.length ? (
          CarouselImages?.map((item) => (
            <div>
              <div
                style={{
                  color: "white",
                  backgroundColor: "black",
                  borderRadius: 10,
                  height: 23,
                  width: 23,
                  display: "flex",
                  justifyContent: "center",
                  position: "relative",
                  left: 3,
                  bottom: 5,
                  cursor: "pointer",
                }}
                onClick={() => {
                  setCarouselImages(
                    CarouselImages?.filter(
                      (file) => CollectionImage?.file_url != item?.file_url
                    )
                  );
                }}
              >
                <Typography>x</Typography>
              </div>
              <img src={item?.file_url} alt="collection" height="200px" />
            </div>
          ))
        ) : CollectionImage ? (
          <div>
            <img
              src={CollectionImage?.file_url}
              alt="collection"
              height="200px"
            />
          </div>
        ) : (
          ""
        )}
      </div>

      <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <PrimaryButton
          text={"Submit"}
          classBtn="primary proposal"
          style={{
            maxWidth: "320px",
            marginTop: "40px",
          }}
          click={() => {
            if (edit)
              updateNow({
                name_en: CollectionEGName,
                name_ar: CollectionARName,
                desc: CollectionDesc,
                image: CollectionImage,
                listOfCarousel: CarouselImages,
                tag_ids: CollectionTags,
                taxon_ids: [
                  ...cat?.filter((ids, index) => cat.indexOf(ids) == index),
                  ...SubCat?.filter(
                    (ids, index) => SubCat.indexOf(ids) == index
                  ),
                ],
                deleted_document_ids: DeletedImages,
              });
            else {
              AddNewCollection({
                name_en: CollectionEGName,
                name_ar: CollectionARName,
                desc: CollectionDesc,
                image: CollectionImage,
                listOfCarousel: [CollectionImage, ...CarouselImages],
                tag_ids: CollectionTags,
                taxon_ids: [
                  ...cat?.filter((ids, index) => cat.indexOf(ids) == index),
                  ...SubCat?.filter(
                    (ids, index) => SubCat.indexOf(ids) == index
                  ),
                ],
              });
            }
          }}
        />
      </div>
    </>
  );
}

export default withTranslation()(UploadCollectionImageTemplate);
