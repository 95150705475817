import React from 'react'
import DanRewardTemplate from '../../../Templates/Home/Profile/DanReward/DanReward.Template'

const DanReward = () => {

    return (
        <DanRewardTemplate  />
    )
}

export default DanReward
