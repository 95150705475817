import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { deleteCollectionAPI } from "../../../Contexts/API/Home/Collections/deleteCollection";
import { useCollection } from "../../../Hooks/useCollection/useCollection";
import CollectionTemplate from "../../../Templates/Home/Collection/Collection.Template";
import NewCollectionTemplate from "../../../Templates/Home/NewCollection/NewCollection.Template";

function Collection() {
  const params = useParams();
  // 0 => all
  const [taxonId, setTaxonId] = useState(
    new URLSearchParams(useLocation().search).get("taxon")
  );

  const [categorySelected, setCategorySelected] = useState();
  const [page, setPage] = useState(1);
  const {
    bestSellerCollection,
    loadBestSellerCollection,
    collectionDataDetails,
    GetCollection,
    isCollectionLoading,
    ListCollectionProducts,
    collectionProducts,
    isProductsLoading,
    hasMore,
    optionsTypes,
  } = useCollection();

  const [Ids, setIds] = useState([]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setIds(typeof value === "string" ? value.split(",") : value);
    setPage(1);
  };

  useEffect(() => {
    loadBestSellerCollection();
  }, []);

  useEffect(() => {
    setPage(1);
    setIds([]);
    GetCollection(params?.id, taxonId);
  }, [params?.id]);

  useEffect(() => {
    if (collectionDataDetails)
      if (categorySelected == null) {
        setCategorySelected(collectionDataDetails?.subcategories?.at(0)?.id);
      }
  }, [categorySelected, collectionDataDetails]);

  useEffect(() => {
    if (categorySelected)
      ListCollectionProducts(params?.id, categorySelected, page, setPage, Ids);
  }, [categorySelected, Ids]);

  const loadMore = () => {
    ListCollectionProducts(params?.id, categorySelected, page, setPage, Ids);
  };

  const DeleteCollection = async (id) => {
    const { res, err } = await deleteCollectionAPI(id);
    if (res) {
      window.location = `/collections`;
    }
    if (err) {
      toast.error(err);
    }
  };

  return (
    <NewCollectionTemplate
      CollectionID={params?.id}
      collectionData={collectionDataDetails}
      isCollectionLoading={isCollectionLoading}
      categorySelected={categorySelected}
      setCategorySelected={setCategorySelected}
      collectionProducts={collectionProducts}
      isProductsLoading={isProductsLoading}
      hasMore={hasMore}
      setPage={setPage}
      page={page}
      loadMore={loadMore}
      bestSellerCollection={bestSellerCollection}
      Ids={Ids}
      handleChange={handleChange}
      optionsTypes={optionsTypes}
      DeleteCollection={DeleteCollection}
    />
  );
}

export default Collection;
