import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";

import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/messaging";
import "firebase/compat/database";


const config = {
    
    //esolar keys

    apiKey: "AIzaSyC_n7TccswAPW05aRdKOTb8U_1M150BjZ4",
    authDomain: "esolar-13ea4.firebaseapp.com",
    databaseURL: "https://esolar-13ea4-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "esolar-13ea4",
    storageBucket: "esolar-13ea4.appspot.com",
    messagingSenderId: "269992098856",
    appId: "1:269992098856:web:6241508cfec35f36a6891d",
    measurementId: "G-3VRKE7S508"
};



const firebaseApp = firebase.initializeApp(config);
const RealTimeDatabase = firebase.database()



//----------old using firestore--------------------




const auth = getAuth()


// const sendMessage = async (userId, message) => {
//     if (message && userId) {
//         const docRef = await addDoc(collection(db, `rooms/${userId}/messages`), {
//             text: message,
//             uid: userId,
//             createdAt: serverTimestamp()
//         }).catch(() => null);

//         return docRef;
//     }
// }


export { auth,  RealTimeDatabase };