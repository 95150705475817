import * as URL from "../../URLs";

import { axiosFn, handleError } from "../../index";

export async function getBuildingTypes() {
  return await axiosFn("get", URL.BUILDING_TYPES)
    .then((res) => {
      return {
        res: res.data,
        err: null,
        status: res?.status,
      };
    })
    .catch(handleError);
}
export async function getBuildingTypesAPI() {
  let { err, res, status } = await getBuildingTypes();

  return { err, res, status };
}
