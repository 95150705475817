import "./RequestForQuotation.css";

import { Grid, Typography } from "@material-ui/core";

import PrimaryButton from "../../Atoms/Buttons/Primary/PrimaryButton";
import { useHistory } from "react-router-dom";

const RequestForQuotation = () => {
  const history = useHistory();
  return (
    <div style={{ border: "solid 1px black", borderRadius: 8, padding: 30 }}>
      <Typography
        style={{ fontSize: 38, color: "#212121", fontWeight: "bold" }}
      >
        Request For Quotation
      </Typography>
      <Typography
        style={{ fontSize: 28, color: "#212121" }}
        className="mb-20px"
      >
        Tell the suppliers what you need
      </Typography>
      <Grid container>
        <Grid item>
          <PrimaryButton
            text="Create Post"
            classBtn={"btn btn-secondry rounded-8"}
            click={() => history.push("/submit_rfq")}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default RequestForQuotation;
