import { useState } from "react";
import { icons, images } from "../../../../Assets/images/IconHelper";
import BlogHeader from "../../../../Components/Molecules/BlogHeader/BlogHeader";
import BlogImage from "../../../../Components/Molecules/BlogImage/BlogImage";
import BlogInteractions from "../../../../Components/Organisms/BlogInteractions/BlogInteractions";
import SocialModal from "../../../../Components/Organisms/Modals/SocialModal/SocialModal";
import RecipeAdditional from "../../../../Components/Organisms/Recipe/RecipeAdditional/RecipeAdditional";
import RecipeIngredients from "../../../../Components/Organisms/Recipe/RecipeIngredients/RecipeIngredients";
import RecipeSteps from "../../../../Components/Organisms/Recipe/RecipeSteps/RecipeSteps";
import "./Recipe.css";

const RecipeTemplate = () => {
  const [ingredients, setIngredients] = useState([
    {
      img: images.banana,
      name: "banana",
    },
    {
      img: images.apple,
      name: "apple",
    },
    {
      img: icons.profilePlaceholder,
      name: "1 pinch of salt",
    },
    {
      img: images.banana,
      name: "banana",
    },
  ]);

  const [steps] = useState([
    "Heat a Belgian waffle iron.",
    "Mix the flour, sugar, and baking powder together in a mixing bowl. Stir in 1 cup eggnog, butter, and the egg until well blended. Add more eggnog if needed to make a pourable batter.",
    "Lightly grease or spray the waffle iron with non-stick cooking spray. Pour some batter onto the preheated waffle iron, close the top, and cook until golden brown and crisp on both sides. Waffles are usually cooked with steam subsides. Transfer waffles to a serving plate, and keep warm.",
    " Meanwhile, place the raspberry preserves in a pan, and heat over medium heat until pourable.",
    " To serve, drizzle raspberry preserves over each waffle, and top with raspberries. If desired, add a dollop of whipped cream to each waffle.",
  ]);

  const [state, setState] = useState({
    likes: 0,
    comments: 0,
    liked: false,
  });

  const likeBlog = () => {
    if (state.liked) {
      setState({
        ...state,
        liked: false,
        likes: state.likes - 1,
      });
    } else {
      setState({
        ...state,
        liked: true,
        likes: state.likes + 1,
      });
    }
  };

  const [open, setOpen] = useState(false);
  const addComment = (newComment) => {
    //    console.log(newComment);
  };

  const [comments, setComments] = useState([
    {
      id: 1,
      by: "Ahmed esam",
      date: "2 hour ago",
      text: " Used” or “Remarketed” equipment often describes products sold with an “as-is” supposition. You might purchase used equipment from an end-user organization or auction company that is selling surplus assets. Produ",
    },
    {
      id: 2,
      by: "Adrian Farmer",
      date: "2 hour ago",
      text: " Used” or “Remarketed” equipment often describes products sold with an “as-is” supposition. You might purchase used equipment from an end-user organization or auction company that is selling surplus assets. Produ",
    },
    {
      id: 3,
      by: "Ruby Washington",
      date: "2 hour ago",
      text: " Used” or “Remarketed” equipment often describes products sold with an “as-is” supposition. You might purchase used equipment from an end-user organization or auction company that is selling surplus assets. Produ",
    },
  ]);
  const [openModal, setopenModal] = useState(false);
  const toggleModal = () => {
    setopenModal(!openModal);
  };

  return (
    <div>
      <BlogHeader
        title="Cooked Coconut Mussels"
        duration="± 5 mins"
        ingredients="4 ingredients"
      />

      <BlogImage img={images.processBg} />

      <RecipeIngredients ingredients={ingredients} />

      <RecipeSteps steps={steps} />

      <RecipeAdditional
        time="12 mins"
        tags="Sweet, Coconut, Quick, Easy, Homemade"
        nutrition={[
          "222 calories",
          "6.2 g fat",
          "7.2 g carbohydrates",
          "28.6 g protein",
          "68 mg cholesterol",
          "268 mg sodium",
        ]}
      />

      <BlogInteractions
        liked={state.liked}
        toggleLiked={likeBlog}
        likes={state.likes}
        comments={comments}
        numComments={state.comments}
        addComment={addComment}
        toggleModal={toggleModal}
      />

      <SocialModal
        open={openModal}
        handleClose={toggleModal}
        link={`${window.location.href}/`}
      />
    </div>
  );
};

export default RecipeTemplate;
