import React, { useState } from "react";
import PaymentsListTemplate from "../../../Templates/Home/Profile/PaymentsList/PaymentsList.Template";
import { getCreditCardsAPI } from "../../../Contexts/API/Home/CreditCards/getCreditCards";
import { useEffect } from "react";

const PaymentsList = () => {
  const [err, setErr] = useState(false);

  const getCreditCards = async () => {
    let result = await getCreditCardsAPI();
    setErr(result === "Something went wrong!");
    if (!err) {
      setCreditCards(result?.data);
    }
  };
  useEffect(() => {
    getCreditCards();
  }, []);

  const [openModal, setopenModal] = useState(false);
  const [pickedPayment, setpickedPayment] = useState(0);
  const [creditCards, setCreditCards] = useState([]);
  // console.log({ pickedPayment })

  return (
    <PaymentsListTemplate
      creditCards={creditCards}
      open={openModal}
      handleClose={() => setopenModal(!openModal)}
      setpickedPayment={setpickedPayment}
    />
  );
};

export default PaymentsList;
