import { Grid } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import { icons, images } from '../../../../Assets/images/IconHelper';
import IngredientCard from '../../../Molecules/IngredientCard/IngredientCard';
import './RecipeIngredients.css'
import '../RecipeStyles.css'

const RecipeIngredients = ({ t, ingredients }) => {
    return (
        <div className="recipe--container">
            <h2 className="recipe-ingredients-title">
            {t("dan kitchen.ingredients")}
            </h2>

            <Grid container spacing={2}>
                {ingredients.map((ing, index) => <Grid item xs={6} key={index} >
                    <IngredientCard
                        img={ing.img}
                        name={ing.name}
                    />
                </Grid>)}

            </Grid>
        </div>
    );
}

export default withTranslation()(RecipeIngredients);