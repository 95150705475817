import { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import PhoneInput from "react-phone-input-2";
import PrimaryButton from "../../Atoms/Buttons/Primary/PrimaryButton";
import TextArea from "../../Atoms/Inputs/TextArea/TextArea";
import TextFieldInput from "../../Atoms/Inputs/TextField/TextFieldInput";
import './ClientForm.css';
import "react-phone-input-2/lib/style.css";
import ar from 'react-phone-input-2/lang/ar.json'

const ClientForm = ({ t, i18n }) => {

    const [data, setData] = useState({
        name: "",
        email: "",
        phone: "",
        message: ""
    })

    const [err, setErr] = useState({
        name: "",
        email: "",
        phone: "",
        message: ""
    })


    const handleSubmit = () => {

        let isError = false;
        let temp = {
            name: data.name.trim(),
            phone: data.phone.trim(),
            email: data.email.trim(),
            message: data.message.trim()
        }
        let tmpErr = {
            name: "",
            email: "",
            phone: "",
            message: ""
        }
        setData(temp);

        if (!temp.name) {
            tmpErr.name = "You must provide a name"
            isError = true
        }
        if (temp.phone.length < 6) {
            tmpErr.phone = "You must provide a valid phone number"
            isError = true
        }

        if (!validateMail(temp.email) || temp.email.indexOf(' ') !== -1) {
            tmpErr.email = "Email must be in format: example@email.com"
            isError = true
        }

        if (!temp.message) {
            tmpErr.message = "You must provide a message"
            isError = true;
        }

        setErr(tmpErr);

        if (!isError) {
            console.log(temp);
            setData({
                name: "",
                email: "",
                phone: "",
                message: ""
            })
        }
    }

    const validateMail = (email) => {
        const re = /\w+@\w+\.\w+/;
        return email.match(re);
    }

    const [loc, setLoc] = useState("");
    useEffect(() => {
        if(i18n.language === 'ar'){
            setLoc(ar);
        }else{
            setLoc("");
        }
    }, [i18n.language])

    return (
        <div className="client-form-container">
            <p className="client-label">
            {t("become client.name")}
            </p>

            <div className="client-input">
                <TextFieldInput
                    error={!!err.name}
                    value={data.name}
                    onchange={(e) => setData({ ...data, name: e })}
                    placeholder={t("become client.enter name")}
                    helperText={err.name}
                />
            </div>

            <p className="client-label">
            {t("become client.email")}
            </p>

            <div className="client-input">
                <TextFieldInput
                    error={!!err.email}
                    value={data.email}
                    onchange={(e) => setData({ ...data, email: e })}
                    placeholder="example@email.com"
                    helperText={err.email}
                />
            </div>


            <p className="client-label">
            {t("become client.phone")}
            </p>

            <div className={`client-input client-phone ${err.phone ? "client-error" : ""}`}>
                <PhoneInput
                    country={'sa'}
                    value={data.phone}
                    placeholder={t('loginBtn.Your Phone Number')}
                    onChange={(e) => setData({ ...data, phone: e })}
                    localization={loc}
                />
                {err.phone && <p className="textbox-error">
                    {err.phone}
                </p>}
            </div>

            <p className="client-label">
            {t("become client.phone")}
            </p>

            <div className={`client-input client-phone ${err.message ? "client-error" : ""}`}>
                <TextArea
                    value={data.message}
                    onchange={(e) => setData({ ...data, message: e.target.value })}
                    placeholder={t("become client.enter message")}
                />
                {err.message && <p className="textbox-error">
                    {err.message}
                </p>}
            </div>

            <div className="client-button-container">
                <PrimaryButton
                    text={t("become client.send")}
                    classBtn="primary"
                    click={handleSubmit}
                />
            </div>

        </div>
    );
}

export default withTranslation()(ClientForm);