import "./Home360Tour.css";

import { Grid, Typography } from "@material-ui/core";

import PrimaryButton from "../../Atoms/Buttons/Primary/PrimaryButton";
import { images } from "../../../Assets/images/IconHelper";

const Home360Tour = () => {
  return (
    <Grid
      container
      style={{
        backgroundColor: "#F9F9F9",
      }}
    >
      {/* Left Side */}
      <Grid
        container
        item
        xs={12}
        sm={6}
        style={{ paddingLeft: "13%", paddingRight: "13%" }}
      >
        <Grid container justifyContent="center" style={{ paddingBottom: 100 }}>
          {/* Title */}
          <Typography className="home-360-tour-title">
            Explore our 360 Tour
          </Typography>
          {/* Content */}
          <Typography className="content">
            Dan Foods Believies in Food Safety. We take Food Safty very
            seriously. so our customers trust us to provide them with the safest
            products . we also believe in customer services and always put our
            valued customers frist
          </Typography>
          {/* Watch Now Button */}
          <a
            href={"https://mpembed.com/show/?m=B6cBt4u9cps&mpu=497"}
            target="_blank"
            style={{ width: "100%" }}
          >
            <PrimaryButton text="Watch Now" classBtn={"watch-now"} />
          </a>
        </Grid>
      </Grid>
      {/* Right Side */}
      <Grid
        item
        xs={12}
        sm={6}
        style={{
          backgroundImage: `url(${images.tourSideImage})`,
          backgroundSize: "cover",
        }}
      >
        {/* <div
          style={{
            backgroundImage: `url(${images.tourSideImage})`,
            height: ,
            backgroundRepeat: "no-repeat",
          }}
        ></div> */}
      </Grid>
    </Grid>
  );
};

export default Home360Tour;
