import * as URL from "../URLs";

import { axiosFn, handleError } from "../index";

import { SignInAPI } from "./Signin";
import { toast } from "react-toastify";

export async function sendSignUpData(recivedData) {
  const data = { ...recivedData };

  return await axiosFn("post", URL.AUTH_SIGNUP, {
    ...data,
    device: { firebase_token: "test" },
  })
    .then(async (res) => {
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}
export async function SignUpAPI(data) {
  let { err, res } = await sendSignUpData(data);
  if (err || res) {
    if (res) {
      let response = await SignInAPI({
        user: {
          grant_type: "password",
          username: data.user.email,
          password: data.user.password,
        },
      });
      return { response };
    }
  }
  if (err) {
    toast.error(err);
    return { err };
  }
}
