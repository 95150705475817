import {
  Checkbox,
  Container,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";

const MultiDropDownSelect = ({
  name,
  id,
  items,
  value,
  handleChange,
  label,
  placeholder,
  error = false,
  errorMessage,
  Style,
  placeHolderStyle = "",
  labelStyle,
  classes,
  ContainerStyle,
}) => {
  return (
    <FormControl error={error} style={ContainerStyle}>
      <Select
        multiple
        name={name}
        id={id}
        className={classes}
        sx={{
          mt: "12px",
          maxWidth: "140px",
          width: "100%",
          borderRadius: "8px !important",
          border: "solid 1px #9d9d9d",
        }}
        value={value}
        onChange={(e) => handleChange(e)}
        displayEmpty
        style={Style}
        renderValue={(selected) => (
          <em>
            {placeholder}{" "}
            {items?.filter((el) => selected?.indexOf(el?.id) !== -1)?.length !=
            0
              ? `( ${
                  items?.filter((el) => selected?.indexOf(el?.id) !== -1)
                    ?.length
                } )`
              : ""}
          </em>
        )}
      >
        {items?.map((item) => (
          <MenuItem key={item?.id} value={item?.id}>
            <Checkbox checked={value?.indexOf(item?.id) > -1} />
            {item?.name}
          </MenuItem>
        ))}
      </Select>
      {error && <FormHelperText>{errorMessage}</FormHelperText>}
    </FormControl>
  );
};

export default MultiDropDownSelect;
