import React from "react";
import { PropTypes } from "prop-types";
import Modal from "@material-ui/core/Modal";

import styles from "./FullPreview.module.css";

const FullPreview = ({ visible, visibleabltyToggle, src, children }) => {
  return src ? (
    <Modal
      onClose={visibleabltyToggle}
      open={visible}
      className={styles.FullPreview}
    >
      <div className={styles.container}>
        <div className={styles.image}>
          <img src={src} alt="FullPreview" />
        </div>

        {children}
      </div>
    </Modal>
  ) : null;
};

FullPreview.propTypes = {
  visible: PropTypes.bool,
  visibleabltyToggle: PropTypes.instanceOf(Function),
  src: PropTypes.string,
  children: PropTypes.element,
};

export default FullPreview;
