import * as URL from "../../URLs";

import { axiosFn, handleError } from "../../index";

import { RealTimeDatabase } from "../../../firebase";
import { makeFilterString } from "../../../../utils/FilterString/FilterString";
import { uploadToS3API } from "../../S3/uploadToS3";

var chatId = localStorage.getItem("chatId");

export async function getChatRooms(filterObj = {}) {
  return await axiosFn("get", URL.CHAT_ROOMS + makeFilterString(filterObj))
    .then((res) => {
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}

export const GetMessages = async (MessageNode) => {
  let message_array = [];
  const evt = new CustomEvent("getmessage");
  await RealTimeDatabase.ref(MessageNode)
    .orderByChild("created_at")
    .on("child_added", async (snapshot) => {
      message_array = [...message_array, snapshot.val()];
      evt.data = message_array;
      window.dispatchEvent(evt);
    });
};

export async function visitChatRoom(chat_id) {
  chatId = chat_id;
  return await axiosFn("post", URL.CHAT_ROOMS + "/" + chat_id + "/" + URL.VISIT)
    .then((res) => {
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}

export const SendMessage = async (message, file = [], sender_id) => {
  let FirebaseDataObj = {};
  if (file.length > 0) {
    FirebaseDataObj = {
      created_at: new Date().toISOString(),
      sender_id: sender_id,
      text: message,
    };
    file.map((el) => {
      FirebaseDataObj = {
        ...FirebaseDataObj,
        file_name: el.name,
        file_size: el.size,
        file_type: el.type,
        file_url: el.url,
      };
    });
  } else {
    FirebaseDataObj = {
      text: message,
      created_at: new Date().toISOString(),
      sender_id: sender_id,
    };
  }

  Object.keys(FirebaseDataObj).forEach(
    (key) =>
      (FirebaseDataObj[key] === undefined ||
        FirebaseDataObj[key]?.length === 0) &&
      delete FirebaseDataObj[key]
  );
  await RealTimeDatabase.ref(localStorage.getItem("Messages_node"))
    .push()
    .set(FirebaseDataObj);
  const messageObj = {
    body: message,
    chat_message_type: "text",
  };
  await sendMessageAPI(chatId, messageObj);
};

export const handleFiles = async (files) => {
  let file = {};
  const arrayOfExtensions = [];
  //get extensions
  for (let [key, value] of Object.entries(files)) {
    arrayOfExtensions.push({
      extension: value.name.substring(value.name.indexOf(".") + 1),
    });
  }

  let res = await uploadToS3API(files, arrayOfExtensions);

  let result = files.map(async (el) => {
    if (res) {
      file = {
        name: el.name,
        url: res?.res[0]?.file_url,
        type: el.type,
        size: el.size,
      };
    }
    return file;
  });
  return result;
};

export const handleFileInputs = (fileList, file) => {
  return [file[0]];
};

export async function sendMessageAPI(chat_id, messageObj) {
  return await axiosFn(
    "post",
    URL.CHAT_ROOMS + "/" + chat_id + "/" + URL.SEND_MESSAGE,
    messageObj
  )
    .then((res) => {
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}
