import { containerClasses } from "@mui/system";
import { useState } from "react";
import { useLocation, useParams } from "react-router";
import { toast } from "react-toastify";
import { CreateProposalAPI } from "../../../Contexts/API/Home/Proposals/CreateProposal";
import { CreateQuotationsAPI } from "../../../Contexts/API/Home/Quotations/CreateQuotations";
import SubmitETradelingProposalTemplate from "../../../Templates/Home/SubmitETradelingProposal/SubmitETradelingProposal.Template";

function SubmitETradelingProposal() {
  let Params = useParams();
  const handleSubmit = async (QuotationData) => {
    const { res, err } = await CreateQuotationsAPI(QuotationData);
    if (res) {
      window.location = "/proposal/success";
    }
    if (err) {
      toast.error(err?.at(0));
    }
    // toast.success("Your review has been submitted successfully");
  };
  return (
    <SubmitETradelingProposalTemplate
      RequestID={Params?.id}
      handleSubmit={handleSubmit}
    />
  );
}

export default SubmitETradelingProposal;
