import { Box, CircularProgress, Typography } from "@mui/material";

import { Container } from "@mui/system";
import DeleteRequestModal from "../../../../Components/Organisms/Modals/DeleteRequestModal/DeleteRequestModal";
import RequestDetailsBody from "../../../../Components/Organisms/RequestDetailsBody/RequestDetailsBody";
import RequestDetailsHeader from "../../../../Components/Molecules/RequestDetailsHeader/RequestDetailsHeader";

export const InProgressRequestDetailsTemplate = ({
  id,
  isProductRequestLoading,
  productRequest,
  deleteHandler,
  acceptHandler,
  rejectHandler,
  handleModal,
  modalOpen,
  systemConfig,
  handleCancel,
}) => {
  return (
    <Container component="main" maxWidth="lg">
      <RequestDetailsHeader
        id={id}
        deleteHandler={deleteHandler}
        handleModal={handleModal}
        status={"inprogress"}
      />
      {isProductRequestLoading ? (
        <div className="progress-indicator">
          <CircularProgress style={{ color: "var(--secondary-color)" }} />
        </div>
      ) : (
        <div>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              mt: "16px",
            }}
          >
            <Typography sx={{ fontSize: "22px", fontWeight: 600 }}>
              Request Details
            </Typography>
            <Typography
              sx={{
                fontSize: "16px",
                color: "#ff5252",
                cursor: "pointer",
                fontWeight: "normal",
              }}
              onClick={() => handleCancel(productRequest?.id)}
            >
              Cancel this request
            </Typography>
          </Box>
          <RequestDetailsBody
            productRequest={productRequest}
            acceptHandler={acceptHandler}
            rejectHandler={rejectHandler}
            id={id}
            systemConfig={systemConfig}
            status={"inprogress"}
          />
        </div>
      )}
      <DeleteRequestModal
        open={modalOpen}
        id={id}
        deleteHandler={deleteHandler}
        handleClose={handleModal}
      />
    </Container>
  );
};
