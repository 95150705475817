import React from "react";
import { useParams } from "react-router-dom";
import BoxItemsTemplate from "../../../Templates/Home/Profile/MyBoxes/BoxItems.Template";
import { getBoxAPI } from "../../../Contexts/API/Home/Boxes/getBox";
import { useEffect } from "react";
import { useState } from "react";
const BoxItems = () => {
  const [title, setTittle] = useState("");
  const { id } = useParams();
  const fetchBox = async () => {
    const res = await getBoxAPI(id);
    if (res) {
      setTittle(res?.data?.attributes.name);
    }
  };
  useEffect(() => {
    fetchBox();
  }, []);
  return <BoxItemsTemplate title={title} />;
};

export default BoxItems;
