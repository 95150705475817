import "./SallabHomeTemplate.css";

import { icons, images } from "../../../Assets/images/IconHelper";

import ButtonGroup from "../../../Components/Molecules/ButtonGroup/ButtonGroup";
import CustomerFeedbackBanner from "../../../Components/Organisms/CustomerFeedbackBanner/CustomerFeedbackBanner";
import Home360Tour from "../../../Components/Molecules/Home360Tour/Home360Tour";
import HomeCarousel from "../../../Components/Organisms/HomePage/HomeCarousel/HomeCarousel";
import HomeCollectionsCarousel from "../../../Components/Organisms/Carousels/HomeCollectionsCarousel/HomeCollectionsCarousel";
import HomeFeaturesBanner from "../../../Components/Organisms/HomeFeaturesBanner/HomeFeaturesBanner";
import { Link } from "react-router-dom";
import OurCategories from "../../../Components/Organisms/OurCategories/OurCategories";
import OurProfile from "../../../Components/Organisms/HomePage/OurProfile/OurProfile";
import PeopleViewed from "../../../Components/Organisms/PeopleViewed/PeopleViewed";
import PrimaryButton from "../../../Components/Atoms/Buttons/Primary/PrimaryButton";
import { Typography } from "@material-ui/core";
import TabsCarousel from "../../../Components/Organisms/Carousels/TabsCarousel/TabsCarousel";
import { useState } from "react";
import { withTranslation } from "react-i18next";

const SallabHomeTemplate = ({
  categories,
  bestSellerCollection,
  bathroomCollection,
  t,
  tags,
  FeatureBanners,
}) => {
  const [selectedMarketingTag, setSelectedMarketingTag] = useState(null);
  const handleSelectingMarketingTag = (category) => {
    setSelectedMarketingTag(category?.id);
  };

  return (
    <>
      <div className="template-app">
        <TabsCarousel
          categories={tags && tags?.filter((item) => item?.marketing_tag)}
          selectedCategory={selectedMarketingTag}
          setSelectedCategory={setSelectedMarketingTag}
          handleSelectingCategory={handleSelectingMarketingTag}
          home={true}
          tags={true}
        />
        <HomeCarousel featuredBanners={FeatureBanners} />
        <OurCategories
          categories={categories}
          tags={tags && tags?.filter((item) => !item?.marketing_tag)}
        />
        <iframe
          src="https://mpembed.com/show/?m=B6cBt4u9cps&mpu=497"
          title="360Live"
          style={{
            width: "100%",
            height: "500px",
            marginTop: "50px",
            border: "0",
          }}
        ></iframe>
        <Typography
          align="center"
          variant="h4"
          className="title"
          style={{ marginTop: "50px", marginBottom: "20px" }}
        >
          {t("bestSeller.title")}
        </Typography>
        <div className="see-all-btn">
          <Link to={`/collections`}>
            <PrimaryButton
              classBtn="btn-secondry btn-rounded btn-mini"
              text={t("bestSeller.seeAll")}
            />
          </Link>
        </div>
        <PeopleViewed items={bestSellerCollection} />
      </div>
      {/* BestSeller is People viewed (needs a generic name) */}

      {/* TODO */}
      <HomeFeaturesBanner
        features={[
          { icon: icons.categoriesIcon, title: "Browse By Categories" },
          { icon: icons.rotationIcon, title: "360 Tours" },
          { icon: icons.walletIcon, title: "Easy Payment" },
        ]}
        backgroundImage={images.featuresBannerBackground}
        title={"CREATE THE HOME YOU'VE ALWAYS WANTED"}
      />
      <HomeCollectionsCarousel bathroomCollection={bathroomCollection} />
      <Home360Tour />
      <CustomerFeedbackBanner />
      <div className="our-profile-container">
        <OurProfile />
      </div>
    </>
  );
};

export default withTranslation()(SallabHomeTemplate);
