import "./NavbarLogo.css";

import { icons, images } from "../../../Assets/images/IconHelper";
import { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";

import { Grid } from "@material-ui/core";
import IconOverlay from "../../Atoms/IconOverlay/IconOverlay";
import { Link } from "react-router-dom";
import NotificationPanel from "../NotificationPanel/NotificationPanel";
import ProfileList from "../ProfileList/ProfileList";
import SearchBox from "../../Molecules/SearchBox/SearchBox";
import { withTranslation } from "react-i18next";
import { systemContext } from "../../../Contexts/systemContext/systemContext";

function NavbarLogo(props) {
  const { t, getAccount, mobileView, systemConfig, account } = props;

  const history = useHistory();
  const location = useLocation();

  const [isAuth, setIsAuth] = useState(false);

  let query = new URLSearchParams(location.search).get("query");

  const [search, setSearch] = useState("");

  // const [account, setAccount] = useState({});

  // const loadAccount = async () => {
  //   const acc = await getAccount();
  //   setAccount(acc?.data);
  // };

  useEffect(() => {
    if (localStorage.getItem("TOKEN")) {
      setIsAuth(true);
      // loadAccount();
    }
  }, []);

  useEffect(() => {
    if (query) setSearch(query.trim());
    else setSearch("");
  }, [query]);

  const handleSearch = () => {
    history.replace(
      systemConfig &&
        JSON.parse(systemConfig["System Configurations"]["Collections"])
        ? `/collections`
        : "/shopping"
    );
    // console.log(search);
    // if (search) {
    //   history.replace(`/searchresults?query=${search}`);
    // } else history.replace("/searchresults");
  };

  return (
    <div className="Nav_logo">
      <Grid container>
        <Grid item xs={6} sm={3} style={{ justifyContent: "flex-start" }}>
          <Link to="/">
            <img
              alt="logo"
              src={
                systemConfig
                  ? systemConfig["System Configurations"]["Logo"]
                  : ""
              }
              width="90%"
              // height="99px"
            />
          </Link>
        </Grid>

        <Grid item xs={6} sm={9}>
          <Grid container justify="flex-start">
            <Grid item xs={12} sm={8} className="nav-item1">
              <SearchBox
                type="search"
                placeholder={t("placeholder.What your are Looking for?")}
                onchange={(s) => setSearch(s)}
                value={search}
                submitFn={() => {
                  handleSearch();
                }}
                startIcon={<IconOverlay src={icons.search} />}
              />
            </Grid>

            <Grid item xs={8} sm={3} className="nav-item2">
              {isAuth ? (
                <ProfileList
                  mobileView={mobileView}
                  account={account}
                  systemConfig={systemConfig}
                />
              ) : (
                <Link to="/login">
                  {t("loginBtn.Signin")} {t("general.or")}{" "}
                  {t("loginBtn.Signup")}
                </Link>
              )}
            </Grid>
            <Grid item xs={4} sm={1} className="nav-item3">
              <NotificationPanel />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
export default withTranslation()(NavbarLogo);
/* 
 <Row className="NavBar">
 <Col>
   <Navbar expand="lg" className={!props.home && "box-shadow-nav"}>
     <Navbar.Brand className="NavBarLogo" style={{ cursor: "pointer" }}>
       <NavLink to="/">
         <img src={icons.Logo} alt="logo"></img>
       </NavLink>
     </Navbar.Brand>
     <Navbar.Toggle aria-controls="basic-navbar-nav" />
     <Navbar.Collapse id="basic-navbar-nav">
       <Nav className="mx-auto links">
         <div>
           <NavLink
             to="/"
             activeClassName={`${
               localizationAr
                 ? "activeLinkHome_localization"
                 : "activeLink"
             }`}
             className="NavLink"
             exact
           >
             {t("navBar.Home")}
           </NavLink>
         </div>
         <div>
           <NavLink
             to="/courses/all"
             activeClassName={`${
               localizationAr
                 ? "activeLinkCourses_localization"
                 : "activeLinkCourses"
             }`}
             className="NavLink"
           >
             {t("navBar.Courses")}
           </NavLink>
         </div>
          <div>
           <NavLink
             to="/Tracks"
             activeClassName="activeLink"
             className="NavLink"
           >
             {t("navBar.Tracks")}
           </NavLink>
         </div> 

         <div className="d-lg-none">
           <NavLink to="/login" className="NavLink">
             Log in
           </NavLink>
         </div>
         <div className="d-lg-none">
           <NavLink to="/signUp" className="NavLink">
             Sign up
           </NavLink>
         </div>

         <div className="NavLink">
           <NavDropdown
             title={t("navBar.AboutAlmanasa")}
             id="basic-nav-dropdown"
             className="aboutus-dropdown"
             disabled
           >
             <Link to="/" className="dropdown-item">
               {t("navBar.Whoweare")}
             </Link>
             {/*  <NavDropdown.Item href="#action/3.2">
               {t("navBar.wheretofindus")}
             </NavDropdown.Item> 
           </NavDropdown>
         </div>
       </Nav>
     </Navbar.Collapse>
     <Form inline className="d-none d-lg-flex">
       <Row>
         <Col xs={"auto"}>
           {/* <button
             className="m-0 p-0"
             style={{
               background: "none",
               border: "none",
               height: "auto",
               width: "auto",
             }}
           >
             <img
               src={icons.searchIcon}
               style={{ height: "19px", width: "18px" }}
             />
           </button> 
         </Col>
         <Col className="d-flex">
           {isAuth && (
             <Link to="/mycart">
               {" "}
               <span
                 className="NavCount mx-2"
                 style={{ cursor: "pointer" }}
               >
                 {cartCount}
               </span>
             </Link>
           )}
           {isAuth && (
             <NavLink
               to={"/mycart"}
               activeClassName={`${
                 localizationAr
                   ? "activeLinkHome_localization"
                   : // : "activeLinkHome"
                     "activeLink"
               }`}
               className="NavCart"
               style={{ position: "initial" }}
             >
               {t("navBar.Cart")}
             </NavLink>
           )}
         </Col>
       </Row>
     </Form>
   </Navbar>
 </Col>
 </Row> 
*/
