import React, { useContext } from "react";
import { systemContext } from "../../../Contexts/systemContext/systemContext";
import ProfileTemplate from "../../../Templates/Home/Profile/Profile.Template";

const Profile = () => {
  const { systemConfig, account } = useContext(systemContext);
  return <ProfileTemplate systemConfig={systemConfig} account={account} />;
};

export default Profile;
