import { useEffect, useState } from "react";

import BasketTemplate from "../../../Templates/Home/Basket/Basket.Template";
import { useCart } from "../../../Hooks/useCart/useCart";
import { useWishlist } from "../../../Hooks/useWishlist/useWishlist";

function Basket() {
  const [state, setState] = useState({
    mobileView: false,
  });
  const { mobileView } = state;

  const {
    cart,
    isCartLoading,
    deleteFromCart,
    cartItems,
    increaseItemQuantity,
    decreaseItemQuantity,
    applyCoupon,
    removeCoupon,
    loadCart,
  } = useCart();
  const { addToWishlist } = useWishlist();
  useEffect(() => {
    if (localStorage.getItem("TOKEN")) {
      loadCart();
    }
  }, []);

  const [countDetecter, setCountDetecter] = useState(0);

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 600
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };

    setResponsiveness();
    window.addEventListener("resize", () => setResponsiveness());

    return () => {
      window.removeEventListener("resize", () => setResponsiveness());
    };
  }, [window.innerWidth]);

  const handleMoveToWishlist = async (variantID, id) => {
    await addToWishlist(variantID);
    await deleteFromCart(id);
  };

  return (
    <BasketTemplate
      mobileView={mobileView}
      cartData={cart}
      handleRemoveFromCart={deleteFromCart}
      handleApplyCoupon={applyCoupon}
      countDetecter={countDetecter}
      setCountDetecter={setCountDetecter}
      isCartLoading={isCartLoading}
      cartItems={cartItems}
      increaseItemQuantity={increaseItemQuantity}
      decreaseItemQuantity={decreaseItemQuantity}
      removeCoupon={removeCoupon}
      handleMoveToWishlist={handleMoveToWishlist}
    />
  );
}

export default Basket;
