import { Box, CircularProgress, Grid, Typography } from "@mui/material";

import IconOverlay from "../../../Components/Atoms/IconOverlay/IconOverlay";
import { Link } from "react-router-dom";
import { icons } from "../../../Assets/images/IconHelper";

const VendorCard = ({ data }) => {
  return (
    <Grid container style={{ marginTop: 20 }}>
      {/* Vendor Logo */}
      <Grid
        item
        xs={12}
        style={{
          width: "100%",
          height: "100%",
          paddingRight: 10,
        }}
      >
        <div
          style={{
            height: 124,
            width: "100%",
            border: "1px solid #f8f8f8",
            // backgroundColor: "#979797",
            paddingTop: 8,
            paddingRight: 8,
            display: "flex",
            justifyContent: "flex-end",
            backgroundImage: `url(${data?.company_logo})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
          }}
        >
          {/* Favorite Icon? */}
          <div
            style={{
              borderRadius: 10,
              height: 23,
              width: 23,
              display: "flex",
              justifyContent: "center",
              position: "relative",
              cursor: "pointer",
            }}
          >
            <IconOverlay src={icons.addToWishlist} />
          </div>
        </div>
      </Grid>
      {/* Vendor Information (Name, Rating and Type) */}
      <Grid
        item
        xs={12}
        style={{
          backgroundColor: "#f8f8f8",
          paddingLeft: 8,
          paddingRight: 8,
          paddingTop: 16,
          paddingBottom: 16,
        }}
      >
        <Link to={`vendordetails/${data?.id}/about`}>
          <Typography>{data?.company_name}</Typography>
        </Link>
        <div style={{ display: "flex" }}>
          <IconOverlay
            src={icons.starIcon}
            style={{ marginBottom: 4, marginRight: 2 }}
          />
          <Typography
            gutterBottom
            variant="h5"
            component="div"
            sx={{
              fontSize: "12px",
              color: "#9e9e9e",
              fontWeight: "500",
              fontFamily: "Roboto",
              marginRight: 0.4,
            }}
          >
            {0}
          </Typography>
          <Typography
            gutterBottom
            variant="h5"
            component="div"
            sx={{
              fontSize: "10px",
              color: "#a9a9a9",
              fontWeight: "300",
              fontFamily: "Roboto",
              marginTop: 0.5,
            }}
          >
            {"(" + 0 + ")"}
          </Typography>
        </div>
        <Typography
          style={{ color: "#6f6f6f", fontSize: 14, fontWeight: "bold" }}
          noWrap
        >
          {data?.vendor_types?.map((type, index) => {
            const and = index + 1 === data?.vendor_types.length ? "" : "& ";
            return `${type?.name}` + " " + and;
          })}
        </Typography>
      </Grid>
    </Grid>
  );
};

const VendorsTemplate = ({ vendorList, loading }) => {
  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          borderBottom: "1px solid #d8d8d8",
          paddingBottom: "12px",
        }}
      >
        <Typography variant="h4">Vendors</Typography>
        <Typography>Sort By</Typography>
      </Box>
      {loading ? (
        <div className="progress-indicator">
          <CircularProgress style={{ color: "var(--secondary-color)" }} />
        </div>
      ) : (
        <Grid container justifyContent="flex-start" spacing={4}>
          {vendorList?.map((data) => (
            <Grid item xs={6} md={3}>
              <VendorCard data={data} />
            </Grid>
          ))}
        </Grid>
      )}
    </Box>
  );
};

export default VendorsTemplate;
