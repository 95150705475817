import { useHistory, useParams } from "react-router-dom";

import { InProgressRequestDetailsTemplate } from "../../../Templates/Home/Profile/InProgressRequestDetails/InProgressRequestDetails.template";
import RequestDetailsTemplate from "../../../Templates/Home/Profile/EtradelingRequestDetails/RequestDetails.template";
import { systemContext } from "../../../Contexts/systemContext/systemContext";
import { toast } from "react-toastify";
import { useContext } from "react";
import { useEffect } from "react";
import { useServiceRequests } from "../../../Hooks/useServiceRequests/useServiceRequests";
import { useState } from "react";

//ESOLAR
const EsolarRequestDetailsPage = () => {
  const { systemConfig } = useContext(systemContext);
  const [modalOpen, setModalOpen] = useState(false);
  //HOOKS
  const history = useHistory();
  let { id } = useParams();
  const {
    deleteServiceRequest,
    serviceRequest,
    loadServiceRequest,
    isServiceRequestLoading,
    acceptServiceProposal,
    rejectServiceProposal,
    cancelServiceRequest,
  } = useServiceRequests();

  //EFFECTS
  useEffect(() => {
    loadServiceRequest(id);
  }, []);

  //HANDLERS
  const deleteHandler = async (id) => {
    await deleteServiceRequest(id);
    history.push("/profile/my_requests");
  };
  const acceptHandler = async (requestID, quotationID) => {
    await acceptServiceProposal(requestID, quotationID);
    history.push("/profile/my_requests");
  };
  const rejectHandler = async (requestID, quotationID) => {
    await rejectServiceProposal(requestID, quotationID);
    history.push("/profile/my_requests");
  };
  const editHandler = () => {};

  const handleModal = () => {
    setModalOpen(!modalOpen);
  };

  const handleCancel = async (requestID) => {
    await cancelServiceRequest(requestID);
    history.push("/profile/my_requests");
    toast.success("Request Canceled");
  };
  return (
    <>
      {serviceRequest?.status === "inprogress" ? (
        <InProgressRequestDetailsTemplate
          productRequest={serviceRequest}
          isProductRequestLoading={isServiceRequestLoading}
          deleteHandler={deleteHandler}
          id={id}
          acceptHandler={acceptHandler}
          rejectHandler={rejectHandler}
          handleModal={handleModal}
          modalOpen={modalOpen}
          systemConfig={systemConfig}
          handleCancel={handleCancel}
        />
      ) : (
        <RequestDetailsTemplate
          productRequest={serviceRequest}
          isProductRequestLoading={isServiceRequestLoading}
          deleteHandler={deleteHandler}
          id={id}
          acceptHandler={acceptHandler}
          rejectHandler={rejectHandler}
          handleModal={handleModal}
          modalOpen={modalOpen}
          systemConfig={systemConfig}
        />
      )}
    </>
  );
};

export default EsolarRequestDetailsPage;
