import * as URL from "../URLs";

import { axiosFn, handleError } from "../index";

import firebase from "firebase/compat/app";
import reactDom from "react-dom";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

// import { signInWithEmailAndPassword } from "firebase/auth";
// import { auth } from "../../firebase";

export async function sendSignInData(recivedData) {
  const data = { ...recivedData };

  return await axiosFn("post", URL.AUTH_LOGIN, {
    ...data.user,
    device: { firebase_token: "test" },
  })
    .then((res) => {
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}

export async function SignInAPI(data) {
  let { err, res } = await sendSignInData(data);

  if (res) {
    //firebase
    //--------------------------------------
    // firebase.auth().signInWithEmailAndPassword(data.user.username, data.user.password).then(()=>{
    //   window.location.reload();
    //   window.location = "/";
    // }).catch(function (error) {
    //     // Handle Errors here.
    //     var errorCode = error.code;
    //     var errorMessage = error.message;
    //     if (errorCode = 'auth/user-not-found') {
    //       firebase.auth().createUserWithEmailAndPassword(data.user.username, data.user.password).then(() => {
    //         window.location.reload();
    //         window.location = "/";
    //       }).catch(function (error) {
    //         var errorCode = error.code;
    //         var errorMessage = error.message;
    //         if (errorCode == 'auth/weak-password') {
    //           toast.error('The password is too weak.');
    //         } else {
    //           toast.error(errorMessage);
    //         }
    //         console.log(error);
    //       });
    //     } else if (errorCode === 'auth/wrong-password') {
    //       toast.error('Wrong password.');
    //     } else {
    //       toast.error(errorMessage);
    //     }
    //     console.log(error);
    //   });
    //------------------------------------

    localStorage.setItem("TOKEN", res?.access_token);
    localStorage.setItem("USER_TYPE", "user");
    let exp = res?.expires_in + res?.created_at;
    localStorage.setItem("EXP", exp);
    return { res };
  }
  if (err) {
    if (err === "البريد الالكتروني غير صحيح") {
      toast.error("البريد الالكتروني غير مسجل من قبل");
    } else {
      toast.error(err);
    }
    return { err };
  }
}
