import MyRequestsTemplate from "../../../Templates/Home/Profile/EsolarMyRequests/EsolarMyRequests.template";
import { queryParamsFormatter } from "../../../utils/queryParamsFormatter/queryParamsFormatter";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useRequests } from "../../../Hooks/useRequests/useRequests";
import { useState } from "react";

function EtradelingMyQuotationPage() {
  const [queryParams, setQueryParams] = useState({ status: "pending" });
  const [modalOpen, setModalOpen] = useState(false);
  const history = useHistory();

  const {
    loadProductRequests,
    productRequests,
    productRequestsLoading,
    deleteRequest,
    hasMore,
    currentPage,
    setCurrentPage,
    loadMoreProductRequests,
  } = useRequests();

  useEffect(() => {
    loadProductRequests(queryParamsFormatter(queryParams));
    setCurrentPage(1);
  }, [queryParams]);

  const handleEdit = (id) => {
    //TODO: Redirect to edit page
  };

  const handleViewRequestDetails = (id) => {
    //Redirect to request details page
    history.push(`/profile/requests/${id}`);
  };

  const handleTabsChange = (status) => {
    setQueryParams({ ...queryParams, status: status });
  };

  const handleDelete = async (id) => {
    await deleteRequest(id);
    loadProductRequests(queryParamsFormatter(queryParams));
  };

  const handleModal = () => {
    setModalOpen(!modalOpen);
  };

  return (
    <MyRequestsTemplate
      requests={productRequests}
      isLoading={productRequestsLoading}
      handleEdit={handleEdit}
      handleViewRequestDetails={handleViewRequestDetails}
      handleTabsChange={handleTabsChange}
      handleDelete={handleDelete}
      hasMore={hasMore}
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
      loadProductRequests={loadProductRequests}
      queryParams={queryParams}
      loadMoreProductRequests={loadMoreProductRequests}
      handleModal={handleModal}
      modalOpen={modalOpen}
    />
  );
}

export default EtradelingMyQuotationPage;
