import MyAddress from "../../../Pages/Home/Profile/MyAdress.Page";

import "./ChangeAddress.css";

const ChangeAddressTemplate = () => {
  return (
    <div>
      <MyAddress checkout={true} />
    </div>
  );
};

export default ChangeAddressTemplate;
