import { Grid, Paper } from '@material-ui/core'
import React from 'react'
import { withTranslation } from 'react-i18next';
import '../HomePage.css';
import './OurAdvantage.css';
import { images, icons } from '../../../../Assets/images/IconHelper';
import IconOverlay from '../../../Atoms/IconOverlay/IconOverlay';

const OurAdvantage = (props) => {


    const { t } = props

    return (

        <div className="imgContainer" style={{ backgroundImage: `url(${images.advBg})` }}>
            <div className="imgContainerContent">
                <Paper className="paper">
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <h2 className="home-title adv-title">{t('ourAdvantage.ourAdvantage')}</h2>
                        </Grid>

                        <Grid item xs={4} sm={4}>
                            <div className="flex-col">
                                {/* <img alt="all year products" src={icons.allYearProducts} width="100px" height="100px" /> */}
                                <IconOverlay src={icons.allYearProducts} iconStyle="icon-primary" width="100px" height="100px"/>
                                <p className="heading">{t('ourAdvantage.allYear.title')}</p>
                                {/* <img alt="separator" src={icons.rectangle} width="37px" height="4px" /> */}
                                <IconOverlay src={icons.rectangle} iconStyle="icon-primary" width="37px" height="4px"/>
                                <p className="text">{t('ourAdvantage.allYear.desc')}</p>
                            </div>
                        </Grid>

                        <Grid item xs={4} sm={4}>
                            <div className="flex-col">
                                {/* <img alt="all year products" src={icons.fresh} width="100px" height="100px" /> */}
                                <IconOverlay src={icons.fresh} iconStyle="icon-primary" width="100px" height="100px"/>
                                <p className="heading">{t('ourAdvantage.fresh.title')}</p>
                                {/* <img alt="separator" src={icons.rectangle} width="37px" height="4px" /> */}
                                <IconOverlay src={icons.rectangle} iconStyle="icon-primary" width="37px" height="4px"/>
                                <p className="text">{t('ourAdvantage.fresh.desc')}</p>
                            </div>
                        </Grid>

                        <Grid item xs={4} sm={4}>
                            <div className="flex-col">
                                {/* <img alt="all year products" src={icons.delivery} width="100px" height="100px" /> */}
                                <IconOverlay src={icons.delivery} iconStyle="icon-primary" width="100px" height="100px"/>
                                <p className="heading">{t('ourAdvantage.delivery.title')}</p>
                                {/* <img alt="separator" src={icons.rectangle} width="37px" height="4px" /> */}
                                <IconOverlay src={icons.rectangle} iconStyle="icon-primary" width="37px" height="4px"/>
                                <p className="text">{t('ourAdvantage.delivery.desc')}</p>
                            </div>
                        </Grid>
                    </Grid>
                </Paper>
            </div>
        </div>

    )
}

export default withTranslation()(OurAdvantage);
