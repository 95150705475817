import {withTranslation} from "react-i18next";
import { Grid, Typography } from "@material-ui/core";
import { images } from "../../../Assets/images/IconHelper";

const MessageItem = (props) => {
    const { type, image, message, time, files } = props;

    return(
        <Grid className="mb-20px" container style={type == 'sent' ? {display:"flex",alignItems:'center',justifyContent:'end'}: {display:"flex",alignItems:'center',justifyContent:'start'}}>
            {type != 'sent' && <img width="35" height="35" style={{margin:"0 15px",borderRadius:'50%', border: "1px solid var(--secondary-color)"}} src={image ? image : images.profileRectangle}/>}
            <Grid className={type == 'sent' ? "sent" : "received"}>
                {message && (
                    <Typography component="p">
                        {message}
                    </Typography>
                )}
                {files && files.includes("pdf") ? <a target="_blank" href={files}><u style={{fontStyle:'italic'}}>docs</u> </a> : files && <img src={files}/>}
            </Grid>
            <Typography 
                style={{
                    width: "95%", 
                    textAlign: "end", 
                    display: "block", 
                    fontSize: "12px",
                    color: "rgba(0, 0, 0, 0.5)",
                    marginTop: "5px"
                }} 
                component="h6"
            >
                {new Date(time).toLocaleString().split(',')[1]}
            </Typography>
        </Grid>
    )
};

export default withTranslation()(MessageItem);