import React from "react";
import { useState, useEffect } from "react";
import ProductReviewTemplate from "../../../Templates/Home/Profile/Orders/ProductReview.Template";
import { getAccountAPI } from "../../../Contexts/API/Home/MyAccount/getAccount";
import { getProductDetailsAPI } from "../../../Contexts/API/Home/Products/getProductDetails";
import { NavLink, useHistory, useParams } from "react-router-dom";
import { addReviewAPI } from "../../../Contexts/API/Home/Reviews/addReview";
import { toast } from "react-toastify";
function ProductReview() {
  const history = useHistory();

  const { product, number } = useParams();
  const [rating, setRating] = useState(0);
  const [feedback, setFeedback] = useState("");
  const [review, setReview] = useState({
    name: "default",
    title: "defualt",
    user_id: 0,
    product_id: product,
    rating: 0,
  });
  //GET USER ID - NOT WORKING (API CALL ISSUE)
  const useFetch = (getData) => {
    const [isLoading, setIsLoading] = useState(false);
    const [apiData, setApiData] = useState(null);
    const [serverError, setServerError] = useState(null);
    const [accountID, setAccountID] = useState(null);

    useEffect(() => {
      setIsLoading(true);
      const fetchData = async () => {
        try {
          const resp = await getData(product);
          const data = await resp?.data;
          const { res } = await getAccountAPI();
          const id = await res?.data?.id;
          setReview({ ...review, user_id: id });
          setApiData(data);
          setIsLoading(false);
        } catch (error) {
          setServerError(error);
          setIsLoading(false);
        }
      };

      fetchData();
    }, [getData]);

    return { isLoading, apiData, serverError, accountID };
  };
  const { isLoading, apiData, serverError, accountID } =
    useFetch(getProductDetailsAPI);
  useEffect(() => {
    setReview({ ...review, review: feedback, rating: rating });
  }, [rating, feedback]);
  const changeRating = (newRating) => {
    setRating(newRating);
  };

  const handleFeedback = (t) => {
    setFeedback(t);
  };
  const goBack = () => {
    history.push("/profile/orders");
  };
  const handleSubmit = () => {
    addReviewAPI(review);
    toast.success("Your review has been submitted successfully");
    goBack();
  };
  return (
    <>
      <ProductReviewTemplate
        rating={rating}
        number={number}
        apiData={apiData}
        changeRating={changeRating}
        handleFeedback={handleFeedback}
        review={review}
        handleSubmit={handleSubmit}
      />
    </>
  );
}

export default ProductReview;
