import { useEffect, useState } from "react";

import EsolarMainTemplate from "../Templates/EsolarMainTemplate";
import MainTemplate from "../Templates/Main.Template";
import { addToCartAPI } from "../Contexts/API/Home/Cart/addToCart";
import { deleteFromCartAPI } from "../Contexts/API/Home/Cart/deleteFromCart";
import { getAccountAPI } from "../Contexts/API/Home/MyAccount/getAccount";
import { getAddressesAPI } from "../Contexts/API/Home/Addresses/getAddresses";
import { getCartAPI } from "../Contexts/API/Home/Cart/getCart";
import { getCategoriesListAPI } from "../Contexts/API/Home/Categories/getCategoriesList";
import { getListOfBoxesAPI } from "../Contexts/API/Home/Boxes/getBoxes";
import { getListOfCountriesAPI } from "../Contexts/API/Home/Countries/getListOfCountries";
import { getProductsListAPI } from "../Contexts/API/Home/Products/getProductsList";
import { useSystem } from "../Hooks/useSystem/useSystem";

const EsolarMainPage = (props) => {
  // const { systemConfig, isSystemConfigLoading, loadSystemConfig } = useSystem();

  // useEffect(() => {
  //   loadSystemConfig();
  // }, []);

  const [state, setState] = useState({
    mobileView: false,
  });

  const { mobileView } = state;

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 600
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };

    setResponsiveness();
    window.addEventListener("resize", () => setResponsiveness());

    return () => {
      window.removeEventListener("resize", () => setResponsiveness());
    };
  }, [window.innerWidth]);

  const getCategoriesList = async () => {
    return await getCategoriesListAPI();
  };

  const getCountriesList = async () => {
    return await getListOfCountriesAPI();
  };

  const [locations, setLocations] = useState([]);

  const loadLocations = async () => {
    const locs = await getCountriesList();
    setLocations(locs.data);
  };
  const [cart, setCart] = useState(null);
  const [cartLoading, setCartLoading] = useState(null);

  const loadCart = async () => {
    setCartLoading(true);
    const { res, status, err } = await getCartAPI();

    if (!err) {
      setCart(res);
      setCartLoading(false);
    }
  };

  const [products, setProducts] = useState(null);
  const [productsLoading, setProductsLoading] = useState(null);

  const loadProducts = async () => {
    setProductsLoading(true);
    const p = await getProductsListAPI();
    setProducts(p);
    setProductsLoading(false);
  };

  const [categories, setCategories] = useState(null);
  const [subCategories, setSubCategories] = useState({});

  const [catLoading, setCatLoading] = useState(true);

  const loadCat = async () => {
    setCatLoading(true);
    const c = await getCategoriesList();

    const mainCats = await c?.res?.data?.filter(
      (c) => c?.attributes?.is_leaf === false
    );
    const subCats = await c?.data?.filter(
      (c) => c?.attributes?.is_leaf === true
    );
    setSubCategories({ data: subCats });
    setCategories({ data: mainCats });

    setCatLoading(false);
  };

  const [addresses, setAddresses] = useState([]);
  const [pickedAddress, setPickedAddress] = useState({});

  const loadAddresses = async () => {
    let result = await getAddressesAPI();

    if (result) {
      setAddresses(result?.data);
      setPickedAddress(result?.data?.at(0));
      // setPickedAddress(result?.data[0]?.attributes);
    } else {
      setAddresses([]);
    }
  };

  const [account, setAccount] = useState(null);

  const getAccount = async () => {
    const { res } = await getAccountAPI();
    if (res) setAccount(res?.data);
  };

  useEffect(() => {
    if (localStorage.getItem("TOKEN")) {
      loadAddresses();
      loadCart();
      getAccount();
    }

    loadCat();
    loadLocations();
    loadProducts();
  }, []);

  const [badges, setBadges] = useState({
    wishlist: 0,
    cart: 0,
    notifications: 0,
  });

  // const getAccount = async () => {
  //   const { res } = await getAccountAPI();
  //   // console.log(res);
  //   return res;
  // };

  const addToCart = async (data) => {
    const res = await addToCartAPI(data);
    return res;
  };

  const deleteFromCart = async (id) => {
    const res = await deleteFromCartAPI(id);
    return res;
  };

  const [modalOpen, setModalOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(0);
  const [boxes, setBoxes] = useState([]);
  const [boxesDetector, setBoxesDetector] = useState(0);

  const handleCloseModal = () => {
    setModalOpen(!modalOpen);
  };

  const modalState = {
    modalOpen,
    setModalOpen,
    selectedProduct,
    setSelectedProduct,
    boxes,
    setBoxes,
    handleCloseModal,
    boxesDetector,
    setBoxesDetector,
  };

  return (
    <EsolarMainTemplate
      onLanguageHandle={props.onLanguageHandle}
      value={props.value}
      modalState={modalState}
      badges={badges}
      setBadges={setBadges}
      locations={locations}
      getAccount={getAccount}
      account={account}
      mobileView={mobileView}
      addToCart={addToCart}
      deleteFromCart={deleteFromCart}
      categories={categories}
      catLoading={catLoading}
      subCategories={subCategories}
      setSubCategories={setSubCategories}
      products={products}
      productsLoading={productsLoading}
      cart={cart}
      setCart={setCart}
      cartLoading={cartLoading}
      addresses={addresses}
      setAddresses={setAddresses}
      pickedAddress={pickedAddress}
      setPickedAddress={setPickedAddress}
      systemConfig={props.systemConfig}
      isSystemConfigLoading={props.isSystemConfigLoading}
    />
  );
};

export default EsolarMainPage;
