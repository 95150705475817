import { axiosFn, handleError } from "../../index";
import * as URL from "../../URLs";

export async function nextCheckout() {
  return await axiosFn("patch", `${URL.CHECKOUT}/next`)
    .then((res) => {
      return {
        res: res?.data,
        err: null,
        status: res?.status,
      };
    })
    .catch(handleError);
}
export async function nextCheckoutAPI() {
  let { res, status, err } = await nextCheckout();

  return { res, status, err };
}
