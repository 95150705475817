import * as React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { SpinnerCircular } from "spinners-react";

import { CircularProgress, Divider, Grid, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";

import "./RequestsEsolar.css";

import DropDownSelect from "../../../Components/Atoms/DropDownSelect/DropDownSelect";
import VendorRequestCard from "../../../Components/Molecules/VendorRequestCard/VendorRequestCard";
import DetailsVendorRequestCard from "../../../Components/Molecules/DetailsVendorRequestCard/DetailsVendorRequestCard";
import InfiniteScroll from "react-infinite-scroll-component";

function RequestsEsolarTemplate({
  Requests,
  ServiceTypes,
  BuildingTypes,
  service,
  setService,
  building,
  setBuilding,
  date,
  setDate,
  ReqLoading,
  systemConfig,
  hasMore,
  page,
  loadMore,
  setPage,
}) {
  const [activeService, setActiveService] = useState("");

  useEffect(() => {
    if (Requests?.length != 0) setActiveService(Requests?.at(0)?.id);
  }, [Requests]);

  return (
    <Container
      component="main"
      sx={{ paddingLeft: "0 !important", paddingRight: "0 !important" }}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography sx={{ fontSize: "26px", width: "fit-content" }}>
          Requests list
        </Typography>
        <Box
          sx={{
            width: "fit-content",
            display: "flex",
            alignItems: "center",
          }}
        >
          <DropDownSelect
            classes={"BorderLessSelect"}
            items={BuildingTypes}
            value={building}
            handleChange={(newValue) => setBuilding(newValue)}
            placeholder={"Building type"}
            Style={{
              fontSize: "14px",
              fontWeight: "300",
              marginTop: "0",
            }}
            placeHolderStyle={{ fontStyle: "normal" }}
            // disablePlaceHolder={false}
          />
          <DropDownSelect
            classes={"BorderLessSelect"}
            items={ServiceTypes}
            value={service}
            handleChange={(newValue) => setService(newValue)}
            placeholder={"Type of service"}
            Style={{
              fontSize: "14px",
              fontWeight: "300",
              marginTop: "0",
            }}
            placeHolderStyle={{ fontStyle: "normal" }}
            // disablePlaceHolder={false}
          />
          <DropDownSelect
            ContainerStyle={{
              flexDirection: "row",
              alignItems: "center",
            }}
            classes={"BorderLessSelect"}
            label={"Sort by:"}
            items={[
              { id: 1, name: "Date ( new to old )" },
              { id: 2, name: "Date ( old to new )" },
            ]}
            value={date}
            handleChange={(newValue) => {
              setPage(1);
              setDate(newValue);
            }}
            Style={{
              fontSize: "14px",
              fontWeight: "300",
              marginTop: "0",
              borderWidth: "0 !important",
            }}
            labelStyle={{
              fontSize: "14px",
              fontWeight: "600",
              width: "80px",
            }}
            placeHolderStyle={{
              fontStyle: "normal",
              fontSize: "14px",
              fontWeight: "300",
            }}
          />
        </Box>
      </Box>
      <Divider
        sx={{
          width: "100%",
          height: "1px",
          background: "#d8d8d8",
          margin: "10px 0",
        }}
      />
      {ReqLoading ? (
        <div className="progress-indicator">
          <CircularProgress style={{ color: "var(--secondary-color)" }} />
        </div>
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={5}>
            <InfiniteScroll
              dataLength={(Requests && Requests?.length) ?? 0}
              scrollThreshold={0.7}
              next={loadMore}
              hasMore={hasMore}
              loader={
                ReqLoading && page != 1 ? (
                  <div className="progress-indicator">
                    <CircularProgress
                      style={{ color: "var(--secondary-color)" }}
                    />
                  </div>
                ) : (
                  ""
                )
              }
              style={{ overflow: "" }}
            >
              {Requests?.map((item, i) => (
                <VendorRequestCard
                  Key={i}
                  activeService={activeService}
                  setActiveService={setActiveService}
                  Request={item}
                  systemConfig={systemConfig}
                />
              ))}
            </InfiniteScroll>
          </Grid>
          <Grid item xs={7}>
            {Requests?.length != 0 && Requests && (
              <DetailsVendorRequestCard
                Request={Requests?.find((item) => item?.id == activeService)}
                systemConfig={systemConfig}
              />
            )}
          </Grid>
        </Grid>
      )}
    </Container>
  );
}

export default RequestsEsolarTemplate;
