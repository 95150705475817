import * as URL from "../../URLs";

import { axiosFn, handleError } from "../../index";

// import { toast } from "react-toastify";

export async function createRequest(recivedData) {
  return await axiosFn("post", URL.PRODUCT_REQUESTS, recivedData)
    .then((res) => {
      return {
        res: res?.data,
        err: null,
        status: res?.status,
      };
    })
    .catch(handleError);
}
export async function createRequestAPI(data) {
  let { err, res, status } = await createRequest(data);

  return { res, err, status };
}
