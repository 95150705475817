import React, { useState } from "react";
import { useEffect, useRef } from "react";

import { CircularProgress } from "@material-ui/core";
import OrderCard from "../../../../Components/Organisms/Cards/OrderCard/OrderCard";
import Title from "../../../../Components/Atoms/Title/Title";
import { withTranslation } from "react-i18next";

function MyOrdersTemplate({ t, orders, isOrdersLoading }) {
  return (
    <>
      <Title text={t("Profile.MyOrders")} style={{ marginTop: "10px" }} />

      {isOrdersLoading ? (
        <div className="progress-indicator">
          <CircularProgress style={{ color: "var(--secondary-color)" }} />
        </div>
      ) : (
        <>
          {orders?.map((order) => {
            return (
              <OrderCard
                key={order?.id}
                id={order?.id}
                orderNumber={order?.attributes?.number}
                deliveryDate="" //(need) delivery date in order list
                products={order?.attributes?.products}
              />
            );
          })}
        </>
      )}
    </>
  );
}

export default withTranslation()(MyOrdersTemplate);
