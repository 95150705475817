import * as React from "react";

import { Box, Collapse, Container, Grid, Slide } from "@mui/material";

import Installments from "../../../../Components/Organisms/Installments/Installments";
import ProposalBody from "../../../../Components/Molecules/ProposalBody/ProposalBody";
import ProposalButtons from "../../../../Components/Molecules/ProposalButtons/ProposalButtons";
import ProposalHeader from "../../../../Components/Molecules/ProposalHeader/ProposalHeader";
import Typography from "@mui/material/Typography";
import { dateFormatter } from "../../../../utils/dateFormatter/dateFormatter";
import { icons } from "../../../../Assets/images/IconHelper";
import { useHistory } from "react-router-dom";

const ProposalDetailsTemplate = ({
  proposal,
  acceptHandler,
  rejectHandler,
}) => {
  const history = useHistory();
  const formattedDate = dateFormatter(proposal?.created_at);
  const arrivalDate = new Date(proposal?.datetime);

  return (
    <Container component="main" maxWidth="lg">
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          borderBottom: "1px #979797 solid",
        }}
      >
        {" "}
        <Box
          sx={{ display: "flex", cursor: "pointer", mt: "16px", pb: "18px" }}
        >
          <div>
            <img
              src={icons.dropdown}
              className="back_icon"
              onClick={() => history.goBack()}
            />
          </div>
          <Typography
            sx={{
              color: "#6f6f6f",
              mx: "16px",
              fontSize: "16px",
              fontWeight: 600,
            }}
          >
            Proposal Details
          </Typography>
        </Box>
      </Box>
      <Box sx={{ px: "5px" }}>
        <ProposalHeader data={proposal} my={3} />
        <ProposalButtons
          mb={"38px"}
          mt={1}
          acceptHandler={acceptHandler}
          rejectHandler={rejectHandler}
          data={proposal}
        />
        <ProposalBody data={proposal} />
        <Box sx={{ mt: 3 }}>
          <Typography sx={{ fontSize: 24, fontWeight: 900 }}>
            Installments
          </Typography>
          <Installments installments={proposal?.installments} />
        </Box>
      </Box>
    </Container>
  );
};

export default ProposalDetailsTemplate;
