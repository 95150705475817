import { axiosFn, handleError } from "../../index";
import * as URL from "../../URLs";
// import { toast } from "react-toastify";

export async function addReview(recivedData) {
  const data = { ...recivedData };
  return await axiosFn("post", URL.REVIEWS, data)
    .then((res) => {
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}
export async function addReviewAPI(data) {
  let { err, res } = await addReview(data);

  if (res) {
    if (err) {
      return err;
    }
  }
}
