import { useEffect, useRef, useState } from "react";
import { icons } from "../../../Assets/images/IconHelper";
import "./BlogInteractions.css";
import { withTranslation } from "react-i18next";
import IconOverlay from "../../Atoms/IconOverlay/IconOverlay";
import TextFieldInput from "../../Atoms/Inputs/TextField/TextFieldInput";
import PrimaryButton from "../../Atoms/Buttons/Primary/PrimaryButton";
import BlogComment from "../BlogComment/BlogComment";

const BlogInteractions = ({
  t,
  liked,
  toggleLiked,
  likes,
  comments,
  numComments,
  addComment,
  toggleModal,
}) => {
  const [newComment, setNewComment] = useState("");
  const refInput = useRef();

  const focusComment = () => {
    refInput.current.focus();
    refInput.current.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    if (localStorage.getItem("goToComment") && refInput) {
      localStorage.removeItem("goToComment");
      focusComment();
    }
  }, [refInput]);

  const handleComment = () => {
    addComment(newComment);
    setNewComment("");
  };

  return (
    <div>
      <div className="blog-socials">
        <div className="blog-interaction" onClick={toggleLiked}>
          <IconOverlay src={icons.like} iconStyle={liked ? "icon-red" : ""} />
          <p>{likes}</p>
        </div>

        <div className="blog-interaction" onClick={() => focusComment()}>
          <IconOverlay src={icons.comment} />
          <p>{numComments}</p>
        </div>

        <div className="blog-interaction" onClick={toggleModal}>
          <IconOverlay src={icons.share} />
        </div>
      </div>

      <div className="blog-newComment">
        <TextFieldInput
          value={newComment}
          onchange={(c) => setNewComment(c)}
          placeholder={t("dan kitchen.new comment")}
          onKeyDown={(e) => {
            if (e.key === "Enter") handleComment();
          }}
          inputRef={refInput}
        />
      </div>

      {comments.map((comment) => (
        <div className="blog-comment" key={comment.id}>
          <BlogComment
            by={comment.by}
            date={comment.date}
            text={comment.text}
          />
        </div>
      ))}

      <div className="blog-seemore">
        <PrimaryButton text={t("dan kitchen.see more")} classBtn="outline" />
      </div>
    </div>
  );
};

export default withTranslation()(BlogInteractions);
