import { Box } from "@mui/system";
import IconOverlay from "../../Atoms/IconOverlay/IconOverlay";
import { Typography } from "@mui/material";
import { dateFormatter } from "../../../utils/dateFormatter/dateFormatter";
import { icons } from "../../../Assets/images/IconHelper";

//data prop represents proposal data
const ProposalHeader = ({ data, my }) => {
  const formattedDate = dateFormatter(data?.created_at);
  return (
    <Box sx={{ display: "flex", justifyContent: "space-between", my: my }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
        }}
      >
        <img
          src={data?.vendor_image ? data?.vendor_image : icons.shopIcon}
          style={{ width: 48, height: 48, borderRadius: "50%" }}
        />
        <Box>
          <Typography
            gutterBottom
            variant="h5"
            component="div"
            sx={{
              fontSize: "14px",
              color: "#3f3f3f",
              fontWeight: "500",
              fontFamily: "Roboto",

              marginLeft: 2,
              marginRight: 2,
            }}
          >
            {data?.vendor_name}
          </Typography>
          <div style={{ display: "flex", marginLeft: 20, marginRight: 5 }}>
            <IconOverlay
              src={icons.starIcon}
              style={{ marginBottom: 4, marginRight: 2 }}
            />
            <Typography
              gutterBottom
              variant="h5"
              component="div"
              sx={{
                fontSize: "12px",
                color: "#9e9e9e",
                fontWeight: "500",
                fontFamily: "Roboto",
                marginRight: 0.4,
              }}
            >
              {data?.vendor_rating}
            </Typography>
            <Typography
              gutterBottom
              variant="h5"
              component="div"
              sx={{
                fontSize: "10px",
                color: "#a9a9a9",
                fontWeight: "300",
                fontFamily: "Roboto",
                marginTop: 0.5,
              }}
            >
              {"(" + data?.vendor_reviews_count + ")"}
            </Typography>
          </div>
        </Box>
      </Box>

      <Typography
        gutterBottom
        variant="h5"
        component="div"
        sx={{ fontSize: "14px", color: "#6e7a8a", fontWeight: "normal" }}
      >
        {data?.due_date ? dateFormatter(data?.due_date, true) : formattedDate}
      </Typography>
    </Box>
  );
};

export default ProposalHeader;
