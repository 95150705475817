import { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { getAllProductRequestAPI } from "../../../Contexts/API/Home/Requests/getAllProductRequest";
import { getListAllRequestsAPI } from "../../../Contexts/API/Home/Requests/getRequests";
import { SelectChoicesContext } from "../../../Contexts/SelectChoicesContext/SelectChoicesContext";
import { systemContext } from "../../../Contexts/systemContext/systemContext";
import { useServiceRequests } from "../../../Hooks/useServiceRequests/useServiceRequests";
import RequestsEsolarTemplate from "../../../Templates/Home/RequestsEsolar/RequestsEsolar.Template";

function RequestsEsolarPage() {
  const { loadBuildingTypes, loadServiceTypes, buildingTypes, serviceTypes } =
    useServiceRequests();

  useEffect(() => {
    loadBuildingTypes();
    loadServiceTypes();
  }, []);

  const { systemConfig } = useContext(systemContext);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [Requests, setRequests] = useState([]);
  const [ReqLoading, setReqLoading] = useState(true);

  const [filter, setFilter] = useState("");
  const [service, setService] = useState("");
  const [building, setBuilding] = useState("");
  const [date, setDate] = useState(1);

  const loadRequests = async () => {
    if (page == 1) {
      setReqLoading(true);
      setRequests([]);
    }
    const res = await getListAllRequestsAPI(filter, page, setPage);
    if (res) {
      setPage(page + 1);
      if (page == 1) {
        setRequests(res?.requests);
      } else {
        setRequests([...Requests, ...res?.requests]);
      }
      setHasMore(page < res?.total_pages);
      setReqLoading(false);
    }
  };

  const loadMore = () => {
    loadRequests();
  };

  useEffect(() => {
    if (localStorage.getItem("TOKEN") && systemConfig && filter) {
      loadRequests();
    }
  }, [filter, systemConfig]);

  useEffect(() => {
    setPage(1);
    const serviceType = service ? `filter_by_service_type_id=${service}` : "";
    const buildingType = building
      ? `filter_by_building_type_id=${building}`
      : "";
    const sorting = date == 1 ? "sort_by_date=desc" : "sort_by_date=asc";

    const filters = [sorting, serviceType, buildingType].filter((f) => f);
    setFilter(filters.join("&"));
  }, [service, building, date]);

  return (
    <RequestsEsolarTemplate
      Requests={Requests}
      ServiceTypes={serviceTypes}
      BuildingTypes={buildingTypes}
      service={service}
      setService={setService}
      building={building}
      setBuilding={setBuilding}
      date={date}
      setDate={setDate}
      ReqLoading={ReqLoading}
      systemConfig={systemConfig}
      hasMore={hasMore}
      page={page}
      loadMore={loadMore}
      setPage={setPage}
    />
  );
}

export default RequestsEsolarPage;
