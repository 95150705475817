import { Grid } from "@material-ui/core";
import StarRatings from "react-star-ratings";
import React, { useState } from "react";
import styles from "./ProductSummary.module.css";
import { QuantityPicker } from "../../Molecules";
import { withTranslation } from "react-i18next";
import { useEffect } from "react";
import { CircularProgress, Typography } from "@mui/material";
import IconOverlay from "../../Atoms/IconOverlay/IconOverlay";
import { icons } from "../../../Assets/images/IconHelper";

const ProductSummary = (props) => {
  const {
    t,
    productOptions,
    isVariantsLoading,
    availableVariant,
    VariantOptions,
    SelectedVariant,
    setSelectedVariant,
  } = props;
  const [rating, setRating] = useState(3);

  useEffect(() => {
    if (props.rating) {
      setRating(Number(props.rating));
    }
  }, [props.rating]);
  return (
    <Grid
      xs={12}
      item
      // className={`${styles.container}`}
    >
      <p className={styles.name}>{props.name}</p>
      {/* <p className={styles.details}>{props.details}</p>
            <p className={styles.model}>{props.model}</p> */}
      <StarRatings
        rating={rating}
        starRatedColor="var(--star-color)"
        numberOfStars={5}
        starDimension="16px"
        starSpacing="2px"
      />
      {props.was && (
        <Grid item xs={12} className={styles.priceContainer}>
          <Grid item xs={4}>
            <p>{t("product details.was")}:</p>
          </Grid>
          <Grid item xs={8}>
            <p className={styles.wasPrice}>{props.was}</p>
          </Grid>
        </Grid>
      )}

      {props.now && (
        <Grid item xs={12} className={styles.priceContainer}>
          <Grid item xs={4}>
            <p>{t("product details.now")}:</p>
          </Grid>
          <Grid item xs={8}>
            <p className={styles.nowPrice}>{props.now}</p>
          </Grid>
        </Grid>
      )}

      {props.saving && (
        <Grid item xs={12} className={styles.priceContainer}>
          <Grid item xs={4}>
            <p>{t("product details.saving")}:</p>
          </Grid>
          <Grid item xs={8}>
            <p className={styles.savingPrice}>{props.saving}</p>
          </Grid>
        </Grid>
      )}

      {isVariantsLoading ? (
        <div className="progress-indicator">
          <CircularProgress style={{ color: "var(--secondary-color)" }} />
        </div>
      ) : (
        productOptions?.map((option) => (
          <Grid item xs={12} style={{ display: "flex", alignItems: "center" }}>
            <Typography
              variant="h6"
              style={{
                color: "#202428",
                fontSize: "18px",
                fontWeight: "semi-bold",
                textTransform: "capitalize",
              }}
            >
              {option.name}:
            </Typography>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
              }}
            >
              {option.name != "color"
                ? option?.option_values?.map((value) => (
                    <div
                      className="Collection-product-tab"
                      style={
                        SelectedVariant?.find((one) => one.id == value.id)
                          ? { borderColor: "#c21703" }
                          : VariantOptions?.find((one) => one.id == value.id)
                              ?.is_available || VariantOptions?.length == 0
                          ? { borderColor: "#303030" }
                          : {
                              background: "rgb(86,86,86,0.2)",
                              borderColor: "#303030",
                              cursor: "not-allowed",
                            }
                      }
                      onClick={() => {
                        if (
                          SelectedVariant?.find(
                            (one) => one?.option_type_id == value.option_type_id
                          )
                        )
                          if (
                            SelectedVariant?.find((one) => one?.id == value.id)
                          )
                            setSelectedVariant([
                              ...SelectedVariant?.filter(
                                (one) => one?.id != value.id
                              ),
                            ]);
                          else
                            setSelectedVariant([
                              ...SelectedVariant?.filter(
                                (one) =>
                                  one?.option_type_id != value.option_type_id
                              ),
                              {
                                id: value.id,
                                option_type_id: value.option_type_id,
                              },
                            ]);
                        else
                          setSelectedVariant([
                            ...SelectedVariant,
                            {
                              id: value.id,
                              option_type_id: value.option_type_id,
                            },
                          ]);
                      }}
                    >
                      <Typography
                        className="Collection-product-tab-text"
                        style={
                          SelectedVariant?.find((one) => one.id == value.id)
                            ? { color: "#c21703" }
                            : { color: "#303030" }
                        }
                      >
                        {value.name}
                      </Typography>
                    </div>
                  ))
                : option?.option_values?.map((value) => (
                    <div
                      className="Collection-product-color-tab"
                      style={{
                        backgroundColor: value.name,
                        border:
                          SelectedVariant?.find((one) => one?.id == value.id) &&
                          "solid 1px #c21703",
                        cursor:
                          VariantOptions?.find((one) => one.id == value.id)
                            ?.is_available || VariantOptions?.length == 0
                            ? "pointer"
                            : "not-allowed",
                      }}
                      onClick={() => {
                        if (
                          SelectedVariant?.find(
                            (one) => one?.option_type_id == value.option_type_id
                          )
                        )
                          if (
                            SelectedVariant?.find((one) => one?.id == value.id)
                          )
                            setSelectedVariant([
                              ...SelectedVariant?.filter(
                                (one) => one?.id != value.id
                              ),
                            ]);
                          else
                            setSelectedVariant([
                              ...SelectedVariant?.filter(
                                (one) =>
                                  one?.option_type_id != value.option_type_id
                              ),
                              {
                                id: value.id,
                                option_type_id: value.option_type_id,
                              },
                            ]);
                        else
                          setSelectedVariant([
                            ...SelectedVariant,
                            {
                              id: value.id,
                              option_type_id: value.option_type_id,
                            },
                          ]);
                      }}
                    >
                      <IconOverlay
                        iconStyle={
                          SelectedVariant?.find((one) => one.id == value.id)
                            ? "ClickedCollectionTrueColor"
                            : "CollectionTrueColor"
                        }
                        style={{
                          display: !(
                            VariantOptions?.find((one) => one.id == value.id)
                              ?.is_available || VariantOptions?.length == 0
                          )
                            ? "none"
                            : "",
                        }}
                        src={icons.CollectionTrueColor}
                        width="13px"
                      />
                    </div>
                  ))}
            </div>
          </Grid>
        ))
      )}

      <Grid item xs={12} className={styles.priceContainer}>
        <Grid item xs={4} className={styles.productSummaryQuantity}>
          <p>{t("product details.quantity")}:</p>
        </Grid>
        <Grid item xs={8} className={styles.productSummaryQuantityPicker}>
          <QuantityPicker
            quantity={props.quantity}
            QuantityDetecter={"product-details"}
            collectionProduct={true}
            increaseItemQuantity={() => props.setQuantity(props.quantity + 1)}
            decreaseItemQuantity={() => {
              if (props.quantity > 1) props.setQuantity(props.quantity - 1);
            }}
            id={"id"}
            oldQuantity={3}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default withTranslation()(ProductSummary);
