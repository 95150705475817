import * as URL from "../../URLs";

import { axiosFn, handleError } from "../../index";

export async function deleteProposal(id) {
  return await axiosFn("delete", `${URL.PROPOSALS}/${id}`)
    .then((res) => {
      return {
        res: res?.data,
        err: null,
        status: res?.status,
      };
    })
    .catch(handleError);
}
export async function deleteProposalAPI(id) {
  let { res, err, status } = await deleteProposal(id);

  return { res, err, status };
}
