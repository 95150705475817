import * as React from "react";
import { useContext, useRef, useState } from "react";

import { Divider, Grid, textFieldClasses, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";

import { icons } from "../../../Assets/images/IconHelper";

import IconOverlay from "../../../Components/Atoms/IconOverlay/IconOverlay";
import Stepper from "../../../Components/Molecules/Stepper/Stepper";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import EtradelingProposalPhase1 from "./Phases/EtradelingProposalPhase1.Template";
import EtradelingProposalPhase2 from "./Phases/EtradelingProposalPhase2.Template";
import EtradelingProposalPhase3 from "./Phases/EtradelingProposalPhase3.Template";

function SubmitETradelingProposalTemplate({ RequestID, handleSubmit }) {
  const [proposal, setProposal] = useState({
    date: "",
    details: "",
    estimated_total_price: "",
    currency_type_id: "1",
  });

  // Files
  const [additionalFiles, setAdditionalFiles] = useState([]);
  const [deletedDocumentIDS, setDeletedDocumentIDS] = useState([]);

  // Stepper states
  const [value, setValue] = useState(1);
  const handleChange = (newValue) => {
    setValue(newValue);
  };

  const [stepsValidation, setStepsValidation] = useState([
    {
      condition: false,
      number: 1,
    },
    {
      condition: false,
      number: 2,
    },
  ]);

  const steps = [
    {
      title: "Details",
      number: 1,
      component: (
        <EtradelingProposalPhase1
          proposal={proposal}
          setProposal={setProposal}
          handleChange={handleChange}
          stepsValidation={stepsValidation}
          setStepsValidation={setStepsValidation}
          additionalFiles={additionalFiles}
          setAdditionalFiles={setAdditionalFiles}
          deletedDocumentIDS={deletedDocumentIDS}
          setDeletedDocumentIDS={setDeletedDocumentIDS}
        />
      ),
    },
    {
      title: "Preview",
      number: 2,
      component: (
        <EtradelingProposalPhase3
          proposal={proposal}
          setProposal={setProposal}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          RequestID={RequestID}
          additionalFiles={additionalFiles}
        />
      ),
    },
  ];

  return (
    <Container component="main" maxWidth="sm">
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            width: "100%",
            alignItems: "center",
          }}
        >
          <Link to="/requests">
            <IconOverlay
              style={{ transform: "rotate(90deg)" }}
              src={icons.dropdown}
              iconStyle="icon-black"
            />
          </Link>
          <Typography
            variant="h5"
            sx={{
              width: "100%",
              margin: "0 13px",
              fontSize: "26px",
              fontWeight: "600",
            }}
          >
            Submit a proposal
          </Typography>
        </Box>
        <Divider
          sx={{
            width: "100%",
            height: "1px",
            background: "#d8d8d8",
            margin: "10px 0",
          }}
        />
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Typography variant="h3" sx={{ fontSize: "26px", fontWeight: "600" }}>
            To
          </Typography>
          <Box
            sx={{
              display: "flex",
              padding: "0 30px",
            }}
          >
            <IconOverlay
              src={icons.oval}
              iconStyle="icon-primary"
              style={{ width: 50, height: 50 }}
            />
            <Box>
              <Typography
                variant="h5"
                sx={{ fontSize: "18px", padding: "0 10px", fontWeight: "600" }}
              >
                Client name
              </Typography>
              <Typography
                variant="p"
                sx={{
                  fontSize: "12px",
                  color: "#9e9e9e",
                  padding: "0 10px",
                  lineHeight: 1,
                }}
              >
                4.6 (3000)
              </Typography>
            </Box>
          </Box>
        </Box>
        <Divider
          sx={{
            width: "100%",
            height: "1px",
            background: "#d8d8d8",
            margin: "10px 0",
          }}
        />
        <Stepper
          steps={steps}
          stepValue={value}
          stepHandle={handleChange}
          stepsValidation={stepsValidation}
        />
      </Box>
    </Container>
  );
}

export default SubmitETradelingProposalTemplate;
