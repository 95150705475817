import { Menu, MenuItem } from "@material-ui/core";
import { useContext, useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { NavLink } from "react-router-dom";
import { icons } from "../../../Assets/images/IconHelper";
import { BadgesContext } from "../../../Contexts/badgesContext/badgesContext";
import IconOverlay from "../../Atoms/IconOverlay/IconOverlay";
import NotificationItem from "../../Molecules/NotificationItem/NotificationItem";

import "./NotificationPanel.css";

const NotificationPanel = (props) => {
  const { t, i18n, account } = props;
  const history = useHistory();
  const [notifications, setNotifications] = useState([]);

  const editNotifications = (id, newNot) => {
    const index = notifications.findIndex((not) => not.id === id);
    if (index !== -1) {
      let newArray = notifications.slice(0);
      newArray[index] = newNot;
      setNotifications(newArray);
    }
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const isMenuOpen = Boolean(anchorEl);

  const { badges } = useContext(BadgesContext);

  const [notificationsCount, setNotificationsCount] = useState(
    badges?.notifications
  );
  useEffect(() => {
    setNotificationsCount(notifications.length);
  }, []);

  const [open, setopen] = useState();
  const handleClose = () => {
    setopen(!open);
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
    setNotificationsCount(0);
  };

  const handleMenuClose = (to) => {
    if (to !== "close") {
      history.push(to);
    }
    setAnchorEl(null);
  };

  const menuId = "notifiction-panel";
  const renderProdMenu = (
    <Menu
      anchorEl={anchorEl}
      getContentAnchorEl={null}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={(e) => handleMenuClose("close")}
      className={i18n.language === "ar" ? "rtl_modal ar" : ""}
      PaperProps={{ className: "notification-panel" }}
    >
      <p className=" notification-header">{t("notifications.notifications")}</p>
      {notifications.map((link) => (
        <NotificationItem
          notification={link}
          editNotifications={editNotifications}
          handleMenuClose={handleMenuClose}
        />
      ))}
    </Menu>
  );

  return (
    <div className="notification-panel">
      <div
        container
        aria-controls={menuId}
        aria-haspopup={true}
        onClick={handleMenuOpen}
        color="inherit"
        className="notifications-bellicon"
      >
        <IconOverlay
          src={icons.bell}
          iconStyle="icon-black"
          width="18px"
          height="21px"
        />
        {notificationsCount > 0 ? (
          <span className="notifications-dot notifications-dot-ar">
            {notificationsCount}
          </span>
        ) : null}
      </div>

      {renderProdMenu}
    </div>
  );
};

export default withTranslation()(NotificationPanel);
