import "./OurProfile.css";

import { Grid, Typography } from "@mui/material";

import PrimaryButton from "../../../Atoms/Buttons/Primary/PrimaryButton";
import { images } from "../../../../Assets/images/IconHelper";
import { withTranslation } from "react-i18next";

const OurProfile = (props) => {
  const { t } = props;
  return (
    <Grid
      container
      justifyContent="center"
      style={{
        backgroundImage: `url(${images.ourProfile})`,
        backgroundSize: "cover",
        borderRadius: "15px",
        paddingBottom: 30,
        paddingTop: 10,
      }}
    >
      <Grid item>
        <Typography className="profile-title" align="center">
          {t("ourProfile.title")}
        </Typography>
        <Typography align="center" className="profile-text">
          {t("ourProfile.text")}
        </Typography>
        <div
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <PrimaryButton
            classBtn="btn-white btn-rounded btn-small btn-shadow"
            text={t("ourProfile.download")}
            style={{ maxWidth: "150px" }}
          />
        </div>
      </Grid>
    </Grid>
  );
};

export default withTranslation()(OurProfile);
