import React, { useState } from "react";
import "./Orders.css";
import "react-step-progress-bar/styles.css";
import "react-circular-progressbar/dist/styles.css";
import _ from "lodash";
import {
  buildStyles,
  CircularProgressbarWithChildren,
} from "react-circular-progressbar";

import { NavLink, useParams } from "react-router-dom";
import { icons } from "../../../../Assets/images/IconHelper";

import { Grid, Typography } from "@material-ui/core";
import OrderedItem from "../../../../Components/Molecules/OrderedItem/OrderedItem";
import { useEffect } from "react";
import { withTranslation } from "react-i18next";
import PrimaryButton from "../../../../Components/Atoms/Buttons/Primary/PrimaryButton";
import OrderStatus from "../../../../Components/Organisms/Modals/OrderStatus/OrderStatus";

function Separator(props) {
  return (
    <div
      style={{
        position: "absolute",
        height: "100%",
        transform: `rotate(${props.turns}turn)`,
      }}
    >
      <div style={props.style} />
    </div>
  );
}

function RadialSeparators(props) {
  const turns = 1 / props.count;
  return _.range(props.count).map((index) => (
    <Separator turns={index * turns} style={props.style} />
  ));
}

function OrderDetailsTemplate(props) {
  const {
    t,
    deliveryAddress,
    paymentMethod,
    orderSummary,
    orderItems,
    statesNumber,
  } = props;

  const { number } = useParams();
  const [status, setStatus] = useState(0);
  const [showButton, setShowButton] = useState(true);
  const statusCalc = () => {
    const state = orderSummary?.attributes?.order_state;
    if (state === "pending") {
      setStatus(0);
      setShowButton(false);
    } else if (state === "payment_requested") {
      setStatus(25);
    } else if (state === "ready") {
      setStatus(50);
    } else if (state === "shipped") {
      setStatus(75);
    } else if (state === "delivered") {
      setStatus(100);
    }
  };
  useEffect(() => statusCalc(), [paymentMethod]);

  const [openModal, setopenModal] = useState(false);
  const toggleModal = () => {
    setopenModal(!openModal);
  };

  return (
    <>
      <Grid container>
        <Grid xs={1} item>
          <NavLink to="/profile/orders">
            <img src={icons.dropdown} className="back_icon" />
          </NavLink>
        </Grid>
        <Grid item xs={11}>
          <p className="back_label">Order number: {number}</p>
        </Grid>
      </Grid>
      <Grid container className="detail_card">
        <Grid item xs={12}>
          <p className="card_title">{t("order.Deliver Address")}</p>
        </Grid>
        <Grid item xs={12}>
          <p className="card_username">
            {`${deliveryAddress?.attributes?.firstname} ${deliveryAddress?.attributes?.lastname}`}
          </p>
        </Grid>
        <Grid item xs={12}>
          <p className="card_address">{`${deliveryAddress?.attributes?.address1}`}</p>
        </Grid>
      </Grid>
      <Grid container className="detail_card">
        <Grid item xs={12}>
          <p className="card_title">{t("order.Payment Method")}</p>
        </Grid>
        <Grid item xs={12}>
          <p className="card_payment">{`${
            paymentMethod?.attributes?.payment_method_name == "check"
              ? "Cash on Delivery COD"
              : paymentMethod?.attributes?.payment_method_name
          }`}</p>
        </Grid>
      </Grid>
      <Grid container className="detail_card">
        {/* <Grid item xs={12}>
          <p className="card_title">Item 1</p>
        </Grid> */}
        {orderSummary?.attributes?.state === "canceled" ? (
          <Grid container style={{ justifyContent: "center", padding: "10px" }}>
            <h2
              style={{ textAlign: "center", color: "red" }}
              className="card_title"
            >
              Order Canceled
            </h2>
          </Grid>
        ) : (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{ display: "flex", alignItems: "center", width: "100%" }}
            >
              <div style={{ width: 100, height: 100 }}>
                <CircularProgressbarWithChildren
                  value={
                    (orderSummary?.attributes?.order_state_number /
                      statesNumber) *
                    100
                  }
                  styles={buildStyles({
                    strokeLinecap: "butt",
                    textColor:
                      orderSummary?.attributes?.order_state_number ==
                      statesNumber
                        ? "#00bb18"
                        : "#f0b70d",
                    pathColor:
                      orderSummary?.attributes?.order_state_number ==
                      statesNumber
                        ? "#00bb18"
                        : "#f0b70d",
                    trailColor: "#979797",
                  })}
                  strokeWidth={8}
                  text={`${
                    orderSummary?.attributes?.order_state_number ?? ""
                  }/${statesNumber ?? ""}`}
                >
                  <RadialSeparators
                    count={statesNumber}
                    style={{
                      background: "#fff",
                      width: "2px",
                      // This needs to be equal to props.strokeWidth
                      height: `${8}%`,
                    }}
                  />
                </CircularProgressbarWithChildren>
              </div>
              <Typography
                style={{
                  maxWidth: "260px",
                  marginInlineStart: "15px",
                  fontSize: "24px",
                  color:
                    orderSummary?.attributes?.order_state_number == statesNumber
                      ? "#00bb18"
                      : "#f0b70d",
                  fontWeight: "bold",
                  lineHeight: "normal",
                }}
              >
                {orderSummary?.attributes?.order_state ?? ""}
              </Typography>
            </div>
            <PrimaryButton
              classBtn="btn-rounded btn-small outline"
              style={{
                maxWidth: "136px",
                height: "40px",
              }}
              text={"See status details"}
              click={() => toggleModal()}
            />
          </div>
        )}
        {orderSummary?.attributes?.products?.map((item) => {
          return (
            <OrderedItem
              item_name={item?.name}
              item_currency={item?.cost_currency}
              item_price={`${item?.price} / KG`}
              item_arrive="Oct 22 - Nov 10" //(need)
              item_quantity={item?.quantity}
              order_number={number}
              item_id={item?.id}
              btn_text={t("order.Review This Order")}
              showButton={showButton}
              item_image={item?.image}
            />
          );
        })}
        {/* {orderItems?.map((item) => {
          return (
            <OrderedItem
              item_name={item?.attributes?.name}
              item_currency={item?.attributes?.currency}
              item_price={`${item?.attributes?.price} / KG`}
              item_arrive="Oct 22 - Nov 10" //(need)
              item_quantity={item?.attributes?.quantity}
              order_number={number}
              item_id={item?.id}
              btn_text={t("order.Review This Order")}
              showButton={showButton}
              item_image={item?.image}
            />
          );
        })} */}
      </Grid>
      <Grid container className="detail_card">
        <Grid item xs={12}>
          <p className="card_title">{t("order.Order Summery")}</p>
        </Grid>
        <Grid item xs={8}>
          <p className="">{`${t("order.Subtotal")} ( ${
            orderSummary?.attributes?.item_count
          } ${t("order.items")} )`}</p>
        </Grid>
        <Grid item xs={4}>
          <p className="">{`${orderSummary?.attributes?.currency} ${orderSummary?.attributes?.item_total}`}</p>
        </Grid>
        <Grid item xs={8} className="order-underline">
          <p>+ {t("order.Delivery")}:</p>
        </Grid>
        <Grid item xs={4} className="order-underline">
          <p>{`${orderSummary?.attributes?.currency} ${orderSummary?.attributes?.ship_total}`}</p>
        </Grid>
        {/* <img src={images.line} /> */}
        <Grid item xs={8}>
          <p className="">{t("order.Total")}</p>
        </Grid>
        <Grid item xs={4}>
          <p className="">{`${orderSummary?.attributes?.currency} ${orderSummary?.attributes?.total}`}</p>
        </Grid>
      </Grid>
      <OrderStatus
        open={openModal}
        handleClose={toggleModal}
        history={orderSummary?.attributes?.order_state_history?.map((item) => ({
          ...item,
          checked: true,
        }))}
        remaining={orderSummary?.attributes?.remaining_order_states}
      />
    </>
  );
}

export default withTranslation()(OrderDetailsTemplate);

// -                  <p
//                     className="step_text"
//                     style={
//                       accomplished
//                         ? {
//                             color:
//                               status === 100
//                                 ? "var(--success-color)"
//                                 : "var(--primary-color)",
//                           }
//                         : { color: "#c7c7c7" }
//                     }
//                   >
//                     {" "}
//                     {t("order.In Processing")}
//                   </p>
//                 </div>
//               )}
//             </Step>
//             <Step transition="scale">
//               {({ accomplished }) => (
//                 <div
//                   style={{
//                     display: "flex",
//                     flexDirection: "column",
//                     alignItems: "center",
//                   }}
//                 >
//                   <div
//                     className="step"
//                     style={
//                       accomplished
//                         ? {
//                             backgroundColor:
//                               status === 100
//                                 ? "var(--success-color)"
//                                 : "var(--primary-color)",
//                           }
//                         : { backgroundColor: "#c7c7c7" }
//                     }
//                   >
//                     <p className="step_content">2</p>
//                   </div>
//                   <p
//                     className="step_text"
//                     style={
//                       accomplished
//                         ? {
//                             color:
//                               status === 100
//                                 ? "var(--success-color)"
//                                 : "var(--primary-color)",
//                           }
//                         : { color: "#c7c7c7" }
//                     }
//                   >
//                     {" "}
//                     {t("Payment Requested")}
//                   </p>
//                 </div>
//               )}
//             </Step>
//             <Step transition="scale">
//               {({ accomplished }) => (
//                 <div
//                   style={{
//                     display: "flex",
//                     flexDirection: "column",
//                     alignItems: "center",
//                   }}
//                 >
//                   <div
//                     className="step"
//                     style={
//                       accomplished
//                         ? {
//                             backgroundColor:
//                               status === 100
//                                 ? "var(--success-color)"
//                                 : "var(--primary-color)",
//                           }
//                         : { backgroundColor: "#c7c7c7" }
//                     }
//                   >
//                     <p className="step_content">3</p>
//                   </div>
//                   <p
//                     className="step_text"
//                     style={
//                       accomplished
//                         ? {
//                             color:
//                               status === 100
//                                 ? "var(--success-color)"
//                                 : "var(--primary-color)",
//                           }
//                         : { color: "#c7c7c7" }
//                     }
//                   >
//                     {t("Ready")}
//                   </p>
//                 </div>
//               )}
//             </Step>
//             <Step transition="scale">
//               {({ accomplished }) => (
//                 <div
//                   style={{
//                     display: "flex",
//                     flexDirection: "column",
//                     alignItems: "center",
//                   }}
//                 >
//                   <div
//                     className="step"
//                     style={
//                       accomplished
//                         ? {
//                             backgroundColor:
//                               status === 100
//                                 ? "var(--success-color)"
//                                 : "var(--primary-color)",
//                           }
//                         : { backgroundColor: "#c7c7c7" }
//                     }
//                   >
//                     <p className="step_content">4</p>
//                   </div>
//                   <p
//                     className="step_text"
//                     style={
//                       accomplished
//                         ? {
//                             color:
//                               status === 100
//                                 ? "var(--success-color)"
//                                 : "var(--primary-color)",
//                           }
//                         : { color: "#c7c7c7" }
//                     }
//                   >
//                     {t("order.Out for delivery")}
//                   </p>
//                 </div>
//               )}
//             </Step>
//             <Step transition="scale">
//               {({ accomplished }) => (
//                 <div
//                   style={{
//                     display: "flex",
//                     flexDirection: "column",
//                     alignItems: "center",
//                   }}
//                 >
//                   <div
//                     className="step"
//                     style={
//                       accomplished
//                         ? {
//                             backgroundColor:
//                               status === 100
//                                 ? "var(--success-color)"
//                                 : "var(--primary-color)",
//                           }
//                         : { backgroundColor: "#c7c7c7" }
//                     }
//                   >
//                     <p className="step_content">5</p>
//                   </div>
//                   <p
//                     className="step_text"
//                     style={
//                       accomplished
//                         ? {
//                             color:
//                               status === 100
//                                 ? "var(--success-color)"
//                                 : "var(--primary-color)",
//                           }
//                         : { color: "#c7c7c7" }
//                     }
//                   >
//                     {t("order.Delivered")}
//                   </p>
//                 </div>
//               )}
//             </Step>
//           </ProgressBar>
