import {
  Container,
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";

const DropDownSelect = ({
  name,
  id,
  items,
  value,
  handleChange,
  label,
  placeholder,
  error = false,
  errorMessage,
  Style,
  placeHolderStyle = "",
  labelStyle,
  classes,
  ContainerStyle,
  disablePlaceHolder = true,
  multiple,
  returnIDonly = true,
}) => {
  return (
    <FormControl fullWidth error={error} style={ContainerStyle}>
      <Typography style={labelStyle}>{label}</Typography>
      <Select
        name={name}
        id={id}
        className={classes}
        sx={{ mt: "12px" }}
        value={value}
        onChange={(e) => handleChange(e.target.value)}
        displayEmpty
        fullWidth
        style={Style}
        multiple={multiple}
      >
        {placeholder && (
          <MenuItem value="" disabled={disablePlaceHolder}>
            {placeHolderStyle ? (
              <Typography style={placeHolderStyle}>{placeholder}</Typography>
            ) : (
              <em>{placeholder}</em>
            )}
          </MenuItem>
        )}

        {items?.map((item) =>
          returnIDonly ? (
            <MenuItem value={item?.id}> {item?.name}</MenuItem>
          ) : (
            <MenuItem value={item}> {item?.name}</MenuItem>
          )
        )}
      </Select>
      {error && <FormHelperText>{errorMessage}</FormHelperText>}
    </FormControl>
  );
};

export default DropDownSelect;
