import { Box, CircularProgress } from "@mui/material";
import { useContext, useState } from "react";

import { Container } from "@mui/system";
import DeleteRequestModal from "../../../../Components/Organisms/Modals/DeleteRequestModal/DeleteRequestModal";
import InfiniteScroll from "react-infinite-scroll-component";
import RequestCard from "../../../../Components/Organisms/RequestCard/RequestCard";
import TabNav from "../../../../Components/Molecules/TabNav/TabNav";
import { queryParamsFormatter } from "../../../../utils/queryParamsFormatter/queryParamsFormatter";
import { systemContext } from "../../../../Contexts/systemContext/systemContext";

// import RequestCard from "../../../../Components/Organisms/Cards/RequestCard/RequestCard";

function MyRequestsTemplate({
  currentPage,
  hasMore,
  requests,
  isLoading,
  handleDelete,
  handleViewRequestDetails,
  handleTabsChange,
  queryParams,
  loadMoreProductRequests,
  handleModal,
  modalOpen,
}) {
  const { systemConfig } = useContext(systemContext);
  const EsolarRequestCardInfo = {
    rowTitles: [
      "Type of services:",
      "Building type:",
      "Submitted of proposals :",
    ],
    rowInfo: [""],
  };
  const EtradelingRequestCardInfo = {
    rowTitles: ["Product Name:", "Category:", "Status:"],
  };
  const [selectedId, setSelectedId] = useState(null);
  //Missing number of submitted proposals from back end
  return (
    <Container component="main" maxWidth="lg">
      <h1>My Requests</h1>
      <TabNav handleTabsChange={handleTabsChange} />
      {isLoading ? (
        <Box sx={{ display: "flex", justifyContent: "center", mt: "50%" }}>
          <CircularProgress style={{ color: "var(--secondary-color)" }} />
        </Box>
      ) : (
        <>
          <InfiniteScroll
            dataLength={requests && requests?.length}
            hasMore={hasMore}
            scrollThreshold={0.99}
            loader={
              <Box
                sx={{ display: "flex", justifyContent: "center", mt: "50%" }}
              >
                <CircularProgress style={{ color: "var(--secondary-color)" }} />
              </Box>
            }
            next={() => {
              loadMoreProductRequests(
                queryParamsFormatter({ ...queryParams, page: currentPage })
              );
            }}
          >
            {requests?.map((request) => {
              return (
                <RequestCard
                  id={request?.id}
                  date={request?.datetime}
                  handleDelete={handleDelete}
                  handleViewRequestDetails={handleViewRequestDetails}
                  rowTitles={
                    systemConfig["System Configurations"]["Project Name"] ===
                    "esolar"
                      ? EsolarRequestCardInfo.rowTitles
                      : EtradelingRequestCardInfo.rowTitles
                  }
                  rowInfo={[
                    request?.product_name
                      ? request?.product_name
                      : request?.service_type_name,
                    request?.category_name
                      ? request?.category_name
                      : request?.building_type_name,
                    request?.quotations
                      ? request?.quotations.length + " Quotation"
                      : request?.proposals.length,
                  ]}
                  handleModal={handleModal}
                  modalOpen={modalOpen}
                  setSelectedId={setSelectedId}
                  statusColor={"#3ed181"}
                  acceptedQuotation={
                    request?.accepted_quotation
                      ? request?.acceptedQuotation
                      : request?.accepted_proposal
                  }
                />
              );
            })}
          </InfiniteScroll>
        </>
      )}
      <DeleteRequestModal
        open={modalOpen}
        id={selectedId}
        deleteHandler={handleDelete}
        handleClose={handleModal}
      />
    </Container>
  );
}

export default MyRequestsTemplate;
