import {
  Box,
  Divider,
  Grid,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import { Route, Switch, useHistory } from "react-router-dom";

import IconOverlay from "../../../Components/Atoms/IconOverlay/IconOverlay";
import PrimaryButton from "../../../Components/Atoms/Buttons/Primary/PrimaryButton";
import VendorAboutTemplate from "./VendorAbout.template";
import { VendorProductsPage } from "../../../Pages/Home/VendorDetails/VendorProducts.page";
import { icons } from "../../../Assets/images/IconHelper";
import { useState } from "react";

const VendorDetailsSideNav = ({ handleSwitch, currentPage, tabs }) => {
  return (
    <List
      sx={{
        width: "100%",
        maxWidth: 360,
        bgcolor: "background.paper",
        boxShadow: "0 2px 8px 0 rgba(0, 0, 0, 0.25)",
        padding: "0",
      }}
      component="nav"
      aria-labelledby="nested-list-subheader"
    >
      {tabs.map((tab) => {
        return (
          <ListItemButton
            style={{
              borderBottom: "1px solid #e0e0e0",
              borderLeft:
                currentPage === tab.tabName ? "4px solid #363738" : "",
              height: "70px",
              backgroundColor: currentPage === tab.tabName ? "#f5f5f5" : "",
            }}
            onClick={() => handleSwitch(tab.tabName, tab.link)}
          >
            <ListItemIcon>
              <IconOverlay src={tab.tabIcon} />
            </ListItemIcon>
            <ListItemText
              primary={tab.tabName}
              primaryTypographyProps={{
                style: {
                  fontSize: "18px",
                  color: "#545454",
                },
              }}
            />
          </ListItemButton>
        );
      })}
    </List>
  );
};

export const VendorDetailsTemplate = ({ data, my, id = 3 }) => {
  const tabs = [
    {
      tabName: "About",
      tabIcon: icons.vendorAboutIcon,
      link: `/vendordetails/${id}/about`,
    },
    {
      tabName: "Products",
      tabIcon: icons.vendorProductsIcon,
      link: `/vendordetails/${id}/products`,
    },
    {
      tabName: "Our Work",
      tabIcon: icons.vendorOurWorkIcon,
      link: `/vendordetails/${id}/ourwork`,
    },
    {
      tabName: "Reviews",
      tabIcon: icons.vendorReviewsIcon,
      link: `/vendordetails/${id}/reviews`,
    },
  ];
  const [currentPage, setCurrentPage] = useState("About");
  const history = useHistory();
  const handleSwitch = (pageTitle, link) => {
    setCurrentPage(pageTitle);
    history.push(link);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={4} xl={2}>
        <VendorDetailsSideNav
          handleSwitch={handleSwitch}
          currentPage={currentPage}
          tabs={tabs}
        />
      </Grid>
      <Grid item xs={12} sm={8} xl={10}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mb: "24px",
            mt: "15px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            <img
              src={data?.vendor_image ? data?.vendor_image : icons.shopIcon}
              style={{ width: 58, height: 58, borderRadius: "50%" }}
            />
            <Box>
              <Typography
                gutterBottom
                variant="h5"
                component="div"
                sx={{
                  fontSize: "14px",
                  color: "#3f3f3f",
                  fontWeight: "500",
                  fontFamily: "Roboto",
                  marginLeft: 2,
                  marginRight: 2,
                }}
              >
                {data?.vendor_name}
              </Typography>
              <div style={{ display: "flex", marginLeft: 20, marginRight: 5 }}>
                <IconOverlay
                  src={icons.starIcon}
                  style={{ marginBottom: 4, marginRight: 2 }}
                />
                <Typography
                  gutterBottom
                  variant="h5"
                  component="div"
                  sx={{
                    fontSize: "12px",
                    color: "#9e9e9e",
                    fontWeight: "500",
                    fontFamily: "Roboto",
                    marginRight: 0.4,
                  }}
                >
                  {data?.vendor_rating}
                </Typography>
                <Typography
                  gutterBottom
                  variant="h5"
                  component="div"
                  sx={{
                    fontSize: "10px",
                    color: "#a9a9a9",
                    fontWeight: "300",
                    fontFamily: "Roboto",
                    marginTop: 0.5,
                  }}
                >
                  {"(" + data?.vendor_reviews_count + ")"}
                </Typography>
              </div>
            </Box>
          </Box>
          <Box sx={{ display: "flex" }}>
            <PrimaryButton
              size="small"
              color="primary"
              text="Request Service from this vendor"
              classBtn=" rounded-20 outline-secondry btn-white-secondry"
              style={{
                marginTop: "11px",
              }}
              // click={() => history.push(`/edit_request/${id}`)}
            />{" "}
          </Box>
        </Box>
        <Divider />
        <Typography
          sx={{ mt: "18px", mb: "16px", fontSize: "18px", fontWeight: 600 }}
        >
          {currentPage}
        </Typography>
        <Divider />
        <Switch>
          <Route
            exact
            path="/vendordetails/:id/about"
            component={VendorAboutTemplate}
          />
          <Route
            exact
            path="/vendordetails/:id/products"
            component={VendorProductsPage}
          />
          <Route
            exact
            path="/VendorDetails/:id/ourwork"
            component={() => <h1>our work</h1>}
          />
          <Route
            exact
            path="/VendorDetails/:id/review"
            component={() => <h1>review</h1>}
          />
        </Switch>
      </Grid>
    </Grid>
  );
};
