import { CircularProgress, Typography } from "@material-ui/core";

import BestSeller from "../../../Components/Organisms/HomePage/BestSeller/BestSeller";
import EtradelingCategoriesCarousel from "../../../Components/Organisms/Carousels/EtradelingCategoriesCarousel/EtradelingCategoriesCarousel";
import HomeCarousel from "../../../Components/Organisms/HomePage/HomeCarousel/HomeCarousel";
import HomeFeaturesBanner from "../../../Components/Organisms/HomeFeaturesBanner/HomeFeaturesBanner";
import RequestForQuotation from "../../../Components/Molecules/RequestForQuotation/RequestForQuotation";
import { icons } from "../../../Assets/images/IconHelper";

const EtradelingHomeTemplate = ({
  categories,
  areCategoriesLoading,
  featuredBanners,
  bestSellerProducts,
  areProductsLoading,
}) => {
  return (
    <>
      <div className="esolar-home-template">
        <HomeCarousel featuredBanners={featuredBanners} />
      </div>
      <div className="esolar-home-template">
        <Typography
          align="center"
          className="mb-20px"
          style={{
            fontSize: 38,
            fontWeight: "bold",
            color: "#174c7e",
          }}
        >
          Categories
        </Typography>
        {areCategoriesLoading ? (
          <div className="progress-indicator">
            <CircularProgress style={{ color: "var(--secondary-color)" }} />
          </div>
        ) : (
          <EtradelingCategoriesCarousel categories={categories} />
        )}
      </div>
      {/* Request for Quotation section */}
      <div className="esolar-home-template">
        <RequestForQuotation />
      </div>

      <div
        className="esolar-home-template pb-20px pt-10px"
        style={{
          backgroundColor: "rgba(216, 216, 216, 0.15)",
        }}
      >
        {areProductsLoading ? (
          <div className="progress-indicator">
            <CircularProgress style={{ color: "var(--secondary-color)" }} />
          </div>
        ) : (
          <BestSeller title={"Best Seller"} items={bestSellerProducts} />
        )}
      </div>

      <div className="esolar-home-template"></div>
      <HomeFeaturesBanner
        features={[
          {
            icon: icons.etradelingCategoriesIcon,
            title: "Browse By Categores",
          },
          { icon: icons.eTradelingDeliveryIcon, title: "Fast Delivery" },
          { icon: icons.etradelingWalletIcon, title: "Easy Payment Option" },
        ]}
        backgroundImage={""}
        backgroundColor={"#174c7e"}
        title={"Our Benefits"}
      />
      <div
        className="esolar-home-template  pt-10px pb-20px"
        style={{
          backgroundColor: "rgba(216, 216, 216, 0.15)",
        }}
      >
        {areProductsLoading ? (
          <div className="progress-indicator">
            <CircularProgress style={{ color: "var(--secondary-color)" }} />
          </div>
        ) : (
          <BestSeller title={"Best Seller"} items={bestSellerProducts} />
        )}
      </div>
    </>
  );
};

export default EtradelingHomeTemplate;
