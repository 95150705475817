import {
  Box,
  CircularProgress,
  Container,
  Typography,
} from "@material-ui/core";

import RequestQuotationForm from "../../../Components/Organisms/RequestQuotationForm/RequestQuotationForm";

const SubmitRFQTemplate = ({
  categories,
  loading,
  setQueryParams,
  subCategories,
  tradeTerms,
  units,
  handleSubmitQuotationRequest,
  isRequestSubmitted,
  acceptHandler,
  rejectHandler,
  productRequest,
}) => {
  return (
    <Container maxWidth="lg" style={{ paddingLeft: 100, paddingRight: 100 }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          backgroundColor: "#f8f8f8",
          paddingY: "48px",
          borderRadius: 8,
        }}
      >
        <Typography style={{ fontSize: 14, color: "#252a34" }}>
          We'd Love to help you
        </Typography>
        <Typography variant="h4" sx={{ fontSize: 36 }}>
          Submit RFQ
        </Typography>
        <Typography align="center" style={{ fontSize: 18, color: "#252a34" }}>
          Tell suppliers what you need The more specific your information, the
          faster response you will get.
        </Typography>
      </Box>
      {loading ? (
        <div className="progress-indicator">
          <CircularProgress style={{ color: "var(--secondary-color)" }} />
        </div>
      ) : (
        <RequestQuotationForm
          categories={categories}
          setQueryParams={setQueryParams}
          subCategories={subCategories}
          tradeTerms={tradeTerms}
          units={units}
          handleSubmitQuotationRequest={handleSubmitQuotationRequest}
          isRequestSubmitted={isRequestSubmitted}
          acceptHandler={acceptHandler}
          rejectHandler={rejectHandler}
          productRequest={productRequest}
        />
      )}
    </Container>
  );
};

export default SubmitRFQTemplate;
