import * as React from "react";
import { useState, useRef, useEffect } from "react";
import * as yup from "yup";
import { useFormik } from "formik";

import { Grid, Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";

import PrimaryButton from "../../../../Components/Atoms/Buttons/Primary/PrimaryButton";
import { uploadToS3API } from "../../../../Contexts/API/S3/uploadToS3";
import { AddCircleOutline } from "@material-ui/icons";
import UploadedFile from "../../../../Components/Molecules/UploadedFile/UploadedFile";

const EtradelingProposalPhase1 = ({
  proposal,
  setProposal,
  handleChange,
  stepsValidation,
  setStepsValidation,
  additionalFiles,
  setAdditionalFiles,
  deletedDocumentIDS,
  setDeletedDocumentIDS,
}) => {
  // VALIDATION SCHEMA
  const validationSchema = yup.object({
    date: yup.date().required("Date is required"),
    estimated_total_price: yup
      .number()
      .required("Estimated total price is required"),
    details: yup.string().required("Details is required"),
  });

  //FORMIK
  const formik = useFormik({
    initialValues: {
      date: proposal?.date,
      estimated_total_price: proposal?.estimated_total_price,
      details: proposal?.details,
    },
    validationSchema: validationSchema,
    onSubmit: () => {
      handleChange(2);
    },
  });

  // validating steps
  useEffect(() => {
    if (
      formik.isValid &&
      formik.values.estimated_total_price &&
      formik.values.date &&
      formik.values.details
    ) {
      setStepsValidation([
        ...stepsValidation.filter((item) => item.number != 1),
        { number: 1, condition: true },
      ]);
    } else {
      setStepsValidation([
        { number: 1, condition: false },
        { number: 2, condition: false },
      ]);
    }
  }, [formik.isValid]);

  // details limit
  const [textAreaCharCount, setTextAreaCharCount] = useState(
    proposal?.details?.length
  );
  useEffect(() => {
    setTextAreaCharCount(proposal?.details?.length);
  }, [proposal?.details]);

  //REFS

  const inputFilee = useRef(null);

  const onFileChangee = async (event, setter, state) => {
    if (event.target.files && event.target.files[0]) {
      const arrayOfExtensions = [];
      const files = event.target.files;
      for (let [key, value] of Object.entries(files)) {
        arrayOfExtensions.push({
          extension: value.name.substring(value.name.indexOf(".") + 1),
        });
      }
      console.log(arrayOfExtensions);
      const { res } = await uploadToS3API(files, arrayOfExtensions);
      console.log(res);
      // const fileUrls = files.map((file) => URL.createObjectURL(file));

      setter([...state, ...res]);
      // dispatch(uploadProductImageStart(img));
      // setProfilePicture(URL.createObjectURL(img));
    }
  };

  const removeFile = (setter, state, id, backendId) => {
    const newState = state.filter((file, index) => id !== index);
    setter(newState);
    setDeletedDocumentIDS([...deletedDocumentIDS, backendId]);
  };

  return (
    <Box component="form" sx={{ mt: 2, width: "100%" }}>
      <Typography
        variant="h6"
        sx={{ fontSize: "19px", width: "100%", marginBottom: "5px" }}
      >
        <span style={{ color: "red" }}>Tip:</span> you can change date and time
        if not suitable to you
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sx={{ marginTop: "20px" }}>
          <Typography sx={{ marginBottom: "10px" }}>Due date</Typography>
          <TextField
            fullWidth
            type="date"
            id="date"
            name="date"
            value={formik.values?.date ?? proposal?.date}
            onChange={(e) => {
              setProposal({ ...proposal, date: e.target.value });
              formik.setFieldValue("date", e.target.value);
            }}
            error={formik.touched.date && Boolean(formik.errors.date)}
            helperText={formik.touched.date && formik.errors.date}
          />
        </Grid>
        <Grid item xs={12} sm={12} sx={{ marginTop: "10px" }}>
          <Typography sx={{ marginBottom: "10px" }}>Details</Typography>
          <TextareaAutosize
            className="textarea"
            style={{
              width: "100%",
              height: "230px",
              resize: "none",
              // paddingLeft: "10px",
              paddingTop: "10px",
              borderTop:
                formik.touched.details && Boolean(formik.errors.details)
                  ? "1px solid #d32f2f"
                  : "1px solid black",
              borderLeft:
                formik.touched.details && Boolean(formik.errors.details)
                  ? "1px solid #d32f2f"
                  : "1px solid black",
              borderRight:
                formik.touched.details && Boolean(formik.errors.details)
                  ? "1px solid #d32f2f"
                  : "1px solid black",
              borderBottom: "1px white solid",
            }}
            maxLength="500"
            aria-label="empty textarea details"
            placeholder="Tell us about what you want"
            value={formik.values?.details ?? proposal?.details}
            onChange={(e) => {
              setProposal({ ...proposal, details: e.target.value });
              formik.setFieldValue("details", e.target.value);
            }}
          />

          {/* Word count inside the text area */}
          <Box
            sx={{
              marginTop: "-6px",
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
              borderRight:
                formik.touched.details && Boolean(formik.errors.details)
                  ? "1px solid #d32f2f"
                  : "1px solid black",
              borderLeft:
                formik.touched.details && Boolean(formik.errors.details)
                  ? "1px solid #d32f2f"
                  : "1px solid black",
            }}
          >
            <Typography
              sx={{
                mr: "25px",
                color: "#4a4a48",
                mb: "10px",
              }}
            >
              {textAreaCharCount}/500
            </Typography>
          </Box>
          {/* Attach files box */}
          <Box
            sx={{
              backgroundColor: "#D8D8D8",
              width: "100%",
              borderBottom:
                formik.touched.details && Boolean(formik.errors.details)
                  ? "1px solid #d32f2f"
                  : "1px solid #3c4550",
              borderRight:
                formik.touched.details && Boolean(formik.errors.details)
                  ? "1px solid #d32f2f"
                  : "1px solid #3c4550",
              borderLeft:
                formik.touched.details && Boolean(formik.errors.details)
                  ? "1px solid #d32f2f"
                  : "1px solid #3c4550",
            }}
          >
            <Typography
              sx={{
                color: "#000000",
                mb: "12px",
                fontWeight: "500",
                fontSize: "14px",
                ml: "10px",
                pt: "10px",
                textDecoration: "underline",
                cursor: additionalFiles?.length < 5 ? "pointer" : "not-allowed",
              }}
              onClick={() => {
                if (additionalFiles?.length < 5) inputFilee.current.click();
              }}
            >
              <input
                multiple
                type="file"
                id="file"
                ref={inputFilee}
                style={{ display: "none" }}
                onChange={(event) => {
                  onFileChangee(event, setAdditionalFiles, additionalFiles);
                }}
              />
              Attach files for additional information ({" "}
              {additionalFiles?.length} / 5 )
            </Typography>
            {additionalFiles?.length > 0 ? (
              <Box style={{ display: "flex", padding: "10px" }}>
                {additionalFiles.map((file, index) => {
                  return (
                      <UploadedFile
                        fileType={file.extension}
                        fileName={file.file_name}
                        id={index}
                        handleRemove={removeFile}
                        files={additionalFiles}
                        setter={setAdditionalFiles}
                        backendId={file.id}
                      />
                  );
                })}
              </Box>
            ) : (
              ""
            )}
          </Box>

          {formik.touched.details && formik.errors.details && (
            <Typography
              sx={{
                color: "#d32f2f",
                fontWeight: "400",
                fontSize: " 0.75rem",
                lineHeight: "1.66",
                letterSpacing: "0.03333em",
                textAlign: "left",
                marginTop: "3px",
                marginRight: "14px",
                marginBottom: 0,
                marginLeft: "14px",
              }}
            >
              {formik.errors.details}
            </Typography>
          )}
        </Grid>
        <Grid item container spacing={2} xs={12} sm={12}>
          <Typography
            sx={{
              marginLeft: "16px",
              marginBottom: "-6px",
              marginTop: "20px",
            }}
          >
            Estimated total price
          </Typography>
          <Grid item xs={10}>
            <TextField
              fullWidth
              type="number"
              id="estimated_total_price"
              name="estimated_total_price"
              value={
                formik.values?.estimated_total_price ??
                proposal?.estimated_total_price
              }
              onChange={(e) => {
                setProposal({
                  ...proposal,
                  estimated_total_price: parseInt(e.target.value),
                });
                formik.setFieldValue(
                  "estimated_total_price",
                  parseInt(e.target.value)
                );
              }}
              error={
                formik.touched.estimated_total_price &&
                Boolean(formik.errors.estimated_total_price)
              }
              helperText={
                formik.touched.estimated_total_price &&
                formik.errors.estimated_total_price
              }
            />
          </Grid>
          <Grid item xs={2}>
            <Select
              labelId="currency_type_id"
              id="currency_type_id"
              style={{ fontSize: "14px", paddingRight: "30px !important" }}
              value={proposal?.currency_type_id}
              onChange={(e) =>
                setProposal({ ...proposal, currency_type_id: e.target.value })
              }
              defaultValue={1}
              fullWidth
            >
              <MenuItem value={1}>EGP</MenuItem>
              <MenuItem value={2}>USD</MenuItem>
              <MenuItem value={3}>USD</MenuItem>
            </Select>
          </Grid>
        </Grid>
      </Grid>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          marginTop: "30px",
        }}
      >
        <PrimaryButton
          text={"Next"}
          classBtn="primary proposal"
          style={{
            maxWidth: "320px",
          }}
          click={formik.handleSubmit}
        />
      </Box>
    </Box>
  );
};
export default EtradelingProposalPhase1;
