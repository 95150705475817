import * as URL from "../../URLs";

import { axiosFn, handleError } from "../../index";

export async function getTaxonCollections(id, page, filter) {
  return await axiosFn(
    "get",
    `${
      URL.COLLECTIONS
    }/${id}/taxon_collections?products_details=true&subcategories_details=true&page=${page}&${
      filter ?? ""
    }`
  )
    .then((res) => {
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}
export async function getTaxonCollectionsAPI(id, page, filter) {
  let { err, res } = await getTaxonCollections(id, page, filter);

  if (res) {
    return res;
  }

  if (err) {
    return err;
  }
}
