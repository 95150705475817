import "./EsolarExploreCard.css";

import { Grid, Typography } from "@mui/material";

import PrimaryButton from "../../Atoms/Buttons/Primary/PrimaryButton";
import { images } from "../../../Assets/images/IconHelper";

const EsolarExploreCard = ({
  image,
  buttonText,
  title,
  description = "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500sf",
  buttonHandler,
}) => {
  return (
    <Grid container style={{ border: "solid #e2e2e2 2px", borderRadius: 10 }}>
      <Grid
        item
        xs={3}
        container
        justifyContent="center"
        style={{
          backgroundImage: `url(${image})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "left",
        }}
      ></Grid>
      <Grid
        item
        xs={9}
        container
        justifyContent="center"
        direction="column"
        paddingTop={3}
        paddingBottom={3}
        paddingLeft={3}
        paddingRight={3}
      >
        <Typography className={"explore-card-title"}>{title}</Typography>
        <Typography className="futuraPT">{description}</Typography>
        <div style={{ width: "30%", marginTop: 10 }}>
          <PrimaryButton
            text={buttonText}
            classBtn={" outline-secondry explore-card-button btn-shadow"}
            click={buttonHandler}
          />
        </div>
      </Grid>
    </Grid>
  );
};

export default EsolarExploreCard;
