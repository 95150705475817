import MyAddressTemplate from "../../../Templates/Home/Profile/MyAddress/MyAddress.Template";

import { toast } from "react-toastify";
import { useAddress } from "../../../Hooks/useAddress/useAddress";
import { useEffect } from "react";

const MyAddress = (props) => {
  const {
    addresses,
    setAddresses,
    addAddress,
    editAddress,
    deleteAddress,
    openModal,
    toggleModalVisibility,
    isAddressLoading,
    selectAddress,
    loadAddresses,
  } = useAddress();

  useEffect(() => {
    loadAddresses();
  }, []);

  return (
    <MyAddressTemplate
      setAddresses={setAddresses}
      addresses={addresses ?? []}
      open={openModal}
      addAddress={addAddress}
      deleteAddress={deleteAddress}
      editAddress={editAddress}
      handleClose={toggleModalVisibility}
      isCheckout={props.checkout}
      isAddressLoading={isAddressLoading}
      selectAddress={selectAddress}
    />
  );
};

export default MyAddress;

// const [addresses, setAddresses] = useState([]);

// const [pickedAddress, setpickedAddress] = useState(0);

// const getAddresses = async () => {
//   let result = await getAddressesAPI();
//   setErr(result === "Something went wrong!");

//   if (!err) {
//     setAddresses(result?.data);
//   } else {
//     setAddresses([]);
//   }
// };
