import * as URL from "../../URLs";

import { axiosFn, handleError } from "../../index";

export async function getCategoriesList(queryParams) {
  return await axiosFn(
    "get",
    `${URL.CATEGORIES}${queryParams ? `?${queryParams}` : ""}`,
    null,
    false
  )
    .then((res) => {
      return {
        res: res?.data,
        err: null,
        status: res?.status,
      };
    })
    .catch(handleError);
}

export async function getCategoriesListAPI(queryParams) {
  let { err, res, status } = await getCategoriesList(queryParams);

  return { res, err, status };
}
