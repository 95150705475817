import AddAudio from "./icons/add-audio.svg";
import AlsallabLogin from "./AlsallabLogin.webp";
import BestSeller from "./icons/bestSeller.svg";
import CarouselLeftArrow from "./icons/CarouselLeftArrow.svg";
import CarouselRightArrow from "./icons/CarouselRightArrow.svg";
import Clear from "./icons/clear.svg";
import CollectionImage from "./CollectionImage.webp";
import CollectionTrueColor from "./icons/CollectionTrueColor.svg";
import MessengerIcon from "./icons/messenger.svg";
import MoreBtn from "./icons/more-btn.svg";
import NewArrival from "./icons/newArrival.svg";
import Offers from "./icons/offers.svg";
import OutLet from "./icons/outLet.svg";
import PlusMessage from "./icons/plus-message.svg";
import SearchMessage from "./icons/search-message.svg";
import SendBtn from "./icons/send-btn.svg";
import TimeLineCheck from "./icons/TimeLineCheck.svg";
import addToBox from "./icons/add-to-box.svg";
import addToWishlist from "./icons/addToWishlist.svg";
import addToWishlist2 from "./icons/addToWishlist2.svg";
import advBg from "./advBg.png";
import allYearProducts from "./icons/allYearProducts.svg";
import appStore from "./appStore.png";
import apple from "./apple.png";
import arrowLeftIcon from "./icons/arrow-left.svg";
import arrowRightIcon from "./icons/arrow-right.svg";
import banana from "./banana.png";
import becomeClient from "./becomeclient.png";
import bell from "./icons/bell.svg";
import bin from "./icons/bin.svg";
import bottomSemiBorder from "./icons/bottom-semi-border.svg";
import box from "./icons/box.svg";
import boxes from "./icons/boxes.svg";
import calendarIcon from "./icons/calendarIcon.svg";
import card from "./icons/credit-card-1.svg";
import carousel1 from "./carousel1.png";
import carouselPlaceHolder from "./carouselPlaceHolder.png";
import cart from "./icons/add-to-cart.svg";
import categoriesIcon from "./icons/categories-icon.svg";
import categoriesPlaceholder from "./categoriesPlaceholder.svg";
import categoryPlaceHolder from "./categoryPlaceHolder.png";
import chatIcon from "./icons/chat-icon.svg";
import clockIcon from "./icons/clockIcon.svg";
import collectionPlaceHolder from "./collectionPlaceHolder.png";
import comment from "./icons/comment.svg";
import confirmation from "./confirmation.png";
import contactUsMap from "./contactusMap.png";
import customersSayBg from "./customersSayBg.png";
import danKitchen from "./danKitchenbg.png";
import deleteIcon from "./icons/delete.svg";
import delivery from "./icons/delivery.svg";
import downArrow from "./icons/down-arrow.svg";
import dropdown from "./icons/back-copy.svg";
import dropdown2 from "./icons/dropdown.svg";
import eTradelingCategoryIcon from "./icons/etradeling-category-icon.svg";
import eTradelingDeliveryIcon from "./icons/etradelingDeliveryIcon.svg";
import edit from "./icons/edit.svg";
import email from "./icons/email.svg";
import esolarAbout from "./esolarAbout.webp";
import esolarAdBanner1 from "./esolarAdBanner1.webp";
import esolarAdBanner2 from "./esolarAdBanner2.webp";
import esolarBackgroundImage from "./esolarFeaturesBackgbround.webp";
import esolarLeftArrow from "./icons/esolarLeftArrow.svg";
import esolarLogo from "./esolarLogo.webp";
import esolarLogoNoText from "./esolarLogoNoText.webp";
import esolarProductImage from "./esolarProductImage.webp";
import esolarRightArrow from "./icons/esolarRightArrow.svg";
import etradelingCategoriesIcon from "./icons/etradelingCategoriesIcon.svg";
import etradelingUploadIcon from "./icons/etradelingUploadIcon.svg";
import etradelingWalletIcon from "./icons/etradelingWalletIcon.svg";
import explore1 from "./explore1.webp";
import explore2 from "./explore2.webp";
import facebookCir from "./icons/facebook-circular-logo.svg";
import faqbg from "./faqbg.png";
import featuredBannerPlaceholder from "./featuredBannerPlaceholder.svg";
import featuresBannerBackground from "./features-banner-background.webp";
import foodSafetyBg from "./foodSafetyBg.png";
import fresh from "./icons/fresh.svg";
import freshCut from "./icons/shape.svg";
import fruits from "./fruitImage.png";
import googlePlay from "./googlePlay.png";
import group from "./icons/group.svg";
import homeBoxes from "./boxImage.png";
import homeCollectionPlaceHolder from "./home-collection-placeholder.webp";
import inputCalendarIcon from "./icons/inputCalendarIcon.svg";
import inputImage from "./inputImage.png";
import insta from "./icons/insta.svg";
import instagramCir from "./icons/instagram.svg";
import installmentArrowDown from "./icons/installmentArrowDown.svg";
import installmentArrowUp from "./icons/installmentArrowUp.svg";
import like from "./icons/like.svg";
import line from "./line-2.svg";
import linked from "./icons/linked.svg";
import loc from "./icons/loc.svg";
import location from "./icons/location.svg";
import logo from "./logo.webp";
import logo2 from "./logoNoText.png";
import logout from "./icons/logout-2.svg";
import mastercard from "./master-card.png";
import medal from "./icons/medal-1.svg";
import medal2 from "./icons/medal.svg";
import messengerCir from "./icons/messengerCir.svg";
import more from "./moreImage.png";
import mushrooms from "./mushroomImage.png";
import ourBlog1 from "./EsolarImages/ourBlog1.webp";
import ourBlog2 from "./EsolarImages/ourBlog2.webp";
import ourBlog3 from "./EsolarImages/ourBlog3.webp";
import ourProfile from "./ourProfile.png";
import oval from "./icons/oval.svg";
import pdfIcon from "./icons/pdfIcon.svg";
import pendingInstallementIcon from "./icons/pendingInstallmentIcon.svg";
import phone from "./icons/phone.svg";
import plus from "./icons/plus.svg";
import processBg from "./processBg.png";
import productPlaceHolder from "./productPlaceHolder.png";
import profile from "./icons/profile.svg";
import profilePictureIcon from "./icons/group-2.svg";
import profilePlaceholder from "./icons/group-17.svg";
import profileRectangle from "./profileRectangle.png";
import ratingsBar from "./icons/ratings-bar.svg";
import ratingsBarFull from "./icons/ratings-bar-full.svg";
import rectangle from "./icons/rectangle.svg";
import redapple from "./redapple.png";
import registrationImage from "./dose-juice-s-t-py-oe-a-3-h-0-unsplash@3x.png";
import repacked from "./icons/apples.svg";
import rotationIcon from "./icons/360-icon.svg";
import salads from "./saladImage.png";
import search from "./icons/search-4.svg";
import searchIcon from "./icons/searchIcon.svg";
import secondaryRectangle from "./icons/secondary-rectangle.svg";
import servicesIcon from "./icons/servicesIcon.svg";
import share from "./icons/share.svg";
import shareFb from "./icons/share-fb.svg";
import shareTwitter from "./icons/share-twitter.svg";
import shareWa from "./icons/share-wa.svg";
import shopIcon from "./icons/shopIcon.svg";
import solarPanels from "./solarPanels.webp";
import star from "./path.svg";
import starIcon from "./icons/starIcon.svg";
import tiktokCir from "./icons/tiktok.svg";
import tourSideImage from "./tour-side-image.webp";
import twitter from "./icons/twitter.svg";
import twitterCir from "./icons/twitter-circular.svg";
import vegetables from "./vegetableImage.png";
import vendorAboutIcon from "./icons/vendorAboutIcon.svg";
import vendorOurWorkIcon from "./icons/vendorOurWorkIcon.svg";
import vendorProductsIcon from "./icons/vendorProductsIcon.svg";
import vendorReviewsIcon from "./icons/vendorReviewsIcon.svg";
import visa from "./visa.png";
import walletIcon from "./icons/wallet-icon.svg";
import whatsappCir from "./icons/whatsappCir.svg";
import wishlist from "./icons/wishlist.svg";
import xIcon from "./icons/invalid-name.svg";

export const images = {
  carouselPlaceHolder,
  productPlaceHolder,
  collectionPlaceHolder,
  categoryPlaceHolder,
  inputImage,
  featuresBannerBackground,
  CollectionImage,
  registrationImage,
  logo,
  appStore,
  googlePlay,
  line,
  star,
  fruits,
  vegetables,
  homeBoxes,
  mushrooms,
  salads,
  more,
  advBg,
  processBg,
  apple,
  foodSafetyBg,
  customersSayBg,
  ourProfile,
  carousel1,
  mastercard,
  visa,
  banana,
  redapple,
  logo2,
  faqbg,
  contactUsMap,
  becomeClient,
  danKitchen,
  profileRectangle,
  homeCollectionPlaceHolder,
  tourSideImage,
  esolarBackgroundImage,
  explore1,
  solarPanels,
  esolarAdBanner1,
  esolarAdBanner2,
  esolarProductImage,
  AlsallabLogin,
  esolarLogo,
  esolarLogoNoText,
  esolarAbout,
  explore2,
  ourBlog1,
  ourBlog2,
  ourBlog3,
  categoriesPlaceholder,
  featuredBannerPlaceholder,
  confirmation,
};
export const icons = {
  etradelingUploadIcon,
  eTradelingCategoryIcon,
  calendarIcon,
  searchIcon,
  servicesIcon,
  bottomSemiBorder,
  categoriesIcon,
  walletIcon,
  rotationIcon,
  bell,
  search,
  wishlist,
  cart,
  dropdown,
  twitter,
  insta,
  linked,
  logout,
  card,
  location,
  medal,
  group,
  profile,
  boxes,
  box,
  plus,
  bin,
  edit,
  medal2,
  rectangle,
  fresh,
  delivery,
  allYearProducts,
  addToBox,
  addToWishlist,
  oval,
  shareFb,
  shareTwitter,
  shareWa,
  secondaryRectangle,
  ratingsBar,
  ratingsBarFull,
  deleteIcon,
  freshCut,
  repacked,
  profilePlaceholder,
  addToWishlist2,
  chatIcon,
  xIcon,
  phone,
  email,
  loc,
  like,
  share,
  comment,
  dropdown2,
  facebookCir,
  twitterCir,
  instagramCir,
  tiktokCir,
  whatsappCir,
  messengerCir,
  profilePictureIcon,
  arrowLeftIcon,
  arrowRightIcon,
  CarouselLeftArrow,
  CarouselRightArrow,
  CollectionTrueColor,
  TimeLineCheck,
  esolarRightArrow,
  esolarLeftArrow,
  Offers,
  NewArrival,
  OutLet,
  BestSeller,
  eTradelingDeliveryIcon,
  etradelingCategoriesIcon,
  etradelingWalletIcon,
  MessengerIcon,
  SearchMessage,
  MoreBtn,
  Clear,
  PlusMessage,
  AddAudio,
  SendBtn,
  shopIcon,
  downArrow,
  clockIcon,
  inputCalendarIcon,
  starIcon,
  pendingInstallementIcon,
  installmentArrowUp,
  installmentArrowDown,
  pdfIcon,
  vendorProductsIcon,
  vendorAboutIcon,
  vendorOurWorkIcon,
  vendorReviewsIcon,
};
