import "./Stepper.css";

import { Box, Divider, Typography } from "@mui/material";

import React from "react";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

function Stepper({
  steps,
  stepValue,
  stepHandle,
  stepsValidation,
  showCircles = true,
}) {
  return (
    <TabContext value={stepValue} sx={{ width: "100%" }}>
      {showCircles ? (
        <>
          <Box sx={{ width: "100%", margin: "15px 0" }}>
            <TabList
              // onChange={handleChange}
              aria-label="lab API tabs example"
              indicatorColor="transparent"
              value={stepValue}
            >
              {steps?.map((label, i) => (
                <div
                  style={{
                    position: steps?.length == 2 ? "unset" : "relative",
                  }}
                >
                  {label.number != 1 && (
                    <Divider
                      sx={{
                        position: "absolute",
                        background: "#979797",
                        top: 20,
                        left:
                          label.number == 2
                            ? steps?.length == 2
                              ? "65px"
                              : "calc(-230% + 40px)"
                            : "calc(-400% + 40px)",
                        right: steps?.length == 2 ? "65px" : "calc(50% + 38px)",
                        display: { xs: "none", md: "unset" },
                      }}
                    />
                  )}
                  <Tab
                    onClick={() => {
                      if (
                        stepsValidation.find(
                          (item) => item.number == label.number
                        )?.condition ||
                        (label.number == 3 &&
                          stepsValidation.every((item) => item.condition))
                      )
                        stepHandle(label.number);
                    }}
                    // style={{ cursor: "unset" }}
                    icon={
                      <Typography
                        sx={{
                          width: "42px",
                          height: "42px",
                          color:
                            stepValue < i + 1
                              ? "#393a3b !important"
                              : stepValue == i + 1
                              ? "#09ce7d !important"
                              : "#ffffff !important",
                          background:
                            stepValue < i + 1
                              ? "#d8d8d8 !important"
                              : stepValue == i + 1
                              ? "transparent !important"
                              : "#09ce7d !important",
                          border:
                            stepValue < i + 1
                              ? "1px solid #979797 !important"
                              : "1px solid #09ce7d !important",
                          borderRadius: "50% !important",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          fontWeight: "bold",
                          fontSize: "16px",
                          fontFamily: "Poppins !important",
                        }}
                      >
                        {label.number}
                      </Typography>
                    }
                    label={
                      <Typography
                        sx={{
                          color:
                            stepValue < i + 1
                              ? "#080808 !important"
                              : "#09ce7d !important",
                          fontWeight: "bold",
                          fontSize: "14px",
                        }}
                      >
                        {label.title}
                      </Typography>
                    }
                    value={label.number}
                    sx={{
                      margin: "0",
                      padding: "0",
                      minWidth: "fit-content",
                    }}
                  />
                </div>
              ))}
            </TabList>
          </Box>

          <Divider
            sx={{
              width: "100%",
              height: "1px",
              background: "#d8d8d8",
              margin: "10px 0",
            }}
          />
        </>
      ) : (
        ""
      )}

      {steps?.map((label) => (
        <TabPanel
          value={label.number}
          sx={{ margin: 0, padding: 0, width: "100%" }}
        >
          {label.component}
        </TabPanel>
      ))}
    </TabContext>
  );
}

export default Stepper;
// export default withTranslation()(Stepper);
