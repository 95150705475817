import { Grid } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import '../RecipeStyles.css'
import './RecipeAdditional.css'

const RecipeAdditional = ({ t, time, tags, nutrition }) => {
    return (
        <div className="recipe--container">
            <h2 className="recipe--title">
                {t("dan kitchen.additional")}
            </h2>

            <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                    <div className="additional-flex">
                        <span className="additional-title">
                            {t("dan kitchen.serving time")} (±)
                        </span>
                        <span className="additional-text">
                            {time}
                        </span>
                    </div>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <div className="additional-flex">
                        <span className="additional-title">
                            {t("dan kitchen.tags")}
                        </span>
                        <span className="additional-text">
                            {tags}
                        </span>
                    </div>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <div className="additional-flex">
                        <span className="additional-title">
                            {t("dan kitchen.nutrition")}
                        </span>
                        <div className="additional-text">
                            {nutrition.map((n, index) =>
                                <span key={index}>{n}</span>
                            )}
                        </div>
                    </div>
                </Grid>


            </Grid>

        </div>
    );
}

export default withTranslation()(RecipeAdditional);