import { CircularProgress, Grid } from "@material-ui/core";
import { useEffect } from "react";
import { withTranslation } from "react-i18next";
import PrimaryButton from "../../Atoms/Buttons/Primary/PrimaryButton";
import ProductItemCard from "../Cards/ProductItemCard/ProductItemCard";

const ProductsTab = (props) => {
    const { loadMore, currentPage, maxPage, t } = props;

    return (
        <>
            <TabPanel value={props.value} index={props.index} >
                <Grid container spacing={2}>
                    {props.products?.map((prod) => <Grid item xs={12} sm={6} md={4} key={prod?.id} >
                        <ProductItemCard
                            id={prod?.id}
                            img={prod?.relationships?.images?.data[0]}
                            title={prod?.attributes?.name}
                            cut={prod?.attributes?.slug}
                            country={prod?.attributes?.currency}
                        />
                    </Grid>
                    )}


                </Grid>

                {!props.loading && <div className="shopping-loadmore">
                    {currentPage < maxPage ?
                        <PrimaryButton text={t("load more")} click={() => loadMore(props.index)} classBtn="outline" /> : null}
                </div>}

                <div className="shopping-loadmore">
                    {props.loading && <CircularProgress style={{ color: "var(--secondary-color)" }} />}
                </div>
            </TabPanel>

        </>
    );
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <div>{children}</div>
            )}
        </div>
    );
}

export default withTranslation()(ProductsTab);