import React from "react";
import { PropTypes } from "prop-types";
import { Grid } from "@material-ui/core";
// import { Search } from "@material-ui/icons";
// import styles from "./SearchBox.module.css";
import PrimaryButton from "../../Atoms/Buttons/Primary/PrimaryButton";
import TextFieldInput from "../../Atoms/Inputs/TextField/TextFieldInput";
// import { icons } from "../../../Assets/images/IconHelper";

const SearchBox = ({
  type,
  text,
  placeholder = "",
  SearchbarStyle = "",
  onchange,
  startIcon,
  submitFn,
  value,
  BGcolor,
}) => {
  return (
    <Grid container spacing={0}>
      <Grid item xs={9}>
        <TextFieldInput
          className="search_field"
          placeholder={placeholder}
          onchange={onchange}
          value={value}
          onKeyDown={(e) => {
            if (e.key === "Enter") submitFn();
          }}
        />
      </Grid>
      <Grid item xs={3} className="" style={{ justifyContent: "flex-start" }}>
        <PrimaryButton
          text={text}
          classBtn={
            type === "search"
              ? `search ${BGcolor}`
              : `primary subscribe ${BGcolor}`
          }
          btnType={type === "search" ? "icon" : "subscribe"}
          startIcon={startIcon}
          click={submitFn}
        ></PrimaryButton>
      </Grid>
    </Grid>
  );
};
SearchBox.propTypes = {
  className: PropTypes.string,
  placeholder: PropTypes.string,
  //   SearchbarStyle: PropTypes.string,
  //   submitFn: PropTypes.instanceOf(Function),
};
export default SearchBox;
