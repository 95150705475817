import React from 'react'
import CareersTemplate from '../../../Templates/Home/Careers/Careers.Template'

function Careers() {
    return (
        <CareersTemplate />
    )
}

export default Careers
