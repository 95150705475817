import { axiosFn, handleError } from "../../index";
import * as URL from "../../URLs";
// import { toast } from "react-toastify";

export async function deleteCollection(id) {
  return await axiosFn("delete", `${URL.COLLECTIONS}/${id}`)
    .then((res) => {
      return {
        res: res?.data,
        err: null,
      };
    })
    .catch(handleError);
}
export async function deleteCollectionAPI(id) {
  let { err, res } = await deleteCollection(id);

  return { err, res };
}
