import { icons, images } from '../../../Assets/images/IconHelper';
import './IngredientCard.css'

const IngredientCard = ({ img, name }) => {
    return (
        <div className="ingredient-container">

            <div className="ingredient-img">
                <img src={img ? img : images.logo2} width="50px" height="50px" alt="" />
            </div>

            <p className="ingredient-name">
                { name }
            </p>

        </div>
    );
}

export default IngredientCard;