import EtradelingHomeTemplate from "../../../Templates/Home/EtradelingHomeTemplate/EtradelingHome.template";
import { queryParamsFormatter } from "../../../utils/queryParamsFormatter/queryParamsFormatter";
import { useCategories } from "../../../Hooks/useCategories/useCategories";
import { useEffect } from "react";
import { useFeaturedBanners } from "../../../Hooks/useFeaturedBanners/useFeaturedBanners";
import { useProducts } from "../../../Hooks/useProducts/useProducts";
import { useState } from "react";

const EtradelingHomePage = () => {
  const [queryParams, setQueryParams] = useState({ "filter[roots]": true });
  const { categories, areCategoriesLoading, loadCategories } = useCategories(
    queryParamsFormatter(queryParams)
  );
  const { featuredBanners, areFeaturedBannersLoading, loadFeaturedBanners } =
    useFeaturedBanners();

  const { loadBestSellerProducts, BestProducts, areProductsLoading } =
    useProducts();
  useEffect(() => {
    loadCategories();
    loadBestSellerProducts();
  }, [queryParams]);

  useEffect(() => {
    loadFeaturedBanners();
  }, []);
  return (
    <EtradelingHomeTemplate
      categories={categories}
      areCategoriesLoading={areCategoriesLoading}
      areFeaturedBannersLoading={areFeaturedBannersLoading}
      featuredBanners={featuredBanners}
      bestSellerProducts={BestProducts}
      areProductsLoading={areProductsLoading}
    />
  );
};

export default EtradelingHomePage;
