import "./EsolarExploreProductsCard.css";

import { Grid, Typography } from "@mui/material";

import PrimaryButton from "../../Atoms/Buttons/Primary/PrimaryButton";
import { images } from "../../../Assets/images/IconHelper";
import { useHistory } from "react-router-dom";

const EsolarExploreProductsCard = ({ image, title, description, id }) => {
  const history = useHistory();
  return (
    <Grid container direction="column" item xs={4}>
      <div
        style={{
          backgroundImage: `url(${image})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
          border: "solid 2px #e2e2e2",
          borderRadius: 10,
          backgroundPosition: "right bottom",
        }}
      >
        <Typography
          marginTop={3}
          marginBottom={1}
          paddingLeft={2}
          className="esolar-explore-products-card-title"
        >
          {title}
        </Typography>
        <Typography
          marginBottom={3}
          paddingLeft={2}
          paddingRight={2}
          className="esolar-explore-products-card-description"
        >
          {description}
        </Typography>
        <div style={{ marginBottom: 180, width: "20%", paddingLeft: 16 }}>
          <PrimaryButton
            text={"See all"}
            classBtn="btn-small rounded-8 outline-secondry btn-shadow"
            click={() => history.push(`/shopping?taxon=${id}`)}
          />
        </div>
      </div>
    </Grid>
  );
};

export default EsolarExploreProductsCard;
