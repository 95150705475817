import "./Checkout.css";

import { useEffect, useState } from "react";

import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import CheckoutAddress from "../../../Components/Organisms/CheckoutAddress/CheckoutAddress";
import CheckoutPayment from "../../../Components/Organisms/CheckoutPayment/CheckoutPayment";
import CheckoutReview from "../../../Components/Organisms/CheckoutReview/CheckoutReview";
import { Grid } from "@material-ui/core";
import OrderSummary from "../../../Components/Organisms/OrderSummary/OrderSummary";
import { useHistory } from "react-router-dom";
import { withTranslation } from "react-i18next";

const CheckoutTemplate = ({
  t,
  i18n,
  address,
  items,
  cartData,
  handlePlaceOrder,
  isPlaceOrderLoading,
}) => {
  const history = useHistory();

  const [placeOrderDisabled, setPlaceOrderDisabled] = useState(true);

  useEffect(() => {
    if (address) setPlaceOrderDisabled(false);
  }, [address]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <div className="checkout-back" onClick={() => history.goBack()}>
          <ArrowBackIosIcon
            style={{
              transform: `rotate(${i18n.language === "ar" ? "180deg" : "0"})`,
            }}
          />
          {t("checkout.checkout")}
        </div>
      </Grid>

      <Grid item xs={12} sm={7} lg={8} className="checkout-item1">
        <div className="checkout-card">
          <CheckoutAddress
            name={
              address &&
              address?.attributes?.firstname + address?.attributes?.lastname
            }
            phone={address && address?.attributes?.phone}
            address={address && address?.attributes?.address1}
          />
        </div>

        <div className="checkout-card">
          <CheckoutPayment />
        </div>

        <div className="checkout-card">
          <CheckoutReview items={items} />
        </div>
      </Grid>

      <Grid item xs={12} sm={5} lg={4} className="checkout-item2">
        <OrderSummary
          itemNumber={cartData?.data?.attributes.item_count || "0"}
          currency={cartData?.data?.attributes.currency || "SR"}
          promo={cartData?.data?.attributes.promo_total || "0"}
          subtotal={cartData?.data?.attributes.pre_tax_total || "0"}
          delivery={cartData?.data?.attributes.ship_total || "0"}
          total={cartData?.data?.attributes.total || "0"}
          btn="placeOrder"
          handlePlaceOrder={handlePlaceOrder}
          placeOrderDisabled={placeOrderDisabled}
          isPlaceOrderLoading={isPlaceOrderLoading}
        />
      </Grid>
    </Grid>
  );
};

export default withTranslation()(CheckoutTemplate);
