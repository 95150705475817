import { axiosFn, handleError } from "../../index";
import * as URL from "../../URLs";
// import { toast } from "react-toastify";

export async function deleteAddress(id) {
  return await axiosFn("delete", `${URL.PROFILE_ADDRESSES}/${id}`)
    .then((res) => {
      return {
        res: res?.data,
        err: null,
      };
    })
    .catch(handleError);
}
export async function deleteAddressAPI(id) {
  let { err, res } = await deleteAddress(id);

  return { err, res };
}
