import MyRequestsTemplate from "../../../Templates/Home/Profile/EsolarMyRequests/EsolarMyRequests.template";
import { queryParamsFormatter } from "../../../utils/queryParamsFormatter/queryParamsFormatter";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useRequests } from "../../../Hooks/useRequests/useRequests";
import { useServiceRequests } from "../../../Hooks/useServiceRequests/useServiceRequests";
import { useState } from "react";

function EsolarMyRequestsPage() {
  const [queryParams, setQueryParams] = useState({ status: "pending" });
  const [modalOpen, setModalOpen] = useState(false);
  const history = useHistory();

  const {
    deleteServiceRequest,
    areRequestsLoading,
    loadServiceRequests,
    loadMoreServiceRequests,
    hasMore,
    setCurrentPage,
    serviceRequests,
    currentPage,
  } = useServiceRequests();

  useEffect(() => {
    loadServiceRequests(queryParamsFormatter(queryParams));
    setCurrentPage(1);
  }, [queryParams]);

  const handleEdit = (id) => {
    //TODO: Redirect to edit page
  };

  const handleViewRequestDetails = (id) => {
    //Redirect to request details page
    history.push(`/profile/requests/${id}`);
  };

  const handleTabsChange = (status) => {
    setQueryParams({ ...queryParams, status: status });
  };

  const handleDelete = async (id) => {
    await deleteServiceRequest(id);
    loadServiceRequests(queryParamsFormatter(queryParams));
  };

  const handleModal = () => {
    setModalOpen(!modalOpen);
  };

  return (
    <MyRequestsTemplate
      requests={serviceRequests}
      isLoading={areRequestsLoading}
      handleEdit={handleEdit}
      handleViewRequestDetails={handleViewRequestDetails}
      handleTabsChange={handleTabsChange}
      handleDelete={handleDelete}
      hasMore={hasMore}
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
      loadProductRequests={loadServiceRequests}
      queryParams={queryParams}
      loadMoreProductRequests={loadMoreServiceRequests}
      handleModal={handleModal}
      modalOpen={modalOpen}
    />
  );
}

export default EsolarMyRequestsPage;
