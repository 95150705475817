import React, { useState } from "react";

import { SignUpAPI } from "../../Contexts/API/Auth/Signup";
import SignupTemplate from "../../Templates/Auth/Signup.Template";
import { useHistory } from "react-router-dom";
import { withTranslation } from "react-i18next";

const Signup = (props) => {
  const { t } = props;
  const [error, seterror] = useState({});
  const history = useHistory();
  const [clicked, setClicked] = useState(false);
  const register = async (data) => {
    setClicked(true);
    if (data?.user?.password?.length < 8) {
      seterror({
        field: "password",
        message: t("form.passwordlessthaneight"),
      });
    } else if (data?.user?.password !== data?.user?.password_confirmation) {
      seterror({
        field: "confirm",
        message: t("form.passwordNotMatch"),
      });
    } else {
      if (data?.user?.name && data?.user?.email) {
        let { response } = await SignUpAPI(data);
        if (response?.res) {
          history.push("/");
        }
      }
      //handle errors
    }
  };

  return (
    <SignupTemplate
      register={register}
      error={error}
      clicked={clicked}
      {...props}
      systemConfig={props.systemConfig}
    />
  );
};

export default withTranslation()(Signup);
