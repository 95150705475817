import "./PeopleViewed.css";

import AlsallabProductCard from "../Cards/AlsallabProductCard/AlsallabProductCard";
import { Grid } from "@material-ui/core";
import { withTranslation } from "react-i18next";

const PeopleViewed = ({ t, items }) => {
  return (
    <Grid container spacing={2}>
      {items?.map((item) => (
        <Grid item xs={6} sm={4} lg={4}>
          <AlsallabProductCard title={item?.name} id={item?.id} image={item?.image} />
        </Grid>
      ))}
    </Grid>
  );
};

export default withTranslation()(PeopleViewed);
