import React from "react";

import { getProductsListAPI } from "../../../Contexts/API/Home/Products/getProductsList";

import SearchResultsTemplate from "../../../Templates/Home/SearchResults/SearchResults.Template";

const getProductsList = async (filter, page) => {
  return await getProductsListAPI(filter, page);
};

function SearchResults() {
  return <SearchResultsTemplate getProductsList={getProductsList} />;
}

export default SearchResults;
