import { TextareaAutosize } from "@material-ui/core";
import "./TextArea.css";
import React from "react";

function TextArea({ placeholder, value, onchange, ...props }) {
  return (
    <TextareaAutosize
      rowsMin={4}
      placeholder={placeholder}
      onChange={(e) => onchange(e.target.value)}
      value={value}
      className="area_text"
      {...props}
    />
  );
}

export default TextArea;
