import * as URL from "../../URLs";

import { axiosFn, handleError } from "../../index";

export async function getCollection(id, taxonId) {
  return await axiosFn(
    "get",
    `${URL.COLLECTIONS}/${id}${taxonId ? `?taxon_id=${taxonId}` : ""}`
  )
    .then((res) => {
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}
export async function getCollectionAPI(id, taxonId) {
  let { err, res } = await getCollection(id, taxonId);

  if (res) {
    return res;
  }

  if (err) {
    return err;
  }
}
