import React from 'react'
import MyItemsTemplate from '../../../Templates/Home/Profile/MyItems/MyItems.Template'


function MyItems() {
    return (
        <MyItemsTemplate />
    )
}

export default MyItems
