import { Box, Grid, Typography } from "@mui/material";

const VendorAboutTemplate = ({ data, my }) => {
  return (
    <Box>
      <Box sx={{ mt: "14px" }}>
        <Typography>Overview</Typography>
        <Typography>Overview from backend</Typography>
      </Box>
      <Grid container sx={{ my: "24px" }}>
        <Grid item xs={6}>
          <Typography>Website</Typography>
          <Typography>Website name</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography>HQ</Typography>
          <Typography>HQ name</Typography>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={6}>
          <Typography>Industry</Typography>
          <Typography>Vendor Industries</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography>Found in</Typography>
          <Typography>Year of founding</Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default VendorAboutTemplate;
