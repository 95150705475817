import { useHistory, useLocation } from "react-router-dom";

import ProposalDetailsTemplate from "../../../Templates/Home/Profile/ProposalDetails/ProposalDetails.template";
import { useServiceRequests } from "../../../Hooks/useServiceRequests/useServiceRequests";

const ProposalDetailsPage = () => {
  //HOOKS
  const location = useLocation();
  const history = useHistory();
  const { acceptServiceProposal, rejectServiceProposal } = useServiceRequests();
  console.log(location.state);
  //HANDLERS
  const acceptHandler = async (requestID, quotationID) => {
    await acceptServiceProposal(requestID, quotationID);
    history.push("/profile/my_requests");
  };
  const rejectHandler = async (requestID, quotationID) => {
    await rejectServiceProposal(requestID, quotationID);
    history.push("/profile/my_requests");
  };
  return (
    <ProposalDetailsTemplate
      proposal={location.state}
      acceptHandler={acceptHandler}
      rejectHandler={rejectHandler}
    />
  );
};

export default ProposalDetailsPage;
