import "./MainTemplate.css";

import { Redirect, Route, Switch } from "react-router-dom";

import AboutUs from "../Pages/Home/AboutUs/AboutUs.Page";
import { AddressContext } from "../Contexts/addressContext/addressContext";
import { BadgesContext } from "../Contexts/badgesContext/badgesContext";
import Basket from "../Pages/Home/Basket/Basket.Page";
import BecomeClient from "../Pages/Home/BecomeClient/BecomeClient.Page";
import Blog from "../Pages/Home/Blog/Blog.Page";
import BlogDetails from "../Pages/Home/DanKitchen/BlogDetails.Page";
import { BoxesContext } from "../Contexts/boxesContext/boxesContext";
import Careers from "../Pages/Home/Careers/Careers.Page";
import { CategoriesContext } from "../Contexts/contextCategories/categoriesContext";
import ChangeAddress from "../Pages/Home/ChangeAddress/ChangeAddress.Page";
import Checkout from "../Pages/Home/Checkout/Checkout.Page";
import ContactUs from "../Pages/Home/ContactUs/ContactUs.Page";
import DanKitchen from "../Pages/Home/DanKitchen/DanKitchen.Page";
import ESolarHomePage from "../Pages/Home/EsolarHome/EsolarHome.page";
import EsolarNavbarApp from "../Components/Organisms/EsolarNavbarApp/EsolarNavbarApp";
import FAQ from "../Pages/Home/FAQ/FAQ.Page";
import Footer from "../Components/Organisms/Footer/Footer";
import Home from "../Pages/Home/Home.Page";
import LogoutHandeler from "../Components/Atoms/LogoutHandler/LogoutHandler";
import MyBoxesModal from "../Components/Organisms/Modals/MyBoxesModal/MyBoxesModal";
import NavbarApp from "../Components/Organisms/NavbarApp/NavbarApp";
import NavbarLanguage from "../Components/Organisms/NavbarLanguage/NavbarLanguage";
import NavbarLogo from "../Components/Organisms/NavbarLogo/NavbarLogo";
import News from "../Pages/Home/News/News.Page";
import Partners from "../Pages/Home/Partners/Partners.page";
import PrivacyPolicy from "../Pages/Home/PrivacyPolicy/PrivacyPolicy.Page";
import { ProductContext } from "../Contexts/productContext/productContext";
import ProductDetails from "../Pages/Home/ProductDetails/ProductDetails.page";
import Products from "../Pages/Home/Products/Products.Page";
import Profile from "../Pages/Home/Profile/Profile.Page";
import Recipe from "../Pages/Home/DanKitchen/Recipe.Page";
import Reports from "../Pages/Home/Reports/Reports.Page";
import SallabHomePage from "../Pages/Home/SallabHome/SallabHome.page";
import SearchResults from "../Pages/Home/SearchResults/SearchResults.Page";
import Shopping from "../Pages/Home/Shopping/Shopping.Page";
import { SubcategoriesContext } from "../Contexts/contextSubCategories/subCategoriesContext";
import TermsConditions from "../Pages/Home/TermsConditions/TermsConditions.Page";
import Wishlist from "../Pages/Home/Wishlist/Wishlist.Page";
import { systemContext } from "../Contexts/systemContext/systemContext";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import NewCollection from "../Pages/Home/NewCollection/NewCollection.Page";
import Collection from "../Pages/Home/Collection/Collection.Page";
import CollectionsPage from "../Pages/Home/Collections/Collections.Page";
import UploadCollectionImage from "../Pages/Home/UploadCollectionImage/UploadCollectionImage.Page";

const MainTemplate = ({
  //PROPS
  modalState,
  account,
  badges,
  setBadges,
  onLanguageHandle,
  value,
  locations,
  getAccount,
  mobileView,
  addToCart,
  deleteFromCart,
  categories,
  catLoading,
  subCategories,
  products,
  productsLoading,
  cart,
  setCart,
  cartLoading,
  addresses,
  setAddresses,
  pickedAddress,
  setPickedAddress,
  systemConfig,
  isSystemConfigLoading,
}) => {
  const location = useLocation();
  console.log("this is the current location", location);
  return (
    <>
      <systemContext.Provider
        value={{ systemConfig, isSystemConfigLoading, account }}
      >
        <BadgesContext.Provider value={{ badges, setBadges }}>
          <NavbarLanguage
            onLanguageHandle={onLanguageHandle}
            value={value}
            locations={locations}
          />
          <CategoriesContext.Provider value={{ categories, catLoading }}>
            <NavbarLogo
              getAccount={getAccount}
              mobileView={mobileView}
              systemConfig={systemConfig}
            />
            <NavbarApp mobileView={mobileView} systemConfig={systemConfig} />
            <BoxesContext.Provider value={{ modalState }}>
              <ProductContext.Provider
                value={{
                  addToCart,
                  deleteFromCart,
                  products,
                  productsLoading,
                  cart,
                  setCart,
                  cartLoading,
                }}
              >
                <AddressContext.Provider
                  value={{
                    addresses,
                    setAddresses,
                    pickedAddress,
                    setPickedAddress,
                  }}
                >
                  <SubcategoriesContext.Provider value={{ subCategories }}>
                    <div
                      className={
                        location.pathname === "/" ? "" : "template-app"
                      }
                    >
                      <Switch>
                        <Route
                          exact
                          path="/"
                          component={
                            systemConfig
                              ? systemConfig["System Configurations"][
                                  "Project Name"
                                ] === "esolar"
                                ? ESolarHomePage
                                : systemConfig["System Configurations"][
                                    "Project Name"
                                  ] === "alsallab"
                                ? SallabHomePage
                                : Home
                              : Home
                          }
                        />
                        <Route path="/profile" component={Profile} />
                        <Route path="/aboutus" component={AboutUs} />
                        <Route path="/careers" component={Careers} />
                        <Route path="/contactus" component={ContactUs} />
                        <Route path="/news" component={News} />
                        <Route path="/terms" component={TermsConditions} />
                        <Route path="/policy" component={PrivacyPolicy} />
                        <Route path="/reports" component={Reports} />
                        <Route path="/shopping" component={Shopping} />
                        <Route path="/product/:id" component={ProductDetails} />
                        <Route path="/partners" component={Partners} />
                        <Route path="/clients" component={BecomeClient} />
                        <Route
                          exact
                          path="/dankitchen"
                          component={DanKitchen}
                        />
                        <Route
                          path="/dankitchen/blog/:id"
                          component={BlogDetails}
                        />
                        <Route exact path="/blog" component={Blog} />
                        <Route path="/blog/:id" component={BlogDetails} />
                        <Route
                          path="/dankitchen/recipe/:id"
                          component={Recipe}
                        />
                        <Route path="/wishlist" component={Wishlist} />
                        <Route path="/basket" component={Basket} />
                        <Route
                          path="/searchresults"
                          component={SearchResults}
                        />
                        <Route exact path="/products">
                          <Redirect to="/products/fresh" />
                        </Route>
                        <Route path="/products/:type" component={Products} />
                        <Route path="/faq" component={FAQ} />
                        <Route path="/checkout" component={Checkout} />
                        <Route
                          path="/changeAddress"
                          component={ChangeAddress}
                        />
                        <Route path="/collection/:id" component={Collection} />
                        <Route
                          path="/collection_new/:id"
                          component={NewCollection}
                        />
                        <Route
                          path="/collections"
                          component={CollectionsPage}
                        />

                        <Route
                          path="/updateCollection/:id"
                          component={UploadCollectionImage}
                        />
                        <Route
                          path="/updateCollection"
                          component={UploadCollectionImage}
                        />
                      </Switch>
                    </div>
                    <MyBoxesModal modalState={modalState} />
                  </SubcategoriesContext.Provider>
                </AddressContext.Provider>
              </ProductContext.Provider>
            </BoxesContext.Provider>
            <LogoutHandeler />
            <Footer systemConfig={systemConfig} />
          </CategoriesContext.Provider>
        </BadgesContext.Provider>
      </systemContext.Provider>
    </>
  );
};

export default MainTemplate;
