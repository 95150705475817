import "./SideNav.css";

import {
  AlsallabState,
  DefaultState,
  ESolarState,
  ETradelingState,
} from "../../../utils/profileStates/profileStates";
import { Button, Drawer } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";

import IconOverlay from "../../Atoms/IconOverlay/IconOverlay";
import { LocalizationContext } from "../../../Contexts/contextLocalization/locaizationContext";
import LogoutModal from "../Modals/LogoutModal/LogoutModal";
import MenuIcon from "@material-ui/icons/Menu";
import { NavLink } from "react-router-dom";
import firebase from "@firebase/app-compat";
import { icons } from "../../../Assets/images/IconHelper";
import { withTranslation } from "react-i18next";

const SideNav = (props) => {
  const { t, mobileView, systemConfig, account } = props;

  const [User, setUser] = useState(localStorage.getItem("USER_TYPE"));

  const handleSwitch = () => {
    User == "user" ? setUser("vendor") : setUser("user");
  };

  useEffect(() => {
    if (localStorage.getItem("USER_TYPE") != User) {
      localStorage.setItem("USER_TYPE", User);
      window.location = "/";
    }
  }, [User]);

  const [open, setopen] = useState();
  const [state, setState] = useState({
    mobileView: false,
    drawerOpen: false,
    links: DefaultState,
  });

  const [ETradeling, setETradeling] = useState({
    mobileView: false,
    drawerOpen: false,
    links: ETradelingState,
  });

  const [ESolar, setESolar] = useState({
    mobileView: false,
    drawerOpen: false,
    links: ESolarState,
  });

  const [Alsallab, setAlsallab] = useState({
    mobileView: false,
    drawerOpen: false,
    links: AlsallabState,
  });

  const { links } =
    systemConfig &&
    systemConfig["System Configurations"]["Project Name"] === "etradeling"
      ? ETradeling
      : systemConfig &&
        systemConfig["System Configurations"]["Project Name"] === "esolar"
      ? ESolar
      : systemConfig &&
        systemConfig["System Configurations"]["Project Name"] === "alsallab"
      ? Alsallab
      : state;

  const handleClose = () => {
    setopen(!open);
  };
  const LogOutBtn = async () => {
    handleClose();
    localStorage.removeItem("TOKEN");
    localStorage.removeItem("USER_TYPE");
    localStorage.removeItem("EXP");
    firebase
      .auth()
      .signOut()
      .then(() => {
        window.location.reload();
        window.location = "/";
      });
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    if (
      systemConfig &&
      systemConfig["System Configurations"]["Project Name"] === "etradeling"
    )
      setETradeling({ ...state, drawerOpen: open });
    else if (
      systemConfig &&
      systemConfig["System Configurations"]["Project Name"] === "esolar"
    ) {
      setState({ ...state, drawerOpen: open });
      setESolar({ ...state, drawerOpen: open });
    } else if (
      systemConfig &&
      systemConfig["System Configurations"]["Project Name"] === "alsallab"
    )
      setAlsallab({ ...state, drawerOpen: open });
    else setState({ ...state, drawerOpen: open });
  };

  return (
    <div>
      {mobileView && (
        <Button onClick={toggleDrawer("left", true)}>
          <MenuIcon />
        </Button>
      )}

      <Drawer
        anchor="left"
        open={state.drawerOpen}
        onClose={toggleDrawer("left", false)}
        variant="temporary"
      >
        <div
          role="presentation"
          onClick={toggleDrawer("left", false)}
          onKeyDown={toggleDrawer("left", false)}
          className="drawer-div"
        >
          {links?.map((link) => (
            <NavLink
              key={link.to}
              to={link.to}
              activeClassName="activeLink"
              className="NavLink"
            >
              <IconOverlay src={link.icon} />
              {t(link.text)}
            </NavLink>
          ))}
          <div className="LogoutBtn" onClick={handleClose}>
            <img src={icons.logout} alt="" /> {t("Profile.LogOut")}
          </div>
          <LogoutModal
            open={open}
            LogOutBtn={LogOutBtn}
            handleClose={handleClose}
          />
        </div>
      </Drawer>

      {!mobileView && (
        <div className="ProfileSideBar_wrapper">
          {links?.map((link) => (
            <NavLink
              key={link.to}
              to={link.to}
              activeClassName="activeLink"
              className="NavLink"
            >
              <IconOverlay src={link.icon} /> {!mobileView && t(link.text)}
            </NavLink>
          ))}

          {systemConfig &&
          JSON.parse(systemConfig["System Configurations"]["Vendor"]) ? (
            account?.attributes?.vendor ? (
              account?.attributes?.vendor?.status == "pending" ? (
                <div className="LogoutBtn" style={{ cursor: "unset" }}>
                  <IconOverlay
                    src={icons.card}
                    iconStyle={"icon-profile-list"}
                  />
                  <span style={{ paddingRight: "4px", textAlign: "start" }}>
                    Vendor account <br />
                    <span style={{ color: "#f0b70d" }}> Pending </span>
                  </span>
                </div>
              ) : account?.attributes?.vendor?.status == "rejected" ? (
                <div className="LogoutBtn" style={{ cursor: "unset" }}>
                  <IconOverlay
                    src={icons.card}
                    iconStyle={"icon-profile-list"}
                  />
                  <span style={{ paddingRight: "4px", textAlign: "start" }}>
                    Vendor account <br />
                    <span style={{ color: "#b01f27" }}>
                      Account rejected & need update
                    </span>
                  </span>
                </div>
              ) : (
                <div className="LogoutBtn" onClick={handleSwitch}>
                  <IconOverlay
                    src={icons.card}
                    iconStyle={"icon-profile-list"}
                  />
                  <span style={{ paddingRight: "4px", textAlign: "start" }}>
                    {`${t("Profile.Switch")} ${
                      User == "user"
                        ? t("Vendor account")
                        : t("Customer account")
                    }`}
                  </span>
                </div>
              )
            ) : (
              <div
                className="LogoutBtn"
                onClick={() => {
                  window.location = "/become_vendor";
                }}
              >
                <IconOverlay src={icons.card} iconStyle={"icon-profile-list"} />
                <span style={{ paddingRight: "4px", textAlign: "start" }}>
                  Become Vendor <br />
                  <span style={{ textDecoration: "underline" }}>
                    create account
                  </span>
                </span>
              </div>
            )
          ) : (
            ""
          )}

          <div className="LogoutBtn" onClick={handleClose}>
            <img src={icons.logout} alt="" />{" "}
            {!mobileView && t("Profile.LogOut")}
          </div>

          <LogoutModal
            open={open}
            LogOutBtn={LogOutBtn}
            handleClose={handleClose}
          />
        </div>
      )}
    </div>
  );
};

export default withTranslation()(SideNav);

{
  /* 
      <NavLink
        to="/profile/account"
        activeClassName="activeLink"
        className="NavLink"
      >
        <img src={icons.profile} alt="" /> {!mobileView && t("Profile.MyAccount")}
      </NavLink>

      <NavLink
        to="/profile/orders"
        activeClassName="activeLink"
        className="NavLink"
      >
        <img src={icons.boxes} alt="" /> {!mobileView && t("Profile.MyOrders")}
      </NavLink>
      <NavLink
        to="/profile/boxes"
        activeClassName="activeLink"
        className="NavLink"
        exact
      >
        <img src={icons.box} alt="" /> {!mobileView && t("Profile.MyBoxes")}
      </NavLink>

      <NavLink
        to="/profile/items"
        activeClassName="activeLink"
        className="NavLink"
        exact
      >
        <img src={icons.group} alt="" /> {!mobileView && t("Profile.MyItems")}
      </NavLink>
      <NavLink
        to="/profile/rewards"
        activeClassName="activeLink"
        className="NavLink"
        exact
      >
        <img src={icons.medal} alt="" /> {!mobileView && t("Profile.DanRewards")}
      </NavLink>
      <NavLink
        to="/profile/addresses"
        activeClassName="activeLink"
        className="NavLink"
        exact
      >
        <img src={icons.location} alt="" /> {!mobileView && t("Profile.MyAddresses")}
      </NavLink>
      <NavLink
        to="/profile/paymentlist"
        activeClassName="activeLink"
        className="NavLink"
        exact
      >
        <img src={icons.card} alt="" /> {!mobileView && t("Profile.MyPayment")}
      </NavLink> */
}
