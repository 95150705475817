import "./FiltersSidepanel.css";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Checkbox,
  CircularProgress,
  Drawer,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
} from "@material-ui/core";
import { useContext, useEffect, useRef, useState } from "react";

import { CategoriesContext } from "../../../Contexts/contextCategories/categoriesContext";
import CloseIcon from "@material-ui/icons/Close";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MenuIcon from "@material-ui/icons/Menu";
import TextFieldInput from "../../Atoms/Inputs/TextField/TextFieldInput";
import { withTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

// import PrimaryButton from "../../Atoms/Buttons/Primary/PrimaryButton";

const FiltersSidepanel = (props) => {
  const {
    t,
    mobileView,
    setTaxonId,
    taxonId,
    IsPrice = true,
    options,
    sub_categories,
    selectedSubCategory,
    setSelectedSubCategory,
    selectedOptionIds,
    setSelectedOptionIds,
    setSelectedType,
    loading,
  } = props;
  const categoriesCxt = useContext(CategoriesContext);
  const history = useHistory();

  const [drawerOpen, setDrawerOpen] = useState(false);

  //String minPrice,maxPrice
  const [priceRange, setPriceRange] = useState("");

  const handleCategory = (event, index) => {
    if (setTaxonId) {
      setSelectedOptionIds([]);
      setSelectedSubCategory(null);
      setTaxonId([event.target.value]);
      history.replace({
        pathname: "shopping",
        search: `?taxon=${event.target.value}`,
      });
    }
  };

  const [price, setPrice] = useState({
    min: "",
    max: "",
    minErr: false,
    maxErr: false,
  });

  const handlePrice = () => {
    //  console.log("handle price")
    if (!price.min && !price.max) {
      setPrice({ ...price, min: "", max: "" });
      setPriceRange("");
    } else if (!price.min) {
      setPrice({ ...price, minErr: true });
      setPriceRange("");
    } else if (!price.max) {
      setPrice({ ...price, minErr: false, maxErr: true });
      setPriceRange("");
    } else {
      setPrice({ ...price, minErr: false, maxErr: false });
      setDrawerOpen(false);
      const priceFilter = `${price.min},${price.max}`;
      // console.log(priceFilter)
      setPriceRange(`filter[price]=${price.min},${price.max}`);
    }
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setDrawerOpen(open);
  };

  useEffect(() => {
    setDrawerOpen(false);
  }, [mobileView]);

  const renderSidePanel = (
    <div className="filters">
      <Accordion defaultExpanded={true}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <p className="filters-accordion-title">
            {t("shoppingPage.category")}
          </p>
        </AccordionSummary>
        <AccordionDetails>
          <div>
            <FormControl component="fieldset">
              <FormGroup>
                {categoriesCxt?.categories?.data?.map((cat, index) => (
                  <FormControlLabel
                    key={cat.id}
                    control={
                      <Checkbox
                        value={cat.id}
                        checked={taxonId == cat.id}
                        onChange={(e) => handleCategory(e, index)}
                        name={cat.attributes.name}
                      />
                    }
                    label={cat.attributes.name}
                    className="filters-checkbox"
                  />
                ))}
              </FormGroup>
            </FormControl>
          </div>
          {/* <div className="progress-indicator">
            {loading && (
              <CircularProgress style={{ color: "var(--secondary-color)" }} />
            )}
          </div> */}
        </AccordionDetails>
      </Accordion>

      <Accordion defaultExpanded={true}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <p className="filters-accordion-title">
            {t("shoppingPage.sub category")}
          </p>
        </AccordionSummary>
        <AccordionDetails>
          <div>
            <FormControl component="fieldset">
              <FormGroup>
                {sub_categories?.map((cat) => (
                  <FormControlLabel
                    key={cat.id}
                    control={
                      <Checkbox
                        value={cat.id}
                        checked={selectedSubCategory == cat.id}
                        onChange={(e) => {
                          if (selectedSubCategory == e.target.value)
                            setSelectedSubCategory();
                          else setSelectedSubCategory(e.target.value);
                        }}
                        name={cat?.name}
                      />
                    }
                    label={cat.name}
                    className="filters-checkbox"
                  />
                ))}
              </FormGroup>
            </FormControl>
          </div>
        </AccordionDetails>
      </Accordion>

      {loading ? (
        <div className="progress-indicator">
          <CircularProgress style={{ color: "var(--secondary-color)" }} />
        </div>
      ) : (
        <>
          {options &&
            options?.map((option) => (
              <Accordion defaultExpanded={true}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <p className="filters-accordion-title">{option?.name}</p>
                </AccordionSummary>
                <AccordionDetails>
                  <div>
                    <FormControl component="fieldset">
                      <FormGroup>
                        {option?.option_values?.map((value) => (
                          <FormControlLabel
                            control={
                              <Checkbox
                                value={value?.id}
                                checked={selectedOptionIds?.find(
                                  (item) => item == value.id
                                )}
                                onChange={(e) => {
                                  if (
                                    selectedOptionIds?.find(
                                      (item) => item == value.id
                                    )
                                  ) {
                                    setSelectedOptionIds(
                                      selectedOptionIds?.filter(
                                        (item) => item != value.id
                                      )
                                    );
                                  } else {
                                    setSelectedOptionIds([
                                      ...selectedOptionIds,
                                      e.target.value,
                                    ]);
                                  }
                                }}
                                name={value?.name}
                              />
                            }
                            label={value?.name}
                            className="filters-checkbox"
                          />
                        ))}
                      </FormGroup>
                    </FormControl>
                  </div>
                </AccordionDetails>
              </Accordion>
            ))}
        </>
      )}

      {IsPrice && (
        <Accordion defaultExpanded={true}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <p className="filters-accordion-title">{t("shoppingPage.price")}</p>
          </AccordionSummary>
          <AccordionDetails>
            <div className="filters-flex">
              <TextFieldInput
                value={price.min}
                error={price.minErr}
                onchange={(e) =>
                  isNaN(e) ? null : setPrice({ ...price, min: e })
                }
                className="filters-textbox"
                onKeyDown={(e) => {
                  if (e.key === "Enter") handlePrice();
                }}
              />
              <span className="filters-textbox">{t("shoppingPage.to")}</span>
              <TextFieldInput
                value={price.max}
                error={price.maxErr}
                onchange={(e) =>
                  isNaN(e) ? null : setPrice({ ...price, max: e })
                }
                className="filters-textbox"
                onKeyDown={(e) => {
                  if (e.key === "Enter") handlePrice();
                }}
              />
              <p className="filters-go" onClick={handlePrice}>
                {t("shoppingPage.go")}
              </p>
            </div>
          </AccordionDetails>
        </Accordion>
      )}
    </div>
  );

  return (
    <div>
      {mobileView && (
        <Button onClick={toggleDrawer("left", true)}>
          <MenuIcon />
        </Button>
      )}

      <Drawer
        anchor="left"
        open={drawerOpen}
        onClose={toggleDrawer("left", false)}
        variant="temporary"
      >
        <div
          onClick={toggleDrawer("left", false)}
          onKeyDown={toggleDrawer("left", false)}
          className="filter-drawer-header"
        >
          <IconButton>
            <CloseIcon />
          </IconButton>
          <p className="filter-drawer-filterby">Filter By:</p>
        </div>
        <div
          role="presentation"
          // onClick={toggleDrawer('left', false)}
          // onKeyDown={toggleDrawer('left', false)}
        >
          {renderSidePanel}
        </div>
      </Drawer>

      {!mobileView && <div>{renderSidePanel}</div>}
    </div>
  );
};

export default withTranslation()(FiltersSidepanel);
