import React, { useEffect, useState } from "react";

import HomeTemplate from "../../Templates/Home/Home.Template";
import { RealTimeDatabase } from "../../Contexts/firebase";
import { auth } from "../../Contexts/firebase";
import { getAdBannerAPI } from "../../Contexts/API/Home/AdBanner/getAdBanner";
import { sendMessageAPI } from "../../Contexts/API/Home/Chats/sendMessage";
import { useAuthState } from "react-firebase-hooks/auth";
import { getFeaturedBannersAPI } from "../../Contexts/API/Home/FeaturedBanners/getFeaturedBanners";

const Home = (props) => {
  const [user] = useAuthState(auth);
  const [userId, setUserId] = useState(auth?.currentUser?.uid);
  const [lastMessage, setLastMessage] = useState(null);

  const [morePages, setMorePages] = useState(false);
  const [FeatureBanners, setFeatureBanners] = useState([]);

  useEffect(() => {
    getFeaturedBannersAPI().then((res) =>
      setFeatureBanners(res?.featured_banners)
    );
  }, []);

  useEffect(() => {
    setUserId(auth?.currentUser?.uid);
  }, [user]);

  //--------Realtime database RD-------------------------
  const [messages, setMessages] = useState([]);
  const [messagesNode, setMessagesNode] = useState(userId);

  async function getMessagesRD(chatId, scroll) {
    // const { err } = await withPromise(CoreAPI.Chats.visitChat)(chatId)();
    // if (err) return;

    let TempMessagesNode = userId;

    if (messagesNode !== TempMessagesNode) {
      setMessages([]);
      await RealTimeDatabase.ref(`messages/${messagesNode}`).off();
    }

    if (TempMessagesNode) {
      setMessagesNode(TempMessagesNode);
      if (lastMessage) {
        await RealTimeDatabase.ref(`messages/${TempMessagesNode}`)
          .orderByChild("date")
          .limitToLast(30)
          .endBefore(lastMessage)
          .once("value", (snapshot) => {
            let tmp = [];
            snapshot.forEach((childSnapshot) => {
              let childKey = childSnapshot.key;

              if (tmp.length === 0) {
                setLastMessage(childSnapshot.val().date);
              }
              tmp.push(childSnapshot.val());
            });
            setMessages((prev) => [...tmp, ...prev]);
            if (tmp.length === 30) {
              setMorePages(true);
            } else {
              setMorePages(false);
            }
          });
      } else {
        let count = 0;
        await RealTimeDatabase.ref(`messages/${TempMessagesNode}`)
          .orderByChild("date")
          .limitToLast(30)
          .on("child_added", async (snapshot) => {
            setMessages((prev) => [...prev, snapshot.val()]);

            if (count === 0) {
              setLastMessage(snapshot.val().date);
            }
            count++;

            if (count < 30) {
              setMorePages(false);
              scroll();
            } else if (count === 30) {
              setMorePages(true);
              scroll();
            }
          });
      }
    }
  }

  async function sendMessageRD(
    chatRoomId,
    messageType,
    message,
    mediaType,
    payload,
    sender
  ) {
    const dataObj = {
      chat_room_message: {
        message_type: messageType,
        message,
        media_type: mediaType,
        payload: "", //payload[0],
      },
    };

    const { err, data } = await sendMessageAPI(chatRoomId, dataObj);
    if (err) return false;

    const FirebaseDataObj = {
      date: data?.data?.chat_message?.date,
      message_type: data?.data?.chat_message?.message_type,
      message,
      media_type: data?.data?.chat_message?.media_type,
      payload,
      sender,
    };

    Object.keys(FirebaseDataObj).forEach(
      (key) =>
        (FirebaseDataObj[key] === undefined ||
          FirebaseDataObj[key]?.length === 0) &&
        delete FirebaseDataObj[key]
    );

    // console.log(FirebaseDataObj)
    await RealTimeDatabase.ref(`messages/${messagesNode}`)
      .push()
      .set(FirebaseDataObj);

    return true;
  }

  //--------------------------------------------------------------

  return (
    <HomeTemplate
      onLanguageHandle={props.onLanguageHandle}
      value={props.value}
      userId={userId}
      messages={messages}
      sendMessageRD={sendMessageRD}
      getMessagesRD={getMessagesRD}
      morePages={morePages}
      adBanners={FeatureBanners}
    />
  );
};

export default Home;
