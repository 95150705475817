import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { withTranslation } from "react-i18next";
import "./SideNav.css";
import { icons } from '../../../Assets/images/IconHelper';
import LogoutModal from '../Modals/LogoutModal/LogoutModal';
import { Button, Drawer } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import IconOverlay from '../../Atoms/IconOverlay/IconOverlay';


const ProductSideNav = (props) => {
    const { t, mobileView } = props;
    const [open, setopen] = useState();
    const [state, setState] = useState({
        mobileView: false,
        drawerOpen: false,
        links: [
            {
                to: "/products/fresh",
                icon: icons.freshCut,
                text: 'navbar.Fresh cut'
            },
            {
                to: "/products/repacked",
                icon: icons.repacked,
                text: 'navbar.RePacked'
            },
            {
                to: "/products/more",
                icon: icons.box,
                text: 'navbar.More'
            }
        ]
    });

    const { links } = state;


    const handleClose = () => {
        setopen(!open)
    }

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        console.log(open);
        setState({ ...state, drawerOpen: open });
    };

    return (
        <div>
            {mobileView && <Button onClick={toggleDrawer('left', true)}><MenuIcon /></Button>}

            <Drawer anchor='left' open={state.drawerOpen} onClose={toggleDrawer('left', false)} variant="temporary" >
                <div
                    role="presentation"
                    onClick={toggleDrawer('left', false)}
                    onKeyDown={toggleDrawer('left', false)}
                    className="drawer-div"
                >
                    {
                        links.map((link) =>
                        (
                            <NavLink
                                key={link.to}
                                to={link.to}
                                activeClassName="activeLink"
                                className="NavLink"
                            >

                                <IconOverlay src={link.icon} /> {t(link.text)}
                            </NavLink>
                        )
                        )
                    }
                </div>
            </Drawer>

            {!mobileView && <div className="ProfileSideBar_wrapper">
                {links.map((link) =>
                (
                    <NavLink
                        key={link.to}
                        to={link.to}
                        activeClassName="activeLink"
                        className="NavLink"
                    >
                        <IconOverlay src={link.icon} /> {!mobileView && t(link.text)}
                    </NavLink>
                ))}
            </div>}
        </div>
    )
}

export default withTranslation()(ProductSideNav);