import { useContext, useEffect, useState } from "react";

import { BadgesContext } from "../../Contexts/badgesContext/badgesContext";
import { UpdateQuantityAPI } from "../../Contexts/API/Home/Products/UpdateQuantity";
import { addToCartAPI } from "../../Contexts/API/Home/Cart/addToCart";
import { applyCouponAPI } from "../../Contexts/API/Home/Cart/ApplyCoupon";
import { createCartAPI } from "../../Contexts/API/Home/Cart/createCart";
import { deleteFromCartAPI } from "../../Contexts/API/Home/Cart/deleteFromCart";
import { getCartAPI } from "../../Contexts/API/Home/Cart/getCart";
import { removeCouponAPI } from "../../Contexts/API/Home/Cart/RemoveCoupon";
import { toast } from "react-toastify";

export const useCart = () => {
  const { badges, setBadges } = useContext(BadgesContext);
  const [cart, setCart] = useState({});
  const [cartItems, setCartItems] = useState([]);
  const [isCartLoading, setIsCartLoading] = useState(false);

  const loadCart = async () => {
    setIsCartLoading(true);
    const { res, err, status } = await getCartAPI();
    if (status === 404) {
      await createCartAPI();
    }
    if (status === 200) {
      setCart(res?.data);
      setCartItems(res?.included);
      setBadges({ ...badges, cart: res?.data?.attributes?.item_count });
      setIsCartLoading(false);
    }
    if (err) {
      setIsCartLoading(false);
    }
  };

  const addToCart = async (id, count) => {
    setIsCartLoading(true);
    const { res, err, status } = await addToCartAPI({
      variant_id: id,
      quantity: count,
    });
    if (status === 200) {
      setIsCartLoading(false);
      setBadges({ ...badges, cart: res?.data?.attributes?.item_count });
      toast.success("Product added to cart successfully");
    }
    if (err) {
      setIsCartLoading(false);
      toast.error(err);
    }
  };

  const deleteFromCart = async (id) => {
    setIsCartLoading(true);
    const { err, status, res } = await deleteFromCartAPI(id);
    if (status === 200) {
      setIsCartLoading(false);
      loadCart();
    }
    if (err) {
      setIsCartLoading(false);
      toast.error(err);
    }
  };

  const increaseItemQuantity = async (id, quantity) => {
    setIsCartLoading(true);
    const { err, status, res } = await UpdateQuantityAPI(id, quantity + 1);
    if (status === 200) {
      setIsCartLoading(false);

      loadCart();
    }
    if (err) {
      setIsCartLoading(false);
      toast.error(err);
    }
  };

  const decreaseItemQuantity = async (id, quantity) => {
    setIsCartLoading(true);
    const { err, status, res } = await UpdateQuantityAPI(id, quantity - 1);
    if (status === 200) {
      setIsCartLoading(false);

      loadCart();
    }
    if (err) {
      setIsCartLoading(false);
      toast.error(err);
    }
  };

  const applyCoupon = async (code) => {
    setIsCartLoading(true);
    const { err, res, status } = await applyCouponAPI(code);
    if (status === 200) {
      setIsCartLoading(false);
      loadCart();
    }
    if (err) {
      setIsCartLoading(false);
      toast.error(err);
    }
  };

  const removeCoupon = async (code) => {
    setIsCartLoading(true);
    const { err, res, status } = await removeCouponAPI(code);
    if (status === 200) {
      setIsCartLoading(false);
      loadCart();
    }
    if (err) {
      setIsCartLoading(false);
      toast.error(err);
    }
  };

  return {
    cart,
    cartItems,
    isCartLoading,
    deleteFromCart,
    increaseItemQuantity,
    decreaseItemQuantity,
    addToCart,
    applyCoupon,
    removeCoupon,
    loadCart,
    badges,
  };
};
