import { getBestSellerProductsAPI } from "../../Contexts/API/Home/Products/getBestSellerProducts";
import { useState } from "react";
import { getProductsListAPI } from "../../Contexts/API/Home/Products/getProductsList";

export const useProducts = () => {
  const [BestProducts, setBestProducts] = useState(null);
  const [Products, setProducts] = useState(null);
  const [TotalCount, setTotalCount] = useState(null);
  const [TotalPages, setTotalPages] = useState(null);
  const [areProductsLoading, setAreProductsLoading] = useState(false);

  const loadProducts = async (filter, page) => {
    setAreProductsLoading(true);
    const res = await getProductsListAPI(filter, page);
    if (res) {
      setProducts(res?.data);
      setTotalCount(res?.meta?.total_count);
      setTotalPages(res?.meta?.total_pages);
    }
    setAreProductsLoading(false);
  };

  const loadBestSellerProducts = async () => {
    setAreProductsLoading(true);
    const { res } = await getBestSellerProductsAPI();
    if (res) {
      setBestProducts(res?.products);
    }
    setAreProductsLoading(false);
  };

  return {
    BestProducts,
    areProductsLoading,
    loadBestSellerProducts,
    Products,
    loadProducts,
    TotalCount,
    TotalPages,
  };
};
