import * as URL from "../../URLs";

import { axiosFn, handleError } from "../../index";

export async function addToWishlist(body) {
  return await axiosFn(
    "post",
    `${URL.DEFAULT_WISHLIST}/${localStorage.getItem(
      "WISHTOKEN"
    )}/wished_products`,
    body
  )
    .then((res) => {
      return {
        res: res.data,
        err: null,
        status: res?.status,
      };
    })
    .catch(handleError);
}
export async function addToWishlistAPI(body) {
  let { err, res, status } = await addToWishlist(body);

  return { res, err, status };
}
