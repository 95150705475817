import WishlistTemplate from "../../../Templates/Home/Wishlist/Wishlist.Template";
import { useCart } from "../../../Hooks/useCart/useCart";
import { useEffect } from "react";
import { useWishlist } from "../../../Hooks/useWishlist/useWishlist";

function Wishlist() {
  const { wishlistItems, isWishlistLoading, loadWishlist, deleteFromWishlist } =
    useWishlist();

  const { addToCart } = useCart();

  useEffect(() => {
    loadWishlist();
  }, []);

  //Adds product to cart and removes it from wishlist
  const moveProductToCart = async (variantID, id) => {
    await deleteFromWishlist(id);
    await addToCart(variantID, 1);
  };

  return (
    <WishlistTemplate
      wishlistItems={wishlistItems}
      isWishlistLoading={isWishlistLoading}
      moveProductToCart={moveProductToCart}
      deleteFromWishlist={deleteFromWishlist}
    />
  );
}

export default Wishlist;
