import { getAdBannerAPI } from "../../Contexts/API/Home/AdBanner/getAdBanner";
import { useState } from "react";

export const useAdBanners = () => {
  const [adBanners, setAdBanners] = useState(null);
  const [areAdBannersLoading, setAreAdBannersLoading] = useState(false);

  const loadAdBanners = async () => {
    setAreAdBannersLoading(true);
    const { res, err, status } = await getAdBannerAPI();
    if (res) {
      setAdBanners(res?.ad_banners);
    }
    setAreAdBannersLoading(false);
  };

  return { adBanners, areAdBannersLoading, loadAdBanners };
};
