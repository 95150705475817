import * as React from "react";

import Box from "@mui/material/Box";
import InstallmentCard from "../../../../Components/Molecules/InstallmentCard/InstallmentCard";
import PrimaryButton from "../../../../Components/Atoms/Buttons/Primary/PrimaryButton";
import { Typography } from "@mui/material";
import { useEffect } from "react";
import { useState } from "react";

const ProposalPhase2 = ({
  proposal,
  setProposal,
  handleChange,
  stepsValidation,
  setStepsValidation,
}) => {
  const [InstallmentsValidation, setInstallmentsValidation] = useState([]);
  const [isClicked, setIsClicked] = useState(false);

  useEffect(() => {
    if (InstallmentsValidation.every((item) => item.condition)) {
      setStepsValidation([
        ...stepsValidation.filter((item) => item.number != 2),
        { number: 2, condition: true },
      ]);
    } else {
      setStepsValidation([
        ...stepsValidation.filter((item) => item.number != 2),
        { number: 2, condition: false },
      ]);
    }
  }, [InstallmentsValidation, proposal]);

  const DeleteInstallment = (Id) => {
    setProposal({
      ...proposal,
      installments: proposal?.installments?.filter((item) => item.id != Id),
    });
  };
  return (
    <Box
      component="form"
      sx={{
        mt: 2,
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h2"
            sx={{
              width: "100%",
              textAlign:
                proposal?.installments?.length == 0 ? "center" : "left",
              fontSize: proposal?.installments?.length == 0 ? "24px" : "18px",
              fontWeight: "600",
              margin: "10px 0",
            }}
          >
            Add Installments ( optional )
          </Typography>
          {proposal?.installments?.length != 0 && (
            <PrimaryButton
              text={"+ Add installment"}
              classBtn="secondary mini_outline"
              style={{ maxWidth: "170px" }}
              click={() =>
                setProposal({
                  ...proposal,
                  installments: [
                    ...proposal?.installments,
                    {
                      id: proposal?.installments?.length + 1,
                      title: "",
                      estimated_price: "",
                      currency_type_id: "1",
                      details: "",
                      documents_attributes: [],
                      duration: "",
                      due_date: "",
                    },
                  ],
                })
              }
            />
          )}
        </Box>
        <Typography
          variant="h4"
          sx={{
            width: "100%",
            textAlign: proposal?.installments?.length == 0 ? "center" : "left",
            fontSize: proposal?.installments?.length == 0 ? "18px" : "16px",
            marginTop: "32px",
          }}
        >
          you can add stages of install and constraction of this job
        </Typography>
      </Box>
      {proposal?.installments?.length == 0 && (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            marginTop: "28px",
          }}
        >
          <PrimaryButton
            text={"+ Add installment"}
            classBtn="secondary mini_outline"
            style={{ maxWidth: "170px" }}
            click={() =>
              setProposal({
                ...proposal,
                installments: [
                  ...proposal?.installments,
                  {
                    id: proposal?.installments?.length + 1,
                    title: "",
                    estimated_price: "",
                    currency_type_id: "1",
                    details: "",
                    documents_attributes: [],
                    duration: "",
                    due_date: "",
                  },
                ],
              })
            }
          />
        </Box>
      )}
      {proposal?.installments?.map((item, i) => (
        <InstallmentCard
          number={i + 1}
          installment={item}
          proposal={proposal}
          setProposal={setProposal}
          DeleteInstallment={DeleteInstallment}
          InstallmentsValidation={InstallmentsValidation}
          setInstallmentsValidation={setInstallmentsValidation}
          isClicked={isClicked}
        />
      ))}
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          marginTop: "30px",
        }}
      >
        <PrimaryButton
          text={"Next"}
          classBtn="btn-secondry proposal"
          style={{
            maxWidth: "320px",
          }}
          click={() => {
            setIsClicked(true);
            if (InstallmentsValidation.every((item) => item.condition)) {
              handleChange(3);
            }
          }}
        />
      </Box>
    </Box>
  );
};

export default ProposalPhase2;
