import "./AddressCard.css";

import { Checkbox, FormControlLabel, Grid } from "@material-ui/core";

import IconOverlay from "../../../Atoms/IconOverlay/IconOverlay";
import PrimaryButton from "../../../Atoms/Buttons/Primary/PrimaryButton";
import React from "react";
import { icons } from "../../../../Assets/images/IconHelper";
import { useHistory } from "react-router-dom";
import { useState } from "react";
import { withTranslation } from "react-i18next";

const AddressCard = (props) => {
  const {
    t,
    address,
    addresses,
    setAddresses,
    selectAddress,
    noDelete,
    noEdit,
    noCheckbox,
    displayCard,
  } = props;
  const history = useHistory();
  return (
    <Grid container className="address_card">
      <Grid item xs={12} sm={8}>
        <p className="title">
          {address?.attributes?.address1}, {address?.attributes?.address2}
        </p>
      </Grid>
      {displayCard ? (
        <Grid item xs={4}></Grid>
      ) : (
        <Grid
          item
          xs={12}
          sm={4}
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          {noCheckbox ? (
            <div>
              <PrimaryButton
                text={"Your Addresses →"}
                classBtn={"your-address "}
                click={() => history.push("/profile/addresses")}
              />
            </div>
          ) : (
            <FormControlLabel
              style={{ width: "27px", height: "27px" }}
              control={
                <Checkbox
                  checked={address?.attributes?.is_default}
                  onChange={() => {
                    // let a = [...addresses];
                    // let temp = a[0];
                    // a[0] = a[props.index];
                    // a[props.index] = temp;
                    // setAddresses(a);
                    selectAddress(address.id);
                  }}
                />
              }
            />
          )}
        </Grid>
      )}
      <Grid item xs={4} className="label">
        <label>{t("loginBtn.Name")}:</label>
      </Grid>
      <Grid item xs={8}>
        <p>{`${address?.attributes?.firstname} ${address?.attributes?.lastname}`}</p>
      </Grid>
      <Grid item xs={4} className="label">
        <label>{t("loginBtn.phone")}:</label>
      </Grid>
      <Grid item xs={8}>
        <p>{address?.attributes?.phone}</p>
      </Grid>
      <Grid item xs={4} className="label">
        <label>{t("address.Address")}:</label>
      </Grid>
      <Grid item xs={8}>
        <p>{`${address?.attributes?.address1}, ${address?.attributes?.address2}, ${address?.attributes?.city}, ${address?.attributes?.state_code}, ${address?.attributes?.country_name}.`}</p>
      </Grid>
      {noEdit ? (
        <></>
      ) : (
        <Grid item xs={1}>
          <PrimaryButton
            //startIcon={<img src={icons.edit} alt="edit" />}
            startIcon={
              <IconOverlay src={icons.edit} iconStyle="icon-primary" />
            }
            classBtn="borderless success"
            text={t("general.edit")}
            click={() => {
              props.setDataToEdit({ ...address?.attributes, id: props.id });
              props.pressEdit("edit");
            }}
          />
        </Grid>
      )}
      {noDelete ? (
        <></>
      ) : (
        <Grid item xs={3}>
          <PrimaryButton
            //startIcon={<img src={icons.bin} alt="delete" />}
            startIcon={<IconOverlay src={icons.bin} iconStyle="icon-white" />}
            classBtn="borderless error"
            text={t("general.delete")}
            click={() => props.deleteAddress(props.id)}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default withTranslation()(AddressCard);
