import { Box, Tab, Tabs } from "@mui/material";

import { useState } from "react";

function TabNav({ handleTabsChange }) {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  return (
    <Box sx={{ borderBottom: 1, borderColor: "divider", mt: "24px" }}>
      <Tabs value={value} onChange={handleChange} variant="fullWidth">
        <Tab
          label="Pending"
          style={{
            fontSize: "22px",
            fontWeight: "600",

            marginRight: 30,
          }}
          {...a11yProps(0)}
          onClick={() => handleTabsChange("pending")}
        />
        <Tab
          label="In Progress"
          style={{
            fontSize: "22px",
            fontWeight: "600",
            marginLeft: 20,
            marginRight: 30,
          }}
          {...a11yProps(1)}
          onClick={() => handleTabsChange("inprogress")}
        />
        <Tab
          label="Finished"
          style={{
            fontSize: "22px",
            fontWeight: "600",
            marginLeft: 20,
            marginRight: 30,
          }}
          {...a11yProps(2)}
          onClick={() => handleTabsChange("finished")}
        />
      </Tabs>
    </Box>
  );
}

export default TabNav;
