import { axiosFn, handleError } from "../../index";
import * as URL from "../../URLs";
import { toast } from "react-toastify";

export async function CreateProposal(proposal) {
  const data = {
    proposal: { ...proposal },
  };
  return await axiosFn("post", URL.PROPOSALS, proposal)
    .then((res) => {
      return {
        res: res,
        err: null,
      };
    })
    .catch(handleError);
}
export async function CreateProposalAPI(proposal) {
  let { err, res } = await CreateProposal(proposal);

  return { res, err };
}
