import firebase from "firebase/compat/app";
import { useEffect } from "react";

const LogoutHandeler = () => {
  useEffect(() => {
    let exp = localStorage.getItem("EXP") * 1000;
    let now = Date.now();

    if (exp) {
      if (now < exp) {
        setTimeout(() => {
          logout();
        }, exp - now);
      } else {
        logout();
      }
    }
  }, []);

  const logout = () => {
    localStorage.removeItem("TOKEN");
    localStorage.removeItem("USER_TYPE");
    localStorage.removeItem("EXP");

    firebase
      .auth()
      .signOut()
      .then(() => {
        window.location.reload();
      });
  };

  return <></>;
};

export default LogoutHandeler;
