import { axiosFn, handleError } from "../../index";
import * as URL from "../../URLs";
import { toast } from "react-toastify";

export async function CreateQuotations(quotation) {
  return await axiosFn("post", URL.QUOTATIONS, quotation)
    .then((res) => {
      return {
        res: res,
        err: null,
      };
    })
    .catch(handleError);
}
export async function CreateQuotationsAPI(quotation) {
  let { err, res } = await CreateQuotations(quotation);

  return { res, err };
}
