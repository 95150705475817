import "./EsolarOurBlog.css";

import { Grid, Typography } from "@material-ui/core";

import PrimaryButton from "../../Atoms/Buttons/Primary/PrimaryButton";
import { images } from "../../../Assets/images/IconHelper";

const EsolarBlogCard = ({ image, date, title }) => {
  return (
    <Grid container item direction="column" xs={4} alignContent="center">
      <Grid item container className="mb-20px">
        <img src={image} style={{ width: "100%" }} />
      </Grid>
      <Grid item>
        <Typography className="our-blog-date">22 Oct 2018</Typography>
      </Grid>
      <Grid item className="mb-20px">
        <Typography className="our-blog-card-title">
          Impact Of Extrinsic Motivation On Intrinsic
        </Typography>
      </Grid>
      <Grid item style={{ width: "150px" }}>
        <PrimaryButton
          text="Read More"
          classBtn="outline-secondry rounded-5 "
        />
      </Grid>
    </Grid>
  );
};

const EsolarOurBlog = () => {
  return (
    <>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        direction="column"
      >
        <Grid item className="mb-20px">
          <Typography className="our-blog-title">Our Blog </Typography>
        </Grid>
        <Grid item className="mb-20px">
          <PrimaryButton
            text="See all"
            classBtn={"rounded-5 btn-secondry btn-small btn-shadow"}
          />
        </Grid>
      </Grid>
      <Grid container direction="row" spacing={4}>
        <EsolarBlogCard image={images.ourBlog1} />
        <EsolarBlogCard image={images.ourBlog2} />
        <EsolarBlogCard image={images.ourBlog3} />
      </Grid>
    </>
  );
};

export default EsolarOurBlog;
