import { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { CategoriesContext } from "../../../Contexts/contextCategories/categoriesContext";

import { useCategories } from "../../../Hooks/useCategories/useCategories";

import CollectionsTemplate from "../../../Templates/Home/Collections/Collections.template";

const CollectionsPage = () => {
  const history = useHistory();
  const categories = useContext(CategoriesContext);
  const {
    loadOptionTypes,
    loadTags,
    options,
    areOptionsLoading,
    collections,
    sub_categories,
    tags,
    NoOfCollections,
    hasMore,
    loadCollectionsByTag,
    areCollectionsLoading,
  } = useCategories();

  // STATES
  const [taxonId, setTaxonId] = useState(
    new URLSearchParams(useLocation().search).get("taxon")
  );
  const [selectedOptionIds, setSelectedOptionIds] = useState(
    JSON.parse(new URLSearchParams(useLocation().search).get("options")) ?? []
  );
  const [selectedSubCategory, setSelectedSubCategory] = useState(
    JSON.parse(new URLSearchParams(useLocation().search).get("sub")) ?? null
  );
  const [selectedType, setSelectedType] = useState(
    JSON.parse(new URLSearchParams(useLocation().search).get("types")) ?? []
  );
  const [selectedTag, setSelectedTag] = useState(
    new URLSearchParams(useLocation().search).get("tag")
  );
  const [page, setPage] = useState(1);

  useEffect(() => {
    if (
      new URLSearchParams(history?.location?.search).get("taxon") != taxonId &&
      JSON.parse(new URLSearchParams(history?.location?.search).get("taxon"))
    ) {
      setTaxonId([new URLSearchParams(history?.location?.search).get("taxon")]);
      setSelectedOptionIds([]);
      setSelectedSubCategory(null);
      setSelectedType([]);
    }
  }, [history?.location?.search]);

  // HANDLING VALUES
  const handleSelectingCategory = (category) => {
    setPage(1);
    if (category?.id == selectedSubCategory) setSelectedSubCategory(null);
    else setSelectedSubCategory(category?.id);
  };
  const handleSelectingType = (category) => {
    setPage(1);
    if (selectedType?.find((item) => item == category?.id))
      setSelectedType(selectedType?.filter((item) => item != category?.id));
    else setSelectedType([...selectedType, category?.id]);
  };
  const handleSelectingTag = (category) => {
    setPage(1);
    setSelectedTag(parseInt(category));
  };

  // LOAD TAGS
  useEffect(() => {
    if (history?.location?.search?.includes("tag")) loadTags();
  }, []);

  useEffect(() => {
    if (history?.location?.search?.includes("tag") && selectedTag)
      loadCollectionsByTag(selectedTag);
  }, [selectedTag]);

  const [openModal, setopenModal] = useState(false);

  const loadMore = (FullLoading) => {
    if (selectedSubCategory)
      loadOptionTypes(
        selectedSubCategory,
        selectedType
          ? [...selectedOptionIds, ...selectedType]
          : selectedOptionIds,
        page,
        setPage,
        FullLoading
      );
    else if (taxonId)
      loadOptionTypes(
        taxonId,
        selectedType
          ? [...selectedOptionIds, ...selectedType]
          : selectedOptionIds,
        page,
        setPage,
        FullLoading
      );
  };

  // LOADING ON CHOOSING ANY NEW OPTION VALUES
  useEffect(() => {
    loadMore(true);
  }, [taxonId, selectedOptionIds, selectedSubCategory, selectedType]);

  // SELECT DEFAULT TAXON WHEN THERE ARE NO TAXON SELECTED
  useEffect(() => {
    if (!taxonId?.at(0) && !history?.location?.search?.includes("tag")) {
      setTaxonId([categories?.categories?.data?.at(0)?.id]);
    }
  }, [taxonId, categories]);

  // HANDLING URL
  useEffect(() => {
    if (!history?.location?.search?.includes("tag"))
      history.replace({
        pathname: "collections",
        search: `?taxon=${taxonId}${
          selectedOptionIds?.length ? `&options=[${selectedOptionIds}]` : ""
        }${selectedType?.length ? `&types=[${selectedType}]` : ""}${
          selectedSubCategory ? `&sub=${selectedSubCategory}` : ""
        }`,
      });
  }, [taxonId, selectedOptionIds, selectedSubCategory, selectedType]);

  const [state, setState] = useState({
    mobileView: false,
  });

  const { mobileView } = state;

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 600
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };

    setResponsiveness();
    window.addEventListener("resize", () => setResponsiveness());

    return () => {
      window.removeEventListener("resize", () => setResponsiveness());
    };
  }, [window.innerWidth]);

  return (
    <CollectionsTemplate
      collections={collections}
      loading={areOptionsLoading}
      NoOfCollections={NoOfCollections}
      categories={categories}
      setTaxonId={setTaxonId}
      taxonId={taxonId}
      openModal={openModal}
      mobileView={mobileView}
      handleCloseModal={() => setopenModal(!openModal)}
      options={options}
      sub_categories={sub_categories}
      selectedSubCategory={selectedSubCategory}
      setSelectedSubCategory={setSelectedSubCategory}
      handleSelectingCategory={handleSelectingCategory}
      selectedOptionIds={selectedOptionIds}
      setSelectedOptionIds={setSelectedOptionIds}
      selectedType={selectedType}
      handleSelectingType={handleSelectingType}
      setSelectedType={setSelectedType}
      tags={tags}
      selectedTag={selectedTag}
      handleSelectingTag={handleSelectingTag}
      hasMore={hasMore}
      page={page}
      loadMore={loadMore}
      setPage={setPage}
      areCollectionsLoading={areCollectionsLoading}
    />
  );
};

export default CollectionsPage;
