import { CircularProgress, Grid } from "@material-ui/core";
import { icons, images } from "../../../../Assets/images/IconHelper";

import IconOverlay from "../../../Atoms/IconOverlay/IconOverlay";
import PrimaryButton from "../../../Atoms/Buttons/Primary/PrimaryButton";
import SubCategoriesCard from "../../Cards/SubCategoriesCard/SubCategoriesCard";
import { useEffect } from "react";
import { useState } from "react";
import { Carousel } from "react-responsive-carousel";
import CarouselItem from "../../../Molecules/CarouselItem/CarouselItem";

const CategoriesCarousel = ({ collections, isCollectionLoading }) => {
  const [state, setState] = useState(null);
  const [configurableProps, setConfigurableProps] = useState({
    showArrows: true,
    showStatus: false,
    showIndicators: false,
    infiniteLoop: true,
    showThumbs: true,
    useKeyboardArrows: true,
    stopOnHover: true,
    swipeable: false,
    dynamicHeight: false,
    emulateTouch: false,
    autoFocus: false,
    thumbWidth: 100,
    selectedItem: 0,
    interval: 2000,
    transitionTime: 500,
    swipeScrollTolerance: 5,
    ariaLabel: "ariaLabel",
  });
  const pages = [];
  const getPages = (array) => {
    let page = [];
    for (var j = 0; j < array?.length; j++) {
      page.push(array[j]);
      if ((j + 1) % 6 === 0 && j !== 0) {
        pages.push(page);
        page = [];
      } else if (j === array.length - 1) {
        pages.push(page);
        page = [];
      }
    }
    return pages;
  };

  const arrowStyles = {
    position: "absolute",
    zIndex: 2,
    top: "calc(30% - 15px)",
    width: 40,
    height: 30,
    cursor: "pointer",
  };

  useEffect(() => {
    setState(getPages(collections));
    setConfigurableProps({ ...configurableProps, selectedItem: 0 });
  }, [collections]);

  return (
    <div style={{ position: "relative" }}>
      <Carousel
        className="flip-container carousel-arrow"
        showArrows={true}
        showThumbs={false}
        showStatus={false}
        infiniteLoop={false}
        autoPlay={true}
        interval={6000}
        renderArrowNext={(clickHandler) => (
          <div onClick={clickHandler} className={"CarouselRightArrow"}>
            <IconOverlay src={icons.CarouselRightArrow} />
          </div>
        )}
        renderArrowPrev={(clickHandler) => (
          <div onClick={clickHandler} className={"CarouselLeftArrow"}>
            <IconOverlay src={icons.CarouselLeftArrow} />
          </div>
        )}
      >
        {state ? (
          isCollectionLoading ? (
            <div className="progress-indicator">
              <CircularProgress style={{ color: "var(--secondary-color)" }} />
            </div>
          ) : (
            state?.map((page) => {
              return (
                <Grid container direction="row" spacing={3}>
                  {page?.map((Category) => {
                    return (
                      <>
                        <SubCategoriesCard
                          categories={true}
                          title={Category?.attributes?.name}
                          text={Category?.attributes?.name}
                          image={
                            Category?.attributes?.image ??
                            images.CollectionImage
                          }
                          filter={Category.id}
                          key={Category?.attributes?.name}
                        />
                      </>
                    );
                  })}
                </Grid>
              );
            })
          )
        ) : (
          ""
        )}
      </Carousel>
    </div>
  );
};

export default CategoriesCarousel;

// <CarouselWithArrows
//   SubCategories={state}
//   isCollectionLoading={isCollectionLoading}
// />
/* <Carousel
      centerMode
      centerSlidePercentage={100}
      {...configurableProps}
      renderArrowPrev={(onClickHandler, hasPrev, label) =>
        hasPrev && (
          <div>
            <PrimaryButton
              style={{ ...arrowStyles, left: 0 }}
              classBtn=""
              click={onClickHandler}
              startIcon={
                <IconOverlay
                  src={icons.arrowLeftIcon}
                  iconStyle="icon-primary"
                />
              }
            />
          </div>
        )
      }
      renderArrowNext={(onClickHandler, hasNext, label) =>
        hasNext && (
          <div>
            <PrimaryButton
              style={{ ...arrowStyles, right: 0 }}
              classBtn=""
              click={onClickHandler}
              startIcon={
                <IconOverlay
                  src={icons.arrowRightIcon}
                  iconStyle="icon-primary"
                />
              }
            />
          </div>
        )
      }
    >
      {isCollectionLoading ? (
        <div className="progress-indicator">
          <CircularProgress style={{ color: "var(--secondary-color)" }} />
        </div>
      ) : (
        state?.map((page) => {
          return (
            <Grid container direction="row" spacing={3}>
              {page.map((subCategory) => {
                return (
                  <>
                    <SubCategoriesCard
                      title={subCategory.name}
                      text={subCategory.name}
                      image={subCategory?.image ?? images.CollectionImage}
                      filter={subCategory.id}
                      key={subCategory.name}
                      filterParent={subCategory?.parent_subcategory?.id}
                    />
                  </>
                );
              })}
            </Grid>
          );
        })
      )}
    </Carousel> */
