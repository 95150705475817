import { withTranslation } from "react-i18next";
import { Grid, Divider, Typography } from '@material-ui/core';
import "./ChatList.css";
import { icons } from "../../../Assets/images/IconHelper";
import TextFieldInput from "../../Atoms/Inputs/TextField/TextFieldInput";
import ChatItem from "../../Molecules/Chat/ChatItem";
import { useState } from "react";

const ChatList = (props) => {
    const { chatRooms, updateMessages, updateChatBox, updateListWithSearch } = props;
    const [searchKeywords, setSearchKeywords] = useState("");

    const getSearchKeywords = (keyword) => {
        setSearchKeywords(keyword);
        const filterOj = {
            'search_key': keyword
        }
        updateListWithSearch(filterOj);
    };

    return(
        <Grid container className="chat-list-container">
            <Grid container lg={10} className="chat-list-header">
                <Grid style={{margin: "0 10px"}}>
                    <img src={icons.MessengerIcon} alt="messenger-icon"/>
                </Grid>
                <Grid>
                    <Typography component="h1" style={{fontWeight: "bolder"}}>
                        Messenger 
                    </Typography>
                </Grid>
            </Grid>
            <Divider style={{width: "100%", borderBottom: "rgba(151, 151, 151, 0.5)", marginBottom: "15px"}}/>
            <Grid lg={10} className="chat-search-input">
                <TextFieldInput
                    StartAdornment={<img src={icons.SearchMessage}/>}
                    placeholder="Search messages"
                    style={{background: "rgba(216, 216, 216, 0.25)"}}
                    onChange={(e) => getSearchKeywords(e.target.value)}
                    value={searchKeywords}
                />
            </Grid>
            <Grid container  className="chat-list-body">
                <ChatItem
                    chatRooms={chatRooms}
                    updateMessages={updateMessages}
                    updateChatBox={updateChatBox}
                />
            </Grid>
        </Grid>
    )
};

export default withTranslation()(ChatList);