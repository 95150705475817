import "./BestSeller.css";
import "../HomePage.css";

import { Grid } from "@material-ui/core";
import PrimaryButton from "../../../Atoms/Buttons/Primary/PrimaryButton";
import ProductCard from "../../Cards/ProductCard/ProductCard";
import { useWishlist } from "../../../../Hooks/useWishlist/useWishlist";
import { withTranslation } from "react-i18next";

const BestSeller = ({ items, t, title }) => {
  const { addToWishlist, deleteFromWishlist } = useWishlist();
  return (
    <Grid container style={{ marginTop: 50, marginBottom: 50 }}>
      <Grid item xs={12}>
        <h2 className="home-title best-title">{title}</h2>
      </Grid>
      <Grid item xs={12}>
        <div className="button-container">
          <div>
            <PrimaryButton
              classBtn="primary btn-rounded btn-small"
              text={t("bestSeller.seeAll")}
            />
          </div>
        </div>
      </Grid>

      <Grid container spacing={4}>
        {items?.map((item) => (
          <Grid item xs={6} sm={4} lg={3}>
            <ProductCard
              title={item?.name}
              id={item?.id}
              img={item?.default_variant?.image}
              row={false}
              variant="featured"
              regularImg={true}
              price={item?.default_variant?.price}
              currency={item?.default_variant?.currency}
              addToWishlist={addToWishlist}
              deleteFromWishlist={deleteFromWishlist}
            />
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

export default withTranslation()(BestSeller);
