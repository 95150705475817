import "./NavbarApp.css";

import {
  AppBar,
  Button,
  ClickAwayListener,
  Grid,
  Menu,
  MenuItem,
  Tab,
  Tabs,
} from "@material-ui/core";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useContext, useEffect, useState } from "react";

import { BadgesContext } from "../../../Contexts/badgesContext/badgesContext";
import { CategoriesContext } from "../../../Contexts/contextCategories/categoriesContext";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import IconOverlay from "../../Atoms/IconOverlay/IconOverlay";
import MenuIcon from "@material-ui/icons/Menu";
import PrimaryButton from "../../Atoms/Buttons/Primary/PrimaryButton";
import { SpinnerCircular } from "spinners-react";
import { createCartAPI } from "../../../Contexts/API/Home/Cart/createCart";
import { getCartAPI } from "../../../Contexts/API/Home/Cart/getCart";
import { icons } from "../../../Assets/images/IconHelper";
import { withTranslation } from "react-i18next";
import { useCart } from "../../../Hooks/useCart/useCart";

const NavbarApp = (props) => {
  const { t, i18n, mobileView, systemConfig } = props;

  const { badges } = useContext(BadgesContext);

  const categories = useContext(CategoriesContext);
  const catLoading = categories.catLoading;

  const [tabValue, setTabValue] = useState(0);

  const history = useHistory();

  //logic for handling the product dropdown menu
  const [anchorEl, setAnchorEl] = useState(null);
  const isMenuOpen = Boolean(anchorEl);

  const handleProductMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = (e, to) => {
    setAnchorEl(null);
    if (to !== "close") {
      history.push(`/products/${to}`);
      handleClickAway(1);
    }
  };

  //logic for handling the shopping dropdown menu
  const [anchorEl2, setAnchorEl2] = useState(null);
  const isMenu2Open = Boolean(anchorEl2);

  const handleShoppingMenuOpen = (event) => {
    setAnchorEl2(event.currentTarget);
  };

  const handleMenu2Close = (e, to) => {
    setAnchorEl2(null);
    if (to !== "close") {
      history.push(
        systemConfig &&
          JSON.parse(systemConfig["System Configurations"]["Collections"])
          ? `/collections?taxon=${to}`
          : `/shopping?taxon=${to}`
      );
      handleClickAway(5);
    }
  };

  const menuProductId = "product-menu";

  const renderProdMenu = (
    <Menu
      anchorEl={anchorEl}
      getContentAnchorEl={null}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      id={menuProductId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={(e) => handleMenuClose(e, "close")}
      className={i18n.language}
    >
      <MenuItem onClick={(e) => handleMenuClose(e, "fresh")} id="fresh">
        {t("navbar.Fresh cut")}
      </MenuItem>
      <MenuItem onClick={(e) => handleMenuClose(e, "repacked")}>
        {t("navbar.RePacked")}
      </MenuItem>
      <MenuItem onClick={(e) => handleMenuClose(e, "more")}>
        {t("navbar.More")}
      </MenuItem>
    </Menu>
  );

  const menuShoppingId = "shopping-menu";
  const renderShopMenu = (
    <Menu
      anchorEl={anchorEl2}
      getContentAnchorEl={null}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      id={menuShoppingId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenu2Open}
      onClose={(e) => handleMenu2Close(e, "close")}
      className={i18n.language}
    >
      {catLoading ? (
        <Grid container alignItems="center" justify="center">
          <SpinnerCircular
            size={50}
            thickness={180}
            speed={95}
            color="rgba(65, 172, 57, 1)"
            secondaryColor="rgba(111, 172, 57, 0.44)"
          />
        </Grid>
      ) : (
        categories?.categories?.data?.map((cat, index) => {
          return (
            <MenuItem onClick={(e) => handleMenu2Close(e, cat.id)}>
              {cat.attributes.name}
            </MenuItem>
          );
        })
      )}

      {/* 
        <MenuItem onClick={(e) => handleMenu2Close(e, "1")}>Categories</MenuItem>
        <MenuItem onClick={(e) => handleMenu2Close(e, "2")}>Men</MenuItem>
        <MenuItem onClick={(e) => handleMenu2Close(e, "3")}>Women</MenuItem>
        <MenuItem onClick={(e) => handleMenu2Close(e, "4")}>Sportswear</MenuItem>
        <MenuItem onClick={(e) => handleMenu2Close(e, "5")}>
          New Collection
        </MenuItem>
        <MenuItem onClick={(e) => handleMenu2Close(e, "6")}>
          Special Offers
        </MenuItem> 
      */}
    </Menu>
  );

  const [showNav, setShowNav] = useState(!mobileView);

  useEffect(() => {
    setShowNav(!mobileView);
  }, [mobileView]);

  const toggleNav = () => {
    setShowNav(!showNav);
  };

  const handleClickAway = (value) => {
    if (mobileView && showNav) {
      setShowNav(false);
    }
    if (value !== -1) {
      setTabValue(value);
    }
  };

  const [wishlistCount, setWishlistCount] = useState(badges?.wishlist);
  const [cartCount, setCartCount] = useState(badges?.cart);

  // const loadCart = async () => {
  //   const { res, status } = await getCartAPI();

  //   if (status === 404) {
  //     await createCartAPI();
  //     const { res } = await getCartAPI();

  //     if (res)
  //       setBadges({ ...badges, cart: res?.data?.attributes?.item_count });
  //   } else {
  //     setBadges({ ...badges, cart: res?.data?.attributes?.item_count });
  //   }
  // };

  const { loadCart } = useCart();

  useEffect(() => {
    if (localStorage.getItem("TOKEN")) loadCart();
  }, []);

  useEffect(() => {
    setWishlistCount(badges?.wishlist);
    setCartCount(badges?.cart);
  }, [badges]);

  let location = useLocation();

  useEffect(() => {
    switch (location["pathname"].split("/")[1]) {
      case "":
        setTabValue(0);
        break;
      case "collections":
        setTabValue(1);
        break;
      case "contactus":
        setTabValue(2);
        break;
      case "blog":
        setTabValue(3);
        break;
      case "aboutus":
        setTabValue(4);
        break;
      // case "product":
      //   setTabValue(1);
      //   break;
      // case "partners":
      //   setTabValue(2);
      //   break;
      // case "clients":
      //   setTabValue(3);
      //   break;
      // case "dankitchen":
      //   setTabValue(4);
      //   break;
      // case "shopping":
      //   setTabValue(5);
      //   break;
      case "wishlist":
        setTabValue(6);
        break;
      case "basket":
        setTabValue(7);
        break;
      default:
        if (location["pathname"].startsWith("/product")) {
          setTabValue(1);
        } else {
          setTabValue(8);
        }
    }
  }, [location]);

  const [isBasketDisabled, setIsBasketDisabled] = useState(cartCount === 0);

  useEffect(() => {
    if (cartCount > 0) setIsBasketDisabled(false);
    else setIsBasketDisabled(true);
  }, [cartCount]);

  const [User, setUser] = useState(localStorage.getItem("USER_TYPE"));

  return (
    <ClickAwayListener onClickAway={() => handleClickAway(-1)}>
      <AppBar className="Nav_header">
        {mobileView && (
          <PrimaryButton
            btnType="icon"
            startIcon={<MenuIcon />}
            click={toggleNav}
          />
        )}
        {showNav && (
          <Tabs value={tabValue} className="nav_tabs">
            <Tab
              value={0}
              label={t("navbar.Home")}
              component={Link}
              to="/"
              onClick={() => handleClickAway(0)}
            />

            {/* <Tab
              value={1}
              label={t("navbar.Products")}
              component={Button}
              // endIcon={<img src={icons.dropdown} alt="" />}
              endIcon={<ExpandMoreIcon />}
              onClick={handleProductMenuOpen}
              aria-controls={menuProductId}
              aria-haspopup={true}
              color="inherit"
            /> */}
            <Tab
              value={1}
              label={t("navbar.Categories")}
              // label={t("navbar.Shopping")}
              component={Button}
              endIcon={<ExpandMoreIcon />}
              onClick={handleShoppingMenuOpen}
              aria-controls={menuShoppingId}
              aria-haspopup={true}
              color="inherit"
            />
            {/* {User == "vendor" && (
              <Tab
                value={9}
                label={"Requests"}
                component={Link}
                to="/requests"
                onClick={() => handleClickAway(9)}
              />
            )} */}
            <Tab
              value={2}
              label={t("navbar.ContactUs")}
              component={Link}
              to="/contactus"
              onClick={() => handleClickAway(2)}
            />
            <Tab
              value={3}
              label={t("navbar.Blog")}
              component={Link}
              to="/blog"
              onClick={() => handleClickAway(3)}
            />
            <Tab
              value={4}
              label={t("navbar.AboutUs")}
              component={Link}
              to="/aboutus"
              onClick={() => handleClickAway(4)}
            />
            {/* 
            <Tab
              value={3}
              label={t("navbar.Clients")}
              component={Link}
              to="/clients"
              onClick={() => handleClickAway(3)}
            />

            <Tab
              value={4}
              label={t("navbar.Dan Kitchen")}
              component={Link}
              to="/dankitchen"
              onClick={() => handleClickAway(4)}
            /> */}

            {/* <IconButton
            value={5}
            aria-controls={menuShoppingId}
            aria-haspopup={true}
            onClick={handleShoppingMenuOpen}
            color="inherit"
            className="nav-iconbutton"
          >
            <p>{t('navbar.Shopping')} &nbsp; </p>
            <img src={icons.dropdown} alt="" />
          </IconButton> */}
            {/* <Tab
              value={2}
              label={t("navbar.Partners")}
              component={Link}
              to="/partners"
              onClick={() => handleClickAway(2)}
            /> */}
            <div className={`nav-right nav-wishlist`}>
              <Tab
                value={6}
                className={`nav-right  ${
                  tabValue === 6 ? "nav-selected-color" : ""
                }`}
                icon={
                  <IconOverlay
                    src={icons.wishlist}
                    width="20px"
                    height="19px"
                  />
                }
                label={t("navbar.Whishlist")}
                component={Link}
                to="/wishlist"
                onClick={() => handleClickAway(6)}
              />
              {wishlistCount > 0 ? (
                <span
                  class="nav-dot nav-dot-ar"
                  onClick={() => history.push("/wishlist")}
                >
                  {wishlistCount}
                </span>
              ) : null}
            </div>

            <div
              style={{
                cursor: isBasketDisabled ? "not-allowed" : "pointer",
              }}
              className="nav-wishlist"
            >
              <Tab
                disabled={isBasketDisabled}
                value={7}
                className={`${tabValue === 7 ? "nav-selected-color" : ""}`}
                // icon={<img src={icons.cart} width="20px" height="19px" alt="heart" />}
                icon={
                  <IconOverlay src={icons.cart} width="20px" height="19px" />
                }
                label={t("navbar.Basket")}
                component={Link}
                to="/basket"
                onClick={() => handleClickAway(7)}
              />
              {cartCount > 0 ? (
                <span
                  class="nav-dot nav-dot-ar"
                  onClick={() => history.push("/basket")}
                >
                  {cartCount}
                </span>
              ) : null}
            </div>
            <Tab
              value={8}
              component="p"
              style={{
                width: "0px",
                visibility: "hidden",
                position: "absolute",
              }}
            />
          </Tabs>
        )}

        {renderProdMenu}
        {renderShopMenu}
      </AppBar>
    </ClickAwayListener>
  );
};

export default withTranslation()(NavbarApp);
