import SubmitProposalTemplate from "../../Templates/Home/SubmitProposal/SubmitProposal.Template";
import { useEffect } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useProposals } from "../../Hooks/useProposals/useProposals";

const EsolarEditProposalPage = () => {
  let params = useParams();
  const { loadProposal, loading, proposal, updateProposal } = useProposals();

  useEffect(() => {
    loadProposal(params?.proposalID);
  }, []);
  const handleSubmit = async (data, id) => {
    await updateProposal(id, data);
  };
  return (
    <SubmitProposalTemplate
      loading={loading}
      data={proposal}
      RequestID={params?.id}
      handleSubmit={handleSubmit}
      edit={true}
    />
  );
};

export default EsolarEditProposalPage;
