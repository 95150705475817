import React, { useEffect, useState } from "react";
import "./NavbarLanguage.css";
import { Grid, Menu, MenuItem } from "@material-ui/core";
import LanguageChange from "../../Molecules/LanguageChange/LanguageChange";
import { findFlagUrlByIso2Code } from "country-flags-svg";
import IconOverlay from "../../Atoms/IconOverlay/IconOverlay";
import { icons } from "../../../Assets/images/IconHelper";
import { withTranslation } from "react-i18next";
import { getListOfCountriesAPI } from "../../../Contexts/API/Home/Countries/getListOfCountries";

function NavbarLanguage(props) {
  const { t, i18n, locations } = props;
  useEffect(async () => {
    let locId = localStorage.getItem("location");
    if (locId) {
      setSelectedLocation(getLocById(locId));
    } else {
      setSelectedLocation(getLocById(locId));
      localStorage.setItem("location", 1);
    }
  }, [locations]);

  const [selectedLocation, setSelectedLocation] = useState({
    id: 1,
    name: "Riyadh",
    country: "Saudi Arabia",
    iso2: "sa",
  });

  const getLocById = (id) => {
    return locations?.find((loc) => loc.id * 1 === id * 1);
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const isMenuOpen = Boolean(anchorEl);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = (id) => {
    if (id !== "close") {
      setSelectedLocation(getLocById(id));
      localStorage.setItem("location", id);
    }
    setAnchorEl(null);
  };

  const menuCountriesId = "countries-menu";
  //   const renderCountriesMenu = (

  //   );

  return (
    <Grid container className="Nav_lang">
      <Grid item xs={8} style={{ textAlign: "start" }}>
        <div className="nav-dropdown-flex">
          <div
            container
            aria-controls={menuCountriesId}
            aria-haspopup={true}
            onClick={handleMenuOpen}
            color="inherit"
            className="countries-container"
          >
            {/* <img
              className="flag-img"
              src={findFlagUrlByIso2Code(selectedLocation?.attributes?.iso)}
              alt=""
            /> */}
            <p className="deliver-to"> {t("deliver")} </p>
            <p className="selected-location">
              {selectedLocation?.attributes?.name}
            </p>
            <IconOverlay
              src={icons.dropdown2}
              iconStyle="icon-primary nav-flag-dropdown"
            />
          </div>
        </div>
      </Grid>

      <Grid item xs={4} style={{ textAlign: "end" }}>
        <LanguageChange {...props} />
      </Grid>
      <Menu
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        id={menuCountriesId}
        keepMounted
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        open={isMenuOpen}
        onClose={(e) => handleMenuClose("close")}
        className={`country-list-menu ${i18n.language}`}
      >
        {locations?.map((loc) => (
          <MenuItem
            onClick={(e) => handleMenuClose(loc.id)}
            key={loc.id}
            className="country-list-item"
          >
            {/* <img
              className="flag-img"
              src={findFlagUrlByIso2Code(loc?.attributes?.iso)}
              alt=""
            /> */}
            {loc.attributes.name}
          </MenuItem>
        ))}
      </Menu>
    </Grid>
  );
}

export default withTranslation()(NavbarLanguage);
