import * as React from "react";

import { Divider, Typography } from "@mui/material";

import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import IconOverlay from "../../../Components/Atoms/IconOverlay/IconOverlay";
import { Link } from "react-router-dom";
import ProposalPhase1 from "./Phases/ProposalPhase1.Template";
import ProposalPhase2 from "./Phases/ProposalPhase2.Template";
import ProposalPhase3 from "./Phases/ProposalPhase3.Template";
import Stepper from "../../../Components/Molecules/Stepper/Stepper";
import { icons } from "../../../Assets/images/IconHelper";
import { numberFormatter } from "../../../utils/dateFormatter/dateFormatter";
import { useEffect } from "react";
import { useState } from "react";

function SubmitProposalTemplate({
  RequestID,
  handleSubmit,
  data,
  edit,
  request,
}) {
  const [proposal, setProposal] = useState({
    date: "",
    time: "",
    details: "",
    estimated_total_price: "",
    currency_type_id: "1",
    installments: [],
  });

  // Files
  const [additionalFiles, setAdditionalFiles] = useState([]);
  const [deletedDocumentIDS, setDeletedDocumentIDS] = useState([]);

  // Stepper states
  const [value, setValue] = useState(1);
  const handleChange = (newValue) => {
    setValue(newValue);
  };

  const [stepsValidation, setStepsValidation] = useState([
    {
      condition: false,
      number: 1,
    },
    {
      condition: false,
      number: 2,
    },
  ]);

  useEffect(() => {
    // If Proposal Data exists then this is an edit page not a create page
    if (data) {
      const proposalData = data?.proposals[0];
      const dateTime = new Date(proposalData?.datetime);
      //formatting time to work with the time input format
      let hours = dateTime.getHours();
      let minutes = dateTime.getMinutes();
      minutes = numberFormatter(minutes);
      hours = numberFormatter(hours);
      const time = hours + ":" + minutes;

      let day = dateTime.getDate();
      day = numberFormatter(day);
      let month = dateTime.getMonth() + 1;
      month = numberFormatter(month);
      const date = dateTime.getFullYear() + "-" + month + "-" + day;

      const proposalEditData = {
        date: date,
        time: time,
        details: proposalData?.details,
        estimated_total_price: proposalData?.estimated_total_price,
        currency_type_id: proposalData?.currency_type_id,
        installments: proposalData?.installments,
      };

      setProposal(proposalEditData);
    }
  }, [data]);
  const steps = [
    {
      title: "Details",
      number: 1,
      component: (
        <ProposalPhase1
          data={data}
          proposal={proposal}
          setProposal={setProposal}
          handleChange={handleChange}
          stepsValidation={stepsValidation}
          setStepsValidation={setStepsValidation}
          additionalFiles={additionalFiles}
          setAdditionalFiles={setAdditionalFiles}
          deletedDocumentIDS={deletedDocumentIDS}
          setDeletedDocumentIDS={setDeletedDocumentIDS}
        />
      ),
    },
    {
      title: "Installments",
      number: 2,
      component: (
        <ProposalPhase2
          data={data}
          proposal={proposal}
          setProposal={setProposal}
          handleChange={handleChange}
          stepsValidation={stepsValidation}
          setStepsValidation={setStepsValidation}
        />
      ),
    },
    {
      title: "Preview",
      number: 3,
      component: (
        <ProposalPhase3
          proposal={proposal}
          setProposal={setProposal}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          RequestID={RequestID}
          edit={edit}
          proposalID={data?.proposals[0]?.id}
          additionalFiles={additionalFiles}
        />
      ),
    },
  ];

  return (
    <Container component="main" maxWidth="sm">
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            width: "100%",
            alignItems: "center",
          }}
        >
          <Link to="/requests">
            <IconOverlay
              style={{ transform: "rotate(90deg)" }}
              src={icons.dropdown}
              iconStyle="icon-primary"
            />
          </Link>
          <Typography
            variant="h5"
            sx={{
              width: "100%",
              margin: "0 13px",
              fontSize: "26px",
              fontWeight: "600",
            }}
          >
            Submit a proposal
          </Typography>
        </Box>
        <Divider
          sx={{
            width: "100%",
            height: "1px",
            background: "#d8d8d8",
            margin: "10px 0",
          }}
        />
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Typography variant="h3" sx={{ fontSize: "26px", fontWeight: "600" }}>
            To
          </Typography>
          <Box
            sx={{
              display: "flex",
              padding: "0 30px",
            }}
          >
            <IconOverlay
              src={icons.oval}
              iconStyle="icon-primary"
              style={{ width: 50, height: 50 }}
            />
            <Box>
              <Typography
                variant="h5"
                sx={{ fontSize: "18px", padding: "0 10px", fontWeight: "600" }}
              >
                {request?.client_name}
              </Typography>
              <Typography
                variant="p"
                sx={{
                  fontSize: "12px",
                  color: "#9e9e9e",
                  padding: "0 10px",
                  lineHeight: 1,
                }}
              >
                {request?.client_rating} ({request?.client_reviews_count})
              </Typography>
            </Box>
          </Box>
        </Box>
        <Divider
          sx={{
            width: "100%",
            height: "1px",
            background: "#d8d8d8",
            margin: "10px 0",
          }}
        />
        <Stepper
          steps={steps}
          stepValue={value}
          stepHandle={handleChange}
          stepsValidation={stepsValidation}
        />
      </Box>
    </Container>
  );
}

export default SubmitProposalTemplate;
