import { useLocation, useParams } from "react-router";

import { CreateProposalAPI } from "../../../Contexts/API/Home/Proposals/CreateProposal";
import SubmitProposalTemplate from "../../../Templates/Home/SubmitProposal/SubmitProposal.Template";
import { containerClasses } from "@mui/system";
import { toast } from "react-toastify";
import { useState } from "react";

function SubmitProposalPage() {
  let Params = useParams();
  const location = useLocation();

  const handleSubmit = async (ProposalData) => {
    const { res, err } = await CreateProposalAPI(ProposalData);
    if (res) {
      window.location = "/proposal/success";
    }
    if (err) {
      toast.error(err?.at(0));
    }
  };

  return (
    <SubmitProposalTemplate
      RequestID={Params?.id}
      request={location.state}
      handleSubmit={handleSubmit}
      edit={false}
    />
  );
}

export default SubmitProposalPage;
