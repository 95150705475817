import { MenuItem } from "@material-ui/core";
import { useState } from "react";
import { withTranslation } from "react-i18next";
import { icons } from "../../../Assets/images/IconHelper";
import IconOverlay from "../../Atoms/IconOverlay/IconOverlay";
import './NotificationItem.css';


const NotificationItem = ({ i18n, t, notification, handleMenuClose, editNotifications }) => {

    const [seen, setSeen] = useState(notification.opened);

    const handleClick = () => {
        let opened = true;
        setSeen(opened);

        editNotifications(notification.id, {
            ...notification,
            opened
        })
        handleMenuClose(notification.to)
    }

    return (
        <>
            <MenuItem
                onClick={(e) => handleClick()}
                key={notification.to}
                className="notification-menu"
            >

                <div className={`notification-item ${seen ? "notification-seen" : ""}`}>
                    <div className={`notification-item-container ${i18n.language === 'ar' ? "ar-notification" : ""}`}>
                        <div>
                            <div className="notifications-carticon">
                                <IconOverlay src={icons.cart} iconStyle="icon-secondary" width="18px" height="21px" />
                            </div>
                        </div>

                        <p className="notification-text">
                            {notification.text}
                        </p>
                    </div>
                    <div className="notification-date">
                        {notification.date}
                    </div>

                </div>

            </MenuItem>
        </>
    );
}

export default withTranslation()(NotificationItem);