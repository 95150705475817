import React from 'react'
import ReportsTemplate from '../../../Templates/Home/Reports/Reports.Template'

function Reports() {
    return (
        <ReportsTemplate />
    )
}

export default Reports
