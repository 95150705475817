import "./OrderedItem.css";

import React, { useContext, useState } from "react";

import { Grid } from "@material-ui/core";
import ImageView from "../ImageView/ImageView";
import { NavLink } from "react-router-dom";
import PrimaryButton from "../../Atoms/Buttons/Primary/PrimaryButton";
import { images } from "../../../Assets/images/IconHelper";
import { systemContext } from "../../../Contexts/systemContext/systemContext";
import { withTranslation } from "react-i18next";

function OrderedItem(props) {
  //   const [showButton] = useState(props.showButton == "hide" ? false : true);
  const { t, showButton } = props;
  const { systemConfig } = useContext(systemContext);
  return (
    <Grid container spacing={3} style={{ marginBlock: "20px" }}>
      <Grid
        item
        xs={12}
        md={4}
        className="order_img"
        style={{
          width: "calc(100% - 10px)",
          border: "1px solid var(--beige-color)",
        }}
      >
        <ImageView
          src={
            props?.item_image ? props?.item_image : images.productPlaceHolder
          }
          className="order_item_img"
        />
      </Grid>
      <Grid item xs={12} md={8} className="order_details">
        <h6 style={{ color: "var(--dark-color)" }}>{props.item_name}</h6>
        <h6 style={{ color: "var(--primary-color)", marginBottom: "16px" }}>
          <span>{props.item_currency} </span>
          {props.item_price}
        </h6>
        <p>
          <span>{t("product details.arrives")} : </span> {props.item_arrive}
        </p>
        <p>
          <span>{t("product details.quantity")} : </span> {props.item_quantity}
        </p>

        {showButton && (
          <NavLink to={`${props.item_id}/review/${props.order_number}`}>
            <PrimaryButton classBtn="outline" text={props.btn_text} />
          </NavLink>
        )}
      </Grid>
    </Grid>
  );
}

export default withTranslation()(OrderedItem);
