import * as React from "react";

import AccordionDots from "../../../../Components/Molecules/AccordionDots/AccordionDots";
import Box from "@mui/material/Box";
import IconOverlay from "../../../../Components/Atoms/IconOverlay/IconOverlay";
import { Link } from "react-router-dom";
import PrimaryButton from "../../../../Components/Atoms/Buttons/Primary/PrimaryButton";
import { Typography } from "@mui/material";
import UploadedFile from "../../../../Components/Molecules/UploadedFile/UploadedFile";
import { icons } from "../../../../Assets/images/IconHelper";
import { useState } from "react";

const ProposalPhase3 = ({
  proposalID,
  proposal,
  handleChange,
  handleSubmit,
  RequestID,
  additionalFiles,
  edit,
}) => {
  return (
    <Box
      component="form"
      onSubmit={console.log("test")}
      sx={{ mt: 2, width: "100%" }}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Typography
          variant="h2"
          sx={{
            textAlign: "left",
            fontSize: "21px",
            fontWeight: "900",
          }}
        >
          Preview proposal
        </Typography>
        <PrimaryButton
          startIcon={<IconOverlay src={icons.bin} iconStyle="icon-primary" />}
          classBtn="borderless edit"
          text={"Edit"}
          click={() => handleChange(1)}
        />
      </Box>
      <Box sx={{ width: "100%", textAlign: "start" }}>
        <Typography
          variant="h4"
          sx={{
            textAlign: "left",
            fontSize: "14px",
            fontWeight: "600",
            marginTop: "26px",
            color: "#303030",
          }}
        >
          Job details
        </Typography>
        <Typography
          variant="p"
          sx={{
            textAlign: "left",
            fontSize: "16px",
            color: "#303030",
            fontWeight: "400",
          }}
        >
          {proposal?.details}
        </Typography>
        {additionalFiles?.length > 0 ? (
          <Box style={{ display: "flex", padding: "10px" }}>
            {additionalFiles.map((file, index) => {
              return (
                <a href={file.file_url} target="_blank">
                  <UploadedFile
                    fileType={file.extension}
                    fileName={file.file_name}
                    id={index}
                    files={additionalFiles}
                    backendId={file.id}
                  />
                </a>
              );
            })}
          </Box>
        ) : (
          ""
        )}
        <Typography
          variant="h4"
          sx={{
            textAlign: "left",
            fontSize: "14px",
            marginTop: "26px",
            color: "#303030",
            fontWeight: "600",
          }}
        >
          Date and time
        </Typography>
        <Typography
          variant="p"
          sx={{
            textAlign: "left",
            fontSize: "16px",
            color: "#303030",
            fontWeight: "400",
          }}
        >
          {proposal?.date}
          {/* {proposal?.time_range_id} */}
        </Typography>
        <Typography
          variant="h4"
          sx={{
            textAlign: "left",
            fontSize: "14px",
            marginTop: "26px",
            color: "#303030",
            fontWeight: "600",
          }}
        >
          Estimated price
        </Typography>
        <Typography
          variant="p"
          sx={{
            textAlign: "left",
            fontSize: "16px",
            color: "#303030",
            fontWeight: "400",
          }}
        >
          {proposal?.estimated_total_price}
        </Typography>
      </Box>
      {proposal?.installments?.length != 0 && (
        <>
          <Typography
            variant="h2"
            sx={{
              textAlign: "left",
              fontSize: "22.4px",
              fontWeight: "900",
              marginTop: "26px",
            }}
          >
            Installments
          </Typography>
          <AccordionDots Data={proposal?.installments} />
        </>
      )}

      {/* <Link
        to="/submit_proposal/success"
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          marginTop: "30px",
        }}
      > */}

      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          marginTop: "30px",
        }}
      >
        <PrimaryButton
          text={"Submit proposal"}
          classBtn="btn-secondry proposal"
          style={{
            maxWidth: "320px",
          }}
          click={() => {
            if (edit) {
              handleSubmit(
                {
                  proposal: {
                    request_id: parseInt(RequestID),
                    datetime: `${proposal?.date} ${proposal?.time ?? ""}`,
                    details: proposal?.details,
                    estimated_total_price: proposal?.estimated_total_price,
                    currency_type_id: proposal?.currency_type_id,
                    documents_attributes: additionalFiles?.map((item) => ({
                      url: item?.file_url,
                    })),
                    installments_attributes: proposal?.installments?.map(
                      (item) => ({
                        id: item?.id,
                        title: item.title,
                        due_date: item?.due_date,
                        duration: item?.duration,
                        estimated_price: item?.estimated_price,
                        currency_type_id: item?.currency_type_id,
                        details: item?.details,
                        documents_attributes: item?.documents_attributes?.map(
                          (file) => ({ url: file?.file_url })
                        ),
                      })
                    ),
                  },
                },
                proposalID
              );
            } else {
              handleSubmit({
                proposal: {
                  request_id: parseInt(RequestID),
                  datetime: `${proposal?.date} ${proposal?.time ?? ""}`,
                  details: proposal?.details,
                  estimated_total_price: proposal?.estimated_total_price,
                  currency_type_id: proposal?.currency_type_id,
                  documents_attributes: additionalFiles?.map((item) => ({
                    url: item?.file_url,
                  })),
                  installments_attributes: proposal?.installments?.map(
                    (item) => ({
                      title: item.title,
                      due_date: item?.due_date,
                      duration: item?.duration,
                      estimated_price: item?.estimated_price,
                      currency_type_id: item?.currency_type_id,
                      details: item?.details,
                      documents_attributes: item?.documents_attributes?.map(
                        (file) => ({ url: file?.file_url })
                      ),
                    })
                  ),
                },
              });
            }
          }}
        />
      </Box>
      {/* </Link> */}
    </Box>
  );
};
export default ProposalPhase3;
