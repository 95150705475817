import { axiosFn, handleError } from "../../index";
import * as URL from "../../URLs";

export async function getProductsList(filter, page = 1) {
  return await axiosFn(
    "get",
    `${URL.PRODUCT}?${filter ? filter : ""}&page=${page}`,
    undefined,
    false
  )
    .then((res) => {
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}
export async function getProductsListAPI(filter, page) {
  let { err, res } = await getProductsList(filter, page);

  if (res) {
    return res;
  }

  if (err) {
    return err;
  }
}
