import './BlogImage.css'

const BlogImage = ({ img, caption }) => {
    return (
        <div className="blogImage">

            <div className="blogImage-img">
                <img src={img} alt="" />

                <p className="blogImage-caption">{caption}</p>
            </div>
        </div>
    );
}

export default BlogImage;