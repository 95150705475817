import { Checkbox, FormControl, FormControlLabel, FormGroup, Grid } from '@material-ui/core';
import { useState } from 'react';
import { withTranslation } from 'react-i18next';
import TextFieldInput from '../../Atoms/Inputs/TextField/TextFieldInput';
import './CheckoutPayment.css';

const CheckoutPayment = ({ t }) => {

    const [isCard, setIsCard] = useState(false);
    const [isCOD, setIsCOD] = useState(false);

    const [card, setCard] = useState({
        number: "",
        cvv: "",
        exp: "",
        name: "",
        zip: ""
    })

    return (

        <div className="checkoutPayment-card">
            <p className="checkoutPayment-title">
                {t("checkout.method")}
            </p>

            <p className="checkoutPayment-subtitle">
                {t("checkout.how pay")}
            </p>

            <div className="CheckoutPayment-check">
                <FormControl component="fieldset">
                    <FormControlLabel
                        label= {t("checkout.credit")}
                        className="CheckoutPayment-checkbox"
                        control={
                            <Checkbox value="card"
                                checked={isCard}
                                onChange={(e) => setIsCard(!isCard)}
                                name="card" />
                        }
                    />
                </FormControl>
            </div>

            <Grid container spacing={3} className="checkoutPayemnt-grid">
                <Grid item xs={12} sm={6} >
                    <TextFieldInput placeholder={t("card.card number")} value={card.number} onchange={(num) => setCard({ ...card, number: num })} />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextFieldInput placeholder={t("card.secure code")} value={card.cvv} onchange={(cvv) => setCard({ ...card, cvv })} />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextFieldInput placeholder={t("card.exp date")} value={card.exp} onchange={(exp) => setCard({ ...card, exp })} />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextFieldInput placeholder={t("card.name")} value={card.name} onchange={(name) => setCard({ ...card, name })} />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextFieldInput placeholder={t("card.zip")} value={card.zip} onchange={(zip) => setCard({ ...card, zip })} />
                </Grid>

            </Grid>

            <div className="CheckoutPayment-check">
                <FormControl component="fieldset">
                    <FormControlLabel
                        label= {t("checkout.cod")}
                        className="CheckoutPayment-checkbox"
                        control={
                            <Checkbox value="cod"
                                checked={isCOD}
                                onChange={(e) => setIsCOD(!isCOD)}
                                name="cod" />
                        }
                    />
                </FormControl>
            </div>

        </div>
    );
}

export default withTranslation()(CheckoutPayment);