//HomesDE
// export const BASE = "http://3.68.52.251";
// export const BASE_URL = `${BASE}/api/v2`;

// export const BASE = "https://api.homes-de.com";
// export const BASE_URL = `${BASE}/api/v2`;

//Sallab
// export const BASE = "https://apistaging.azizsallab.com";
// export const BASE_URL = `${BASE}/api/v2`;

// export const BASE = "https://apistagingazizsallab.iplatformsa.com";
// export const BASE_URL = `${BASE}/api/v2`;

// export const BASE = "https://api.azizsallab.com";
// export const BASE_URL = `${BASE}/api/v2`;

//E-Solar
// export const BASE = "https://apistagingesolar.inovaeg.com";
// export const BASE_URL = `${BASE}/api/v2`;

// export const BASE = "http://18.196.56.240";
// export const BASE_URL = `${BASE}/api/v2`;

export const BASE = "https://apiesolar.inovaeg.com";
export const BASE_URL = `${BASE}/api/v2`;

//E-tradeling
// export const BASE = "https://apistaging.etradeling.com";
// export const BASE_URL = `${BASE}/api/v2`;

// export const BASE = "http://3.122.18.153";
// export const BASE_URL = `${BASE}/api/v2`;

// export const BASE = "https://api.etradeling.com";
// export const BASE_URL = `${BASE}/api/v2`;

export const AUTH_SIGNUP = "/storefront/account";
export const AUTH_LOGIN = "/auth/token/create";

export const CREDITCARD = "/storefront/account/credit_cards";
export const PROFILE_ADDRESSES = "/storefront/account/addresses";

export const PRODUCT = "/storefront/products";
export const ACCOUNT = "/storefront/account";
export const ORDERS = "/storefront/account/orders";
export const CATEGORIES = "/storefront/taxons";
export const SPECIAL_CATEGORIES = "/taxons";
export const CART = "/storefront/cart";
export const QUANTITY = "/storefront/cart/set_quantity";
export const APPLY_COUPON = "/storefront/cart/apply_coupon_code";
export const REMOVE_COUPON = "/storefront/cart/remove_coupon_code";
export const COUNTRIES = "/storefront/countries";
export const REVIEWS = "/products/1/reviews";
export const DEFAULT_WISHLIST = "/storefront/wishlists";
export const CHECKOUT = "/storefront/checkout";
export const BOXES = "/boxes";
export const PRESIGNED_URL = "/presigned_url";
export const GET_COLLECTION_BY_CATEGORY = "/collections";

export const COLLECTIONS = "/collections";
export const AD_BANNER = "/ad_banners";
export const LIST_BEST_SELLER = "/collections/best_seller";
export const PRODUCTS = "/products";
export const SYSTEM = "/system_configurations";
export const FEATURED_BANNERS = "/featured_banners";

export const REQUESTS = "/requests"; //ESolar Requests
export const TAGS = "/tags";

export const BEST_SELLER_PRODUCTS = "/best_sellers";

export const CHAT_ROOMS = "/chat_rooms";
export const VISIT = "/visit";
export const SEND_MESSAGE = "/send_message";
export const CATEGORIES_LIST = "/taxons_list";
export const TRADE_TERMS = "/trade_terms";
export const UNITS = "/units";
export const PRODUCT_REQUESTS = "/product_requests"; //Etradeling Requests
export const PROPOSALS = "/proposals";
export const VENDOR = "/vendors";
export const VENDORTYPES = "/vendortypes";
export const ALL_REQUESTS = "/requests/index";
export const SERVICE_TYPES = "/servicetypes";
export const BUILDING_TYPES = "/buildingtypes";

export const QUOTATIONS = "/quotations";

export const INSTALLMENTS = "/installments";
