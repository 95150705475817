import "./Basket.css";

import { CircularProgress, Grid } from "@material-ui/core";

import OrderSummary from "../../../Components/Organisms/OrderSummary/OrderSummary";
import ProductCard from "../../../Components/Organisms/Cards/ProductCard/ProductCard";
import { withTranslation } from "react-i18next";

function BasketTemplate({
  mobileView,
  t,
  cartData,
  handleRemoveFromCart,
  handleApplyCoupon,
  countDetecter,
  setCountDetecter,
  isCartLoading,
  reload,
  cartItems,
  increaseItemQuantity,
  decreaseItemQuantity,
  removeCoupon,
  handleMoveToWishlist,
}) {
  return (
    <div>
      <h2 className="basket-title">{t("navbar.Basket")}</h2>
      {isCartLoading ? (
        <div className="progress-indicator">
          <CircularProgress style={{ color: "var(--secondary-color)" }} />
        </div>
      ) : (
        <Grid container>
          <Grid item xs={12} sm={8} className="basket-item1">
            <div className="basket-items">
              <Grid container spacing={3}>
                {cartData?.attributes?.products?.map((prod) => (
                  <>
                    {/* {cartItems?.map((prod) =>
                  prod.type == "line_item" ? ( */}
                    <Grid item key={prod.id} style={{ width: "100%" }}>
                      <ProductCard
                        reload={reload}
                        id={prod.line_item_id}
                        row={!mobileView}
                        quantityPicker="true"
                        quantity={prod?.quantity} //(need) quantity in response
                        img={prod?.image}
                        title={prod?.name}
                        rating={4} //(need) rating in response
                        currency={prod?.cost_currency}
                        price={prod?.price}
                        unit="KG" //(need) unit in response
                        discount="" //(need) discount percentage for each item or on total?
                        variant="cart"
                        handleRemoveFromCart={handleRemoveFromCart}
                        countDetecter={countDetecter}
                        setCountDetecter={setCountDetecter}
                        variantID={prod?.id}
                        parent={"basket"}
                        increaseItemQuantity={increaseItemQuantity}
                        decreaseItemQuantity={decreaseItemQuantity}
                        handleMoveToWishlist={handleMoveToWishlist}
                        productOptions={prod?.option_values}
                        arrives={prod?.option_values && "Oct 22 - Nov 10"}
                      />
                    </Grid>
                    {/* ) : null  */}
                  </>
                ))}
              </Grid>
            </div>
          </Grid>

          <Grid item xs={12} sm={4} className="basket-item2">
            <OrderSummary
              itemNumber={cartData?.attributes?.item_count || "0"}
              currency={cartData?.attributes?.currency || "SR"}
              promo={cartData?.attributes?.promo_total}
              subtotal={cartData?.attributes?.pre_tax_total || "0"}
              delivery={cartData?.attributes?.ship_total || "0"}
              total={cartData?.attributes?.total || "0"}
              handleApplyCoupon={handleApplyCoupon}
              removeCoupon={removeCoupon}
              promoCode={cartData?.attributes?.promo_code}
            />
          </Grid>
        </Grid>
      )}
    </div>
  );
}

export default withTranslation()(BasketTemplate);
//  <ProductCard
// reload={reload}
// id={prod.id}
// row={!mobileView}
// quantityPicker="true"
// quantity={prod?.attributes?.quantity}
// img={""}
// title={prod?.attributes?.name}
// rating={4} //(need) rating in response
// currency={prod?.attributes?.currency}
// price={prod?.attributes?.price}
// unit="KG" //(need) unit in response
// discount="" //(need) discount percentage for each item or on total?
// variant="cart"
// handleRemoveFromCart={handleRemoveFromCart}
// countDetecter={countDetecter}
// setCountDetecter={setCountDetecter}
// variantID={prod?.relationships?.variant?.data.id}
// parent={"basket"}
// increaseItemQuantity={increaseItemQuantity}
// decreaseItemQuantity={decreaseItemQuantity}
// handleMoveToWishlist={handleMoveToWishlist}
// productOptions={prod?.option_values}
// />
//                       <ProductCard
//                         reload={reload}
//                         id={prod.product_id}
//                         row={!mobileView}
//                         quantityPicker="true"
//                         quantity={prod?.quantity}
//                         img={prod?.image}
//                         title={prod?.name}
//                         rating={4} //(need) rating in response
//                         currency={prod?.cost_currency}
//                         price={prod?.price}
//                         unit="KG" //(need) unit in response
//                         discount="" //(need) discount percentage for each item or on total?
//                         variant="cart"
//                         handleRemoveFromCart={handleRemoveFromCart}
//                         countDetecter={countDetecter}
//                         setCountDetecter={setCountDetecter}
//                         variantID={prod?.id}
//                         parent={"basket"}
//                         increaseItemQuantity={increaseItemQuantity}
//                         decreaseItemQuantity={decreaseItemQuantity}
//                         handleMoveToWishlist={handleMoveToWishlist}
//                         productOptions={prod?.option_values}
//                       />
