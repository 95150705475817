import { timeAMPM } from "../timeAMPM/timeAMPM";

//takes a date string
//returns date in a specific format ex:(8:00 PM | 2 Jan 2022)
export const dateFormatter = (date, noTime) => {
  const dateObject = new Date(date);
  const day = dateObject.getDate();
  const month = dateObject.toLocaleString("default", { month: "long" });
  const year = dateObject.getFullYear();
  const time = timeAMPM(dateObject);
  if (noTime) {
    return `${day} ${month} ${year}`;
  }
  return `${time} | ${day} ${month} ${year}`;
};

//Adds a 0 infront of single digit numbers (format used for dates and time)
export const numberFormatter = (number) => {
  if (number < 10) {
    return "0" + number;
  } else {
    return number;
  }
};
