import VendorsTemplate from "../../../Templates/Home/Vendors/Vendors.template";
import { listVendorsAPI } from "../../../Contexts/API/Home/Vendor/listAllVendors";
import { useEffect } from "react";
import { useFetch } from "../../../Hooks/useFetch/useFetch";
import { useState } from "react";

const VendorsPage = () => {
  const [vendorList, isVendorListLoading, error, reload] =
    useFetch(listVendorsAPI);

  return (
    <VendorsTemplate
      vendorList={vendorList?.res?.vendors}
      loading={isVendorListLoading}
    />
  );
};

export default VendorsPage;
