import {withTranslation} from "react-i18next";
import { Divider, Grid, Typography } from "@material-ui/core";
import PrimaryButton from "../../../Components/Atoms/Buttons/Primary/PrimaryButton";
import { icons } from "../../../Assets/images/IconHelper";
import MessageItem from "../../Molecules/Chat/MessageItem";
import TextFieldInput from "../../Atoms/Inputs/TextField/TextFieldInput";
import FileInput from "../../Atoms/Inputs/FileInput/FileInput";
import { useState, useEffect } from "react";

const ChatBox = (props) => {
    const { messagesList, sendMessage, setMessage, message, handleFileInput } = props;
    const chatName = localStorage.getItem("chatName");
    const chatImage = localStorage.getItem("chatProfileImg");

    useEffect(() => {
        var chatbox = document.getElementById('chat-box');
        chatbox.scrollTop = chatbox.scrollHeight;
    }, [<MessageItem/>, message]);

    return(
        <Grid container className="chat-box-container">
            <Grid container lg={10} className="chat-box-header">
                <Grid lg={2} style={{marginTop: "7px"}}>
                    {chatImage == "" || chatImage === "null" ? (
                        <Typography 
                            component="span" 
                            style={{
                                width: "35px",
                                height: "35px",
                                borderRadius: "50%",
                                display: "block",
                                background: "#ddd"
                            }}>

                        </Typography>
                    ) : (
                        <img 
                            src={chatImage}
                            style={{
                                width: "35px",
                                height: "35px",
                                borderRadius: "50%",
                                display: "block",
                                background: "#ddd",
                                border: "1px solid var(--secondary-color)"
                            }}
                        />
                    )}
                </Grid>
                <Grid lg={7}>
                    <Typography component="h1" style={{lineHeight: "3"}}>
                        {chatName == "" ? "Chat Name" : chatName}
                    </Typography>
                </Grid>
                <Grid container lg={3}>
                    <Grid lg={6}>
                        <PrimaryButton
                            btnType="icon"
                            startIcon={<img src={icons.MoreBtn}/>}
                        />
                    </Grid>
                    <Grid lg={6}>
                        <PrimaryButton
                            btnType="icon"
                            startIcon={<img src={icons.Clear}/>}
                            click={() => {
                                localStorage.removeItem("Messages_node");
                                localStorage.removeItem("chatName");
                                localStorage.removeItem("chatProfileImg");
                                window.location.reload();
                            }}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Divider style={{width: "100%"}}/>
            <Grid lg={12} id="chat-box" className="chat-box-body">
                {messagesList?.map((messageItem) => (
                    <MessageItem 
                        type={messageItem?.sender_id == localStorage.getItem("user_id") ? "sent" : "received"}
                        message={messageItem?.text}
                        time={messageItem?.created_at}
                        files={messageItem?.file_url}
                    />
                ))}
            </Grid>
            <Grid lg={12} container>
                <Divider style={{width:"100%", marginBottom: "20px"}}/>
                <Grid lg={2} style={{textAlign: "center", lineHeight: 2.5, cursor: "pointer"}}>
                    <FileInput 
                        icon={icons.PlusMessage} 
                        change={handleFileInput} 
                        accept="image/*"
                    />
                </Grid>
                <Grid lg={8}>
                    <TextFieldInput
                        placeholder="Type a message"
                        value={message}
                        onchange={(message) => setMessage(message)}
                        onKeyDown={(e) => {if (e.key === "Enter" && message != "") sendMessage();}}
                    />
                </Grid>
                <Grid lg={2} style={{textAlign: "center"}}>
                    <PrimaryButton
                        btnType="icon"
                        startIcon={<img width="20" src={icons.SendBtn}/>}
                        classBtn="add-message"
                        click={sendMessage}
                        disabled={message == ""}
                    />
                </Grid>
            </Grid>
        </Grid>
    )
};

export default withTranslation()(ChatBox);