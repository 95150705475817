import { useContext, useEffect, useState } from "react";

import { toast } from "react-toastify";
import { addAddressAPI } from "../../Contexts/API/Home/Addresses/addAddress";
import { getAddressesAPI } from "../../Contexts/API/Home/Addresses/getAddresses";
import { deleteAddressAPI } from "../../Contexts/API/Home/Addresses/deleteAddress";
import { editAddressAPI } from "../../Contexts/API/Home/Addresses/editAddress";
import { AddressContext } from "../../Contexts/addressContext/addressContext";

export const useAddress = () => {
  const { addresses, setAddresses, pickedAddress, setPickedAddress } =
    useContext(AddressContext);

  const [openModal, setopenModal] = useState(false);
  const toggleModalVisibility = () => setopenModal(!openModal);
  const [isAddressLoading, setIsAddressLoading] = useState(false);

  useEffect(
    () =>
      setPickedAddress(addresses?.find((item) => item?.attributes?.is_default)),
    [addresses]
  );

  const loadAddresses = async () => {
    setIsAddressLoading(true);
    let result = await getAddressesAPI();
    if (result) {
      setAddresses(result?.data);
      setPickedAddress(
        result?.data?.find((item) => item?.attributes?.is_default)
      );
      setIsAddressLoading(false);
    } else {
      setAddresses([]);
      setIsAddressLoading(false);
    }
  };

  const addAddress = async (data) => {
    setIsAddressLoading(true);
    let { res, err } = await addAddressAPI(data);
    if (res) {
      loadAddresses();
      setopenModal(false);
      setIsAddressLoading(false);
    }
    if (err) {
      toast.error(err);
      setIsAddressLoading(false);
    }
  };

  const deleteAddress = async (id) => {
    setIsAddressLoading(true);
    let { err } = await deleteAddressAPI(id);
    if (!err) {
      loadAddresses();
      setIsAddressLoading(false);
    }
    if (err) {
      toast.error(err);
      setIsAddressLoading(false);
    }
  };

  const editAddress = async (id, data) => {
    setIsAddressLoading(true);
    let { res, err } = await editAddressAPI(id, data);
    if (res) {
      loadAddresses();
      setopenModal(false);
      setIsAddressLoading(false);
    }
    if (err) {
      toast.error(err);
      setIsAddressLoading(false);
    }
  };

  const selectAddress = async (id) => {
    setIsAddressLoading(true);
    let { res, err } = await editAddressAPI(id, {
      address: { is_default: true },
    });
    if (res) {
      loadAddresses();
      setopenModal(false);
      setIsAddressLoading(false);
    }
    if (err) {
      toast.error(err);
      setIsAddressLoading(false);
    }
  };

  return {
    addresses,
    setAddresses,
    loadAddresses,
    addAddress,
    deleteAddress,
    editAddress,
    selectAddress,
    openModal,
    setopenModal,
    toggleModalVisibility,
    isAddressLoading,
  };
};
