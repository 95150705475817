import { NavLink, useHistory } from "react-router-dom";

import { Box } from "@mui/system";
import IconOverlay from "../../Atoms/IconOverlay/IconOverlay";
import PrimaryButton from "../../Atoms/Buttons/Primary/PrimaryButton";
import { Typography } from "@mui/material";
import { icons } from "../../../Assets/images/IconHelper";

function RequestDetailsHeader({ id, handleModal, status }) {
  const history = useHistory();
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        borderBottom: "1px #979797 solid",
      }}
    >
      {" "}
      <Box sx={{ display: "flex", cursor: "pointer", mt: "16px", mb: "18px" }}>
        <NavLink to="/profile/my_requests">
          <img src={icons.dropdown} className="back_icon" />
        </NavLink>{" "}
        <Typography
          sx={{
            color: "#6f6f6f",
            mx: "16px",
            fontSize: "16px",
            fontWeight: 600,
          }}
        >
          Request Number #{id}
        </Typography>
      </Box>
      {status === "inprogress" ? (
        <Box sx={{ display: "flex" }}>
          <PrimaryButton
            size="small"
            color="primary"
            text="Chat with Customer Support"
            // startIcon={<IconOverlay src={icons.edit} iconStyle="icon-black" />}
            classBtn=" rounded-20 outline-secondry btn-white-secondry"
            style={{ marginTop: "11px" }}
            // click={() => history.push(`/edit_request/${id}`)}
          />{" "}
        </Box>
      ) : (
        <Box sx={{ display: "flex" }}>
          <PrimaryButton
            size="small"
            color="primary"
            text="Edit"
            startIcon={<IconOverlay src={icons.edit} iconStyle="icon-black" />}
            classBtn="borderless success btn-regular"
            click={() => history.push(`/edit_request/${id}`)}
            style={{ marginRight: "56px" }}
          />
          <PrimaryButton
            size="small"
            color="primary"
            text="Delete"
            startIcon={<IconOverlay src={icons.bin} iconStyle="icon-white" />}
            classBtn="borderless error"
            click={() => {
              handleModal();
            }}
          />
        </Box>
      )}
    </Box>
  );
}

export default RequestDetailsHeader;
