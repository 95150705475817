import { images } from "../../../Assets/images/IconHelper";
import "./BoxItem.css";
const BoxItem = (props) => {
    return (
        <div className="boxItem-cont">

            <div className="boxItem-img" style={{ backgroundImage: `url(${props.img})` }}>
            </div>

            <div className="boxItem-text">
                {props.text}
            </div>

        </div>
    );
}

export default BoxItem;