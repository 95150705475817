import { Grid } from "@material-ui/core";
import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import { images } from "../../../../Assets/images/IconHelper";
import ProductCard from "../../../../Components/Organisms/Cards/ProductCard/ProductCard";
function BoxItemsTemplate(props) {
  return (
    <div>
      <h2 className="wishlist-title">{props.title}</h2>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={3}>
          <ProductCard
            img={images.apple}
            title={"Place Holder"}
            icon="delete"
            rating={4}
            currency={"SR"}
            price={300}
            unit="KG"
            discount="30"
            variant="wishlist"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <ProductCard
            img={images.apple}
            title={"Place Holder"}
            icon="delete"
            rating={4}
            currency={"SR"}
            price={300}
            unit="KG"
            discount="30"
            variant="wishlist"
          />
        </Grid>
      </Grid>

      {/* <Grid container spacing={3}>
      {included?.map((prod) =>
        prod.type === "variant" ? (
          <Grid item key={prod.id} xs={12} sm={6} md={3}>
            <ProductCard
              id={prod.id}
              img={images.apple}
              title={prod.attributes.sku}
              icon="delete"
              rating={4}
              currency={prod.attributes.currency}
              price={prod.attributes.price}
              unit="KG"
              discount="30"
              variant="wishlist"
              handleAddToCart={handleAddToCart}
            />
          </Grid>
        ) : (
          <></>
        )
      )}
    </Grid> */}
    </div>
  );
}

export default withTranslation()(BoxItemsTemplate);
