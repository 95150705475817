import React, { useEffect, Fragment } from "react";
import { withRouter } from "react-router-dom";

function ScrollToTop({ history, children }) {
  let prev;
  useEffect(() => {
    const unlisten = history.listen((location) => {
      if (location?.pathname != prev?.pathname) window.scrollTo(0, 0);
      prev = location;
    });
    return () => {
      unlisten();
    };
  }, []);

  return <Fragment>{children}</Fragment>;
}

export default withRouter(ScrollToTop);

// !(location?.search && prev?.search != location?.search)
