import React from 'react'
import PartnersTemplate from '../../../Templates/Home/Partners/Partners.Template'

function Partners() {
    return (
        <PartnersTemplate />
    )
}

export default Partners
