import { Grid, IconButton } from '@material-ui/core';
import './FAQCard.css';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useState } from 'react';
import PrimaryButton from '../../../Atoms/Buttons/Primary/PrimaryButton';

const FAQCard = ({title, text}) => {
    const [open, setOpen] = useState(false);

    const toggleCard = () => {
        setOpen(!open);
    }

    return (
        <Grid container className="faq-card">
            <Grid item xs={11}>
                <p className="faq-title">{title}</p>
                <pre className={`faq-text ${open ? `faq-opened` : '' }`}>
                    {text}
                </pre>

            </Grid>
            <Grid item xs={1} onClick={toggleCard} style={{display:'flex', justifyContent:'flex-end'}}>
                <IconButton className={`faq-button ${open ? `faq-rotate` : ''}`}>
                    <ExpandMoreIcon />
                </IconButton>
            </Grid>
        </Grid>
    );
}

export default FAQCard;