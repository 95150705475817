import "./PrimaryButton.css";

import { Button, IconButton } from "@material-ui/core";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";

import PropTypes from "prop-types";
import React from "react";

const useStyles = makeStyles((theme) =>
  createStyles({
    button: {
      // margin: theme.spacing(4),
      padding: 30,
    },
  })
);
function PrimaryButton({
  classBtn,
  text = "",
  click,
  startIcon = null,
  endIcon = null,
  btnType = "",
  disabled,
  style,
}) {
  const classes = useStyles();
  return (
    <>
      {btnType !== "icon" ? (
        <Button
          className={"btn " + classBtn}
          onClick={click}
          startIcon={startIcon}
          endIcon={endIcon}
          disabled={disabled}
          style={style}
        >
          {text}
        </Button>
      ) : (
        <IconButton
          style={style}
          fullWidth
          className={"btn " + classBtn}
          onClick={click}
        >
          {startIcon}
        </IconButton>
      )}
    </>
  );
}

PrimaryButton.propTypes = {
  variant: PropTypes.string,
  classBtn: PropTypes.string,
  text: PropTypes.string,
  click: PropTypes.func,
  startIcon: PropTypes.element,
  endIcon: PropTypes.element,
};

export default PrimaryButton;
