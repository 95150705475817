import "./OrderStatus.css";

import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";

import AccordionDots from "../../../Molecules/AccordionDots/AccordionDots";
import PrimaryButton from "../../../Atoms/Buttons/Primary/PrimaryButton";
import { withTranslation } from "react-i18next";

const OrderStatus = ({ t, i18n, remaining, history, ...props }) => {
  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      className={i18n.language === "ar" ? "rtl_modal" : ""}
      style={{ minWidth: "430px", width: "100%" }}
    >
      <DialogTitle style={{ paddingBottom: "0" }}>
        <div className="modal-header">
          <p className="Modal_header">Order status details</p>
        </div>
      </DialogTitle>

      <DialogContent className="orderStatus-content">
        <AccordionDots remaining={remaining} history={history} />
        <PrimaryButton
          classBtn="btn primary"
          text={"Done"}
          click={props.handleClose}
        />
      </DialogContent>
    </Dialog>
  );
};

export default withTranslation()(OrderStatus);
