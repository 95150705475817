import * as URL from "../../URLs";

import { axiosFn, handleError } from "../../index";

//GETS TRADE TERMS LIST NEEDED TO CREATE A REQUEST
export async function getProductRequests(queryParams) {
  return await axiosFn(
    "get",
    `${URL.PRODUCT_REQUESTS}/?${queryParams}`,
    null,
    false
  )
    .then((res) => {
      return {
        res: res?.data,
        err: null,
        status: res?.status,
      };
    })
    .catch(handleError);
}

export async function getProductRequestsAPI(queryParams) {
  let { err, res, status } = await getProductRequests(queryParams);

  return { res, err, status };
}
