import * as URL from "../../URLs";

import { axiosFn, handleError } from "../../index";

export async function getOrderDetails(number) {
  return await axiosFn(
    "get",
    `${URL.ORDERS}/${number}?include=line_items,shipping_address,payments,promotions`
  )
    .then((res) => {
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}
export async function getOrderDetailsAPI(number) {
  let { err, res } = await getOrderDetails(number);

  if (res) {
    return res;
  }

  if (err) {
    return {};
  }
}
