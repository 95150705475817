import { Box, CircularProgress, Container } from "@mui/material";

import InfiniteScroll from "react-infinite-scroll-component";
import PropmptModal from "../../../../Components/Organisms/Modals/PromptModal/PropmptModal";
import TabNav from "../../../../Components/Molecules/TabNav/TabNav";
import VendorProposalCard from "../../../../Components/Organisms/Cards/ProposalCard/VendorProposalCard";
import { queryParamsFormatter } from "../../../../utils/queryParamsFormatter/queryParamsFormatter";
import { useState } from "react";

const MyProposalsTemplate = ({
  data,
  handleTabsChange,
  isLoading,
  proposals,
  hasMore,
  modalOpen,

  handleDelete,
  handleModal,
  loadMore,
  currentPage,
  queryParams,
}) => {
  const [selectedId, setSelectedId] = useState(null);
  return (
    <Container component="main" maxWidth="lg">
      <h1>My Proposals</h1>
      <TabNav handleTabsChange={handleTabsChange} />

      {isLoading ? (
        <Box sx={{ display: "flex", justifyContent: "center", mt: "50%" }}>
          <CircularProgress style={{ color: "var(--secondary-color)" }} />
        </Box>
      ) : (
        <>
          <InfiniteScroll
            dataLength={proposals && proposals?.length}
            hasMore={hasMore}
            scrollThreshold={0.99}
            loader={
              <Box
                sx={{ display: "flex", justifyContent: "center", mt: "50%" }}
              >
                <CircularProgress style={{ color: "var(--secondary-color)" }} />
              </Box>
            }
            next={() => {
              loadMore(
                queryParamsFormatter({ ...queryParams, page: currentPage })
              );
            }}
          >
            {proposals?.map((proposal) => {
              return (
                <VendorProposalCard
                  request={proposal}
                  proposal={proposal?.proposals[0]}
                  handleModal={handleModal}
                  setSelectedId={setSelectedId}
                />
              );
            })}
          </InfiniteScroll>
        </>
      )}
      <PropmptModal
        open={modalOpen}
        id={selectedId}
        actionHandler={handleDelete}
        handleClose={handleModal}
        title={"Delete Proposal"}
        text={`Are you sure you want to delete proposal`}
      />
    </Container>
  );
};

export default MyProposalsTemplate;

//   {
//     isLoading ? (
//       <Box sx={{ display: "flex", justifyContent: "center", mt: "50%" }}>
//         <CircularProgress style={{ color: "var(--secondary-color)" }} />
//       </Box>
//     ) : (
//       <>
//         <InfiniteScroll
//           dataLength={proposals && proposals?.length}
//           hasMore={hasMore}
//           scrollThreshold={0.99}
//           loader={
//             <Box sx={{ display: "flex", justifyContent: "center", mt: "50%" }}>
//               <CircularProgress style={{ color: "var(--secondary-color)" }} />
//             </Box>
//           }
//           next={() => {
//             loadMoreProductRequests(
//               queryParamsFormatter({ ...queryParams, page: currentPage })
//             );
//           }}
//         >
//           {proposals?.map((request) => {
//             return (
//               <RequestCard
//                 id={request?.id}
//                 date={request?.datetime}
//                 handleDelete={handleDelete}
//                 handleViewRequestDetails={handleViewRequestDetails}
//                 rowTitles={
//                   systemConfig["System Configurations"]["Project Name"] ===
//                   "esolar"
//                     ? EsolarRequestCardInfo.rowTitles
//                     : EtradelingRequestCardInfo.rowTitles
//                 }
//                 rowInfo={[
//                   request?.product_name
//                     ? request?.product_name
//                     : request?.service_type_name,
//                   request?.category_name
//                     ? request?.category_name
//                     : request?.building_type_name,
//                   request?.quotations
//                     ? request?.quotations.length + " Quotation"
//                     : request?.proposals.length,
//                 ]}
//                 handleModal={handleModal}
//                 modalOpen={modalOpen}
//                 setSelectedId={setSelectedId}
//                 statusColor={"#3ed181"}
//                 acceptedQuotation={
//                   request?.accepted_quotation
//                     ? request?.acceptedQuotation
//                     : request?.accepted_proposal
//                 }
//               />
//             );
//           })}
//         </InfiniteScroll>
//       </>
//     );
//   }
//   <DeleteRequestModal
//     open={modalOpen}
//     id={selectedId}
//     deleteHandler={handleDelete}
//     handleClose={handleModal}
//   />;

// {isLoading ? (
//   <Box sx={{ display: "flex", justifyContent: "center", mt: "50%" }}>
//     <CircularProgress style={{ color: "var(--secondary-color)" }} />
//   </Box>
// ) : (
//   <>
//     <InfiniteScroll
//       dataLength={proposals && proposals?.length}
//       hasMore={hasMore}
//       scrollThreshold={0.99}
//       loader={
//         <Box
//           sx={{ display: "flex", justifyContent: "center", mt: "50%" }}
//         >
//           <CircularProgress style={{ color: "var(--secondary-color)" }} />
//         </Box>
//       }
//       next={() => {
//         loadMoreProductRequests(
//           queryParamsFormatter({ ...queryParams, page: currentPage })
//         );
//       }}
//     >
//       {proposals?.map((request) => {
//         return (

//         );
//       })}
//     </InfiniteScroll>
//   </>
// )}
