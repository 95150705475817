import "./EsolarAdBannerCarousel.css";

import { CircularProgress, Grid } from "@mui/material";
import { icons, images } from "../../../../Assets/images/IconHelper";
import { useEffect, useState } from "react";

import { Carousel } from "react-responsive-carousel";
import EsolarAdbannerCard from "../../../Molecules/EsolarAdbannerCard/EsolarAdbannerCard";
import IconOverlay from "../../../Atoms/IconOverlay/IconOverlay";

const EsolarAdBannerCarousel = ({ banners }) => {
  const [state, setState] = useState(null);
  const [configurableProps, setConfigurableProps] = useState({
    showArrows: false,
    showStatus: false,
    showIndicators: false,
    infiniteLoop: true,
    showThumbs: true,
    useKeyboardArrows: true,
    stopOnHover: true,
    swipeable: false,
    dynamicHeight: false,
    emulateTouch: false,
    autoFocus: false,
    thumbWidth: 100,
    selectedItem: 0,
    interval: 2000,
    transitionTime: 500,
    swipeScrollTolerance: 5,
    ariaLabel: "ariaLabel",
  });
  const pages = [];
  const getPages = (array) => {
    let page = [];
    for (var j = 0; j < array?.length; j++) {
      page.push(array[j]);
      if ((j + 1) % 2 === 0 && j !== 0) {
        pages.push(page);
        page = [];
      } else if (j === array.length - 1) {
        pages.push(page);
        page = [];
      }
    }
    return pages;
  };

  useEffect(() => {
    setState(getPages(banners));
    setConfigurableProps({ ...configurableProps, selectedItem: 0 });
  }, [banners]);

  return (
    <Grid container paddingLeft={10} paddingRight={10}>
      <Grid item xs={1} container justifyContent="center">
        <div
          style={{ cursor: "pointer", paddingTop: "50%" }}
          onClick={() => {
            setConfigurableProps({
              ...configurableProps,
              selectedItem:
                configurableProps.selectedItem > 0
                  ? configurableProps.selectedItem - 1
                  : configurableProps.selectedItem,
            });
          }}
          className={""}
        >
          <IconOverlay src={icons.esolarLeftArrow} />
        </div>
      </Grid>
      <Grid item xs={10}>
        {" "}
        <Carousel centerMode centerSlidePercentage={100} {...configurableProps}>
          {1 === 0 ? (
            <div className="progress-indicator">
              <CircularProgress style={{ color: "var(--secondary-color)" }} />
            </div>
          ) : (
            state?.map((page) => {
              return (
                <Grid
                  container
                  direction="row"
                  spacing={3}
                  justifyContent="center"
                >
                  {page.map((banner) => {
                    return (
                      <>
                        <EsolarAdbannerCard
                          title={banner.title}
                          description={banner.description}
                          image={banner.image}
                        />
                      </>
                    );
                  })}
                </Grid>
              );
            })
          )}
        </Carousel>
      </Grid>
      <Grid item xs={1} container justifyContent="center">
        <div
          style={{ cursor: "pointer", paddingTop: "50%" }}
          onClick={() => {
            setConfigurableProps({
              ...configurableProps,
              selectedItem:
                configurableProps.selectedItem < state.length - 1
                  ? configurableProps.selectedItem + 1
                  : configurableProps.selectedItem,
            });
          }}
          className={""}
        >
          <IconOverlay src={icons.esolarRightArrow} />
        </div>
      </Grid>
    </Grid>
  );
};

export default EsolarAdBannerCarousel;
