import { BrowserRouter, Route, Switch } from "react-router-dom";

import { CircularProgress } from "@material-ui/core";
import EsolarMainPage from "./Pages/EsolarMain.Page";
import EtradelingMainPage from "./Pages/EtradelingMain.page";
import Main from "./Pages/Main.Page";
import ScrollToTop from "./ScrollToTop";
import Signin from "./Pages/Auth/Signin.Page";
import Signup from "./Pages/Auth/Signup.Page";
import { useEffect } from "react";

const Routes = (props) => {
  return (
    <BrowserRouter>
      <ScrollToTop>
        <Switch>
          <Route
            path="/login"
            render={() => {
              return <Signin systemConfig={props.systemConfig} />;
            }}
          />
          <Route
            path="/register"
            render={() => {
              return <Signup systemConfig={props.systemConfig} />;
            }}
          />
          <Route
            path="/"
            render={() => {
              if (props?.systemConfig) {
                if (
                  props?.systemConfig["System Configurations"][
                    "Project Name"
                  ] === "esolar"
                ) {
                  return (
                    <EsolarMainPage
                      {...props}
                      onLanguageHandle={props.onLanguageHandle}
                      value={props.value}
                      systemConfig={props.systemConfig}
                      isSystemConfigLoading={props.isSystemConfigLoading}
                    />
                  );
                } else if (
                  props.systemConfig &&
                  props.systemConfig["System Configurations"][
                    "Project Name"
                  ] === "etradeling"
                ) {
                  return (
                    <EtradelingMainPage
                      {...props}
                      onLanguageHandle={props.onLanguageHandle}
                      value={props.value}
                      systemConfig={props.systemConfig}
                      isSystemConfigLoading={props.isSystemConfigLoading}
                    />
                  );
                }
                return (
                  <Main
                    {...props}
                    onLanguageHandle={props.onLanguageHandle}
                    value={props.value}
                    systemConfig={props.systemConfig}
                    isSystemConfigLoading={props.isSystemConfigLoading}
                  />
                );
              } else {
                return (
                  <div className="progress-indicator">
                    <CircularProgress
                      style={{ color: "var(--secondary-color)" }}
                    />
                  </div>
                );
              }
            }}
          />
        </Switch>
      </ScrollToTop>
    </BrowserRouter>
  );
};

export default Routes;
