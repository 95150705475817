import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./NewCollection.css";

import { CircularProgress, Typography } from "@material-ui/core";

import CarouselWithArrows from "../../../Components/Organisms/CarouselWithArrows/CarouselWithArrows";
import PeopleViewed from "../../../Components/Organisms/PeopleViewed/PeopleViewed";
import PrimaryButton from "../../../Components/Atoms/Buttons/Primary/PrimaryButton";
import React, { useContext } from "react";
import { withTranslation } from "react-i18next";
import CollectionProducts from "../../../Components/Organisms/Cards/CollectionProducts/CollectionProducts";
import MultiDropDownSelect from "../../../Components/Atoms/MultiDropDownSelect/MultiDropDownSelect";
import { useHistory } from "react-router-dom";
import { systemContext } from "../../../Contexts/systemContext/systemContext";

function NewCollectionTemplate({
  CollectionID,
  collectionData,
  isCollectionLoading,
  categorySelected,
  setCategorySelected,
  collectionProducts,
  isProductsLoading,
  hasMore,
  setPage,
  page,
  loadMore,
  bestSellerCollection,
  optionsTypes,
  t,
  Ids,
  handleChange,
  DeleteCollection,
}) {
  const history = useHistory();
  const { account } = useContext(systemContext);
  return (
    <>
      {isCollectionLoading ? (
        <div className="progress-indicator">
          <CircularProgress style={{ color: "var(--secondary-color)" }} />
        </div>
      ) : (
        <>
          {account && account?.attributes?.email == "admin@inovaeg.com" && (
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <PrimaryButton
                classBtn=" rounded-8 btn-shadow btn-small primary secondaryColor"
                text={"upload collection images"}
                style={{
                  width: "fit-content",
                  marginBottom: "10px",
                  marginRight: "5px",
                  marginLeft: "5px",
                }}
                click={() => {
                  history.push(`/updateCollection/${CollectionID}`);
                }}
              />
              <PrimaryButton
                classBtn=" rounded-8 btn-shadow btn-small primary secondaryColor"
                text={"delete collection"}
                style={{
                  width: "fit-content",
                  marginBottom: "10px",
                }}
                click={() => {
                  DeleteCollection(CollectionID);
                }}
              />
            </div>
          )}

          <CarouselWithArrows
            Slides={
              collectionData?.documents?.length
                ? collectionData?.documents
                : [{ url: collectionData?.image }]
            }
          />
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="h5">
              {collectionData?.name}
              {/* <span style={{ fontSize: "18px" }}>( 5 items )</span> */}
            </Typography>
            <PrimaryButton
              classBtn="btn-rounded btn-small outline"
              style={{ maxWidth: "180px", height: "40px" }}
              text={"view in 360"}
            />
          </div>

          <div className="TabsContainer">
            {/* <div
              className={
                categorySelected == 0
                  ? "ChoosedCollectionMainTab"
                  : "CollectionMainTab"
              }
              onClick={() => {
                setPage(1);
                setCategorySelected(0);
              }}
            >
              <Typography
                style={{
                  fontSize: "14px",
                  color: categorySelected == 0 ? "#c21703" : "#6f6f6f",
                }}
              >
                All
              </Typography>
            </div> */}
            {collectionData?.subcategories?.map((item) => (
              <div
                className={
                  categorySelected == item.id
                    ? "ChoosedCollectionMainTab"
                    : "CollectionMainTab"
                }
                onClick={() => {
                  setPage(1);
                  setCategorySelected(item.id);
                }}
              >
                <Typography
                  style={{
                    fontSize: "14px",
                    color: categorySelected == item.id ? "#c21703" : "#6f6f6f",
                  }}
                >
                  {item.name}
                </Typography>
              </div>
            ))}
          </div>
          <div>
            <Typography variant="h6" style={{ fontWeight: "bold" }}>
              {t("filter by")}
            </Typography>
            {optionsTypes?.map((item) => (
              <MultiDropDownSelect
                items={item?.option_values}
                label={item?.name}
                value={Ids}
                handleChange={handleChange}
                placeholder={item?.name}
                ContainerStyle={{ width: "100%", maxWidth: "165px" }}
              />
            ))}
          </div>
          {collectionData?.subcategories
            ?.filter((collection) => collection.id == categorySelected)
            ?.map((item, i) => (
              <CollectionProducts
                CollectionTitle={item.name}
                Products={collectionProducts}
                ProductColor={"red"}
                LastOne={
                  categorySelected == 0
                    ? collectionData?.subcategories?.length - 1 == i
                      ? true
                      : false
                    : true
                }
                isProductsLoading={isProductsLoading}
                hasMore={hasMore}
                loadMore={loadMore}
                page={page}
                listAll
              />
            ))}

          <h2 className="people-title">
            {t("dan kitchen.people also viewed")}
          </h2>
          <hr
            style={{
              width: "108px",
              height: "6px",
              backgroundColor: "#c21703",
              border: "none",
              marginBottom: "28px",
              marginTop: "10px",
            }}
          />
          <PeopleViewed items={bestSellerCollection} />
        </>
      )}
    </>
  );
}

export default withTranslation()(NewCollectionTemplate);
