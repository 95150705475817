import { Grid, Tab, Tabs } from "@material-ui/core";
import { useState } from "react";
import { withTranslation } from "react-i18next";
import { icons, images } from "../../../Assets/images/IconHelper";
import BoxItem from "../../Molecules/BoxItem/BoxItem";
import "./BoxDetails.css";

const BoxDetails = (props) => {
  const { t, items } = props;

  const [value, setValue] = useState(0);
  const handleChange = async (event, newValue) => {
      setValue(newValue);
  };

  return (
    <div className="box-details">
      <p className="box-details-descriptions">
        {t("product details.description")}
      </p>
      <img src={icons.secondaryRectangle} />
      <p className="box-details-descriptions-text">{props.description}</p>

      <p className="box-details-what">{t("product details.whats in")}</p>

      <Grid container className="box-details-items">
        <Grid item xs={12} sm={10} md={8}>
          <Grid container>
            {items?.map((i) => (
              <Grid item xs={12} sm={6} key={i.id}>
                <BoxItem text={i.name} img={i.img} />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>

      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        aria-label="full width tabs example"
        variant="scrollable"
      >
        <Tab label={t("product details.description")} {...a11yProps(0)} />
        <Tab label="Rating & Reviews" {...a11yProps(1)} />
      </Tabs>

      <TabPanel value={value} index={0}></TabPanel>

      <TabPanel value={value} index={1}>
        <div className="box-details-reviews">Ratings and reviews tab</div>
      </TabPanel>
    </div>
  );
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <div>{children}</div>
            )}
        </div>
    );
}

export default withTranslation()(BoxDetails);
