import "./DetailsVendorRequestCard.css";

import * as yup from "yup";

import {
  Box,
  Grid,
  MenuItem,
  Select,
  TextField,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";

import IconOverlay from "../../Atoms/IconOverlay/IconOverlay";
import { Link } from "react-router-dom";
import PrimaryButton from "../../Atoms/Buttons/Primary/PrimaryButton";
import UploadedFile from "../UploadedFile/UploadedFile";
import { icons } from "../../../Assets/images/IconHelper";
import { useFormik } from "formik";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { withTranslation } from "react-i18next";

const DetailsVendorRequestCard = ({ Request, systemConfig }) => {
  const history = useHistory();
  return (
    <Box
      sx={{
        borderRadius: "4px",
        border: "solid 0.5px #979797",
        padding: "18px 16px",
        marginTop: "10px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            display: "flex",
          }}
        >
          <IconOverlay
            src={Request?.client_image ?? icons.oval}
            iconStyle="icon-primary"
            style={{ width: 50, height: 50 }}
          />
          <Box>
            <Typography
              variant="h5"
              sx={{
                fontSize: "18px",
                padding: "0 10px",
                fontWeight: "600",
              }}
            >
              {Request?.client_name}
            </Typography>
            <Typography
              variant="p"
              sx={{
                fontSize: "12px",
                color: "#9e9e9e",
                padding: "0 10px",
                lineHeight: 1,
              }}
            >
              <IconOverlay
                src={icons.rating_star}
                iconStyle="like-animation"
                style={{ width: "10px" }}
              />
              {Request?.client_rating} ({Request?.client_reviews_count})
            </Typography>
          </Box>
        </Box>
        <Typography
          variant="h5"
          sx={{
            fontSize: "12px",
            fontWeight: "300",
            color: "#9d9d9d",
            marginTop: "10px",
          }}
        >
          {Request?.datetime?.split("T")[0]}
        </Typography>
      </Box>
      <Grid container>
        <Grid item xs={6}>
          <Box
            sx={{
              width: "100%",
              marginTop: "14px",
            }}
          >
            <Typography
              variant="h5"
              sx={{
                fontSize: "14px",
                fontWeight: "600",
                color: "#303030",
                width: "130px",
              }}
            >
              {systemConfig &&
              systemConfig["System Configurations"]["Project Name"] ===
                "etradeling"
                ? "Product name"
                : "Type of services"}
            </Typography>
            <Typography
              variant="h5"
              sx={{
                fontSize: "14px",
                fontWeight: "300",
                color: "#383838",
              }}
            >
              {systemConfig &&
              systemConfig["System Configurations"]["Project Name"] ===
                "etradeling"
                ? Request?.product_name
                : Request?.service_type_name}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box
            sx={{
              width: "100%",
              marginTop: "14px",
            }}
          >
            <Typography
              variant="h5"
              sx={{
                fontSize: "14px",
                fontWeight: "600",
                color: "#303030",
                width: "130px",
              }}
            >
              {systemConfig &&
              systemConfig["System Configurations"]["Project Name"] ===
                "etradeling"
                ? "Category"
                : "Building type"}
            </Typography>
            <Typography
              variant="h5"
              sx={{
                fontSize: "14px",
                fontWeight: "300",
                color: "#383838",
              }}
            >
              {systemConfig &&
              systemConfig["System Configurations"]["Project Name"] ===
                "etradeling"
                ? Request?.category_name
                : Request?.building_type_name}
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Box sx={{ width: "100%", textAlign: "start" }}>
        <Typography
          variant="h4"
          sx={{
            textAlign: "left",
            fontSize: "14px",
            marginTop: "40px",
            color: "#303030",
            fontWeight: "600",
          }}
        >
          Job details
        </Typography>
        <Typography
          variant="p"
          sx={{
            textAlign: "left",
            fontSize: "14px",
            color: "#383838",
            fontWeight: "400",
            wordBreak: "break-all",
            marginTop: "10px",
          }}
        >
          {Request?.details}
        </Typography>
        {Request?.documents?.length > 0 ? (
          <Box style={{ display: "flex", padding: "10px", flexWrap: "wrap" }}>
            {Request?.documents?.map((file, index) => {
              return (
                <a href={file.url} target="_blank">
                  <UploadedFile
                    fileType={""}
                    fileName={file.url}
                    id={index}
                    files={Request?.documents}
                    backendId={file.id}
                    src={file.url}
                  />
                </a>
              );
            })}
          </Box>
        ) : (
          ""
        )}
        <Typography
          variant="h5"
          sx={{
            textAlign: "left",
            fontSize: "14px",
            marginTop: "40px",
            color: "#303030",
            fontWeight: "600",
          }}
        >
          Date and time
        </Typography>
        <Typography
          variant="p"
          sx={{
            textAlign: "left",
            fontSize: "16px",
            color: "#383838",
            fontWeight: "400",
            marginTop: "10px",
          }}
        >
          Tuesday 21 / 9 /2022 ( 3 pm _ 6 pm )
        </Typography>
        <Typography
          variant="h4"
          sx={{
            textAlign: "left",
            fontSize: "14px",
            marginTop: "40px",
            color: "#303030",
            fontWeight: "600",
          }}
        >
          Address
        </Typography>
        <Typography
          variant="p"
          sx={{
            textAlign: "left",
            fontSize: "16px",
            fontWeight: "400",
            marginTop: "10px",
            color: "#383838",
          }}
        >
          {Request?.address?.country_name} {", "}
          {Request?.address?.address}
        </Typography>
      </Box>
      <div
        // to={`/submit_proposal/${Request?.id}`}
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          marginTop: "30px",
        }}
      >
        <PrimaryButton
          text={"Submit proposal"}
          classBtn="primary proposal"
          style={{
            maxWidth: "320px",
          }}
          click={() =>
            history.push({
              pathname: `/submit_proposal/${Request?.id}`,
              state: Request,
            })
          }
        />
      </div>
    </Box>
  );
};

export default withTranslation()(DetailsVendorRequestCard);
