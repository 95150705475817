import './BlogComment.css';

const BlogComment = ({by, date, text}) => {
    return (
        <div className="blog-comment-container">
            <div className="blog-comment-author">
                {by}
            </div>

            <div className="blog-comment-date">
                {date}
            </div>

            <div className="blog-comment-text">
                {text}
            </div>
        </div>
    );
}


export default BlogComment;
