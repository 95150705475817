import React, { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import ShoppingTemplate from "../../../Templates/Home/Shopping/Shopping.Template";
import { useWishlist } from "../../../Hooks/useWishlist/useWishlist";
import { useCategories } from "../../../Hooks/useCategories/useCategories";
import { CategoriesContext } from "../../../Contexts/contextCategories/categoriesContext";

function Shopping() {
  const history = useHistory();

  const {
    loadOptionTypes,
    options,
    areOptionsLoading,
    Products,
    sub_categories,
    NoOfProducts,
    setProducts,
  } = useCategories();
  const categories = useContext(CategoriesContext);

  // STATES
  const [taxonId, setTaxonId] = useState(
    new URLSearchParams(useLocation().search).get("taxon")
  );
  const [selectedOptionIds, setSelectedOptionIds] = useState(
    JSON.parse(new URLSearchParams(useLocation().search).get("options")) ?? []
  );
  const [selectedSubCategory, setSelectedSubCategory] = useState(
    JSON.parse(new URLSearchParams(useLocation().search).get("sub")) ?? null
  );

  // HANDLING VALUES
  const handleSelectingCategory = (category) => {
    if (category?.id == selectedSubCategory) setSelectedSubCategory(null);
    else setSelectedSubCategory(category?.id);
  };

  const [openModal, setopenModal] = useState(false);

  // LOADING ON CHOOSING ANY NEW OPTION VALUES
  useEffect(() => {
    if (selectedSubCategory)
      loadOptionTypes(selectedSubCategory, selectedOptionIds);
    else if (taxonId) loadOptionTypes(taxonId, selectedOptionIds);
  }, [taxonId, selectedOptionIds, selectedSubCategory]);

  // SELECT DEFAULT TAXON WHEN THERE ARE NO TAXON SELECTED
  useEffect(() => {
    if (!taxonId?.at(0)) {
      setTaxonId([categories?.categories?.data?.at(0)?.id]);
    }
  }, [taxonId, categories]);

  // HANDLING URL
  useEffect(() => {
    if (!history?.location?.search?.includes("tag"))
      history.replace({
        pathname: "shopping",
        search: `?taxon=${taxonId}${
          selectedOptionIds?.length ? `&options=[${selectedOptionIds}]` : ""
        }${selectedSubCategory ? `&sub=${selectedSubCategory}` : ""}`,
      });
  }, [taxonId, selectedOptionIds, selectedSubCategory]);

  const [state, setState] = useState({
    mobileView: false,
  });

  const { mobileView } = state;

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 600
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };

    setResponsiveness();
    window.addEventListener("resize", () => setResponsiveness());

    return () => {
      window.removeEventListener("resize", () => setResponsiveness());
    };
  }, [window.innerWidth]);

  const { addToWishlist } = useWishlist();

  const [sortBy, setSortBy] = useState(0);

  const sortProducts = (prods, crit) => {
    let sorted = prods?.sort((a, b) => {
      if (
        parseInt(a?.default_variant?.price) >
        parseInt(b?.default_variant?.price)
      ) {
        return crit === "desc" ? -1 : 1;
      } else {
        return crit === "desc" ? 1 : -1;
      }
    });

    if (!sorted) {
      sorted = [];
    }

    return sorted;
  };

  //sort the products when the sort dropdown changes
  useEffect(() => {
    setProductsSorted(Products);
  }, [sortBy]);

  const setProductsSorted = (prods) => {
    setProducts((products) => {
      products = [...sortProducts(prods, sortBy === 0 ? "desc" : "asc")];
      return products;
    });
  };

  const handleChange = (event) => {
    setSortBy(event.target.value);
  };

  return (
    <ShoppingTemplate
      openModal={openModal}
      mobileView={mobileView}
      loading={areOptionsLoading}
      totalCount={NoOfProducts}
      products={Products}
      handleCloseModal={() => setopenModal(!openModal)}
      addToWishlist={addToWishlist}
      setTaxonId={setTaxonId}
      taxonId={taxonId}
      options={options}
      categories={categories}
      sub_categories={sub_categories}
      selectedSubCategory={selectedSubCategory}
      setSelectedSubCategory={setSelectedSubCategory}
      handleSelectingCategory={handleSelectingCategory}
      selectedOptionIds={selectedOptionIds}
      setSelectedOptionIds={setSelectedOptionIds}
      sortBy={sortBy}
      handleChange={handleChange}
    />
  );
}

export default Shopping;
