import "./FeatureCard.css";

import { Grid, Typography } from "@material-ui/core";

import IconOverlay from "../../Atoms/IconOverlay/IconOverlay";
import { icons } from "../../../Assets/images/IconHelper";

const FeatureCard = ({ icon, title, description }) => {
  return (
    <Grid
      container
      direction="column"
      style={{ paddingLeft: 30, paddingRight: 30 }}
    >
      <IconOverlay src={icon} iconStyle="icon-secondry" />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Typography className="home-features-abnner-card-title">
          {title}
        </Typography>
      </div>
      <IconOverlay
        src={icons.bottomSemiBorder}
        iconStyle="icon-secondry"
        style={{ marginBottom: 20 }}
      />

      <Typography className="home-features-banner-card-descriptions">
        {description}
      </Typography>
    </Grid>
  );
};

export default FeatureCard;
