import React, { useState } from "react";

import MyAccountTemplate from "../../../Templates/Home/Profile/MyAccount/MyAccount.Template";
import { useEffect } from "react";
import { useProfile } from "../../../Hooks/useProfile/useProfile";

const MyAccount = () => {
  const [openModal, setopenModal] = useState(false);
  const {
    loadProfile,
    isProfileLoading,
    profileData,
    updatePassword,
    updateAccount,
  } = useProfile();

  useEffect(() => {
    loadProfile();
  }, []);

  return (
    <MyAccountTemplate
      profileData={profileData}
      isProfileLoading={isProfileLoading}
      updateAccount={updateAccount}
      updatePassword={updatePassword}
      open={openModal}
      handleClose={() => setopenModal(!openModal)}
    />
  );
};

export default MyAccount;
