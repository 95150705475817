import React, { useEffect, useState } from "react";

import MyOrdersTemplate from "../../../Templates/Home/Profile/Orders/MyOrders.Template";
import { getOrderDetailsAPI } from "../../../Contexts/API/Home/Orders.js/getOrderDetails";
import { getOrdersListAPI } from "../../../Contexts/API/Home/Orders.js/getOrdersList";
import { useFetch } from "../../../Hooks/useFetch/useFetch";

function MyOrders() {
  const [orders, isOrdersLoading, error, reload] = useFetch(getOrdersListAPI);

  return (
    <>
      <MyOrdersTemplate
        orders={orders?.data}
        isOrdersLoading={isOrdersLoading}
      />
    </>
  );
}

export default MyOrders;
