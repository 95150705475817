import "./EsolarAboutSection.css";

import { Grid, Typography } from "@material-ui/core";

import { images } from "../../../Assets/images/IconHelper";
import { withTranslation } from "react-i18next";

const EsolarAboutSection = ({ t }) => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={6} container justifyContent="center">
        <div style={{ width: "100%" }}>
          <img
            src={images.esolarAbout}
            style={{ width: "100%", height: "100%" }}
          />
        </div>
      </Grid>
      <Grid item xs={6}>
        <Typography className="esolar-about-section-title">
          About E-Solar
        </Typography>
        <div className="description-padding">
          <Typography className="e-solar-about-section-description">
            {t("esolar.about")}
          </Typography>
        </div>
      </Grid>
    </Grid>
  );
};

export default withTranslation()(EsolarAboutSection);
