import * as URL from "../../URLs";

import { axiosFn, handleError } from "../../index";

//GETS TRADE TERMS LIST NEEDED TO CREATE A REQUEST
export async function getProductRequest(id) {
  return await axiosFn("get", `${URL.PRODUCT_REQUESTS}/${id}`, null, false)
    .then((res) => {
      return {
        res: res?.data,
        err: null,
        status: res?.status,
      };
    })
    .catch(handleError);
}

export async function getProductRequestAPI(id) {
  let { err, res, status } = await getProductRequest(id);

  return { res, err, status };
}
