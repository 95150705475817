import { Grid } from "@material-ui/core";
import React from "react";
import { useEffect } from "react";
import { withTranslation } from "react-i18next";
import Title from "../../../Components/Atoms/Title/Title";
import BoxCard from "../../../Components/Organisms/Cards/BoxCard/BoxCard";

function MyBoxesTemplate(props) {
  const { t, boxes } = props;
  return (
    <div>
      <Title text={t("Profile.MyBoxes")} />
      <Grid container>
        {boxes?.map((box) => {
          return (
            <Grid item xs={12} md={6}>
              <BoxCard
                title={box?.attributes?.name}
                quantity={box?.relationships.product_boxes.data.length}
                variant={"box"}
                id={box.id}
                handleDeleteBox={() => props.handleDeleteBox(box.id)}
              />
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
}

export default withTranslation()(MyBoxesTemplate);
