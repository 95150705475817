import { CircularProgress, Divider, Grid, Typography } from "@material-ui/core";
import ChatList from "../../../../Components/Organisms/Chat/ChatList";
import "./Messenger.css";
import ChatBox from "../../../../Components/Organisms/Chat/ChatBox";

const MessengerTemplate = (props) => {
    const { chatRooms, updateMessages, messagesList, sendMessage, setMessage, message, handleFileInput, updateListWithSearch } = props;

    return (
        <Grid container className="messenger-container">
            <Grid item xs>
                <ChatList
                    chatRooms={chatRooms}
                    updateMessages={updateMessages}
                    updateListWithSearch={updateListWithSearch}
                />
            </Grid>
            <Divider orientation="vertical" flexItem />
            <Grid item xs>
                {localStorage.getItem("Messages_node") ? (
                    <ChatBox
                        messagesList={messagesList}
                        sendMessage={sendMessage}
                        setMessage={setMessage}
                        message={message}
                        handleFileInput={handleFileInput}
                    />
                ) : (
                    <Typography component="h1" style={{textAlign: "center", lineHeight: "80vh", color: "grey"}}>
                        Please Select Chat Room
                    </Typography>
                )}
            </Grid>
        </Grid>
    );
};

export default MessengerTemplate;