import { BASE, BASE_URL } from "./URLs";

import axios from "axios";
import eventEmitter from "../../utils/EventEmitter/Emitter";

// import { DeviceUUID } from "device-uuid";

export function axiosFn(
  method,
  url,
  recivedData,
  auth = true,
  contentType = "application/json"
) {
  let token;
  token = localStorage.getItem("TOKEN");
  let data = {
    ...recivedData,
  };
  return axios({
    method,
    url:
      url == "/auth/token/create" || url == "/system_configurations"
        ? BASE + url
        : BASE_URL + url,
    data,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": contentType,
      "Logged-As":
        localStorage.getItem("USER_TYPE") == "vendor" ? "vendor" : "customer",
      "accept-language": localStorage.getItem("lang") == "ar" ? "ar" : "en",
      // "Access-Control-Allow-Origin":"*",
    },
  });
}

export async function s3Fn(url, recivedData) {
  let instance = axios.create({
    onUploadProgress: (progressEvent) => {
      console.log(progressEvent);
      eventEmitter.emit(
        "UPLOAD_PROGRESS",
        Math.floor(100 * (progressEvent.loaded / progressEvent.total))
      );
    },
  });
  delete instance.defaults.headers.common;
  try {
    const response = await instance.put(url, recivedData);
    return response;
  } catch (error) {
    console.log({ error });
  }
}

export function handleError(err) {
  let errMsg = "Something went wrong!";

  // if (err.response) errMsg = err.response.data.message;
  if (err.response) errMsg = err.response.data.error;
  return {
    res: null,
    err: errMsg,
    status: err?.response?.status,
  };
}

export function defaultRes(res) {
  return {
    res: res.data.data,
    err: null,
  };
}
