import "./Auth.css";

import Form from "../../Components/Organisms/Forms/Form";
import { Grid } from "@material-ui/core";
import { Link } from "react-router-dom";
import React from "react";
import { images } from "../../Assets/images/IconHelper";
import { withTranslation } from "react-i18next";

const SignupTemplate = (props) => {
  const { t } = props;
  return (
    <Grid container spacing={0}>
      <Grid item md={6}>
        {/* <img
          alt="register image"
          src={images.registrationImage}
          height="1024px"
          width="100%"
        /> */}
        <img
          alt="register image"
          src={
            props.systemConfig
              ? props.systemConfig["System Configurations"]["Signup Image"]
              : ""
          }
          height="1024px"
          width="100%"
        />
      </Grid>
      <Grid item md={6} className="Auth_container">
        <div>
          <img
            alt="logo"
            src={
              props.systemConfig
                ? props.systemConfig["System Configurations"]["Logo"]
                : ""
            }
            width="200px"
            height=""
          />
        </div>
        <div>
          <h1>{t("loginBtn.Signup")}</h1>
          <h3>{t("loginBtn.createAccount")}</h3>
        </div>
        <div>
          <Form
            from={t("loginBtn.Signup")}
            name={t("loginBtn.Name")}
            password={t("loginBtn.Password")}
            email={t("loginBtn.Email")}
            confirm={t("loginBtn.ConfirmPassword")}
            error={props.error}
            onHandle={props.register}
            clicked={props.clicked}
          />
        </div>
        <Grid container className="Auth_account">
          <Grid xs={7} item style={{ textAlign: "end" }}>
            <p className="">{t("loginBtn.haveaccount")}</p>
          </Grid>
          <Grid item xs={2} style={{ textAlign: "start" }}>
            <Link className="" to="/login">
              {t("loginBtn.Signin")}
            </Link>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default withTranslation()(SignupTemplate);
